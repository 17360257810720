import {
	EndPlateType,
	MediumSource,
	NumberOfPlateNozzles,
} from '../../model/CalculationEnums'
import {designPressureLabels, mediumLabels} from '../../model/enumLabels'

export const getMediumName = ({
	mediumSource,
	mediumName,
	mediumNameOtherMedia,
	concentration,
}) => {
	if (mediumSource === MediumSource.Imported) {
		return mediumNameOtherMedia
	}

	if (mediumSource === MediumSource.Brine) {
		return `${mediumLabels[mediumName]} ${concentration}%`
	}

	return mediumLabels[mediumName]
}

export const getNumberOfPlateNozzle = (
	numberOfPlateNozzles: NumberOfPlateNozzles,
) => {
	switch (numberOfPlateNozzles) {
		case NumberOfPlateNozzles.differentEnds:
		case NumberOfPlateNozzles.oneEnd:
			return 1
		case NumberOfPlateNozzles.bothEnds:
			return 2
	}
}

export const getDesignPressureUnit = (designPressure: number) => {
	return designPressureLabels[designPressure].split(' ')[1]
}

export const getShellMaterial = (
	endPlateType: EndPlateType,
	shellMaterialText: string,
) => {
	if (
		endPlateType == EndPlateType.OpenableDouble ||
		endPlateType == EndPlateType.OpenableGirthFlange ||
		endPlateType == EndPlateType.OpenableGirthFlangeDouble ||
		endPlateType == EndPlateType.OpenableVersion
	) {
		return `${shellMaterialText} (Flange and Openable End: Carbon steel)`
	} else {
		return shellMaterialText
	}
}
