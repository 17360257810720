import React from 'react'
import {Form, FormInstance} from 'antd'
import InputNumberUnderline from '../InputNumberUnderline'
import TemperatureUnit from '../SharedFormItem/TemperatureUnit'
import {usePrevious, useConvertCommaToDot} from '../../utils/hookUtils'
import {convertTemperature} from '../../utils/utils'

interface Props {
	form: FormInstance
	isRequired?: boolean
}

const InletTemInput: React.FunctionComponent<Props> = ({form, isRequired}) => {
	const {getFieldValue, setFieldsValue} = form
	const cboT = getFieldValue('cboT')
	const gasInletTemperature = getFieldValue('gasInletTemperature')
	const inletTemperatureLiquid = getFieldValue('inletTemperatureLiquid')
	const prevCboT = usePrevious(cboT)

	React.useEffect(() => {
		const gas = useConvertCommaToDot(gasInletTemperature)
		setFieldsValue({gasInletTemperature: gas})
		const liquid = useConvertCommaToDot(inletTemperatureLiquid)
		setFieldsValue({inletTemperatureLiquid: liquid})
	}, [gasInletTemperature, inletTemperatureLiquid])

	React.useEffect(() => {
		setFieldsValue({
			gasInletTemperature: convertTemperature(
				gasInletTemperature,
				prevCboT,
				cboT,
			),
		})
		setFieldsValue({
			inletTemperatureLiquid: convertTemperature(
				inletTemperatureLiquid,
				prevCboT,
				cboT,
			),
		})
	}, [cboT, setFieldsValue, prevCboT])

	return (
		<Form.Item label="Inlet. Temp">
			<Form.Item
				name={['gasInletTemperature']}
				style={{display: 'inline-block'}}
				rules={[{required: isRequired == undefined ? true : isRequired}]}
			>
				<InputNumberUnderline state="hot" />
			</Form.Item>
			<Form.Item
				name={['inletTemperatureLiquid']}
				style={{display: 'inline-block'}}
			>
				<InputNumberUnderline state="cold" />
			</Form.Item>
			<TemperatureUnit />
		</Form.Item>
	)
}

export default InletTemInput
