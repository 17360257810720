import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {RecoilRoot} from 'recoil'
import './styles/index.scss'
import {BrowserRouter} from 'react-router-dom'
import {ReactQueryDevtools} from 'react-query-devtools'
import {Auth0Provider} from '@auth0/auth0-react'
import {AUTH_CONFIG} from './utils/apiClient'

const app = (
	<RecoilRoot>
		<BrowserRouter>
			<ReactQueryDevtools initialIsOpen />
			<Auth0Provider
				domain={AUTH_CONFIG.domain}
				clientId={AUTH_CONFIG.client_id}
				redirectUri={AUTH_CONFIG.redirectUri}
				audience={AUTH_CONFIG.audience}
				scope={AUTH_CONFIG.scope}
				cacheLocation="localstorage"
			>
				<App />
			</Auth0Provider>
		</BrowserRouter>
	</RecoilRoot>
)

ReactDOM.render(app, document.getElementById('root'))
