import React from 'react'
import PDFPage from './PDFPage'
import {translateIfKeyExist} from '../../utils/offerInfo'
import type {OfferInfoPreviewItem} from '../../model/Offer'
import {camelCaseToSentenceCase, toCamelCase} from '../../utils/utils'

interface Props {
	pdfId: string
	offerInfo: OfferInfoPreviewItem[]
}

const PartialOfferDetailPDF: React.FunctionComponent<Props> = ({
	pdfId,
	offerInfo,
}) => {
	return (
		<PDFPage pdfId={pdfId} marginTop="20px">
			<div className="offer-detail-pdf">
				<table className="offer-info-table">
					<colgroup>
						<col span={1} style={{width: '20%'}} />
						<col span={4} style={{width: '100%'}} />
					</colgroup>
					<tbody>
						<OfferInfoPreviewContent items={offerInfo} />
					</tbody>
				</table>
			</div>
		</PDFPage>
	)
}

interface OfferInfoPreviewContentProps {
	items: OfferInfoPreviewItem[]
	lastElemIndex?: number
}

export const OfferInfoPreviewContent: React.FunctionComponent<
	OfferInfoPreviewContentProps
> = ({items, lastElemIndex}) => (
	<>
		{items.map(
			({name, value, title}, index) =>
				value && (
					<tr
						key={index}
						className={lastElemIndex && index > lastElemIndex ? 'hide' : ''}
					>
						{toCamelCase(name) === 'offerNote' ? (
							<td className="on-pdf on-pdf--offer-info" colSpan={2}>
								<pre>{value}</pre>
							</td>
						) : (
							<>
								<td className="on-pdf on-pdf--offer-info">
									{translateIfKeyExist(
										title ? `offer.info.${title}` : `offer.info.${name}`,
										title ? title : camelCaseToSentenceCase(name),
									)}
								</td>
								<td>
									{Array.isArray(value) ? (
										<>
											{value.map((item, index) => (
												<p key={index}>- {item}</p>
											))}
										</> // render as list for array data e.g. documents
									) : value.indexOf('\n') > -1 ? (
										<pre>
											<div style={{maxWidth: '1100px'}}>{value}</div>
										</pre> // render as pre for text area input which has extra bottom spacing
									) : (
										<>
											<div style={{maxWidth: '1100px'}}>{value}</div>
										</>
									)}
								</td>
							</>
						)}
					</tr>
				),
		)}
	</>
)

export default PartialOfferDetailPDF
