import {currencyLabels} from '../model/enumLabels'
import {
	CurrencyCode,
	UnitAreaOption,
	UnitCapacityOption,
	UnitDynamicViscosityOption,
	UnitEntalphyOption,
	UnitFoulingFactorOption,
	UnitKValueOption,
	UnitMassFlowOption,
	UnitMassOption,
	UnitPressureOption,
	UnitSpecificGravityOption,
	UnitSpecificHeatOption,
	UnitSurfaceTensionOption,
	UnitTemperatureLMTDOption,
	UnitThermalConductivityOption,
	UnitVelocityOption,
	UnitVolumeOption,
} from '../model/CalculationEnums'
import {format} from 'date-fns'
import {enUS, zhCN, da, de, es, fi, fr, hu, nl, pt, ru} from 'date-fns/locale'
import i18next from 'i18next'

export const isNullOrUndefined = obj => obj === null || obj === undefined

export const isInfinitiveNumber = (value: number) => value === 1e35

export const enumToValues = (enumObject, isNumberEnum?: boolean) => {
	return Object.keys(enumObject)
		.map((k: string) => enumObject[k])
		.filter(val => typeof val === (!isNumberEnum ? 'string' : 'number'))
}

export const enumToKeys = enumObject => {
	return Object.keys(enumObject)
		.map((k: string) => enumObject[k])
		.filter(val => isNaN(val))
}

export const formatPrice = (
	price: number,
	currencyRate: number,
	currencyCode: CurrencyCode,
	minimumFractionDigits = 2,
	maximumFractionDigits = 2,
) => {
	const exchangedPrice = price * currencyRate
	return exchangedPrice
		.toLocaleString('fi-FI', {
			style: 'currency',
			currency: currencyLabels[currencyCode],
			minimumFractionDigits: minimumFractionDigits,
			maximumFractionDigits: maximumFractionDigits,
		})
		.replace(',', '.')
}

export const toObject = (names, values) => {
	const result = {}
	for (let i = 0; i < names.length; i++) result[names[i]] = values[i]
	return result
}

export const removeEmptyProperty = obj => {
	Object.keys(obj).forEach(
		k => !obj[k] && obj[k] !== undefined && delete obj[k],
	)
	return obj
}

export const uuid4 = (): string => {
	return Math.random().toString(36).substr(2, 9)
}

export const precise = (x, precision?): string => {
	if (!x || isNaN(x) || Number.isInteger(x)) {
		return x
	}

	const precisionValue = precision || 5

	return Number.parseFloat(x).toPrecision(precisionValue)
}

export const toFixed = (value, fractionDigits = 1) => {
	if (!value || isNaN(value) || Number.isInteger(value)) {
		return value
	}

	const isNegative = value < 0

	let x = isNegative ? value * -1 : value

	if (x < 0.1) {
		fractionDigits = 3
	}

	if (x < 0.01) {
		fractionDigits = 4
	}

	if (x < 0.001) {
		fractionDigits = 5
	}

	if (x < 0.0001) {
		fractionDigits = 6
	}

	if (x < 0.00001) {
		fractionDigits = 7
	}

	if (x < 0.000001) {
		fractionDigits = 8
	}

	if (x < 0.0000001) {
		fractionDigits = 9
	}

	if (isNegative) {
		x = x * -1
	}
	return Number.parseFloat(x).toFixed(fractionDigits)
}
const locales = {
	en: enUS,
	cn: zhCN,
	da: da,
	de: de,
	es: es,
	fi: fi,
	fr: fr,
	hu: hu,
	nl: nl,
	pt: pt,
	ru: ru,
}

export const formatDate = (date: Date, abbreviated: boolean) => {
	return abbreviated
		? date.toLocaleDateString()
		: format(date, 'MMMM dd, yyyy', {locale: locales[i18next.language]})
}

//=================================================
// Unit conversion
//=================================================
export const convertPressure = (
	value: string,
	fromUnit: UnitPressureOption,
	toUnit: UnitPressureOption,
	isOutput = false,
): number | string => {
	let convertedValue = parseFloat(value)
	if (isNaN(convertedValue)) {
		return ''
	}
	// Unify all units to kPa first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitPressureOption.MPa:
				convertedValue *= 1 / 0.001
				break
			case UnitPressureOption.bar:
				convertedValue *= 1 / 0.01
				break
			case UnitPressureOption.mmHg:
				convertedValue *= 1 / 7.499080882
				break
			case UnitPressureOption.atm:
				convertedValue *= 1 / 0.009866728
				break
			case UnitPressureOption.Torr:
				convertedValue *= 1 / 7.499080882
				break
			case UnitPressureOption.Psi:
				convertedValue *= 1 / 0.144990809
				break
			case UnitPressureOption.lbin2:
				convertedValue *= 1 / 0.144990809
				break
			case UnitPressureOption.lbft2:
				convertedValue *= 1 / 208.8097426
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitPressureOption.MPa:
				convertedValue *= 0.001
				break
			case UnitPressureOption.bar:
				convertedValue *= 0.01
				break
			case UnitPressureOption.mmHg:
				convertedValue *= 7.499080882
				break
			case UnitPressureOption.atm:
				convertedValue *= 0.009866728
				break
			case UnitPressureOption.Torr:
				convertedValue *= 7.499080882
				break
			case UnitPressureOption.Psi:
				convertedValue *= 0.144990809
				break
			case UnitPressureOption.lbin2:
				convertedValue *= 0.144990809
				break
			case UnitPressureOption.lbft2:
				convertedValue *= 208.8097426
				break
		}
	}

	return parseFloat(
		isOutput ? precise(convertedValue.toFixed(10)) : convertedValue.toFixed(10),
	)
}

export const convertCapacity = (
	value: string,
	fromUnit: UnitCapacityOption,
	toUnit: UnitCapacityOption,
): number => {
	let convertedValue = parseFloat(value)

	// Unify all units to Kw first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitCapacityOption.Btus:
				convertedValue *= 1 / 0.947817
				break
			case UnitCapacityOption.kBtuh:
				convertedValue *= 1 / 3.412142
				break
			case UnitCapacityOption.ftlbfh:
				convertedValue *= 1 / 2655223.737
				break
			case UnitCapacityOption.ftlbfmin:
				convertedValue *= 1 / 44253.72896
				break
			case UnitCapacityOption.kcalh:
				convertedValue *= 1 / 860.42065
				break
			case UnitCapacityOption.cals:
				convertedValue *= 1 / 239
				break
			case UnitCapacityOption.RefTon:
				convertedValue *= 1 / 0.2843
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitCapacityOption.Btus:
				convertedValue *= 0.947817
				break
			case UnitCapacityOption.kBtuh:
				convertedValue *= 3.412142
				break
			case UnitCapacityOption.ftlbfh:
				convertedValue *= 2655223.737
				break
			case UnitCapacityOption.ftlbfmin:
				convertedValue *= 44253.72896
				break
			case UnitCapacityOption.kcalh:
				convertedValue *= 860.42065
				break
			case UnitCapacityOption.cals:
				convertedValue *= 239
				break
			case UnitCapacityOption.RefTon:
				convertedValue *= 0.2843
				break
		}
	}

	return parseFloat(convertedValue.toFixed(10))
}

export const convertTemperature = (
	value: string,
	fromUnit: UnitTemperatureLMTDOption,
	toUnit: UnitTemperatureLMTDOption,
	isOutput = false,
): number | string => {
	let convertedValue = parseFloat(value)
	if (isNaN(convertedValue)) {
		return ''
	}
	// Unify all units to C first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitTemperatureLMTDOption.F:
				convertedValue = (convertedValue - 32) * (5 / 9)
				break
			case UnitTemperatureLMTDOption.R:
				convertedValue = (convertedValue - 491.67) * (5 / 9)
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitTemperatureLMTDOption.F:
				convertedValue = convertedValue * (9 / 5) + 32
				break
			case UnitTemperatureLMTDOption.R:
				convertedValue = convertedValue * (9 / 5) + 491.67
				break
		}
	}

	return parseFloat(
		isOutput ? precise(convertedValue.toFixed(10)) : convertedValue.toFixed(10),
	)
}

export const convertVelocity = (
	value: number,
	fromUnit: UnitVelocityOption,
	toUnit: UnitVelocityOption,
): number => {
	let convertedValue = value

	if (!convertedValue || isNaN(value) || value.toString() === '0.') {
		return value
	}

	// Unify all units to m/s first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitVelocityOption.fts:
				convertedValue *= 1 / 3.280839895
				break
			case UnitVelocityOption.ftmin:
				convertedValue *= 1 / 196.8503937
				break
			case UnitVelocityOption.ins:
				convertedValue *= 1 / 39.37007874
				break
			case UnitVelocityOption.inmin:
				convertedValue *= 1 / 2362
				break
			case UnitVelocityOption.yds:
				convertedValue *= 1 / 1.093613
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitVelocityOption.fts:
				convertedValue *= 3.280839895
				break
			case UnitVelocityOption.ftmin:
				convertedValue *= 196.8503937
				break
			case UnitVelocityOption.ins:
				convertedValue *= 39.37007874
				break
			case UnitVelocityOption.inmin:
				convertedValue *= 2362
				break
			case UnitVelocityOption.yds:
				convertedValue *= 1.093613
				break
		}
	}

	return parseFloat(convertedValue.toFixed(10))
}

export const convertArea = (
	value: string,
	fromUnit: UnitAreaOption,
	toUnit: UnitAreaOption,
	isOutput = false,
): number | string => {
	let convertedValue = parseFloat(value)

	if (
		!value ||
		// eslint-disable-next-line no-useless-escape
		(convertedValue === 0 && value.match(/[,\.]/g))
	) {
		return value
	}

	// Unify all units to m2 first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitAreaOption.in2:
				convertedValue *= 1 / 1550.0031
				break
			case UnitAreaOption.ft2:
				convertedValue *= 1 / 10.76391042
				break
			case UnitAreaOption.yd2:
				convertedValue *= 1 / 1.195990046
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitAreaOption.in2:
				convertedValue *= 1550.0031
				break
			case UnitAreaOption.ft2:
				convertedValue *= 10.76391042
				break
			case UnitAreaOption.yd2:
				convertedValue *= 1.195990046
				break
		}
	}

	return parseFloat(
		isOutput ? precise(convertedValue.toFixed(10)) : convertedValue.toFixed(10),
	)
}

export const convertMassFlow = (
	value: string,
	fromUnit: UnitMassFlowOption,
	toUnit: UnitMassFlowOption,
	isOutput = false,
): number | string => {
	let convertedValue = parseFloat(value)

	// Unify all units to kg/s first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitMassFlowOption.lbs:
				convertedValue *= 1 / 2.204622622
				break
			case UnitMassFlowOption.kgh:
				convertedValue *= 1 / 3600
				break
			case UnitMassFlowOption.lbh:
				convertedValue *= 1 / 7936.641439
				break
			case UnitMassFlowOption.US_tons:
				convertedValue *= 1 / 0.001102311
				break
			case UnitMassFlowOption.UK_tons:
				convertedValue *= 1 / 0.000984207
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitMassFlowOption.lbs:
				convertedValue *= 2.204622622
				break
			case UnitMassFlowOption.kgh:
				convertedValue *= 3600
				break
			case UnitMassFlowOption.lbh:
				convertedValue *= 7936.641439
				break
			case UnitMassFlowOption.US_tons:
				convertedValue *= 0.001102311
				break
			case UnitMassFlowOption.UK_tons:
				convertedValue *= 0.000984207
				break
		}
	}

	return parseFloat(
		isOutput ? precise(convertedValue.toFixed(10)) : convertedValue.toFixed(10),
	)
}

export const convertKValue = (
	value: string,
	fromUnit: UnitKValueOption,
	toUnit: UnitKValueOption,
	isOutput = false,
): number | string => {
	let convertedValue = parseFloat(value)

	if (
		!value ||
		// eslint-disable-next-line no-useless-escape
		(convertedValue === 0 && value.match(/[,\.]/g))
	) {
		return value
	}

	// Unify all units to W/m2K first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitKValueOption.Btuhft2F:
				convertedValue *= 1 / 0.176110184
				break
			case UnitKValueOption.Btusft2F:
				convertedValue *= 1 / 0.0000489195
				break
			case UnitKValueOption.kcalhm2C:
				convertedValue *= 1 / 0.86042065
				break
			case UnitKValueOption.calscm2C:
				convertedValue *= 1 / 0.0000239006
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitKValueOption.Btuhft2F:
				convertedValue *= 0.176110184
				break
			case UnitKValueOption.Btusft2F:
				convertedValue *= 0.0000489195
				break
			case UnitKValueOption.kcalhm2C:
				convertedValue *= 0.86042065
				break
			case UnitKValueOption.calscm2C:
				convertedValue *= 0.0000239006
				break
		}
	}

	return parseFloat(
		isOutput ? precise(convertedValue.toFixed(10)) : convertedValue.toFixed(10),
	)
}

export const convertFoulingFactor = (
	value: string,
	fromUnit: UnitFoulingFactorOption,
	toUnit: UnitFoulingFactorOption,
	isOutput = false,
): number | string => {
	let convertedValue = parseFloat(value)
	if (isNaN(convertedValue)) {
		return ''
	}

	// Unify all units to m2K/W first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitFoulingFactorOption.hft2FBtu:
				convertedValue *= 1 / 5.678263331
				break
			case UnitFoulingFactorOption.sft2FBtu:
				convertedValue *= 1 / 20441.74801
				break
			case UnitFoulingFactorOption.hm2Ckcal:
				convertedValue *= 1 / 1.162222222
				break
			case UnitFoulingFactorOption.scm2Ccal:
				convertedValue *= 1 / 41840.00002
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitFoulingFactorOption.hft2FBtu:
				convertedValue *= 5.678263331
				break
			case UnitFoulingFactorOption.sft2FBtu:
				convertedValue *= 20441.74801
				break
			case UnitFoulingFactorOption.hm2Ckcal:
				convertedValue *= 1.162222222
				break
			case UnitFoulingFactorOption.scm2Ccal:
				convertedValue *= 41840.00002
				break
		}
	}

	return parseFloat(
		isOutput ? precise(convertedValue.toFixed(10)) : convertedValue.toFixed(10),
	)
}

export const convertDynamicVisco = (
	value: string,
	fromUnit: UnitDynamicViscosityOption,
	toUnit: UnitDynamicViscosityOption,
): number | string => {
	let convertedValue = parseFloat(value)

	// Unify all units to m2K/W first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitDynamicViscosityOption.Poise:
				convertedValue *= 1 / 10
				break
			case UnitDynamicViscosityOption.cP:
				convertedValue *= 1 / 1000
				break
			case UnitDynamicViscosityOption.MPas:
				convertedValue *= 1 / 1000
				break
			case UnitDynamicViscosityOption.lbf_s_in2:
				convertedValue *= 1 / 0.000145038
				break
			case UnitDynamicViscosityOption.lbf_s_ft2:
				convertedValue *= 1 / 0.020885434
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitDynamicViscosityOption.Poise:
				convertedValue *= 10
				break
			case UnitDynamicViscosityOption.cP:
				convertedValue *= 1000
				break
			case UnitDynamicViscosityOption.MPas:
				convertedValue *= 1000
				break
			case UnitDynamicViscosityOption.lbf_s_in2:
				convertedValue *= 0.000145038
				break
			case UnitDynamicViscosityOption.lbf_s_ft2:
				convertedValue *= 0.020885434
				break
		}
	}

	return parseFloat(convertedValue.toFixed(10))
}

export const convertSpecificGravity = (
	value: string,
	fromUnit: UnitSpecificGravityOption,
	toUnit: UnitSpecificGravityOption,
): number | string => {
	let convertedValue = parseFloat(value)

	// Unify all units to kg/m3 first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitSpecificGravityOption.gcm3:
				convertedValue *= 1 / 0.001
				break
			case UnitSpecificGravityOption.ozin3:
				convertedValue *= 1 / 0.000578
				break
			case UnitSpecificGravityOption.ozgal_UK:
				convertedValue *= 1 / 0.1604
				break
			case UnitSpecificGravityOption.ozgal_US:
				convertedValue *= 1 / 0.1335
				break
			case UnitSpecificGravityOption.lbgal_UK:
				convertedValue *= 1 / 0.01002
				break
			case UnitSpecificGravityOption.lbgal_US:
				convertedValue *= 1 / 0.008345
				break
			case UnitSpecificGravityOption.lbin3:
				convertedValue *= 1 / 0.0000361
				break
			case UnitSpecificGravityOption.lbft3:
				convertedValue *= 1 / 0.06243
				break
			case UnitSpecificGravityOption.lbyd3:
				convertedValue *= 1 / 1.6856
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitSpecificGravityOption.gcm3:
				convertedValue *= 0.001
				break
			case UnitSpecificGravityOption.ozin3:
				convertedValue *= 0.000578
				break
			case UnitSpecificGravityOption.ozgal_UK:
				convertedValue *= 0.1604
				break
			case UnitSpecificGravityOption.ozgal_US:
				convertedValue *= 0.1335
				break
			case UnitSpecificGravityOption.lbgal_UK:
				convertedValue *= 0.01002
				break
			case UnitSpecificGravityOption.lbgal_US:
				convertedValue *= 0.008345
				break
			case UnitSpecificGravityOption.lbin3:
				convertedValue *= 0.0000361
				break
			case UnitSpecificGravityOption.lbft3:
				convertedValue *= 0.06243
				break
			case UnitSpecificGravityOption.lbyd3:
				convertedValue *= 1.6856
				break
		}
	}

	return parseFloat(convertedValue.toFixed(10))
}

export const convertSpecificHeat = (
	value: string,
	fromUnit: UnitSpecificHeatOption,
	toUnit: UnitSpecificHeatOption,
): number | string => {
	let convertedValue = parseFloat(value)

	// Unify all units to J/kgK first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitSpecificHeatOption.calgC:
				convertedValue *= 1 / 0.000239006
				break
			case UnitSpecificHeatOption.kcalkgC:
				convertedValue *= 1 / 0.000239006
				break
			case UnitSpecificHeatOption.btylbF:
				convertedValue *= 1 / 0.000238846
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitSpecificHeatOption.calgC:
				convertedValue *= 0.000239006
				break
			case UnitSpecificHeatOption.kcalkgC:
				convertedValue *= 0.000239006
				break
			case UnitSpecificHeatOption.btylbF:
				convertedValue *= 0.000238846
				break
		}
	}

	return parseFloat(convertedValue.toFixed(10))
}

export const convertThermalConductivity = (
	value: string,
	fromUnit: UnitThermalConductivityOption,
	toUnit: UnitThermalConductivityOption,
): number | string => {
	let convertedValue = parseFloat(value)

	// Unify all units to W/mK first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitThermalConductivityOption.Btu_ins_ft2_F:
				convertedValue *= 1 / 0.001927
				break
			case UnitThermalConductivityOption.Btu_inh_ft2_F:
				convertedValue *= 1 / 6.9381
				break
			case UnitThermalConductivityOption.Btu_fth_ft2_F:
				convertedValue *= 1 / 0.5782
				break
			case UnitThermalConductivityOption.cals_cm_C:
				convertedValue *= 1 / 0.002388
				break
			case UnitThermalConductivityOption.kcalh_m_C:
				convertedValue *= 1 / 0.8598
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitThermalConductivityOption.Btu_ins_ft2_F:
				convertedValue *= 0.001927
				break
			case UnitThermalConductivityOption.Btu_inh_ft2_F:
				convertedValue *= 6.9381
				break
			case UnitThermalConductivityOption.Btu_fth_ft2_F:
				convertedValue *= 0.5782
				break
			case UnitThermalConductivityOption.cals_cm_C:
				convertedValue *= 0.002388
				break
			case UnitThermalConductivityOption.kcalh_m_C:
				convertedValue *= 0.8598
				break
		}
	}

	return parseFloat(convertedValue.toFixed(10))
}

export const convertMass = (
	value: number,
	fromUnit: UnitMassOption,
	toUnit: UnitMassOption,
): number => {
	let convertedValue = value

	if (!convertedValue || isNaN(value) || value.toString() === '0.') {
		return value
	}

	// Unify all units to kg first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitMassOption.lb:
				convertedValue *= 1 / 2.205
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitMassOption.lb:
				convertedValue *= 2.205
				break
		}
	}

	return parseFloat(convertedValue.toFixed(10))
}

export const convertVolume = (
	value: number,
	fromUnit: UnitVolumeOption,
	toUnit: UnitVolumeOption,
): number => {
	let convertedValue = value

	if (!convertedValue || isNaN(value) || value.toString() === '0.') {
		return value
	}

	// Unify all units to m3 first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitVolumeOption.dm:
				convertedValue *= 1 / 1000
				break
			case UnitVolumeOption.USgal:
				convertedValue *= 0.003785411784
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitVolumeOption.dm:
				convertedValue *= 1000
				break
			case UnitVolumeOption.USgal:
				convertedValue *= 1 / 0.003785411784
				break
		}
	}

	return parseFloat(convertedValue.toFixed(10))
}

export const convertSurfaceTension = (
	value: number,
	fromUnit: UnitSurfaceTensionOption,
	toUnit: UnitSurfaceTensionOption,
): number => {
	let convertedValue = value

	if (!convertedValue || isNaN(value) || value.toString() === '0.') {
		return value
	}

	// Unify all units to N/m first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitSurfaceTensionOption.mNm:
				convertedValue *= 1 / 1000
				break
			case UnitSurfaceTensionOption.lbfft:
				convertedValue *= 1 / 0.068521766
				break
			case UnitSurfaceTensionOption.lbfin:
				convertedValue *= 1 / 0.005710147
				break
			case UnitSurfaceTensionOption.ergcm2:
				convertedValue *= 1 / 1000
				break
			case UnitSurfaceTensionOption.kgfm:
				convertedValue *= 1 / 0.10197162
				break
			case UnitSurfaceTensionOption.dynecm:
				convertedValue *= 1 / 1000
				break
			case UnitSurfaceTensionOption.mjcm2:
				convertedValue *= 1 / 0.1
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitSurfaceTensionOption.mNm:
				convertedValue *= 1000
				break
			case UnitSurfaceTensionOption.lbfft:
				convertedValue *= 0.068521766
				break
			case UnitSurfaceTensionOption.lbfin:
				convertedValue *= 0.005710147
				break
			case UnitSurfaceTensionOption.ergcm2:
				convertedValue *= 1000
				break
			case UnitSurfaceTensionOption.kgfm:
				convertedValue *= 0.10197162
				break
			case UnitSurfaceTensionOption.dynecm:
				convertedValue *= 1000
				break
			case UnitSurfaceTensionOption.mjcm2:
				convertedValue *= 0.1
				break
		}
	}

	return convertedValue
}

export const convertEthalpy = (
	value: number,
	fromUnit: UnitEntalphyOption,
	toUnit: UnitEntalphyOption,
): number => {
	let convertedValue = value

	if (!convertedValue || isNaN(value) || value.toString() === '0.') {
		return value
	}

	// Unify all units to J/kg first
	if (fromUnit !== toUnit) {
		switch (fromUnit) {
			case UnitEntalphyOption.kJkg:
				convertedValue *= 1000
				break
			case UnitEntalphyOption.Btulb:
				convertedValue *= 1000 / 0.4298
				break
			case UnitEntalphyOption.Btukg:
				convertedValue *= 1000 / 0.0009478
				break
			case UnitEntalphyOption.ftlbflb:
				convertedValue *= 1000 / 334.5
				break
			case UnitEntalphyOption.kcallb:
				convertedValue *= 1000 / 0.1084
				break
			case UnitEntalphyOption.calkg:
				convertedValue *= 1000 / 239
				break
		}

		// Output desired unit.
		switch (toUnit) {
			case UnitEntalphyOption.kJkg:
				convertedValue *= 1 / 1000
				break
			case UnitEntalphyOption.Btulb:
				convertedValue *= 0.4298 / 1000
				break
			case UnitEntalphyOption.Btukg:
				convertedValue *= 0.0009478 / 1000
				break
			case UnitEntalphyOption.ftlbflb:
				convertedValue *= 334.5 / 1000
				break
			case UnitEntalphyOption.kcallb:
				convertedValue *= 0.1084 / 1000
				break
			case UnitEntalphyOption.calkg:
				convertedValue *= 239 / 1000
				break
		}
	}

	return convertedValue
}

export const removeMultipleFieldNameSpacing = (input: string): string => {
	return input.trim().replace(/[-_\s][-_\s]+/g, ' ')
}

export const toCamelCase = (input: string): string => {
	const processing = removeMultipleFieldNameSpacing(input).replace(
		/[-_\s]./g,
		word => word[1].toUpperCase(),
	)

	return processing.charAt(0).toLowerCase() + processing.slice(1)
}

export const camelCaseToSentenceCase = (input: string): string => {
	const processing = input.replace(/[A-Z]/g, match => ` ${match.toLowerCase()}`)
	return processing.charAt(0).toUpperCase() + processing.slice(1)
}

export const decimalCount = num => {
	// Convert to String
	const numStr = String(num)
	// String Contains Decimal
	if (numStr.includes('.')) {
		return numStr.split('.')[1].length
	}
	// String Does Not Contain Decimal
	return 0
}
