import React from 'react'
import DataSheet from '../components/DataSheet/DataSheet'
import ExportDatasheetToExcel from '../components/DataSheet/ExportDatasheetToExcel'
import {useOffer} from '../state/offers'
import {useHistory, useParams} from 'react-router-dom'
import {LoadingOutlined} from '@ant-design/icons'
import {useMfiles} from '../state/mFiles'
import {usePricingCalculations} from '../state/calculations'
import {usePositions} from '../state/position'
import FullPageLoading from '../components/FullPageLoading'
import PdfGenerateButton from '../components/PdfGenerateButton'
import OfferDetailPDF from '../components/DataSheet/OfferDetailPDF'
import Button from '../components/Button'
import queryString from 'query-string'
import {Drawing, Calculation} from '../model/Calculation'
import {Checkbox, Dropdown, Menu} from 'antd'
import {useAuth0} from '@auth0/auth0-react'
import {
	ApplicationType,
	CalculationState,
	DesignClassification,
	DrawingState,
} from '../model/CalculationEnums'
import PartialOfferDetailPDF from '../components/DataSheet/PartialOfferDetailPDF'
import {
	A4HeightInPixels,
	PdfOfferInfoMaxHeightInPixel,
} from '../utils/constants'
import {fillOfferInfoDefaultValues} from '../utils/offerInfo'
import {ExportDrawingButton} from '../components/ExportDrawingButton'
// import ExportDrawingMultipleSelect from '../components/ExportDrawingMultipleSelect'

interface RouteParam {
	offerId?: string
}

const ExportPreview = () => {
	const {offerId} = useParams<RouteParam>()
	const {user} = useAuth0()
	const offerData = useOffer(+offerId)
	const [offerDetailPages, setOfferDetailPages] = React.useState([0])
	const [trackOfferElems, setTrackOfferElems] = React.useState([])
	const [secondPageElemIndex, setSecondPageElemIndex] = React.useState(0)
	const [numOfElemsBeforeOfferInfo, setNumOfElemsBeforeOfferInfo] =
		React.useState(0)

	const {
		data: offerDetail,
		isLoading: isOfferDetailLoading,
		isSuccess: isOfferDetailSuccess,
	} = offerData

	const {
		data: mFiles,
		isLoading: isMFileLoading,
		isSuccess: isMFileSuccess,
	} = useMfiles(offerDetail, user['http://vahterus/roles'][0], {
		enabled: !!offerDetail,
	})

	const positionsData = usePositions(+offerId, {
		query: {isHeatExchangerIncluded: true, onlySuccessfulCalculations: true},
	})
	const {data: positions} = positionsData

	const history = useHistory()
	const {calculationId: pricingCalculationIds} = queryString.parse(
		history.location.search,
	)

	const [previewExtra, setPreviewExtra] = React.useState(true)
	const [offerPageHeight, setOfferPageHeight] = React.useState(
		document.querySelector('#offer-pages')?.clientHeight,
	)
	// First, try to print all offer info fields to calculate how long the document is.
	// Then, break the doc into pages if the content exceeds one page

	// Must use ref callback instead of ref to ensure the calculation of elemens is latest
	// https://reactjs.org/docs/hooks-faq.html under "Measure DOM Node"
	const heightCalculationCallBack = React.useCallback(
		node => {
			if (node !== null) {
				const elems = document.querySelectorAll('.on-pdf')
				const offerInfoElems = document.querySelectorAll('.on-pdf--offer-info')
				const fixedElemsCount = elems.length - offerInfoElems.length
				setNumOfElemsBeforeOfferInfo(fixedElemsCount)

				let elemsInPageArr, sumOfHeights
				const elemsArr = []
				for (let i = 0; i < elems.length; i++) {
					elemsArr.push({elem: elems[i], height: elems[i].clientHeight})
				}

				// Array that track which element is in what page based on value, total height of elems in an object at the last index
				if (elemsArr.length > 0) {
					elemsInPageArr = elemsArr.reduce(
						(init, currentValue) => {
							let indexValue
							if (
								init[0].height + currentValue.height >=
								PdfOfferInfoMaxHeightInPixel
							) {
								indexValue = Math.floor(
									(init[0].height + currentValue.height) /
										PdfOfferInfoMaxHeightInPixel,
								)
							} else {
								indexValue = 0
							}
							init[0].height += currentValue.height
							init.push(indexValue)
							return init
						},
						[{height: 0}],
					)

					elemsInPageArr.push(elemsInPageArr.splice(0, 1)[0])
					sumOfHeights = elemsInPageArr[elemsInPageArr.length - 1].height
				}

				// in case the content exceeds one page
				if (
					offerPageHeight >= A4HeightInPixels ||
					sumOfHeights >= PdfOfferInfoMaxHeightInPixel
				) {
					const numberOfNewPages = Math.ceil(
						sumOfHeights / PdfOfferInfoMaxHeightInPixel,
					)
					const pages = Array.from(Array(numberOfNewPages).keys())
					setOfferDetailPages([...pages])
					setTrackOfferElems(elemsInPageArr)

					const secondPageStartElemIndex = elemsInPageArr.indexOf(1)
					if (secondPageStartElemIndex > -1) {
						setSecondPageElemIndex(
							secondPageStartElemIndex - fixedElemsCount - 1,
						)
					}
				} else {
					setOfferDetailPages([...offerDetailPages])
				}
			}
		},
		[offerPageHeight],
	)

	const {
		data: calculationsData,
		isLoading: isCalculationsLoading,
		isSuccess: isCalculationsSuccess,
	} = usePricingCalculations({
		query: {
			isHeatExchangerIncluded: false,
			calculationId: pricingCalculationIds,
		},
		enabled: !!positions,
		refetchInterval: 2000,
	})

	const handlePreviewExtraChange = () => {
		setPreviewExtra(state => !state)
	}

	const renderDataSheets = () => {
		if (isCalculationsLoading || isOfferDetailLoading || isMFileLoading) {
			return <FullPageLoading />
		}

		if (isCalculationsSuccess && isOfferDetailSuccess && isMFileSuccess) {
			interface CalculationWithOrder extends Calculation {
				order: number
			}
			const calculationsDataWithOrder: CalculationWithOrder[] =
				calculationsData.map(data => {
					const mappedPosition = positions.filter(position => {
						const positionIds = position.calculations.flatMap(e => e.id)
						return positionIds.includes(data.id)
					})
					return {...data, order: mappedPosition[0].order}
				})
			calculationsDataWithOrder.sort((a, b) => a.order - b.order)
			let calculationPrintInfo = []
			calculationPrintInfo = calculationsDataWithOrder.map(
				(calculation, index) => ({
					htmlId: calculation.id.toString(),
					fileName: (index + 1).toString(),
				}),
			)
			let isContainPartialCascadeCalculation = false
			let extendedSheetPosition = 1
			calculationsDataWithOrder.forEach(calculation => {
				if (calculation.heatExchangerType === ApplicationType.PartialCascade) {
					isContainPartialCascadeCalculation = true
				}
				if (calculation.heatExchangerType === ApplicationType.MultiCondenser) {
					calculationPrintInfo.splice(extendedSheetPosition, 0, {
						htmlId: `calculation-export-MC-desuperheater-page-${calculation.id.toString()}`,
						fileName: `calculation-export-MC-desuperheater-page-${calculation.id.toString()}`,
					})
					calculationPrintInfo.splice(extendedSheetPosition + 1, 0, {
						htmlId: `calculation-export-MC-condenser-page-${calculation.id.toString()}`,
						fileName: `calculation-export-MC-condenser-page-${calculation.id.toString()}`,
					})
					calculationPrintInfo.splice(extendedSheetPosition + 2, 0, {
						htmlId: `calculation-export-MC-subcooler-page-${calculation.id.toString()}`,
						fileName: `calculation-export-MC-subcooler-page-${calculation.id.toString()}`,
					})
					extendedSheetPosition += 4
				}
				if (isContainPartialCascadeCalculation) {
					calculationPrintInfo.splice(extendedSheetPosition, 0, {
						htmlId: `extended-properties-${calculation.id.toString()}`,
						fileName: `extended-properties-${calculation.id.toString()}`,
					})
					extendedSheetPosition += 2
				}
				isContainPartialCascadeCalculation = false
			})

			const offerPrintInfo = offerDetailPages.map(page => ({
				htmlId: `offer-detail-${page + 1}`,
				fileName: `offer-detail-${page + 1}`,
				fromElem: trackOfferElems.indexOf(page) - numOfElemsBeforeOfferInfo,
				toElem:
					trackOfferElems.indexOf(page + 1) > 0
						? trackOfferElems.indexOf(page + 1) - numOfElemsBeforeOfferInfo - 1
						: undefined,
			}))

			const selectedPositionIds = calculationsDataWithOrder.map(
				calculation => calculation.positionId,
			)
			const selectedPositions = positions.filter(position =>
				selectedPositionIds.includes(position.id),
			)
			const designClassification =
				(selectedPositions[0]?.calculations[0]?.heatExchanger
					?.designClassification as DesignClassification) ?? 0

			const isCalculationBeingDrawn = (drawings: Drawing[]) => {
				const beingDrawnList = drawings.filter(
					drawing =>
						drawing.state == DrawingState.Failed ||
						drawing.state == DrawingState.New ||
						drawing.state == DrawingState.Running,
				)
				if (beingDrawnList.length > 0) return true
				return false
			}

			// compose offer info list from offer detail
			const offerInfo = fillOfferInfoDefaultValues({
				offerInfo: offerDetail.offerInfo,
				mFiles,
				designClassification,
			})
			const calculationBeingDrawnList = []

			const getCalcOptionsForDrawingExport = () => {
				const calcOptionsForDrawingExport = []
				calculationsDataWithOrder.map(calculation => {
					calcOptionsForDrawingExport.push({
						label: (
							<>
								{selectedPositions.find(p => p.id === calculation.positionId)
									.name +
									' ' +
									calculation.name}
								{calculation.calculationState == CalculationState.Warning ? (
									<span style={{color: 'orange'}}>&nbsp;&#9888;</span>
								) : null}{' '}
								{isCalculationBeingDrawn(calculation.drawing) ? (
									<>
										- drawing
										<LoadingOutlined style={{marginLeft: '16px'}} />
									</>
								) : null}
							</>
						),
						value: calculation.id,
						disabled: isCalculationBeingDrawn(calculation.drawing),
					})
					if (isCalculationBeingDrawn(calculation.drawing))
						calculationBeingDrawnList.push(calculation.id)
				})

				return calcOptionsForDrawingExport
			}

			return (
				<>
					<div className="export-button-row">
						<div style={{marginRight: '24px'}}>
							<Checkbox
								checked={previewExtra}
								onChange={handlePreviewExtraChange}
							>
								Preview Extras
							</Checkbox>
						</div>
						<div style={{marginRight: '24px'}}>
							<ExportDrawingButton
								offerId={+offerId}
								pricingCalculationsQuery={{
									isHeatExchangerIncluded: false,
									calculationId: pricingCalculationIds,
								}}
								calculationsData={calculationsDataWithOrder}
								selectedPositions={selectedPositions}
								plainOptions={getCalcOptionsForDrawingExport()}
								calculationBeingDrawnList={calculationBeingDrawnList}
							/>
						</div>
						<div style={{marginRight: '24px'}}>
							<Dropdown
								overlay={
									<Menu>
										{calculationsDataWithOrder.map((calculation, index) => {
											return (
												<ExportDatasheetToExcel
													exportId={calculation.id.toString()}
													calculation={calculation}
													offerDetail={offerDetail}
													mFilesInfo={mFiles}
													positionId={calculation.positionId.toString()}
													key={index}
												/>
											)
										})}
									</Menu>
								}
								placement="bottomLeft"
							>
								<Button>Download Excel</Button>
							</Dropdown>
						</div>
						<PdfGenerateButton
							fileName={offerDetail.mFileId}
							offerPrintInfo={[...offerPrintInfo]}
							calculationPrintInfo={[...calculationPrintInfo]}
							gettingData={isCalculationsLoading || isOfferDetailLoading}
						/>
					</div>
					<div
						ref={heightCalculationCallBack}
						id="offer-pages"
						contentEditable={true}
						suppressContentEditableWarning={true}
						// To check the height when user change the text
						onInput={() => {
							const height =
								document.querySelector('#offer-pages')?.clientHeight
							setOfferPageHeight(height)
						}}
						style={{marginBottom: '24px'}}
					>
						<OfferDetailPDF
							pdfId="offer-detail-1"
							positions={selectedPositions}
							offer={offerDetail}
							offerInfo={offerInfo}
							mFiles={mFiles}
							previewExtra={previewExtra}
							secondPageElemIndex={secondPageElemIndex}
						/>
						{offerPrintInfo.length > 1
							? offerPrintInfo.map((page, index) =>
									index > 0 ? (
										<PartialOfferDetailPDF
											key={page.htmlId}
											pdfId={page.htmlId}
											offerInfo={offerInfo.slice(page.fromElem, page.toElem)}
										/>
									) : null,
							  )
							: null}
					</div>
					<div>
						{calculationsDataWithOrder.map(calculation => (
							<div key={calculation.id} style={{marginBottom: '24px'}}>
								<DataSheet
									contentEditable={true}
									exportId={calculation.id.toString()}
									calculation={calculation}
									offerDetail={offerDetail}
									mFilesInfo={mFiles}
									positionId={calculation.positionId.toString()}
								/>
							</div>
						))}
					</div>
				</>
			)
		}
	}

	return (
		<div className="main-content main-content--export-preview page-export-preview">
			{renderDataSheets()}
		</div>
	)
}

export default ExportPreview
