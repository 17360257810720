import React from 'react'
import {Form, Select} from 'antd'
import SelectUnderline from '../SelectUnderline'
import {Size} from '../../model/CalculationEnums'
import {enumToValues} from '../../utils/utils'
import {sizeLabels} from '../../model/enumLabels'

const {Option} = Select

interface Props {
	isRequired?: boolean
}

const SizeInput: React.FunctionComponent<Props> = ({isRequired}) => {
	return (
		<Form.Item
			label="Size"
			name="size"
			rules={[{required: isRequired == undefined ? true : isRequired}]}
		>
			<SelectUnderline>
				{enumToValues(Size, true).map(key => (
					<Option key={key} value={key}>
						{sizeLabels[key]}
					</Option>
				))}
			</SelectUnderline>
		</Form.Item>
	)
}

export default SizeInput
