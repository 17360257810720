import {
	areaLabels,
	capacityUnitLabels,
	designPressureUnitLabels,
	dynamicViscoUnitLabels,
	endPlateTypeLabels,
	feetLabels,
	flowDirectorMaterialLabels,
	foulingFactorUnitLabels,
	kValueLabels,
	massFlowUnitLabels,
	massUnitLabels,
	mediumLabels,
	paintingLabels,
	plateMaterialLabels,
	plateThicknessLabels,
	positionLabels,
	pressureUnitLabels,
	shellMaterialLabels,
	specificGravityUnitLables,
	specificHeatUnitLables,
	temUnitLabels,
	thermalConductivityUnitLabels,
	typeApporvalLabels,
	volumeUnitLabels,
} from '../../model/enumLabels'
import {Circulation, EvaporatorType} from '../../model/Evaporator'
import {MediumSource} from '../../model/CalculationEnums'
import {
	ThermalDesignData,
	PlateShellData,
	MediumData,
	ConnectionsData,
} from '../../model/DataSheet'
import {
	getDesignPressureUnit,
	getMediumName,
	getNumberOfPlateNozzle,
	getShellMaterial,
} from './dataSheetUtils'

export const getEvaporatorData = (heatExchangerData, dataText) => {
	const {circulation, combinedC, evaporatorType} = heatExchangerData
	const appTitle =
		evaporatorType === EvaporatorType.DXEvaporator
			? 'EvaporatorDX'
			: combinedC
			? 'FloodedEvaporatorCombinedC'
			: circulation === Circulation.Combined
			? 'FloodedEvaporatorCombined'
			: 'FloodedEvaporator'

	//====================================THERMAL DATA===============================
	const {
		cboQ,
		capacity,
		cboArea,
		area,
		cboT,
		lmtd,
		cboKval,
		kval,
		excessDesignArea,
		foulingFactor,
		cboFF,
	} = heatExchangerData
	const thermalDesignData: ThermalDesignData[] = [
		{
			name: 'Capacity',
			unit: capacityUnitLabels[cboQ],
			value: capacity,
		},
		{
			name: 'Heat transfer area',
			unit: areaLabels[cboArea],
			value: area,
		},
		{
			name: 'Logarithmic mean T',
			unit: temUnitLabels[cboT],
			value: lmtd,
		},
		{
			name: 'K-value',
			unit: kValueLabels[cboKval],
			value: kval,
		},
		{
			name: 'Fouling factor',
			unit: foulingFactorUnitLabels[cboFF],
			value: foulingFactor,
		},
		{
			name: 'Excess Design/Area',
			unit: '%',
			value: excessDesignArea,
		},
	]

	const thermalDesignDataWithKey = thermalDesignData.map((item, index) => ({
		key: index,
		...item,
	}))

	const thermalDesignColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]
	//====================================PLATE SHELL DATA===============================
	const isHotSidePlate =
		heatExchangerData.evaporatorType === EvaporatorType.FloodedEvaporator

	const {
		cboM,
		cboDp,
		cboV,
		volumePlateSide,
		volumeShellSide,
		inletTemperatureLiquid,
		inletTempEvapSide,
		outletTemperatureLiquid,
		evaporatingTemperature,
		flowRateLiquid,
		flowRateVapor,
		pressureDropLiquidSideOutput,
		pressureDropVaporSideOutput,
		dtSuperHeat,
	} = heatExchangerData

	const plateShellData: PlateShellData[] = [
		{
			name: 'TempIN',
			unit: temUnitLabels[cboT],
			value: isHotSidePlate ? inletTemperatureLiquid : inletTempEvapSide,
			value2: isHotSidePlate ? inletTempEvapSide : inletTemperatureLiquid,
		},
		{
			name: 'TempOUT',
			unit: temUnitLabels[cboT],
			value: isHotSidePlate
				? outletTemperatureLiquid
				: evaporatingTemperature + dtSuperHeat,
			value2: isHotSidePlate
				? evaporatingTemperature + dtSuperHeat
				: outletTemperatureLiquid,
		},
		{
			name: 'Evaporating temperature',
			unit: temUnitLabels[cboT],
			value: isHotSidePlate ? null : evaporatingTemperature,
			value2: isHotSidePlate ? evaporatingTemperature : null,
		},
		{
			name: 'Flow rate',
			unit: massFlowUnitLabels[cboM],
			value: isHotSidePlate ? flowRateLiquid : flowRateVapor,
			value2: isHotSidePlate ? flowRateVapor : flowRateLiquid,
		},
		{
			name: 'Pressure drop',
			unit: pressureUnitLabels[cboDp],
			value: isHotSidePlate
				? pressureDropLiquidSideOutput
				: pressureDropVaporSideOutput,
			value2: isHotSidePlate
				? pressureDropVaporSideOutput
				: pressureDropLiquidSideOutput,
		},
		{
			name: 'Liquid volume',
			unit: volumeUnitLabels[cboV],
			value: volumePlateSide,
			value2: volumeShellSide,
		},
		{
			value2: dataText?.fillingBoxesText,
		},
	]

	const plateShellDataWithKey = plateShellData.map((item, index) => ({
		key: index,
		...item,
	}))

	const plateShellColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: `Plate Side (${isHotSidePlate ? 'Hot' : 'Cold'})`,
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: `Shell Side (${isHotSidePlate ? 'Cold' : 'Hot'})`,
		},
	]

	//====================================MEDIUM DATA===============================
	const {
		mediumLiquidSide,
		mediumVaporSide,
		mediumSourceVaporSide,
		mediumSourceLiquidSide,
		mediumVaporSideOtherMedia,
		mediumLiquidSideOtherMedia,
		cboGravi,
		cboSpH,
		cboCdy,
		dynamicViscosityLiquidSide,
		specificGravityLiquidSide,
		specificHeatLiquidSide,
		thermalConductivityLiquidSide,
		saturatedPressure,
		cboVisco,
		concentrationLiquidSide,
		cboP,
	} = heatExchangerData
	const mediumData: MediumData[] = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value: dynamicViscosityLiquidSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value: specificGravityLiquidSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value: specificHeatLiquidSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value: thermalConductivityLiquidSide,
		},
	]

	const mediumDataWithKey = mediumData.map((item, index) => ({
		key: index,
		...item,
	}))

	const mediumNameVaporSide =
		mediumSourceVaporSide != MediumSource.Imported
			? mediumLabels[mediumVaporSide]
			: mediumVaporSideOtherMedia

	const mediumNameLiquidSide = getMediumName({
		mediumSource: mediumSourceLiquidSide,
		mediumName: mediumLiquidSide,
		mediumNameOtherMedia: mediumLiquidSideOtherMedia,
		concentration: concentrationLiquidSide,
	})

	const mediumDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: isHotSidePlate ? 'value' : 'value2',
			key: isHotSidePlate ? 'value' : 'value2',
			title: isHotSidePlate
				? mediumNameLiquidSide
				: mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`,
		},
		{
			dataIndex: isHotSidePlate ? 'value2' : 'value',
			key: isHotSidePlate ? 'value2' : 'value',
			title: isHotSidePlate
				? mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`
				: mediumNameLiquidSide,
		},
	]
	//====================================CONSTRUCTION DATA===============================
	const {
		endPlateType,
		cboMass,
		massOfHeatExchanger,
		position,
		typeApproval,
		designPressure,
		designPressureOnDataSheet,
		designCode,
		designTemperatureMin,
		designTemperatureMax,
		feet,
		designPressurePlateSide,
		designPressureUnitOnDataSheet,
	} = heatExchangerData
	const constructionData = [
		{
			name: 'End Type',
			value: endPlateTypeLabels[endPlateType],
			value2: dataText?.constructionRow1Text,
		},
		{
			name: 'Weight (dry)',
			unit: massUnitLabels[cboMass],
			value: massOfHeatExchanger.toFixed(0),
			value2: dataText?.constructionRow2Text,
		},
		{
			name: 'Position',
			value: positionLabels[position],
			value2: dataText?.constructionRow3Text,
		},
		{
			name: 'Design code',
			value: designCode || typeApporvalLabels[typeApproval],
			value2: dataText?.constructionRow4Text,
		},
		{
			name:
				dataText?.constructionRow5Text != dataText?.constructionRow6Text
					? 'Shell design pressure'
					: 'Design pressure',
			unit: designPressureOnDataSheet
				? designPressureUnitLabels[designPressureUnitOnDataSheet]
				: getDesignPressureUnit(designPressure),
			value: designPressureOnDataSheet || dataText?.constructionRow5Text,
		},
	]
	if (dataText?.constructionRow5Text != dataText?.constructionRow6Text)
		constructionData.push({
			name: 'Plate design pressure',
			unit: getDesignPressureUnit(designPressure),
			value: dataText?.constructionRow6Text || designPressurePlateSide,
		})
	constructionData.push(
		{
			name: 'Design temperature',
			unit: temUnitLabels[cboT],
			value: `${designTemperatureMin}/${designTemperatureMax}`,
			value2: dataText?.constructionRow7Text,
		},
		{
			name: 'Support',
			value: feetLabels[feet],
			value2: dataText?.constructionRow8Text,
		},
	)

	const constructionDataWithKey = constructionData.map((item, index) => ({
		key: index,
		...item,
	}))

	const constructionDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
		{
			dataIndex: 'value2',
			key: 'value2',
		},
	]

	//====================================MATERIALS DATA===============================
	const materialsData = [
		{
			name: 'Plate material',
			value:
				plateMaterialLabels[heatExchangerData.plateMaterial] +
				' ' +
				plateThicknessLabels[heatExchangerData.plateThickness],
		},
		{
			name: 'Shell material',
			value: getShellMaterial(
				endPlateType,
				shellMaterialLabels[heatExchangerData.shellMaterial],
			),
		},
		{
			name: 'Flow director material',
			value: flowDirectorMaterialLabels[heatExchangerData.flowDirectorMaterial],
		},
		{
			name: 'Plate connections',
			value: dataText?.materialsPlateConnections,
		},
		{
			name: 'Shell connections IN',
			value: dataText?.materialsShellConnectionsIn,
		},
		{
			name: 'Shell connections OUT',
			value: dataText?.materialsShellConnectionsOut,
		},
		{
			name: 'Surface treatment',
			value: paintingLabels[heatExchangerData.paint],
		},
		// {
		// 	name: 'Finishing (Shell externally)',
		// },
		// {
		// 	name: '- Stainless steel parts',
		// 	value: 'Pearl blasting',
		// },
		// {
		// 	name: '- Carbon steel parts',
		// 	value: paintingLabels[heatExchangerData.paint],
		// },
	]

	const materialsDataWithKey = materialsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const materialsDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]

	//====================================CONNECTIONS DATA===============================
	const connectionsData: ConnectionsData[] = [
		{
			name: 'Plate side IN',
			size: +heatExchangerData.nozzlePlateSide,
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlate,
		},
		{
			name: 'Plate side OUT',
			size: +heatExchangerData.nozzlePlateSide,
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlateOut,
		},
		{
			name: 'Shell side IN',
			size: heatExchangerData.nozzleShell,
			numberOfPieces: heatExchangerData.numberOfShellNozzles,
			velocity: heatExchangerData.velocityShell,
		},
		{
			name: 'Shell side OUT',
			size: heatExchangerData.nozzleShellOut,
			numberOfPieces: heatExchangerData.numberOfShellNozzlesOut,
			velocity: heatExchangerData.velocityShellOut,
		},
	]

	if (circulation === Circulation.Combined) {
		connectionsData.push({
			velocity: dataText?.actualVelocityText,
		})
	}

	const connectionDataWithKey = connectionsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const connectionDataColumns = [
		{
			title: 'Connections',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Size (DN)',
			dataIndex: 'size',
			key: 'size',
		},
		{
			title: 'Nb of pieces',
			dataIndex: 'numberOfPieces',
			key: 'numberOfPieces',
		},
		{
			title: 'Velocity',
			dataIndex: 'velocity',
			key: 'velocity',
		},
	]

	return {
		appTitle,
		thermalDesignDataWithKey,
		thermalDesignColumns,
		plateShellDataWithKey,
		plateShellColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	}
}
