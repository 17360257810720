import {queryCache, useMutation, useQuery} from 'react-query'
import {client} from '../utils/apiClient'
import {Application} from '../model/Application'
import {
	DesignPressureOption,
	TypeApproval,
	Size,
	PlateMaterial,
	PlateType,
} from '../model/CalculationEnums'
import queryString from 'query-string'

const defaultMutationOptions = {
	onError: (err, variables, recover) =>
		typeof recover === 'function' ? recover() : null,
	onSettled: () => queryCache.invalidateQueries('heatExchanger'),
}

interface PlateThicknessQueryParams {
	designPressure: DesignPressureOption
	size: Size
	plateType: PlateType
	plateMaterial: PlateMaterial
	typeApproval: TypeApproval
}

export function usePlatethickness(params: PlateThicknessQueryParams, options?) {
	const stringified = queryString.stringify(params)

	const results = useQuery<
		{plateThicknessMin: number; plateThicknessMax: number},
		Error
	>({
		queryKey: ['platethickness', {stringified}],
		queryFn: () =>
			client(`applications/validation/platethickness?${stringified}`),
		config: {...options},
	})

	return results
}

export function useHeatExchanger(applicationId, heatExchangerId, options?) {
	const results = useQuery({
		queryKey: ['heatExchanger', {heatExchangerId}, {applicationId}],
		queryFn: () =>
			client(`applications/${applicationId}/heatExchanger/${heatExchangerId}`),
		config: {...options},
	})

	return results
}

export function useUpdateHeatExchanger(options?) {
	return useMutation<Application, Error, Application>(
		updates =>
			client(
				`applications/${updates.application}/heatExchanger/${updates.id}`,
				{
					method: 'PUT',
					data: updates,
				},
			),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

export function useDeleteHeatExchanger(options?) {
	return useMutation<Application, Error, Application>(
		updates =>
			client(
				`applications/${updates.application}/heatExchanger/${updates.id}`,
				{
					method: 'DELETE',
				},
			),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

export function useCreateHeatExchanger(options?) {
	return useMutation<Application, Error, Application>(
		data => client(`applications/${data.application}`, {data}),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}
