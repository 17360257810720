import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Login from './pages/Login'
import {withAuthenticationRequired, useAuth0} from '@auth0/auth0-react'
import AuthenticatedApp from './AuthenticatedApp'
import DrawingPage from './pages/DrawingPage'
import {Alert} from 'antd'
import {sec} from './utils/security'
import './i18n'

const App: React.FunctionComponent = () => {
	const {getAccessTokenSilently, isAuthenticated} = useAuth0()
	sec.setAccessTokenSilently(getAccessTokenSilently)

	const renderUnAuthenticatedApp = () => {
		return (
			<Switch>
				<Route path="/playground" component={DrawingPage} />
				<Route path="/" component={Login} />
			</Switch>
		)
	}

	const ProtectedRoute = ({component, ...args}) => (
		<Route component={withAuthenticationRequired(component)} {...args} />
	)

	const renderEnvAlert = () => {
		const env = process.env.NODE_ENV

		if (env !== 'staging' && env !== 'development') {
			return null
		}

		return <Alert message={`${env} environment`} type="warning" banner={true} />
	}

	// const renderBannerTop = () => {
	// 	return (
	// 		<>
	// 			<div className="banner-top">
	// 				<Alert
	// 					message="CPQ will undergo scheduled update to version 2 from 2:00 PM on June 15th to 11:00 PM on June 16th (EEST). During this period, the system will be unavailable. After update, CPQ 1 will be de-activated. All data from CPQ 1 will be migrated to CPQ 2."
	// 					type="info"
	// 					banner={true}
	// 				/>
	// 			</div>
	// 		</>
	// 	)
	// }

	return (
		<div className="App">
			{renderEnvAlert()}
			{/* {renderBannerTop()} */}
			{isAuthenticated ? (
				<ProtectedRoute component={AuthenticatedApp}></ProtectedRoute>
			) : (
				renderUnAuthenticatedApp()
			)}
		</div>
	)
}

export default App
