import React from 'react'
import {Menu} from 'antd'
import {useHistory} from 'react-router-dom'

interface StepMenu {
	name: string
	shouldShow: boolean
	key: string
}

enum Step {
	calculator = 'calculator',
	dashboard = 'dashboard',
	workspace = 'workspace',
	sizing = 'sizing',
	offer = 'offer',
	documents = 'documents',
	export = 'export',
	previewCalculation = 'preview calculation',
	aiResult = 'AI Result',
}

const getCurrentStep = (pathName: string) => {
	if (pathName.includes('/dashboard')) {
		return Step.dashboard
	} else if (pathName.includes('/preview-calculation')) {
		return Step.previewCalculation
	} else if (pathName.includes('/ai-result')) {
		return Step.aiResult
	} else if (pathName.includes('/sizing')) {
		return Step.sizing
	} else if (pathName.includes('/workspace')) {
		return Step.workspace
	} else if (pathName.includes('/export')) {
		return Step.export
	} else if (pathName.includes('/documents')) {
		return Step.documents
	} else if (pathName.includes('/offer')) {
		return Step.offer
	} else if (pathName.includes('/calculator')) {
		return Step.calculator
	} else {
		return Step.dashboard
	}
}

const checkMenuState = (menus: StepMenu[], name: string) => {
	let result: boolean
	menus.forEach(menu => {
		if (menu.name === name) {
			result = menu.shouldShow
		}
	})
	return result
}

const NavigationMenu = () => {
	const history = useHistory()
	const [step, setStep] = React.useState<string>(() =>
		getCurrentStep(history.location.pathname),
	)

	const [stepMenus, setStepMenu] = React.useState<StepMenu[]>([])
	React.useEffect(() => {
		switch (history.location.pathname) {
			case '/dashboard':
				setStepMenu([
					{
						name: 'Dashboard',
						shouldShow: true,
						key: Step.dashboard,
					},
					{
						name: 'Workspace',
						key: Step.workspace,
						shouldShow: false,
					},
					{
						name: 'Calculator',
						shouldShow: true,
						key: Step.calculator,
					},
					{
						name: 'Sizing Tools',
						key: Step.sizing,
						shouldShow: false,
					},
					{
						name: 'AI Result',
						shouldShow: false,
						key: Step.aiResult,
					},
					{
						name: 'Preview Calculator',
						shouldShow: false,
						key: Step.previewCalculation,
					},
					{
						name: 'Offer',
						key: Step.offer,
						shouldShow: false,
					},
					{
						name: 'Data Sheet',
						key: Step.documents,
						shouldShow: false,
					},
					{
						name: 'Export',
						key: Step.export,
						shouldShow: false,
					},
				])
				break
			case '/calculator/preview-calculation':
				setStepMenu([
					{
						name: 'Dashboard',
						shouldShow: true,
						key: Step.dashboard,
					},
					{
						name: 'Workspace',
						key: Step.workspace,
						shouldShow: false,
					},
					{
						name: 'Calculator',
						shouldShow: true,
						key: Step.calculator,
					},
					{
						name: 'Sizing Tools',
						key: Step.sizing,
						shouldShow: false,
					},
					{
						name: 'AI Result',
						shouldShow: false,
						key: Step.aiResult,
					},
					{
						name: 'Preview Calculator',
						shouldShow: true,
						key: Step.previewCalculation,
					},
					{
						name: 'Offer',
						key: Step.offer,
						shouldShow: false,
					},
					{
						name: 'Data Sheet',
						key: Step.documents,
						shouldShow: false,
					},
					{
						name: 'Export',
						key: Step.export,
						shouldShow: false,
					},
				])
				break

			default:
				if (
					history.location.pathname.includes('/preview-calculation') &&
					history.location.pathname.includes('/sizing')
				) {
					setStepMenu([
						{
							name: 'Dashboard',
							shouldShow: true,
							key: Step.dashboard,
						},
						{
							name: 'Workspace',
							key: Step.workspace,
							shouldShow:
								step === Step.workspace ||
								step === Step.sizing ||
								step === Step.offer ||
								step === Step.documents ||
								step === Step.export ||
								step === Step.previewCalculation ||
								step === Step.aiResult,
						},
						{
							name: 'Calculator',
							shouldShow: false,
							key: Step.calculator,
						},
						{
							name: 'Sizing Tools',
							key: Step.sizing,
							shouldShow:
								step === Step.sizing ||
								step === Step.previewCalculation ||
								step === Step.aiResult,
						},
						{
							name: 'AI Result',
							shouldShow: step === Step.aiResult,
							key: Step.aiResult,
						},
						{
							name: 'Preview Calculator',
							shouldShow: step === Step.previewCalculation,
							key: Step.previewCalculation,
						},
						{
							name: 'Offer',
							key: Step.offer,
							shouldShow:
								step === Step.offer ||
								step === Step.documents ||
								step === Step.export,
						},
						{
							name: 'Data Sheet',
							key: Step.documents,
							shouldShow: step === Step.documents,
						},
						{
							name: 'Export',
							key: Step.export,
							shouldShow: step === Step.export,
						},
					])
				} else {
					setStepMenu([
						{
							name: 'Dashboard',
							shouldShow: true,
							key: Step.dashboard,
						},
						{
							name: 'Workspace',
							key: Step.workspace,
							shouldShow:
								step === Step.workspace ||
								step === Step.sizing ||
								step === Step.offer ||
								step === Step.documents ||
								step === Step.export ||
								step === Step.previewCalculation ||
								step === Step.aiResult,
						},
						{
							name: 'Calculator',
							shouldShow:
								(step === Step.dashboard ||
									step === Step.calculator ||
									step === Step.previewCalculation) &&
								!checkMenuState(stepMenus, 'Workspace'),
							key: Step.calculator,
						},
						{
							name: 'Sizing Tools',
							key: Step.sizing,
							shouldShow:
								step === Step.sizing ||
								step === Step.previewCalculation ||
								step === Step.aiResult,
						},
						{
							name: 'AI Result',
							shouldShow: step === Step.aiResult,
							key: Step.aiResult,
						},
						{
							name: 'Preview Calculator',
							shouldShow: step === Step.previewCalculation,
							key: Step.previewCalculation,
						},
						{
							name: 'Offer',
							key: Step.offer,
							shouldShow:
								step === Step.offer ||
								step === Step.documents ||
								step === Step.export,
						},
						{
							name: 'Data Sheet',
							key: Step.documents,
							shouldShow: step === Step.documents,
						},
						{
							name: 'Export',
							key: Step.export,
							shouldShow: step === Step.export,
						},
					])
				}
				break
		}
	}, [step])

	React.useEffect(() => {
		const unregisterHistoryListener = history.listen(() => {
			const pathName = history.location.pathname

			setStep(getCurrentStep(pathName))
		})

		return () => {
			unregisterHistoryListener()
		}
	}, [history])

	const handleNavigationMenuClick = ({key}) => {
		switch (key) {
			case Step.dashboard: {
				history.push('/dashboard')
				break
			}
			case Step.calculator: {
				history.push('/calculator')
				break
			}
			case Step.sizing: {
				const {pathname} = history.location
				if (step === Step.previewCalculation) {
					history.push(pathname.replace('/preview-calculation', ''))
				}
				if (step === Step.aiResult) {
					history.push(pathname.replace('/ai-result', ''))
				}
				break
			}
			case Step.workspace: {
				const {pathname} = history.location

				if (
					step === Step.offer ||
					step === Step.export ||
					step === Step.documents ||
					step === Step.aiResult
				) {
					const offerId = pathname.split('/')[2]
					history.push(`/workspace/${offerId}`)
				} else {
					const workspacePath = pathname.substring(
						0,
						pathname.indexOf('/sizing'),
					)
					history.push(workspacePath)
				}
				break
			}
			case Step.offer: {
				const {pathname} = history.location

				if (step === Step.documents || step === Step.export) {
					const offerId = pathname.split('/')[2]
					history.push(`/offer/${offerId}`)
				}
				break
			}
			default:
				break
		}
	}

	return (
		<div>
			<Menu
				selectedKeys={[step]}
				mode="horizontal"
				className="vahterus-header-page-nav-menu"
				onClick={handleNavigationMenuClick}
			>
				{stepMenus.map(menu =>
					menu.shouldShow ? (
						<Menu.Item key={menu.key}>
							<div>{menu.name}</div>
						</Menu.Item>
					) : null,
				)}
			</Menu>
		</div>
	)
}
export default NavigationMenu
