import React from 'react'
import {Form, FormInstance, Select} from 'antd'
import SelectUnderline from '../SelectUnderline'
import InputNumberUnderline from '../InputNumberUnderline'
import {convertCapacity, enumToValues} from '../../utils/utils'
import {UnitCapacityOption} from '../../model/CalculationEnums'
import {usePrevious, useConvertCommaToDot} from '../../utils/hookUtils'
import {capacityUnitLabels} from '../../model/enumLabels'

const {Option} = Select

interface Props {
	form: FormInstance
}
const CapacityInput: React.FunctionComponent<Props> = ({form}) => {
	const {getFieldValue, setFieldsValue} = form
	const cboQ = getFieldValue('cboQ')
	const capacity = getFieldValue('capacity')
	const prevCboQ = usePrevious(cboQ)

	React.useEffect(() => {
		const value = useConvertCommaToDot(capacity)
		setFieldsValue({capacity: value})
	}, [capacity])

	React.useEffect(() => {
		setFieldsValue({capacity: convertCapacity(capacity, prevCboQ, cboQ)})
	}, [cboQ, setFieldsValue, prevCboQ])
	return (
		<Form.Item label="Capacity">
			<Form.Item name="capacity">
				<InputNumberUnderline />
			</Form.Item>
			<Form.Item name="cboQ" rules={[{required: true}]}>
				<SelectUnderline>
					{enumToValues(UnitCapacityOption, true).map(key => (
						<Option key={key} value={key}>
							{capacityUnitLabels[key]}
						</Option>
					))}
				</SelectUnderline>
			</Form.Item>
		</Form.Item>
	)
}

export default CapacityInput
