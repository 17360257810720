import React from 'react'
import {/*Menu, */ Table /*, Tag*/} from 'antd'
import {ColumnsType, TableProps} from 'antd/es/table'
import {Link} from 'react-router-dom'

// import TableActions from './TableActions'

import Offer from '../model/Offer'
import {formatDate} from '../utils/utils'

interface Props extends TableProps<Offer> {
	offers: Offer[]
}

const DashboardTable: React.FunctionComponent<Props> = ({offers}) => {
	const offersData = offers?.map(offer => ({
		key: offer.id,
		...offer,
	}))

	const columns: ColumnsType<Offer> = [
		{
			title: 'OFFER ID',
			dataIndex: 'mFileId',
			render: function displayOfferId(text, record) {
				return <Link to={`/workspace/${record.id}`}>{text}</Link>
			},
			// sorter: (a, b) => a.mFileId.localeCompare(b.mFileId),
			// defaultSortOrder: 'descend',
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			render: function displayCreatedAt(text, record) {
				const date = new Date(record.createdAt + 'Z')
				return <span>{formatDate(date, true)}</span>
			},
			sorter: (a, b) =>
				new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
			defaultSortOrder: 'descend',
		},
		{
			title: 'LAST EDITED AT',
			dataIndex: 'lastEditedAt',
			render: function displayLastEditedAt(text, record) {
				const date = new Date(record.lastEditedAt)
				return <span>{formatDate(date, true)}</span>
			},
		},
		{
			title: 'COMPANY',
			dataIndex: 'company',
			render: function displayCompany(text, record) {
				return (
					<span>
						{record.companyName ? (
							record.companyName
						) : (
							<span style={{color: 'rgba(0,0,0,0.5)'}}>N/a</span>
						)}
					</span>
				)
			},
			// sorter: (a, b) => a.companyName.localeCompare(b.companyName),
			// defaultSortOrder: 'descend',
		},
		{
			title: 'PROJECT',
			dataIndex: 'project',
			render: function displayProjectName(text, record) {
				return (
					<span>
						{record.projectName ? (
							record.projectName
						) : (
							<span style={{color: 'rgba(0,0,0,0.5)'}}>N/a</span>
						)}
					</span>
				)
			},
			// sorter: (a, b) => a.projectName.localeCompare(b.projectName),
			// defaultSortOrder: 'descend',
		},
		{
			title: 'REFERENCE',
			dataIndex: 'reference',
			render: function displayReferenceName(text, record) {
				return (
					<span>
						{record.reference ? (
							record.reference
						) : (
							<span style={{color: 'rgba(0,0,0,0.5)'}}>N/a</span>
						)}
					</span>
				)
			},
		},
		// {
		// 	title: 'STATUS',
		// 	dataIndex: 'status',
		// 	render: function displayInTag(status) {
		// 		return <Tag color="orange">{status}</Tag>
		// 	},
		// },
		// {
		// 	title: '',
		// 	dataIndex: '',
		// 	render: function displayDropdown(text, record) {
		// 		const handleActionClick = ({key}) => {
		// 			console.log(
		// 				`Click on menu action ${key} with position id: ${record.id}`,
		// 			)
		// 		}

		// 		const actionMenu = (
		// 			<Menu onClick={handleActionClick}>
		// 				<Menu.Item key="1">Send for review</Menu.Item>
		// 				<Menu.Item key="2">Download</Menu.Item>
		// 				<Menu.Item key="3">Archive</Menu.Item>
		// 				<Menu.Item key="4">Delete</Menu.Item>
		// 				<Menu.Item key="5">Make a copy</Menu.Item>
		// 				<Menu.Item key="6">Make revision</Menu.Item>
		// 			</Menu>
		// 		)

		// 		return <TableActions overlay={actionMenu} />
		// 	},
		// },
	]

	return (
		<>
			<Table
				className="dashboard-table"
				columns={columns}
				dataSource={offersData}
				pagination={{pageSize: 20, showSizeChanger: false}}
			/>
		</>
	)
}

export default DashboardTable
