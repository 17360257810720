export enum CalculationState {
	Warning = 'warning',
	Success = 'success',
	Error = 'error',
	Initial = 'initial',
}

export enum ByPassFactor {
	Normal = 1,
	Ignore = 6,
}

export enum ApplicationType {
	OnePhase = 1,
	Condenser = 2,
	Evaporators = 3,
	Cascade = 4,
	PartialCascade = 5,
	MultiCondenser = 6,
}

export enum CalculationOption {
	Manual = 0,
	Automatic = 1,
	Performance = 2,
	Rating = 3,
	Ai = 4,
}

export enum CalculationOptionMultiCondenser {
	Automatic = 1,
	Performance = 2,
	Rating = 3,
}

export enum Content {
	Dangerous,
	NotDangerous,
}
export enum Size {
	Size2 = 2,
	Size3 = 3,
	Size4 = 4,
	Size5 = 5,
	Size6 = 6,
	Size7 = 7,
	Size9 = 9,
	Size12 = 12,
	Size14 = 14,
}
export enum PlateType {
	HH,
	LL,
	HA,
	TH,
	WA,
	LA,
	WH,
	WL,
	SH,
}

export enum ShellDiameter {
	_213 = 213,
	_267 = 267,
	_340 = 340,
	_380 = 380,
	_490 = 490,
	_590 = 590,
	_640 = 640,
	_730 = 730,
	_820 = 820,
	_960 = 960,
	_1100 = 1100,
	_1310 = 1310,
	_1350 = 1350,
	_1550 = 1550,
	_1800 = 1800,
}
export enum NozzleShell {
	DN25 = 25,
	DN32 = 32,
	DN40 = 40,
	DN50 = 50,
	DN65 = 65,
	DN80 = 80,
	DN100 = 100,
	DN125 = 125,
	DN150 = 150,
	DN200 = 200,
	DN250 = 250,
	DN300 = 300,
	DN350 = 350,
	DN400 = 400,
	DN500 = 500,
	DN600 = 600,
	DN800 = 800,
	DN1000 = 1000,
}
export enum NumberOfPlateNozzles {
	oneEnd = 1,
	differentEnds = 2,
	bothEnds = 3,
}
export enum NumberOfShellNozzles {
	_1pc = 1,
	_2pc = 2,
	_4pc = 4,
}
export enum PlateMaterial {
	AISI316L = 1,
	Titanium = 2,
	AISI904L = 3,
	_254SMO = 4,
	C22 = 5,
	Duplex2205 = 6,
	AISI304L = 7,
}
export enum PlateThickness {
	_0_7mm,
	_0_8mm,
	_1mm,
	_1_2mm,
	_1_25mm,
	_1_5mm,
}
export enum ShellMaterial {
	AISI316L_316 = 0,
	CarbonSteel = 1,
	_254SMO = -3,
	Duplex2205 = -2,
	AISI304L = -1,
}
export enum EndPlateType {
	NoEndPlate = 0,
	OpenableVersion = 1,
	WeldedEndPlates = 2,
	WeldedEccentric = 3,
	OpenableDouble = 4,
	Compact = 5,
	OpenableGirthFlange = 6,
	OpenableGirthFlangeDouble = 7,
	Kettle001 = 8,
	Kettle002 = 9,
	Kettle003 = 10,
}
export enum FlowDirectorMaterial {
	EPDM,
	Nitrile,
	Fluorinesilicone,
	SiliconeN,
	SiliconeHT,
	PTFE,
	Graphite,
	Other,
	NoFlDirector,
}
export enum PlateShellConnections {
	StubEnds,
	ENFlanges,
	ANSIFlanges,
	MaleThread,
	EnFlangesCS = 5,
	ANSIFlangesCS = 6,
	ENFlangesTitanium = 7,
	ANSIFlangesTitanium = 8,
	StubEndSTReduced = 9,
	StubEndClient = 10,
}
export enum Paint {
	System0,
	System1WhiteRAL9010,
	System1BlueRAL5010,
	System21,
	System22,
	System3,
	System4,
	System5,
	System6,
	SystemE,
}
export enum Feet {
	NoFeet,
	EndPlateFeetBoltedCS,
	EndPlateFeetBoltedAISI,
	ShellFeetCS,
	ShellFeetAISI,
	EndPlateFeetWeldedCS,
	EndPlateFeetWeldedAISI,
	BraketsWelededCS,
	BraketsWeldedAISI,
	VerticalFeetWeldedCS,
	VerticalFeetWeldedAISI,
	SleeveWeldedCS,
	SleeveWeldedAISI,
}
export enum PositionEnum {
	_Horizontal = 1,
	_Vertical = 2,
}
export enum TypeApproval {
	PED,
	ASME,
	PEDEN,
}

export enum DesignClassification {
	PED,
	U_STAMP,
	WITHOUTU_STAMP,
	SELO,
	ABS,
	BV,
	DNV,
	CCS,
	KRS,
	LR,
	NK,
	Rina,
}

export enum NumberOfPasses {
	_1Pass = 1,
	_2Passes = 2,
	_3Passes = 3,
	_4Passes = 4,
	_5Passes = 5,
}

export enum FlowDirection {
	Countercurrent,
	Cocurrent,
	Crossflow,
}
export enum HotSide {
	PlateSide = 1,
	ShellSide = 2,
}
export enum Parfile {
	NoOfferDrawing,
	PDFDWGType,
	PDFDWGSTEPMODEL,
}

export enum DesignPressureOption {
	_6bar,
	_10bar,
	_16bar,
	_25bar,
	_40bar,
	_60bar,
	_80bar,
	_100bar,
	_120bar,
	_150bar,
	_150psi,
	_300psi,
	_600psi,
	_900psi,
	_1500psi,
}

export enum MediumSource {
	Imported,
	Brine,
	Liquid,
	RefProp,
}

export enum MediumMixture {
	PureMedium,
	Mixture,
}

export enum FractionType {
	// Mass = 0,
	Mole = 1,
}

export enum Medium {
	OtherMedia,
	Water,
	CaCl2,
	Ethyleneglycol,
	Freezium,
	Hycool,
	Pekasol2000,
	Propyleneglycol,
	Tyfoxit,
	NH3,
	CO2,
	Propane,
	R134a,
	R22,
	R23,
	R404a,
	R407c,
	R410a,
	R507A,
	R408a,
	ArticSHC226,
	DowthermJ,
	Essotherm500,
	GilothermD12,
	MarlothermL,
	MarlothermLH,
	MarlothermS,
	Mobiltherm603,
	Palmoil,
	SAE40W,
	ShellThermiaB,
	SylthermXLT,
	Syltherm,
	Temper,
	ACETONE30230C,
	ARTICSHC22620100C,
	ARTICSHC32640100C,
	BENZOL2575C,
	BIODIESEL10120C,
	BPTRANSCALN50300C,
	BSE17040100C,
	BUTHYLACETAT1020C,
	CALFLOLT2020C,
	CALFLOLT80160C,
	CL001BTTMS20120C,
	CL002BTTMS35140C,
	CL002FEED60130C,
	CLAVUSG6840100C,
	COCOABUTTER20170C,
	COOKINGOIL160210C,
	CP460046378933C,
	CRUDEFATTYACID70100C,
	DIESELOIL520C,
	DIPHYL180340C,
	DISTILLEDFATTYACID16099C,
	DOWTHERMAC255405C,
	DOWTHERMJ5165C,
	DOWTHERMJ2545C,
	DOWTHERMJ100285C,
	DOWTHERMJ35125C,
	DOWTHERMQ40120C,
	DYNALINEMV100160C,
	ETHYLENE10050C,
	FRICK11489878C,
	FRICK13489878C,
	FRICK14489878C,
	FRICK2A489878C,
	FRICK3489878C,
	FRICK33893C,
	FRICK94988C,
	FRICKNG15441011C,
	HEAVYFUELOIL380CST50110C,
	HEAVYFUELOIL380CST110150C,
	HEAVYFUELOIL700CST50150C,
	HEAVYFUELOIL700CST110150C,
	HEXANE3050C,
	KEROSINE5070C,
	LANOLIN4090C,
	LEANGLYCOL45140C,
	LEANGLYCOL5751909C,
	LEANGLYCOL4141933C,
	LEANMEG32128C,
	LEANSULFINOLD601287C,
	LEANTEG5751909C,
	LEANTEG451268C,
	LEANTEG78210C,
	LEANTEG4641906C,
	LPG29130C,
	LVGO104159C,
	M1002080C,
	MARLOTHERM2740C,
	MARLOTHERMSH40120C,
	MARLOTHERMX60300C,
	MEG502080C,
	MEGBRINE075C,
	METHANOL30240C,
	METHANOL1020C,
	MINERALOIL100285C,
	MOBILTHERM605185205C,
	MÄNTYÖLJY50150C,
	NAOCL1030C,
	NHEXANE6020C,
	NHEXANE1459C,
	NOVECHFE71006585C,
	ORIMULSION4001070C,
	PARATHERMNF200350C,
	PHENOLICRESIN1030C,
	PLASTIFICANTEDOP90150C,
	POLYOL1030C,
	POLYURETHANE4090C,
	POR1803090C,
	PRODUCEDWATER60100C,
	REACTOREFFLUENT112226C,
	REACTORFEED28220C,
	REFLO4630100C,
	REFLO6840120C,
	REOFOS75200C,
	REOFOS175200C,
	RICHGLYCOL466170C,
	RICHGLYCOL666160C,
	RICHMEG2254C,
	RICHSULFINOLD401087C,
	RICHTEG669170C,
	RICHTEG4794C,
	RICHTEG511180C,
	RICHTEG45180C,
	SAE4060100C,
	SANTOTHERM6660300C,
	SEAWATER245C,
	SERIOLAETA10050300C,
	SHELLTHERMIAB20150C,
	SODIUMNITRATE401010C,
	SOLESTLT3248985C,
	SOLVENT5030100C,
	SOYABEANOIL60260C,
	SUNFLOWEROIL160210C,
	SYLTHERMXLT30150C,
	SYLTHERMXLT400C,
	TALLOW60226C,
	TEG58120150C,
	TEXACOA6830110C,
	TEXACOPREMIUM6830110C,
	TEXACOWF6830110C,
	THERMERA351020C,
	THERMERA502520C,
	THERMINOL55149216C,
	THERMINOL66120200C,
	THERMINOL72220320C,
	THERMINOLD12200C,
	THERMINOLD1249149C,
	THERMINOLLT4016C,
	TMP58210C,
	TOLUENE20240C,
	TRANSCALLT0130C,
	TRANSCALN1030C,
	UCOTHERMFG8450C,
	UCOTHERMFG80280C,
	VEGOIL100260C,
	VG6840100C,
	VGO100245C,
	XYLENE25145C,
	ISOVG2230180C,
	ISOVG3230180C,
	ISOVG4630180C,
	ISOVG6815180C,
	ISOVG10015180C,
	ISOVG1500140C,
	ISOVG2200140C,
	MOBILARCTICSHC2340200C,
	THERMINOLVP12425C,
	MOLTENSALT260593C,
	NXT717OIL0140C,
	_1BUTENE,
	ACETONE,
	AIR,
	AMMONIA,
	ARGON,
	BENZENE,
	BUTANE,
	C11,
	C12,
	C1CC6,
	C2BUTENE,
	C3CC6,
	C4F10,
	C5F12,
	CF3I,
	CO,
	CO2Refprop,
	COS,
	CYCLOHEX,
	CYCLOPEN,
	CYCLOPRO,
	D2,
	D2O,
	D4,
	D5,
	D6,
	DECANE,
	DEE,
	DMC,
	DME,
	EBENZENE,
	ETHANE,
	ETHANOL,
	ETHYLENE,
	FLUORINE,
	H2S,
	HCL,
	HELIUM,
	HEPTANE,
	HEXANE,
	HYDROGEN,
	IBUTENE,
	IHEXANE,
	IOCTANE,
	IPENTANE,
	ISOBUTAN,
	KRYPTON,
	LNG,
	MD2M,
	MD3M,
	MD4M,
	MDM,
	METHANE,
	METHANOL,
	MLINOLEA,
	MLINOLEN,
	MM,
	MOLEATE,
	MPALMITA,
	MSTEARAT,
	MXYLENE,
	N2O,
	NEON,
	NEOPENTN,
	NF3,
	NITROGEN,
	NONANE,
	NOVEC649,
	OCTANE,
	ORTHOHYD,
	OXYGEN,
	OXYLENE,
	PARAHYD,
	PENTANE,
	PROPANE,
	PROPYLEN,
	PROPYNE,
	PXYLENE,
	R11,
	R113,
	R114,
	R115,
	R116,
	R12,
	R1216,
	R123,
	R1233ZDE,
	R1234YF,
	R1234ZEE,
	R124,
	R125,
	R13,
	R134A,
	R14,
	R141B,
	R142B,
	R143A,
	R152A,
	R161,
	R21,
	R218,
	R22Refprop,
	R227EA,
	R23Refprop,
	R236EA,
	R236FA,
	R245CA,
	R245FA,
	R32,
	R365MFC,
	R40,
	R401A,
	R401B,
	R401C,
	R402A,
	R402B,
	R403A,
	R403B,
	R404A,
	R405A,
	R406A,
	R407A,
	R407B,
	R407C,
	R407D,
	R407E,
	R407F,
	R408A,
	R409A,
	R409B,
	R41,
	R410A,
	R410B,
	R411A,
	R411B,
	R412A,
	R413A,
	R414A,
	R414B,
	R415A,
	R415B,
	R416A,
	R417A,
	R418A,
	R419A,
	R420A,
	R421A,
	R421B,
	R422A,
	R422B,
	R422C,
	R422D,
	R423A,
	R424A,
	R425A,
	R426A,
	R427A,
	R428A,
	R429A,
	R430A,
	R431A,
	R432A,
	R433A,
	R434A,
	R435A,
	R436A,
	R436B,
	R437A,
	R438A,
	R441A,
	R442A,
	R443A,
	R444A,
	R448A,
	R449a,
	R449B,
	R452a,
	R500,
	R501,
	R502,
	R503,
	R504,
	R507ARefprop,
	R508A,
	R508B,
	R509A,
	R510A,
	R512A,
	R513A,
	RC318,
	RE143A,
	RE245CB2,
	RE245FA2,
	RE347MCC,
	SF6,
	SO2,
	T2BUTENE,
	TOLUENE,
	WATER,
	XENON,
	NAOH105020130C,
	ALCALINH3WATER762364020C,
	ETHANOL11604520C,
	METHANOL8475020C,
	GLYCEROL20634040C,
	KALIUMCARBONATE0393530C,
	MAGNESIUMCHLORIDE0203030C,
	NATRIUMCHLORIDE0232130C,
	KALIUMACETATE11414530C,
	COOLFLOWDTX22524580C,
	FRICK12B40120C,
	R1234ZEZ,
	LNGMSC28586MIX,
	FRICK1910100C,
	ARCTICSHC23010120C,
	R515A,
	R515B,
	R1336MZZZ,
	_13BUTADIENE,
	DYNALINEHC505020C,
	FES1378933,
	PAO6830120,
}

export enum UnitDynamicViscosityOption {
	kgms,
	Poise,
	cP,
	MPas,
	lbf_s_in2,
	lbf_s_ft2,
}
export enum UnitSpecificGravityOption {
	kgm3,
	gcm3,
	ozin3,
	ozgal_UK,
	ozgal_US,
	lbgal_UK,
	lbgal_US,
	lbin3,
	lbft3,
	lbyd3,
}
export enum UnitSpecificHeatOption {
	JkgK,
	calgC,
	kcalkgC,
	btylbF,
}
export enum UnitThermalConductivityOption {
	WmK,
	Btu_ins_ft2_F,
	Btu_inh_ft2_F,
	Btu_fth_ft2_F,
	cals_cm_C,
	kcalh_m_C,
}
export enum UnitPressureOption {
	kPa,
	MPa,
	bar,
	mmHg,
	atm,
	Torr,
	Psi,
	lbin2,
	lbft2,
}
export enum UnitDesignPressureOption {
	kPa,
	MPa,
	bar,
	mmHg,
	atm,
	Torr,
	psi,
	lbin2,
	lbft2,
}
export enum UnitMassFlowOption {
	kgs,
	lbs,
	kgh,
	lbh,
	US_tons,
	UK_tons,
}
export enum UnitAreaOption {
	m2,
	in2,
	ft2,
	yd2,
}
export enum UnitKValueOption {
	Wm2,
	Btuhft2F,
	Btusft2F,
	kcalhm2C,
	calscm2C,
}
export enum UnitFoulingFactorOption {
	m2KW,
	hft2FBtu,
	sft2FBtu,
	hm2Ckcal,
	scm2Ccal,
}
export enum UnitVelocityOption {
	ms,
	fts,
	ftmin,
	ins,
	inmin,
	yds,
}
export enum UnitCapacityOption {
	kW,
	Btus,
	kBtuh,
	ftlbfh,
	ftlbfmin,
	kcalh,
	cals,
	RefTon,
}
export enum UnitSurfaceTensionOption {
	Nm,
	mNm,
	lbfft,
	lbfin,
	ergcm2,
	kgfm,
	dynecm,
	mjcm2,
}
export enum UnitTemperatureLMTDOption {
	C,
	F,
	R,
}
export enum UnitEntalphyOption {
	Jkg,
	kJkg,
	Btulb,
	Btukg,
	ftlbflb,
	kcallb,
	calkg,
}
export enum UnitMassOption {
	kg,
	lb,
}

export enum UnitVolumeOption {
	m3,
	dm,
	USgal,
}

export enum UnitAirHumidityOption {
	kgkg,
}

export enum UnitType {
	SI,
	AME,
}

export enum LiquidSide {
	PlateSide = 1,
	ShellSide = 2,
}

export enum CondenserType {
	Normal,
	Vent_Condenser,
	CondGasSeparator,
}

export enum MultiPassCombination {
	DesupCondSubcWaterOnPlateSide = 1,
	DesupCondWaterOnPlateSide = 2,
	CondSubcWaterOnPlateSide = 3,
	DesupCondSubcWaterOnShellSide = 4,
	DesupCondWaterOnShellSide = 5,
	CondSubcWaterOnShellSide = 6,
}

export enum Combination {
	DesupCondSubc = 1,
	DesupCond = 2,
	CondSubc = 3,
}

export enum ManyPointCalc {
	Yes = 1,
	No = 0,
}

export enum CurrencyCode {
	EUR = 1,
	USD = 2,
	GBP = 3,
	CAD = 4,
	CNY = 5,
}

export enum TypeMediumPropertiesCalculation {
	Hot,
	Cold,
	Liquid,
}

export enum DrawingState {
	New = 0,
	Running = 1,
	Failed = 2,
	Completed = 10,
	PermanentlyFailed = 11,
}

export enum PartCascadeType {
	Standard,
	Kettle001,
	Kettle002,
	Kettle003,
}
