import {client} from '../utils/apiClient'
import {queryCache, useMutation, useQuery} from 'react-query'
import Offer, {MFileInfo} from '../model/Offer'

export function useMfiles(offer: Offer, userRole: string, options?) {
	const {mFileId} = offer || {}
	const routeBaseOnRole = userRole == 'Internal' ? 'mFiles' : 'externalMFiles'
	return useQuery<MFileInfo, Error>({
		queryKey: [routeBaseOnRole, mFileId],
		queryFn: () => client(`${routeBaseOnRole}?MfilesOfferQuery=${mFileId}`),
		config: {...options},
	})
}

export function useUpdateOfferMFiles(userRole: string, options?) {
	const routeBaseOnRole = userRole == 'Internal' ? 'mFiles' : 'externalMFiles'
	return useMutation<
		MFileInfo,
		Error,
		{
			mFileOfferId: string
			companyName: string
			customerName: string
			projectName: string
			reference: string
		}
	>(
		({mFileOfferId, companyName, customerName, projectName, reference}) => {
			return client(
				`${routeBaseOnRole}?MfilesOfferQuery=${mFileOfferId}&companyName=${companyName}&customerName=${customerName}&reference=${reference}&projectName=${projectName}`,
			)
		},
		{
			onSettled: data => {
				if (!data) {
					return
				}
				return queryCache.invalidateQueries([routeBaseOnRole, data.offerName])
			},
			...options,
		},
	)
}
