import {atom} from 'recoil'
import {AdvancedSearchQuery} from '../model/Application'

export const previousCalculationState = atom({
	key: 'previousCalculationData',
	default: null,
})

export const searchResultsTableVisibleState = atom({
	key: 'searchResultsTableVisible',
	default: false,
})

export const searchPropertiesFormVisibleState = atom({
	key: 'searchPropertiesFormVisible',
	default: false,
})

export const advancedSearchQueryState = atom({
	key: 'advancedSearchQuery',
	default: null as AdvancedSearchQuery,
})
