import {
	Badge,
	Button,
	Checkbox,
	CheckboxOptionType,
	Divider,
	message,
	Modal,
	// message
} from 'antd'
import queryString from 'query-string'

import {useAuth0} from '@auth0/auth0-react'
import type {CheckboxChangeEvent} from 'antd/es/checkbox'
import {CheckboxValueType} from 'antd/lib/checkbox/Group'
import React, {FC} from 'react'
import Position from '../model/Position'

import {Calculation} from '../model/Calculation'
import {useCreateDrawing, useDrawingServerStatus} from '../state/drawing'
import {DrawingState} from '../model/CalculationEnums'
import {queryCache} from 'react-query'
import DrawingStatusLine from './DrawingStatusLine'

const CheckboxGroup = Checkbox.Group

interface Props {
	offerId: number
	pricingCalculationsQuery: {
		isHeatExchangerIncluded: boolean
		calculationId: string[] | string
	}
	selectedPositions: Position[]
	plainOptions: Array<CheckboxOptionType>
	calculationBeingDrawnList?: Array<CheckboxValueType>
	calculationsData: Calculation[]
}

export const ExportDrawingButton: FC<Props> = ({
	offerId,
	pricingCalculationsQuery,
	selectedPositions,
	plainOptions,
	calculationBeingDrawnList,
	calculationsData,
}) => {
	const [exportDrawingModalOpen, setExportDrawingModalOpen] =
		React.useState<boolean>(false)
	const [checkedList, setCheckedList] = React.useState([])
	const [indeterminate, setIndeterminate] = React.useState(false)
	const [checkAll, setCheckAll] = React.useState(false)
	const {data: status} = useDrawingServerStatus({
		refetchInterval: 2000,
	})
	const {user} = useAuth0()
	const handleModalVisiblity = () => {
		setExportDrawingModalOpen(!exportDrawingModalOpen)
	}

	const [createDrawing] = useCreateDrawing({
		onSuccess: () => {
			queryCache.invalidateQueries(['positions', {offerId}])
			const queryText = queryString.stringify(pricingCalculationsQuery)
			queryCache.invalidateQueries(['pricingCalculations', {queryText}])
			message.success('You will receive the drawings via email')
		},
		onError: () =>
			message.error({
				content: 'Error while request a drawing',
			}),
	})

	const handleExportDrawing = selectedPositions => {
		selectedPositions.map(position => {
			const calcs = position.calculations
			calcs.map(calc => {
				const requestBody = []
				if (checkedList.includes(calc.id)) {
					requestBody.push({
						positionId: calc.positionId,
						calculationId: calc.id,
						email: user.email,
					})
					createDrawing(requestBody)
				}
			})
		})
		setCheckedList([])
	}

	const handleCheckboxGroupChange = (list: CheckboxValueType[]) => {
		setCheckedList(list)
		setIndeterminate(!!list.length && list.length < plainOptions.length)
		setCheckAll(list.length === plainOptions.length)
	}

	const onCheckAllChange = (e: CheckboxChangeEvent) => {
		const changedList = e.target.checked
			? plainOptions.map(item => item.value)
			: []
		setCheckedList(
			changedList.filter(id => !calculationBeingDrawnList.includes(id)),
		)
		setIndeterminate(false)
		setCheckAll(e.target.checked)
	}

	const getDrawingServerStatus = () => {
		let complete = 0,
			inQueue = 0,
			inProgress = 0,
			temporaryFail = 0,
			permanentlyFail = 0
		if (status == undefined) return 'Drawing Server Error'
		status.map(item => {
			switch (item.state) {
				case DrawingState.Completed:
					complete = item.count
					break
				case DrawingState.New:
					inQueue = item.count
					break
				case DrawingState.Running:
					inProgress = item.count
					break
				case DrawingState.Failed:
					temporaryFail = item.count
					break
				case DrawingState.PermanentlyFailed:
					permanentlyFail = item.count
					break
			}
		})
		return (
			<>
				Export drawing server status<br></br> <b>queue:</b> {inQueue},{' '}
				<b>runnning:</b> {inProgress}, <b>fail:</b>{' '}
				{temporaryFail + permanentlyFail}, <b>complete</b> {complete}
			</>
		)
	}

	return (
		<>
			<Badge
				// TODO: This will need to be replaced with API response
				count={
					calculationBeingDrawnList.length > 0
						? calculationBeingDrawnList.length
						: '0'
				}
				color={'blue'}
			>
				<Button
					onClick={handleModalVisiblity}
					type="primary"
					className="vahterus-button"
				>
					Export Drawing
				</Button>
			</Badge>
			<Modal
				title={status ? getDrawingServerStatus() : 'Export drawing'}
				visible={exportDrawingModalOpen}
				onCancel={handleModalVisiblity}
				width={'80%'}
				footer={null}
			>
				<div className="export-drawing-modal-wrapper">
					<div>
						<Checkbox
							indeterminate={indeterminate}
							onChange={onCheckAllChange}
							checked={checkAll}
						>
							Check all
						</Checkbox>
						<Divider />
						<CheckboxGroup
							className="drawing-checkbox-group"
							options={plainOptions}
							onChange={handleCheckboxGroupChange}
							value={checkedList}
						></CheckboxGroup>
						<Button
							className="vahterus-button__export-drawing"
							type="primary"
							disabled={checkedList.length == 0}
							onClick={() => {
								handleExportDrawing(selectedPositions)
							}}
						>
							Export
						</Button>
					</div>
					<div className="custom-divider"></div>
					<div>
						<h4>Your drawing status</h4>
						<ul>
							{calculationsData
								.filter(calculation => calculation.drawing.length > 0)
								.map(calculation => {
									return calculation.drawing.map((drawing, index) => {
										return <DrawingStatusLine drawing={drawing} key={index} />
									})
								})}
						</ul>
					</div>
				</div>
			</Modal>
		</>
	)
}
