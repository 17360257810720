import React from 'react'
import {Button as AntButton} from 'antd'
import {ButtonProps} from 'antd/lib/button'
import classNames from 'classnames'

const Button: React.FunctionComponent<ButtonProps> = ({
	children,
	className,
	...props
}) => {
	const classes = classNames('vahterus-button', className)

	return (
		<AntButton {...props} className={classes}>
			{children}
		</AntButton>
	)
}

Button.defaultProps = {
	type: 'primary',
}

export default Button
