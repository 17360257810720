import {Application} from './Application'
import {
	CalculationState,
	FlowDirection,
	HotSide,
	Medium,
	NumberOfPasses,
	PlateShellConnections,
} from './CalculationEnums'

export default interface OnePhase extends Application {
	numberOfPassesHotSide: NumberOfPasses
	numberOfPassesColdSide: NumberOfPasses
	flowDirection: FlowDirection
	hotSide: HotSide
	pressureDropHotSide: number
	pressureDropColdSide: number
	inletTempHotSide: number
	outletTempHotSide: number
	inletTempColdSide: number
	outletTempColdSide: number
	hotSideMedium: OnePhaseLiquidMedium
	coldSideMedium: OnePhaseLiquidMedium
	mediumHotSide: Medium
	mediumColdSide: Medium
	concentrationHotSide: number
	concentrationColdSide: number
	pressureHotSide: number
	pressureColdSide: number
	plateConnections: PlateShellConnections
	flowRateHotSide: number
	flowRateColdSide: number
	dynamicViscosityHotSide: number
	specificGravityHotSide: number
	specificHeatHotSide: number
	thermalConductivityHotSide: number
	dynamicViscosityColdSide: number
	specificGravityColdSide: number
	specificHeatColdSide: number
	thermalConductivityColdSide: number
	pressureDropHotSideOutput: number
	pressureDropColdSideOutput: number
	foulingFactorOutput: number
	nozzlePlatesideText: string
	velocityPlate: number
	optWPlateLow: number
	optWPlateHigh: number
	optWShellLow: number
	optWShellHigh: number
	temperatureEfficiency: number
	ntuNumber: number
	calculationSuccessful: CalculationState
}

export enum OnePhaseLiquidMedium {
	Liquid = 1,
	Gas = 2,
}
