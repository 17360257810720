import {
	ApplicationType,
	DesignPressureOption,
	EndPlateType,
	Feet,
	FlowDirection,
	FlowDirectorMaterial,
	ManyPointCalc,
	Medium,
	MediumMixture,
	MediumSource,
	NumberOfPlateNozzles,
	NumberOfShellNozzles,
	Paint,
	PlateMaterial,
	PlateShellConnections,
	PlateThickness,
	PlateType,
	PositionEnum,
	ShellMaterial,
	FractionType,
	Size,
	TypeApproval,
	UnitAreaOption,
	UnitCapacityOption,
	UnitDynamicViscosityOption,
	UnitEntalphyOption,
	UnitFoulingFactorOption,
	UnitKValueOption,
	UnitMassFlowOption,
	UnitMassOption,
	UnitPressureOption,
	UnitSpecificGravityOption,
	UnitSpecificHeatOption,
	UnitSurfaceTensionOption,
	UnitTemperatureLMTDOption,
	UnitThermalConductivityOption,
	UnitVelocityOption,
	UnitVolumeOption,
	CurrencyCode,
	NozzleShell,
	DesignClassification,
	UnitAirHumidityOption,
	Combination,
} from './CalculationEnums'
import {OnePhaseLiquidMedium} from './OnePhase'
import {Circulation} from './Evaporator'
import {
	CorrosionAndErosion,
	DangerousnessOfTheContentOfPshePlatePack,
	Fatigue,
	FluidTemperatureInPlatepack,
	HaPlateSizeAndType,
	HaPlateMaterial,
	InspectionOfThePlatepack,
	Installation,
	MeansOfExaminationForPlatepack,
	OperationLifetime,
	SignificanceOfTheProcessMediaConfusionCausedByPlateDamage,
	VolumeOfContentsInPlatepack,
	HaPlateThickness,
	AsmeOptions,
} from './HaEnums'

//==============================================================
// Field Enum options to labels
//==============================================================
export const applicationLabels = {
	[ApplicationType.OnePhase]: '1-Phase',
	[ApplicationType.Condenser]: 'Condensers',
	[ApplicationType.Evaporators]: 'Evaporators',
	[ApplicationType.Cascade]: 'Cascade',
	[ApplicationType.PartialCascade]: 'Partial-Cascade',
	[ApplicationType.MultiCondenser]: 'MultiCondensers',
}

export const combinationLabels = {
	[Combination.DesupCondSubc]: 'Desup + Cond + Subc',
	[Combination.DesupCond]: 'Desup + Cond',
	[Combination.CondSubc]: 'Cond + Subc',
}

export const circulationLabels = {
	[Circulation.External]: 'External',
	[Circulation.Internal]: 'Internal',
	[Circulation.Combined]: 'Combined',
	[Circulation.Kettle]: 'Kettle',
}

export const sizeLabels = {
	[Size.Size2]: 'Size 2',
	[Size.Size3]: 'Size 3',
	[Size.Size4]: 'Size 4',
	[Size.Size5]: 'Size 5',
	[Size.Size6]: 'Size 6',
	[Size.Size7]: 'Size 7',
	[Size.Size9]: 'Size 9',
	[Size.Size12]: 'Size 12',
	[Size.Size14]: 'Size 14',
}

export const designPressureLabels = {
	[DesignPressureOption._6bar]: '6 bar(g)',
	[DesignPressureOption._10bar]: '10 bar(g)',
	[DesignPressureOption._16bar]: '16 bar(g)',
	[DesignPressureOption._25bar]: '25 bar(g)',
	[DesignPressureOption._40bar]: '40 bar(g)',
	[DesignPressureOption._60bar]: '60 bar(g)',
	[DesignPressureOption._80bar]: '80 bar(g)',
	[DesignPressureOption._100bar]: '100 bar(g)',
	[DesignPressureOption._120bar]: '120 bar(g)',
	[DesignPressureOption._150bar]: '150 bar(g)',
	[DesignPressureOption._150psi]: '150 psi(g)',
	[DesignPressureOption._300psi]: '300 psi(g)',
	[DesignPressureOption._600psi]: '600 psi(g)',
	[DesignPressureOption._900psi]: '900 psi(g)',
	[DesignPressureOption._1500psi]: '1500 psi(g)',
}

export const endPlateTypeLabels = {
	[EndPlateType.NoEndPlate]: '',
	[EndPlateType.OpenableVersion]: 'Openable Version',
	[EndPlateType.WeldedEndPlates]: 'Welded End Plates',
	[EndPlateType.WeldedEccentric]: 'Welded/Eccentric',
	[EndPlateType.OpenableDouble]: 'Openable - Double',
	[EndPlateType.OpenableGirthFlange]: 'Openable - Girth Flange',
	[EndPlateType.OpenableGirthFlangeDouble]: 'Openable - Girth Flange (double)',
	[EndPlateType.Compact]: 'Compact',
	[EndPlateType.Kettle001]: 'Kettle-001',
	[EndPlateType.Kettle002]: 'Kettle-002',
	[EndPlateType.Kettle003]: 'Kettle-003',
}

export const feetLabels = {
	[Feet.NoFeet]: 'No feet',
	[Feet.EndPlateFeetBoltedCS]: 'EndPlate feet (Bolted CS)',
	[Feet.EndPlateFeetBoltedAISI]: 'EndPlate feet (Bolted AISI)',
	[Feet.ShellFeetCS]: 'Shell feet (CS)',
	[Feet.ShellFeetAISI]: 'Shell feet (AISI)',
	[Feet.EndPlateFeetWeldedCS]: 'EndPlate feet (Welded CS)',
	[Feet.EndPlateFeetWeldedAISI]: 'EndPlate feet (Welded AISI)',
	[Feet.BraketsWelededCS]: 'Brakets (Welded CS)',
	[Feet.BraketsWeldedAISI]: 'Brakets (Welded AISI)',
	[Feet.VerticalFeetWeldedCS]: 'Vertical feet (Welded CS)',
	[Feet.VerticalFeetWeldedAISI]: 'Vertical feet (Welded AISI)',
	[Feet.SleeveWeldedCS]: 'Sleeve (Welded CS)',
	[Feet.SleeveWeldedAISI]: 'Sleeve (Welded AISI)',
}

export const flowDirectionLabels = {
	[FlowDirection.Countercurrent]: 'Countercurrent',
	[FlowDirection.Cocurrent]: 'Co-current',
	[FlowDirection.Crossflow]: 'Crossflow',
}

export const flowDirectorMaterialLabels = {
	[FlowDirectorMaterial.EPDM]: 'EPDM',
	[FlowDirectorMaterial.Nitrile]: 'Nitrile',
	[FlowDirectorMaterial.Fluorinesilicone]: 'Fluorinesilicone',
	[FlowDirectorMaterial.SiliconeN]: 'Silicone (N)',
	[FlowDirectorMaterial.SiliconeHT]: 'Silicone (HT)',
	[FlowDirectorMaterial.PTFE]: 'PTFE',
	[FlowDirectorMaterial.Graphite]: 'Graphite',
	[FlowDirectorMaterial.Other]: 'Other',
	[FlowDirectorMaterial.NoFlDirector]: 'No Flow Director',
}

export const areaLabels = {
	[UnitAreaOption.m2]: 'm²',
	[UnitAreaOption.in2]: 'in²',
	[UnitAreaOption.ft2]: 'ft²',
	[UnitAreaOption.yd2]: 'yd²',
}

export const kValueLabels = {
	[UnitKValueOption.Wm2]: 'W/m²K',
	[UnitKValueOption.Btuhft2F]: 'Btu/(h.ft².°F)',
	[UnitKValueOption.Btusft2F]: 'Btu/(s.ft².°F)',
	[UnitKValueOption.kcalhm2C]: 'kcal/(h.m².°C)',
	[UnitKValueOption.calscm2C]: 'cal/(s.cm².°C)',
}

export const numberOfPlatesLabels = {
	[NumberOfPlateNozzles.oneEnd]: 'Two pc (one end)',
	[NumberOfPlateNozzles.differentEnds]: 'Two pc (different ends)',
	[NumberOfPlateNozzles.bothEnds]: 'Four pc (both ends)',
}

export const numberOfShellsLabels = {
	[NumberOfShellNozzles._1pc]: '1 pc',
	[NumberOfShellNozzles._2pc]: '2 pc',
	[NumberOfShellNozzles._4pc]: '4 pc',
}

export const paintingLabels = {
	[Paint.System0]: 'System 0 grey (INTERPRIME 198, 50 µm)',
	[Paint.System1WhiteRAL9010]: 'System 1 white (INTERCURE 99, 100 µm)',
	[Paint.System1BlueRAL5010]: 'System 1 blue (INTERCURE 99, 100 µm)',
	[Paint.System21]: 'System 2.1 greenish grey (INTERZINC 22, 50 µm)',
	[Paint.System22]:
		'System 2.2 silver (INTERZINC 22, 50 µm + INTERTHERM 50, 25 µm)',
	[Paint.System3]:
		'System 3 white (INTERZINC 52, 80 µm + INTERCURE 99, 240 µm)',
	[Paint.System4]: 'System 4 white (INTERTHERM 228, 200 µm)',
	[Paint.System5]: 'System 5 (Aluminum oxide blasting)',
	[Paint.System6]: 'System 6 (Steel grit blasting)',
	[Paint.SystemE]: 'System E',
}

export const plateShellConnectionsLabels = {
	[PlateShellConnections.StubEnds]: 'Stub ends',
	[PlateShellConnections.ENFlanges]: 'EN-Flanges-SS',
	[PlateShellConnections.ANSIFlanges]: 'ANSI-Flanges-SS',
	[PlateShellConnections.MaleThread]: 'Male Thread',
	[PlateShellConnections.EnFlangesCS]: 'En-Flanges-CS',
	[PlateShellConnections.ANSIFlangesCS]: 'ANSI-Flanges-CS',
	[PlateShellConnections.ENFlangesTitanium]: 'EN-Flanges-Titanium',
	[PlateShellConnections.ANSIFlangesTitanium]: 'ANSI-Flanges-Titanium',
	[PlateShellConnections.StubEndSTReduced]: 'Stub-End-ST-Reduced',
	[PlateShellConnections.StubEndClient]: 'Stub-End-Client',
}

export const plateMaterialLabels = {
	[PlateMaterial.AISI316L]: 'AISI 316L',
	[PlateMaterial.Titanium]: 'Titanium',
	[PlateMaterial.AISI904L]: 'AISI 904L',
	[PlateMaterial._254SMO]: '254SMO',
	[PlateMaterial.C22]: 'C-22',
	[PlateMaterial.Duplex2205]: 'Duplex (2205)',
	[PlateMaterial.AISI304L]: 'AISI 304L',
}

export const plateThicknessLabels = {
	[PlateThickness._0_7mm]: '0.7 mm',
	[PlateThickness._0_8mm]: '0.8 mm',
	[PlateThickness._1mm]: '1.0 mm',
	[PlateThickness._1_2mm]: '1.2 mm',
	[PlateThickness._1_25mm]: '1.25 mm',
	[PlateThickness._1_5mm]: '1.5 mm',
}

export const plateTypeLabels = {
	[PlateType.HH]: 'HH=1',
	[PlateType.LL]: 'LL=2',
	[PlateType.HA]: 'HA=3',
	[PlateType.TH]: 'TH=3',
	[PlateType.WA]: 'WA=3',
	[PlateType.LA]: 'LA=4',
	[PlateType.WH]: 'WH=7',
	[PlateType.WL]: 'WL=9',
	[PlateType.SH]: 'SH=11',
}

export const positionLabels = {
	[PositionEnum._Horizontal]: 'Horizontal',
	[PositionEnum._Vertical]: 'Vertical',
}

export const typeApporvalLabels = {
	[TypeApproval.PED]: 'PED',
	[TypeApproval.ASME]: 'ASME',
	[TypeApproval.PEDEN]: 'PED-EN',
}

export const designClassificationLabels = {
	[DesignClassification.PED]: 'PED',
	[DesignClassification.U_STAMP]: 'U-Stamp',
	[DesignClassification.WITHOUTU_STAMP]: 'Without U-Stamp',
	[DesignClassification.ABS]: 'ABS',
	[DesignClassification.BV]: 'BV',
	[DesignClassification.CCS]: 'CCS',
	[DesignClassification.DNV]: 'DNV',
	[DesignClassification.KRS]: 'KRS',
	[DesignClassification.LR]: 'LR',
	[DesignClassification.NK]: 'NK',
	[DesignClassification.Rina]: 'Rina',
	[DesignClassification.SELO]: 'Selo',
}

export const nozzleShellASMELabels = {
	[NozzleShell.DN25]: '1"',
	[NozzleShell.DN32]: '1.25"',
	[NozzleShell.DN40]: '1.5"',
	[NozzleShell.DN50]: '2"',
	[NozzleShell.DN65]: '2.5"',
	[NozzleShell.DN80]: '3"',
	[NozzleShell.DN100]: '4"',
	[NozzleShell.DN125]: '5"',
	[NozzleShell.DN150]: '6"',
	[NozzleShell.DN200]: '8"',
	[NozzleShell.DN250]: '10"',
	[NozzleShell.DN300]: '12"',
	[NozzleShell.DN350]: '14"',
	[NozzleShell.DN400]: '16"',
	[NozzleShell.DN500]: '20"',
	[NozzleShell.DN600]: '24"',
	[NozzleShell.DN800]: '32"',
	[NozzleShell.DN1000]: '40"',
}

export const nozzleShellPEDLabels = {
	[NozzleShell.DN25]: 25,
	[NozzleShell.DN32]: 32,
	[NozzleShell.DN40]: 40,
	[NozzleShell.DN50]: 50,
	[NozzleShell.DN65]: 65,
	[NozzleShell.DN80]: 80,
	[NozzleShell.DN100]: 100,
	[NozzleShell.DN125]: 125,
	[NozzleShell.DN150]: 150,
	[NozzleShell.DN200]: 200,
	[NozzleShell.DN250]: 250,
	[NozzleShell.DN300]: 300,
	[NozzleShell.DN350]: 350,
	[NozzleShell.DN400]: 400,
	[NozzleShell.DN500]: 500,
	[NozzleShell.DN600]: 600,
	[NozzleShell.DN800]: 800,
	[NozzleShell.DN1000]: 1000,
}

export const nozzleShellPEDENLabels = {
	[NozzleShell.DN25]: 25,
	[NozzleShell.DN32]: 32,
	[NozzleShell.DN40]: 40,
	[NozzleShell.DN50]: 50,
	[NozzleShell.DN65]: 65,
	[NozzleShell.DN80]: 80,
	[NozzleShell.DN100]: 100,
	[NozzleShell.DN125]: 125,
	[NozzleShell.DN150]: 150,
	[NozzleShell.DN200]: 200,
	[NozzleShell.DN250]: 250,
	[NozzleShell.DN300]: 300,
	[NozzleShell.DN350]: 350,
	[NozzleShell.DN400]: 400,
	[NozzleShell.DN500]: 500,
	[NozzleShell.DN600]: 600,
	[NozzleShell.DN800]: 800,
	[NozzleShell.DN1000]: 1000,
}

export const shellMaterialLabels = {
	[ShellMaterial.AISI316L_316]: 'AISI 316L/316',
	[ShellMaterial.CarbonSteel]: 'Carbon Steel',
	[ShellMaterial.AISI304L]: 'AISI 304L',
	[ShellMaterial.Duplex2205]: 'Duplex (2205)',
	[ShellMaterial._254SMO]: '254SMO',
}

export const specificHeatUnitLables = {
	[UnitSpecificHeatOption.JkgK]: 'J/kgK',
	[UnitSpecificHeatOption.calgC]: 'cal/(g°C)',
	[UnitSpecificHeatOption.kcalkgC]: 'kcal/(kg°C)',
	[UnitSpecificHeatOption.btylbF]: 'Btu/(lb°F)',
}

export const thermalConductivityUnitLabels = {
	[UnitThermalConductivityOption.WmK]: 'W/mK',
	[UnitThermalConductivityOption.Btu_ins_ft2_F]: 'Btuin/(sft²°F)',
	[UnitThermalConductivityOption.Btu_inh_ft2_F]: 'Btuin/(hft²°F)',
	[UnitThermalConductivityOption.Btu_fth_ft2_F]: 'Btuft/(hft²°F)',
	[UnitThermalConductivityOption.cals_cm_C]: 'cal/(scm°C)',
	[UnitThermalConductivityOption.kcalh_m_C]: 'kcal/(hm°C)',
}

export const mediumSourceLabels = {
	[MediumSource.Imported]: 'Imported',
	[MediumSource.Brine]: 'Brine',
	[MediumSource.Liquid]: 'Liquids-Internal',
	[MediumSource.RefProp]: 'RefProp',
}

export const mediumMixtureLabels = {
	[MediumMixture.Mixture]: 'Mixture',
	[MediumMixture.PureMedium]: 'Pure',
}

export const liquidMediumLabels = {
	[OnePhaseLiquidMedium.Gas]: 'Gas',
	[OnePhaseLiquidMedium.Liquid]: 'Liquid',
}

export const ManyPointCalcLabels = {
	[ManyPointCalc.Yes]: 'Yes',
	[ManyPointCalc.No]: 'No',
}

export const mediumLabels = {
	[Medium.OtherMedia]: 'Other Media',
	[Medium.Water]: 'Water',
	[Medium.CaCl2]: 'CaCl2',
	[Medium.Ethyleneglycol]: 'Ethyleneglycol',
	[Medium.Freezium]: 'Freezium',
	[Medium.Hycool]: 'Hycool(1-5)',
	[Medium.Pekasol2000]: 'Pekasol2000',
	[Medium.Propyleneglycol]: 'Propyleneglycol',
	[Medium.Tyfoxit]: 'Tyfoxit(1-5)',
	[Medium.NH3]: 'NH3',
	[Medium.CO2]: 'CO2',
	[Medium.Propane]: 'Propane',
	[Medium.R134a]: 'R134a',
	[Medium.R22]: 'R22',
	[Medium.R23]: 'R23',
	[Medium.R404a]: 'R404a',
	[Medium.R407c]: 'R407c',
	[Medium.R410a]: 'R410a',
	[Medium.R507A]: 'R507A',
	[Medium.R408a]: 'R408a',
	[Medium.ArticSHC226]: 'ArticSHC226',
	[Medium.DowthermJ]: 'DowthermJ',
	[Medium.Essotherm500]: 'Essotherm500',
	[Medium.GilothermD12]: 'GilothermD12',
	[Medium.MarlothermL]: 'MarlothermL',
	[Medium.MarlothermLH]: 'MarlothermLH',
	[Medium.MarlothermS]: 'MarlothermS',
	[Medium.Mobiltherm603]: 'Mobiltherm603',
	[Medium.Palmoil]: 'Palmoil',
	[Medium.SAE40W]: 'SAE40W',
	[Medium.ShellThermiaB]: 'ShellThermiaB',
	[Medium.SylthermXLT]: 'SylthermXLT',
	[Medium.Syltherm]: 'Syltherm',
	[Medium.Temper]: 'Temper',
	[Medium.ACETONE30230C]: 'Acetone[-30/230C]',
	[Medium.ARTICSHC22620100C]: 'Artic SHC 226 [20/100C]',
	[Medium.ARTICSHC32640100C]: 'Artic SHC 326[40/100C]',
	[Medium.BENZOL2575C]: 'Benzol[25/75C]',
	[Medium.BIODIESEL10120C]: 'Biodiesel[10/120C]',
	[Medium.BPTRANSCALN50300C]: 'BP Transcal N[50/300C]',
	[Medium.BSE17040100C]: 'BSE170[40/100C]',
	[Medium.BUTHYLACETAT1020C]: 'Buthylacetat[-10/20C]',
	[Medium.CALFLOLT2020C]: 'Calflo LT[-20/20C]',
	[Medium.CALFLOLT80160C]: 'Calflo LT[80/160C]',
	[Medium.CL001BTTMS20120C]: 'CL-001 bttms[20/120C]',
	[Medium.CL002BTTMS35140C]: 'CL-002 bttms[35/140C]',
	[Medium.CL002FEED60130C]: 'CL-002 Feed[60/130C]',
	[Medium.CLAVUSG6840100C]: 'Clavus G68[40/100C]',
	[Medium.COCOABUTTER20170C]: 'Cocoa Butter[20/170C]',
	[Medium.COOKINGOIL160210C]: 'Cooking Oil[160/210C]',
	[Medium.CP460046378933C]: 'CP-4600-46[37.8/93.3C]',
	[Medium.CRUDEFATTYACID70100C]: 'Crude Fatty Acid[70/100C]',
	[Medium.DIESELOIL520C]: 'Diesel oil[-5/20C]',
	[Medium.DIPHYL180340C]: 'Diphyl[180/340C]',
	[Medium.DISTILLEDFATTYACID16099C]: 'Distilled Fatty Acid[160/99C]',
	[Medium.DOWTHERMAC255405C]: 'Dowtherm AC[255/405C]',
	[Medium.DOWTHERMJ5165C]: 'Dowtherm J[5/165C]',
	[Medium.DOWTHERMJ2545C]: 'Dowtherm J[-25/45C]',
	[Medium.DOWTHERMJ100285C]: 'Dowtherm J[100/285C]',
	[Medium.DOWTHERMJ35125C]: 'Dowtherm J[35/125C]',
	[Medium.DOWTHERMQ40120C]: 'Dowtherm Q[40/120C]',
	[Medium.DYNALINEMV100160C]: 'Dynaline MV[-100/160C]',
	[Medium.ETHYLENE10050C]: 'Ethylene[-100/50C]',
	[Medium.FRICK11489878C]: 'Frick #11[48.9/87.8C]',
	[Medium.FRICK13489878C]: 'Frick #13[48.9/87.8C]',
	[Medium.FRICK14489878C]: 'Frick #14[48.9/87.8C]',
	[Medium.FRICK2A489878C]: 'Frick #2a[48.9/87.8C]',
	[Medium.FRICK3489878C]: 'Frick #3[48.9/87.8C]',
	[Medium.FRICK33893C]: 'Frick #3[38/93C]',
	[Medium.FRICK94988C]: 'Frick #9[49/88C]',
	[Medium.FRICKNG15441011C]: 'Frick #NG1[54.4/101.1C]',
	[Medium.HEAVYFUELOIL380CST50110C]: 'Heavy fuel oil 380 cSt[50/110C]',
	[Medium.HEAVYFUELOIL380CST110150C]: 'Heavy fuel oil 380 cSt[110/150C]',
	[Medium.HEAVYFUELOIL700CST50150C]: 'Heavy fuel oil 700 cSt[50/150C]',
	[Medium.HEAVYFUELOIL700CST110150C]: 'Heavy fuel oil 700 cSt[110/150C]',
	[Medium.HEXANE3050C]: 'Hexane[-30/-50C]',
	[Medium.KEROSINE5070C]: 'Kerosine[50/70C]',
	[Medium.LANOLIN4090C]: 'Lanolin[40/90C]',
	[Medium.LEANGLYCOL45140C]: 'Lean glycol[45/140C]',
	[Medium.LEANGLYCOL5751909C]: 'Lean Glycol[57.5/190.9C]',
	[Medium.LEANGLYCOL4141933C]: 'Lean Glycol[41.4/193.3C]',
	[Medium.LEANMEG32128C]: 'Lean MEG[32/128C]',
	[Medium.LEANSULFINOLD601287C]: 'Lean Sulfinol D[60/128.7C]',
	[Medium.LEANTEG5751909C]: 'Lean TEG[57.5/190.9C]',
	[Medium.LEANTEG451268C]: 'Lean TEG[45/126.8C]',
	[Medium.LEANTEG78210C]: 'Lean TEG[78/210C]',
	[Medium.LEANTEG4641906C]: 'Lean TEG[46.4/190.6C]',
	[Medium.LPG29130C]: 'LPG[-29.1/30C]',
	[Medium.LVGO104159C]: 'LVGO[104/159C]',
	[Medium.M1002080C]: 'M100[20/80C]',
	[Medium.MARLOTHERM2740C]: 'Marlotherm[27/40C]',
	[Medium.MARLOTHERMSH40120C]: 'Marlotherm SH[40/120C]',
	[Medium.MARLOTHERMX60300C]: 'Marlotherm X[-60/300C]',
	[Medium.MEG502080C]: 'MEG 50%[20/80C]',
	[Medium.MEGBRINE075C]: 'MEG Brine[0/75C]',
	[Medium.METHANOL30240C]: 'Methanol[-30/240C]',
	[Medium.METHANOL1020C]: 'Methanol[-10/20C]',
	[Medium.MINERALOIL100285C]: 'Mineral oil[100/285C]',
	[Medium.MOBILTHERM605185205C]: 'Mobiltherm 605[185/205C]',
	[Medium.MÄNTYÖLJY50150C]: 'Mäntyöljy[50/150C]',
	[Medium.NAOCL1030C]: 'NaOCl[10/30C]',
	[Medium.NHEXANE6020C]: 'n-hexane[-60/20C]',
	[Medium.NHEXANE1459C]: 'n-hexane[14/59C]',
	[Medium.NOVECHFE71006585C]: 'Novec HFE 7100[-65/-85C]',
	[Medium.ORIMULSION4001070C]: 'ORIMULSION400[10/70C]',
	[Medium.PARATHERMNF200350C]: 'Paratherm NF[200/350C]',
	[Medium.PHENOLICRESIN1030C]: 'Phenolic Resin[10/30C]',
	[Medium.PLASTIFICANTEDOP90150C]: 'Plastificante DOP[90/150C]',
	[Medium.POLYOL1030C]: 'Polyol[10/30C]',
	[Medium.POLYURETHANE4090C]: 'Polyurethane[40/90C]',
	[Medium.POR1803090C]: 'POR180[30/90C]',
	[Medium.PRODUCEDWATER60100C]: 'Produced Water[60/100C]',
	[Medium.REACTOREFFLUENT112226C]: 'Reactor Effluent[112/226C]',
	[Medium.REACTORFEED28220C]: 'Reactor Feed[28/220C]',
	[Medium.REFLO4630100C]: 'Reflo 46[30/100C]',
	[Medium.REFLO6840120C]: 'Reflo 68[40/120C]',
	[Medium.REOFOS75200C]: 'Reofos[75/200C]',
	[Medium.REOFOS175200C]: 'Reofos 1[75/200C]',
	[Medium.RICHGLYCOL466170C]: 'Rich Glycol[46.6/170C]',
	[Medium.RICHGLYCOL666160C]: 'Rich Glycol[66.6/160C]',
	[Medium.RICHMEG2254C]: 'Rich MEG[22/54C]',
	[Medium.RICHSULFINOLD401087C]: 'Rich Sulfinol D[40/108.7C]',
	[Medium.RICHTEG669170C]: 'Rich TEG[66.9/170C]',
	[Medium.RICHTEG4794C]: 'Rich TEG[4/79.4C]',
	[Medium.RICHTEG511180C]: 'Rich TEG[51.1/180C]',
	[Medium.RICHTEG45180C]: 'Rich TEG[45/180C]',
	[Medium.SAE4060100C]: 'SAE40[60/100C]',
	[Medium.SANTOTHERM6660300C]: 'Santotherm 66[60/300C]',
	[Medium.SEAWATER245C]: 'Sea water[-2/45C]',
	[Medium.SERIOLAETA10050300C]: 'Seriola ETA 100[50/300C]',
	[Medium.SHELLTHERMIAB20150C]: 'Shell Thermia B[20/150C]',
	[Medium.SODIUMNITRATE401010C]: 'Sodium Nitrate 40%[-10/10C]',
	[Medium.SOLESTLT3248985C]: 'Solest LT 32[48.9/85C]',
	[Medium.SOLVENT5030100C]: 'Solvent 50[30/100C]',
	[Medium.SOYABEANOIL60260C]: 'Soyabean Oil[60/260C]',
	[Medium.SUNFLOWEROIL160210C]: 'Sunflower Oil[160/210C]',
	[Medium.SYLTHERMXLT30150C]: 'Syltherm XLT[-30/150C]',
	[Medium.SYLTHERMXLT400C]: 'Syltherm XLT[-40/0C]',
	[Medium.TALLOW60226C]: 'Tallow[60/226C]',
	[Medium.TEG58120150C]: 'TEG 58%[120/150C]',
	[Medium.TEXACOA6830110C]: 'Texaco A68[30/110C]',
	[Medium.TEXACOPREMIUM6830110C]: 'Texaco Premium 68[30/110C]',
	[Medium.TEXACOWF6830110C]: 'Texaco WF68[30/110C]',
	[Medium.THERMERA351020C]: 'Thermera 35% [-10/20C]',
	[Medium.THERMERA502520C]: 'Thermera 50%[-25/20C]',
	[Medium.THERMINOL55149216C]: 'Therminol 55[149/216C]',
	[Medium.THERMINOL66120200C]: 'Therminol 66[120/200C]',
	[Medium.THERMINOL72220320C]: 'Therminol 72[220/320C]',
	[Medium.THERMINOLD12200C]: 'Therminol D12[-20/0C]',
	[Medium.THERMINOLD1249149C]: 'Therminol D12[49/149C]',
	[Medium.THERMINOLLT4016C]: 'Therminol LT[-40/16C]',
	[Medium.TMP58210C]: 'TMP[58/210C]',
	[Medium.TOLUENE20240C]: 'Toluene[-20/240C]',
	[Medium.TRANSCALLT0130C]: 'Transcal LT[0/130C]',
	[Medium.TRANSCALN1030C]: 'Transcal N[10/30C]',
	[Medium.UCOTHERMFG8450C]: 'UCOTHERM FG-8[-45/0C]',
	[Medium.UCOTHERMFG80280C]: 'UCOTHERM FG-8[0/280C]',
	[Medium.VEGOIL100260C]: 'Veg Oil[100/260C]',
	[Medium.VG6840100C]: 'VG68[40/100C]',
	[Medium.VGO100245C]: 'VGO[100/245C]',
	[Medium.XYLENE25145C]: 'Xylene[25/145C]',
	[Medium.ISOVG2230180C]: 'ISO VG 22[-30/180C]',
	[Medium.ISOVG3230180C]: 'ISO VG 32[-30/180C]',
	[Medium.ISOVG4630180C]: 'ISO VG 46[-30/180C]',
	[Medium.ISOVG6815180C]: 'ISO VG 68[-15/180C]',
	[Medium.ISOVG10015180C]: 'ISO VG 100[-15/180C]',
	[Medium.ISOVG1500140C]: 'ISO VG 150[0/140C]',
	[Medium.ISOVG2200140C]: 'ISO VG 220[0/140C]',
	[Medium.MOBILARCTICSHC2340200C]: 'Mobil Arctic SHC 234[0/200C]',
	[Medium.THERMINOLVP12425C]: 'Therminol VP[12/425C]',
	[Medium.MOLTENSALT260593C]: 'Molten Salt[260/593C]',
	[Medium.NXT717OIL0140C]: 'NXT-717 oil[0/140C]',
	[Medium._1BUTENE]: '1BUTENE',
	[Medium.ACETONE]: 'ACETONE',
	[Medium.AIR]: 'AIR',
	[Medium.AMMONIA]: 'AMMONIA',
	[Medium.ARGON]: 'ARGON',
	[Medium.BENZENE]: 'BENZENE',
	[Medium.BUTANE]: 'BUTANE',
	[Medium.C11]: 'C11',
	[Medium.C12]: 'C12',
	[Medium.C1CC6]: 'C1CC6',
	[Medium.C2BUTENE]: 'C2BUTENE',
	[Medium.C3CC6]: 'C3CC6',
	[Medium.C4F10]: 'C4F10',
	[Medium.C5F12]: 'C5F12',
	[Medium.CF3I]: 'CF3I',
	[Medium.CO]: 'CO',
	[Medium.CO2Refprop]: 'CO2',
	[Medium.COS]: 'COS',
	[Medium.CYCLOHEX]: 'CYCLOHEX',
	[Medium.CYCLOPEN]: 'CYCLOPEN',
	[Medium.CYCLOPRO]: 'CYCLOPRO',
	[Medium.D2]: 'D2',
	[Medium.D2O]: 'D2O',
	[Medium.D4]: 'D4',
	[Medium.D5]: 'D5',
	[Medium.D6]: 'D6',
	[Medium.DECANE]: 'DECANE',
	[Medium.DEE]: 'DEE',
	[Medium.DMC]: 'DMC',
	[Medium.DME]: 'DME',
	[Medium.EBENZENE]: 'EBENZENE',
	[Medium.ETHANE]: 'ETHANE',
	[Medium.ETHANOL]: 'ETHANOL',
	[Medium.ETHYLENE]: 'ETHYLENE',
	[Medium.FLUORINE]: 'FLUORINE',
	[Medium.H2S]: 'H2S',
	[Medium.HCL]: 'HCL',
	[Medium.HELIUM]: 'HELIUM',
	[Medium.HEPTANE]: 'HEPTANE',
	[Medium.HEXANE]: 'HEXANE',
	[Medium.HYDROGEN]: 'HYDROGEN',
	[Medium.IBUTENE]: 'IBUTENE',
	[Medium.IHEXANE]: 'IHEXANE',
	[Medium.IOCTANE]: 'IOCTANE',
	[Medium.IPENTANE]: 'IPENTANE',
	[Medium.ISOBUTAN]: 'ISOBUTAN',
	[Medium.KRYPTON]: 'KRYPTON',
	[Medium.LNG]: 'LNG.mix',
	[Medium.MD2M]: 'MD2M',
	[Medium.MD3M]: 'MD3M',
	[Medium.MD4M]: 'MD4M',
	[Medium.MDM]: 'MDM',
	[Medium.METHANE]: 'METHANE',
	[Medium.METHANOL]: 'METHANOL',
	[Medium.MLINOLEA]: 'MLINOLEA',
	[Medium.MLINOLEN]: 'MLINOLEN',
	[Medium.MM]: 'MM',
	[Medium.MOLEATE]: 'MOLEATE',
	[Medium.MPALMITA]: 'MPALMITA',
	[Medium.MSTEARAT]: 'MSTEARAT',
	[Medium.MXYLENE]: 'MXYLENE',
	[Medium.N2O]: 'N2O',
	[Medium.NEON]: 'NEON',
	[Medium.NEOPENTN]: 'NEOPENTN',
	[Medium.NF3]: 'NF3',
	[Medium.NITROGEN]: 'NITROGEN',
	[Medium.NONANE]: 'NONANE',
	[Medium.NOVEC649]: 'NOVEC649',
	[Medium.OCTANE]: 'OCTANE',
	[Medium.ORTHOHYD]: 'ORTHOHYD',
	[Medium.OXYGEN]: 'OXYGEN',
	[Medium.OXYLENE]: 'OXYLENE',
	[Medium.PARAHYD]: 'PARAHYD',
	[Medium.PENTANE]: 'PENTANE',
	[Medium.PROPANE]: 'PROPANE',
	[Medium.PROPYLEN]: 'PROPYLEN',
	[Medium.PROPYNE]: 'PROPYNE',
	[Medium.PXYLENE]: 'PXYLENE',
	[Medium.R11]: 'R11',
	[Medium.R113]: 'R113',
	[Medium.R114]: 'R114',
	[Medium.R115]: 'R115',
	[Medium.R116]: 'R116',
	[Medium.R12]: 'R12',
	[Medium.R1216]: 'R1216',
	[Medium.R123]: 'R123',
	[Medium.R1233ZDE]: 'R1233ZDE',
	[Medium.R1234YF]: 'R1234YF',
	[Medium.R1234ZEE]: 'R1234ZEE',
	[Medium.R124]: 'R124',
	[Medium.R125]: 'R125',
	[Medium.R13]: 'R13',
	[Medium.R134A]: 'R134A',
	[Medium.R14]: 'R14',
	[Medium.R141B]: 'R141B',
	[Medium.R142B]: 'R142B',
	[Medium.R143A]: 'R143A',
	[Medium.R152A]: 'R152A',
	[Medium.R161]: 'R161',
	[Medium.R21]: 'R21',
	[Medium.R218]: 'R218',
	[Medium.R22Refprop]: 'R22',
	[Medium.R227EA]: 'R227EA',
	[Medium.R23Refprop]: 'R23',
	[Medium.R236EA]: 'R236EA',
	[Medium.R236FA]: 'R236FA',
	[Medium.R245CA]: 'R245CA',
	[Medium.R245FA]: 'R245FA',
	[Medium.R32]: 'R32',
	[Medium.R365MFC]: 'R365MFC',
	[Medium.R40]: 'R40',
	[Medium.R401A]: 'R401A',
	[Medium.R401B]: 'R401B',
	[Medium.R401C]: 'R401C',
	[Medium.R402A]: 'R402A',
	[Medium.R402B]: 'R402B',
	[Medium.R403A]: 'R403A',
	[Medium.R403B]: 'R403B',
	[Medium.R404A]: 'R404A',
	[Medium.R405A]: 'R405A',
	[Medium.R406A]: 'R406A',
	[Medium.R407A]: 'R407A',
	[Medium.R407B]: 'R407B',
	[Medium.R407C]: 'R407C',
	[Medium.R407D]: 'R407D',
	[Medium.R407E]: 'R407E',
	[Medium.R407F]: 'R407F',
	[Medium.R408A]: 'R408A',
	[Medium.R409A]: 'R409A',
	[Medium.R409B]: 'R409B',
	[Medium.R41]: 'R41',
	[Medium.R410A]: 'R410A',
	[Medium.R410B]: 'R410B',
	[Medium.R411A]: 'R411A',
	[Medium.R411B]: 'R411B',
	[Medium.R412A]: 'R412A',
	[Medium.R413A]: 'R413A',
	[Medium.R414A]: 'R414A',
	[Medium.R414B]: 'R414B',
	[Medium.R415A]: 'R415A',
	[Medium.R415B]: 'R415B',
	[Medium.R416A]: 'R416A',
	[Medium.R417A]: 'R417A',
	[Medium.R418A]: 'R418A',
	[Medium.R419A]: 'R419A',
	[Medium.R420A]: 'R420A',
	[Medium.R421A]: 'R421A',
	[Medium.R421B]: 'R421B',
	[Medium.R422A]: 'R422A',
	[Medium.R422B]: 'R422B',
	[Medium.R422C]: 'R422C',
	[Medium.R422D]: 'R422D',
	[Medium.R423A]: 'R423A',
	[Medium.R424A]: 'R424A',
	[Medium.R425A]: 'R425A',
	[Medium.R426A]: 'R426A',
	[Medium.R427A]: 'R427A',
	[Medium.R428A]: 'R428A',
	[Medium.R429A]: 'R429A',
	[Medium.R430A]: 'R430A',
	[Medium.R431A]: 'R431A',
	[Medium.R432A]: 'R432A',
	[Medium.R433A]: 'R433A',
	[Medium.R434A]: 'R434A',
	[Medium.R435A]: 'R435A',
	[Medium.R436A]: 'R436A',
	[Medium.R436B]: 'R436B',
	[Medium.R437A]: 'R437A',
	[Medium.R438A]: 'R438A',
	[Medium.R441A]: 'R441A',
	[Medium.R442A]: 'R442A',
	[Medium.R443A]: 'R443A',
	[Medium.R444A]: 'R444A',
	[Medium.R448A]: 'R448A',
	[Medium.R449a]: 'R449a',
	[Medium.R449B]: 'R449B',
	[Medium.R452a]: 'R452a',
	[Medium.R500]: 'R500',
	[Medium.R501]: 'R501',
	[Medium.R502]: 'R502',
	[Medium.R503]: 'R503',
	[Medium.R504]: 'R504',
	[Medium.R507ARefprop]: 'R507A',
	[Medium.R508A]: 'R508A',
	[Medium.R508B]: 'R508B',
	[Medium.R509A]: 'R509A',
	[Medium.R510A]: 'R510A',
	[Medium.R512A]: 'R512A',
	[Medium.R513A]: 'R513A',
	[Medium.RC318]: 'RC318',
	[Medium.RE143A]: 'RE143A',
	[Medium.RE245CB2]: 'RE245CB2',
	[Medium.RE245FA2]: 'RE245FA2',
	[Medium.RE347MCC]: 'RE347MCC',
	[Medium.SF6]: 'SF6',
	[Medium.SO2]: 'SO2',
	[Medium.T2BUTENE]: 'T2BUTENE',
	[Medium.TOLUENE]: 'TOLUENE',
	[Medium.WATER]: 'WATER',
	[Medium.XENON]: 'XENON',
	[Medium.NAOH105020130C]: 'NaOH[10/50%;20/130C]',
	[Medium.ALCALINH3WATER762364020C]: 'ALCALI (NH3/Water)[7.6/23.6%;-40/20C]',
	[Medium.ETHANOL11604520C]: 'Ethanol[11/60%;-45/20C]',
	[Medium.METHANOL8475020C]: 'Methanol[8/47%;-50/20C]',
	[Medium.GLYCEROL20634040C]: 'Glycerol[20/63%;-40/40C]',
	[Medium.KALIUMCARBONATE0393530C]: 'Kalium carbonate[0/39%;-35/30C]',
	[Medium.MAGNESIUMCHLORIDE0203030C]: 'Magnesium chloride[0/20%;-30/30C]',
	[Medium.NATRIUMCHLORIDE0232130C]: 'Natrium chloride[0/23%;-21/30C]',
	[Medium.KALIUMACETATE11414530C]: 'Kalium acetate[11/41%;-45/30C]',
	[Medium.COOLFLOWDTX22524580C]: 'Coolflow DTX[22/52%;-45/80C]',
	[Medium.FRICK12B40120C]: 'Frick #12b[40/120]',
	[Medium.R1234ZEZ]: 'R1234ZEZ',
	[Medium.LNGMSC28586MIX]: 'LNG-MSC-285(86).Mix',
	[Medium.FRICK1910100C]: 'Frick #19[10/100C]',
	[Medium.ARCTICSHC23010120C]: 'Arctic SHC 230[-10/120C]',
	[Medium.R515A]: 'R515A',
	[Medium.R515B]: 'R515B',
	[Medium.R1336MZZZ]: 'R1336MZZZ',
	[Medium._13BUTADIENE]: '13BUTADIENE',
	[Medium.DYNALINEHC505020C]: 'Dynaline HC-50[-50/20C]',
	[Medium.FES1378933]: 'FES #1[37.8/93.3C]',
	[Medium.PAO6830120]: 'PAO 68[-30/120C]',
}

//==============================================================
// Unit Enum options to labels
//==============================================================
export const capacityUnitLabels = {
	[UnitCapacityOption.kW]: 'kW',
	[UnitCapacityOption.Btus]: 'Btu/s',
	[UnitCapacityOption.kBtuh]: 'kBtu/h',
	[UnitCapacityOption.ftlbfh]: 'ft.lbf/h',
	[UnitCapacityOption.ftlbfmin]: 'ft.lbf/min',
	[UnitCapacityOption.kcalh]: 'kcal/h',
	[UnitCapacityOption.cals]: 'cal/s',
	[UnitCapacityOption.RefTon]: 'Ref.Ton',
}

export const velocityUnitLabels = {
	[UnitVelocityOption.ms]: 'm/s',
	[UnitVelocityOption.fts]: 'ft/s',
	[UnitVelocityOption.ftmin]: 'ft/min',
	[UnitVelocityOption.ins]: 'in/s',
	[UnitVelocityOption.inmin]: 'in/min',
	[UnitVelocityOption.yds]: 'yd/s',
}

export const pressureUnitLabels = {
	[UnitPressureOption.kPa]: 'kPa',
	[UnitPressureOption.MPa]: 'MPa',
	[UnitPressureOption.bar]: 'bar(a)',
	[UnitPressureOption.mmHg]: 'mmHg',
	[UnitPressureOption.atm]: 'atm',
	[UnitPressureOption.Torr]: 'Torr',
	[UnitPressureOption.Psi]: 'psi(a)',
	[UnitPressureOption.lbin2]: 'lb/in²',
	[UnitPressureOption.lbft2]: 'lb/ft²',
}

export const designPressureUnitLabels = {
	[UnitPressureOption.kPa]: 'kPa(g)',
	[UnitPressureOption.MPa]: 'MPa(g)',
	[UnitPressureOption.bar]: 'bar(g)',
	[UnitPressureOption.mmHg]: 'mmHg',
	[UnitPressureOption.atm]: 'atm',
	[UnitPressureOption.Torr]: 'Torr',
	[UnitPressureOption.Psi]: 'psi(g)',
	[UnitPressureOption.lbin2]: 'lb/in²',
	[UnitPressureOption.lbft2]: 'lb/ft²',
}

export const dynamicViscoUnitLabels = {
	[UnitDynamicViscosityOption.kgms]: 'kg/ms',
	[UnitDynamicViscosityOption.Poise]: 'Poise',
	[UnitDynamicViscosityOption.cP]: 'cP',
	[UnitDynamicViscosityOption.MPas]: 'mPas',
	[UnitDynamicViscosityOption.lbf_s_in2]: 'lbfs/in²',
	[UnitDynamicViscosityOption.lbf_s_ft2]: 'lbfs/ft²',
}

export const entalphyUnitLables = {
	[UnitEntalphyOption.Jkg]: 'J/kg',
	[UnitEntalphyOption.kJkg]: 'kJ/kg',
	[UnitEntalphyOption.Btulb]: 'Btu/lb',
	[UnitEntalphyOption.Btukg]: 'Btu/kg',
	[UnitEntalphyOption.ftlbflb]: 'ft.lbf/lb',
	[UnitEntalphyOption.kcallb]: 'kcal/lb',
	[UnitEntalphyOption.calkg]: 'cal/kg',
}

export const foulingFactorUnitLabels = {
	[UnitFoulingFactorOption.m2KW]: 'm²K/W',
	[UnitFoulingFactorOption.hft2FBtu]: '(h.ft².°F)/Btu',
	[UnitFoulingFactorOption.sft2FBtu]: '(s.ft².°F)/Btu',
	[UnitFoulingFactorOption.hm2Ckcal]: '(h.m².°C)/kcal',
	[UnitFoulingFactorOption.scm2Ccal]: '(s.cm².°C)/cal',
}

export const massFlowUnitLabels = {
	[UnitMassFlowOption.kgs]: 'kg/s',
	[UnitMassFlowOption.lbs]: 'lb/s',
	[UnitMassFlowOption.kgh]: 'kg/h',
	[UnitMassFlowOption.lbh]: 'lb/h',
	[UnitMassFlowOption.US_tons]: 'USton/s',
	[UnitMassFlowOption.UK_tons]: 'UKton/s',
}

export const specificGravityUnitLables = {
	[UnitSpecificGravityOption.kgm3]: 'kg/m³',
	[UnitSpecificGravityOption.gcm3]: 'g/cm³',
	[UnitSpecificGravityOption.ozin3]: 'oz/in³',
	[UnitSpecificGravityOption.ozgal_UK]: 'oz/gal(UK)',
	[UnitSpecificGravityOption.ozgal_US]: 'oz/gal(US)',
	[UnitSpecificGravityOption.lbgal_UK]: 'lb/gal(UK)',
	[UnitSpecificGravityOption.lbgal_US]: 'lb/gal(US)',
	[UnitSpecificGravityOption.lbin3]: 'lb/in³',
	[UnitSpecificGravityOption.lbft3]: 'lb/ft³',
	[UnitSpecificGravityOption.lbyd3]: 'lb/yd³',
}

export const sigmaUnitLabels = {
	[UnitSurfaceTensionOption.Nm]: 'N/m',
	[UnitSurfaceTensionOption.mNm]: 'nN/m',
	[UnitSurfaceTensionOption.lbfft]: 'lbf/ft',
	[UnitSurfaceTensionOption.lbfin]: 'lbf/in',
	[UnitSurfaceTensionOption.ergcm2]: 'erg/cm²',
	[UnitSurfaceTensionOption.kgfm]: 'kgf/m',
	[UnitSurfaceTensionOption.dynecm]: 'dyne/cm',
	[UnitSurfaceTensionOption.mjcm2]: 'mJ/cm²',
}

export const temUnitLabels = {
	[UnitTemperatureLMTDOption.C]: '°C',
	[UnitTemperatureLMTDOption.F]: '°F',
	[UnitTemperatureLMTDOption.R]: '°R',
}

export const volumeUnitLabels = {
	[UnitVolumeOption.USgal]: 'Usgal',
	[UnitVolumeOption.dm]: 'dm³',
	[UnitVolumeOption.m3]: 'm³',
}

export const massUnitLabels = {
	[UnitMassOption.kg]: 'kg',
	[UnitMassOption.lb]: 'lb',
}

export const airHumidityUnitLabels = {
	[UnitAirHumidityOption.kgkg]: 'kg/kg',
}

export const currencyLabels = {
	[CurrencyCode.EUR]: 'EUR',
	[CurrencyCode.USD]: 'USD',
	[CurrencyCode.GBP]: 'GBP',
	[CurrencyCode.CAD]: 'CAD',
	[CurrencyCode.CNY]: 'CNY',
}

export const fractionLabels = {
	// [FractionType.Mass]: 'Mass',
	[FractionType.Mole]: 'Mole',
}

export const fluidTemperatureInPlatepackLabels = {
	[FluidTemperatureInPlatepack.LessThan65]: 'T <=65 °C',
	[FluidTemperatureInPlatepack.From65To110]: 'T >65°C <=110°C',
	[FluidTemperatureInPlatepack.GreaterThan110]: 'T >110 °C',
}

export const dangerousnessOfTheContentOfPshePlatePackLabels = {
	[DangerousnessOfTheContentOfPshePlatePack.No]: 'No risk',
	[DangerousnessOfTheContentOfPshePlatePack.Little]: 'Little risk',
}

export const significanceOfTheProcessMediaConfusionCausedByPlateDamageLabels = {
	[SignificanceOfTheProcessMediaConfusionCausedByPlateDamage.No]:
		'No significance',
	[SignificanceOfTheProcessMediaConfusionCausedByPlateDamage.Little]:
		'Little significance',
	[SignificanceOfTheProcessMediaConfusionCausedByPlateDamage.Significant]:
		'Significant risk',
}

export const volumeOfContentsInPlatepackLabels = {
	[VolumeOfContentsInPlatepack.LessThan50]: 'V < 50 dm3',
	[VolumeOfContentsInPlatepack.From50To200]: '50 <= V <= 200 dm3',
	[VolumeOfContentsInPlatepack.GreaterThan200]: 'V >200 dm3',
}

export const corrosionAndErosionLabels = {
	[CorrosionAndErosion.No]: 'No significance',
	[CorrosionAndErosion.Moderate]: 'Moderate danger of corrosion',
	[CorrosionAndErosion.Significant]: 'Significant danger of corrosion',
}

export const fatigueLabels = {
	[Fatigue.LessThan500]: '<= 500 full pressure cycles during lifetime',
	[Fatigue.From500To10000]: '500 - 10000 full pressure cycles during lifetime',
	[Fatigue.GreaterThan10000]: '> 10 000 (for ex. Compressors without damping)',
}

export const meansOfExaminationForPlatepackLabels = {
	[MeansOfExaminationForPlatepack.Vacuum]:
		'Vacuum He-leak test for the platepack',
	[MeansOfExaminationForPlatepack.Standard]: 'Standard leak test by air',
}

export const installationLabels = {
	[Installation.Welded4Nozzles]: 'Welded end plates with 4 nozzles',
	[Installation.Welded2Nozzles]: 'Welded end plates with 2 nozzles',
	[Installation.OpenableModel]: 'Openable model',
}

export const operationLifetimeLabels = {
	[OperationLifetime.LessThan10]: 'Operation lifetime <= 10 years',
	[OperationLifetime.From10To20]: 'Operation lifetime 10-20 years',
	[OperationLifetime.GreaterThan20]: 'Operation lifetime >20 years',
}

export const inspectionOfThePlatepackLabels = {
	[InspectionOfThePlatepack.LessThan5]: 'Inspection of platepack <= 5 year',
	[InspectionOfThePlatepack.From5To10]: 'Inspection of platepack 5-10 year',
	[InspectionOfThePlatepack.GreaterThan10]:
		'Inspection of  platepack > 10 year',
}

export const haPlateMaterialLabels = {
	[HaPlateMaterial._14404]: '1.4404 EN 10028-7',
	[HaPlateMaterial._254SMO]: '254SMO EN 1.4547',
	[HaPlateMaterial.HastelloyC22]: 'Hastelloy C-22 VdTÜV W-bl. 479',
	[HaPlateMaterial.HastelloyC276]: 'Hastelloy C-276 VdTÜV W-bl. 400',
	[HaPlateMaterial.Nickel201]: 'Nickel 201',
	[HaPlateMaterial.TitaniumGr1]: 'Titanium Gr1 ASTM B265',
	[HaPlateMaterial._904L]: '904L EN 1.4539',
	[HaPlateMaterial._2205]: '2205 EN 1.4462',
	[HaPlateMaterial.Supra316L]: 'Supra 316L EN 1.4420',
	[HaPlateMaterial.ASTMFDX27]: 'ASTM FDX 27 ',
	[HaPlateMaterial._14307]: '1.4307 EN 10028-7',
}

export const haPlateSizeAndTypeLabels = {
	[HaPlateSizeAndType._2HA]: '2HA',
	[HaPlateSizeAndType._3HH]: '3HH',
	[HaPlateSizeAndType._3LL]: '3LL',
	[HaPlateSizeAndType._3WH]: '3WH',
	[HaPlateSizeAndType._3WA]: '3WA',
	[HaPlateSizeAndType._4HH]: '4HH',
	[HaPlateSizeAndType._4LL]: '4LL',
	[HaPlateSizeAndType._5HH]: '5HH',
	[HaPlateSizeAndType._5LL]: '5LL',
	[HaPlateSizeAndType._5WH]: '5WH',
	[HaPlateSizeAndType._5WL]: '5WL',
	[HaPlateSizeAndType._5TH]: '5TH',
	[HaPlateSizeAndType._5SH]: '5SH',
	[HaPlateSizeAndType._6HH]: '6HH',
	[HaPlateSizeAndType._6LL]: '6LL',
	[HaPlateSizeAndType._7HH]: '7HH',
	[HaPlateSizeAndType._7LL]: '7LL',
	[HaPlateSizeAndType._7WH]: '7WH',
	[HaPlateSizeAndType._7WL]: '7WL',
	[HaPlateSizeAndType._9HH]: '9HH',
	[HaPlateSizeAndType._9LL]: '9LL',
	[HaPlateSizeAndType._9HA]: '9HA',
	[HaPlateSizeAndType._9LA]: '9LA',
	[HaPlateSizeAndType._12HH]: '12HH',
	[HaPlateSizeAndType._12LL]: '12LL',
	[HaPlateSizeAndType._14HH]: '14HH',
	[HaPlateSizeAndType._14LL]: '14LL',
}

export const haPlateThicknessLabels = {
	[HaPlateThickness._0_7mm]: '0.7 mm',
	[HaPlateThickness._0_8mm]: '0.8 mm',
	[HaPlateThickness._1mm]: '1.0 mm',
	[HaPlateThickness._1_2mm]: '1.2 mm',
	[HaPlateThickness._1_5mm]: '1.5 mm',
}

export const asmeOptionsLabels = {
	[AsmeOptions._1_3xDP]: '1.3 x DP, ASME',
	[AsmeOptions._3_0xDP]: '3.0 x DP, ASME-CRN (Canada)',
}
