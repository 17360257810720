import {Application} from './Application'
import {
	CalculationState,
	Medium,
	MediumSource,
	NozzleShell,
	NumberOfPasses,
	FlowDirection,
	NumberOfShellNozzles,
	PlateShellConnections,
	UnitEntalphyOption,
	UnitSurfaceTensionOption,
} from './CalculationEnums'

export enum EvaporatorType {
	FloodedEvaporator = 1,
	DXEvaporator = 0,
}

export enum Circulation {
	External = 1,
	Internal = 2,
	Combined = 3,
	Kettle = 4,
}

export enum EvaporatorMediumType {
	Liquid = 1,
	Gas = 2,
}

export default interface Evaporator extends Application {
	evaporatorType: EvaporatorType
	numberOfPassesDX: NumberOfPasses
	numberOfPassesLiquidSide: NumberOfPasses
	flowDirection: FlowDirection
	pressureDropAllowedLiquid: number
	inletTempEvapSide: number
	evaporatingTemperature: number
	dtSuperHeat: number
	inletTemperatureLiquid: number
	outletTemperatureLiquid: number
	mediumSourceVaporSide: MediumSource
	mediumSourceLiquidSide: MediumSource
	mediumVaporSide: Medium
	mediumLiquidSide: Medium
	concentrationLiquidSide: number
	nozzleShellOut: NozzleShell
	numberOfShellNozzlesOut: NumberOfShellNozzles
	circulation: Circulation
	combinedC: boolean
	fillingBoxes: boolean
	plateConnections: PlateShellConnections
	cboSigma: UnitSurfaceTensionOption
	cboH: UnitEntalphyOption
	flowRateLiquid: number
	flowRateVapor: number
	dynamicViscosityLiquidSide: number
	specificGravityLiquidSide: number
	specificHeatLiquidSide: number
	thermalConductivityLiquidSide: number
	saturatedPressure: number
	deltaPCondSide: number
	dynamicViscosityVaporSideL: number
	specificGravityVaporSideL: number
	specificHeatVaporSideL: number
	thermalConductivityVaporSideL: number
	surfaceTensionVaporSideL: number
	dynamicViscosityVaporSideV: number
	specificGravityVaporSideV: number
	specificHeatVaporSideV: number
	thermalConductivityVaporSideV: number
	dynamicViscosityVaporSideSuperh: number
	specificGravityVaporSideSuperh: number
	specificHeatVaporSideSuperh: number
	thermalConductivityVaporSideSuperh: number
	enthalpyVaporSideOutlet: number
	enthalpyVaporSideL: number
	enthalpyVaporSideV: number
	enthalpyVaporSideSuperh: number
	pressureDropLiquidSideOutput: number
	pressureDropVaporSideOutput: number
	velocityPlate: number
	velocityPlateOut: number
	velocityShellOut: number
	optimumVelocityPlateInLow: number
	optimumVelocityPlateInHigh: number
	optimumVelocityPlateOutLow: number
	optimumVelocityPlateOutHigh: number
	optimumVelocityShellInLow: number
	optimumVelocityShellInHigh: number
	optimumVelocityShellOutLow: number
	optimumVelocityShellOutHigh: number
	optimumVelocityShellOutUpLow: number
	optimumVelocityShellOutUpHigh: number
	velocityDemisterCombined: number
	allowedDemisterCombinded: number
	refrigirantMassInOperation: number
	temperatureEfficiency: number
	calculationSuccessful: CalculationState
}
