import {
	defaultTermsAndConditions,
	getDefaultDocumentList,
	getDefaultManufacturingText,
	getDefaultOfferInfoFields,
	OfferInfoField,
} from './constants'

import i18n from '../i18n'
import {toCamelCase} from './utils'
import {MFileInfo, OfferInfoPreviewItem} from '../model/Offer'
import {DesignClassification} from '../model/CalculationEnums'

export const translateIfKeyExist = (key: string, fallback?: string) => {
	return i18n.exists(key) ? i18n.t(key) : fallback || ''
}

// used for offer info form
export const getInitialOfferInfoValues = (
	offerInfo: OfferInfoPreviewItem[],
) => {
	const result = {}
	offerInfo.forEach(({name, value}) => {
		result[toCamelCase(name)] = Array.isArray(value)
			? JSON.stringify(value)
			: value
	})
	return result
}

// fill offer info fields with default values if any default field left empty
export const fillOfferInfoDefaultValues = ({
	offerInfo,
	mFiles,
	designClassification,
}: {
	offerInfo: OfferInfoPreviewItem[]
	mFiles: MFileInfo
	designClassification: DesignClassification
}) => {
	return offerInfo.map(({name, value, title}) => {
		let fieldValue = value

		if (!fieldValue) {
			// handle special cases. fill in the default values when left blank
			switch (toCamelCase(name)) {
				case 'designManufacturing':
					fieldValue = getDefaultManufacturingText(designClassification)
					break

				case 'deliveryTime':
					fieldValue = i18n.t('offer.info.defaultOfferDeliveryTime')
					break

				case 'termsOfDelivery':
					fieldValue = mFiles[name] + ', Incoterms 2022 '
					break

				case 'termsOfPayment':
					fieldValue = mFiles[name]
					break

				case 'termsOfWarranty':
					fieldValue =
						mFiles[name] || i18n.t('offer.info.defaultTermsOfWarranty')
					break

				case 'offerValidity':
					fieldValue = i18n.t('offer.info.defaultOfferValidity')
					break

				case 'termsAndConditions':
					fieldValue = defaultTermsAndConditions
					break

				case 'offerNote':
					fieldValue = i18n.t('offer.info.defaultOfferNote', {
						responsiblePersonName: mFiles.responsiblePersonName,
					})
					break

				default:
					fieldValue = null
					break
			}
		}

		if (name === 'documents') {
			fieldValue = fieldValue
				? JSON.parse(fieldValue)
				: getDefaultDocumentList(i18n.language, designClassification)
		}

		return {name, value: fieldValue, title}
	})
}

export const isCustomField = (fieldName: string): boolean => {
	const defaultFields = getDefaultOfferInfoFields()
	return !defaultFields.some(field => field.name === fieldName)
}

export const reorderOfferInfo = (offerInfo: OfferInfoPreviewItem[]) => {
	const result = [...offerInfo]
	result.forEach((item, index) => {
		item.order = index
	})
	return result
}

export const getOfferInfoIndexByName = ({
	offerInfoList,
	name,
}: {
	offerInfoList: OfferInfoPreviewItem[] | OfferInfoField[]
	name: string
}) => {
	return offerInfoList.findIndex(field => field.name === toCamelCase(name))
}
