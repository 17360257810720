import {useMutation, queryCache, useQuery} from 'react-query'
import {client, parsePatchBody} from '../utils/apiClient'
import OnePhase from '../model/OnePhase'
import {
	Calculation,
	CalculationCreation,
	CalculationDuplicateCreation,
	CalculationDuplicateDifferentPosition,
	DesignCode,
	SaturatedData,
} from '../model/Calculation'
import Condenser from '../model/Condenser'
import Evaporator from '../model/Evaporator'
import Offer from '../model/Offer'
import queryString from 'query-string'
import {Datatext} from '../model/DataSheet'

const defaultMutationOptions = {
	onError: (err, variables, recover) =>
		typeof recover === 'function' ? recover() : null,
	onSettled: () => queryCache.invalidateQueries('calculations'),
}

export function useCalculations(positionId, options?) {
	return useQuery<Calculation[], Error>({
		queryKey: ['calculations', {positionId}],
		queryFn: () => client(`positions/${positionId}/calculations`),
		config: {...options},
	})
}

export function useCalculation(calculationId?, options?) {
	const results = useQuery<Offer, Error>({
		queryKey: ['calculation', {calculationId}],
		queryFn: () => client(`calculations/${calculationId}`),
		config: {...options},
	})

	return results
}

export function useCalculationDataText(positionId?, calculationId?, options?) {
	const results = useQuery<Datatext, Error>({
		queryKey: ['dataText', {calculationId}],
		queryFn: () =>
			client(`positions/${positionId}/calculations/${calculationId}/dataText`),
		config: {...options},
	})

	return results
}

export function usePricingCalculations(options?) {
	const {query, ...configOptions} = options
	const queryText = queryString.stringify(query)

	const results = useQuery<Calculation[], Error>({
		queryKey: ['pricingCalculations', {queryText}],
		queryFn: () => client(`calculations?${queryText}`),
		config: {...configOptions},
	})

	return results
}

export function useDesignCodeText(options?) {
	return useMutation<
		DesignCode,
		Error,
		{typeApproval: number; designClassification: string}
	>(
		dataBody =>
			client(`calculations/designCode`, {
				data: {
					typeApproval: dataBody.typeApproval,
					designClassification: dataBody.designClassification,
				},
			}),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

export function useSaturatedCalculate(options?) {
	return useMutation<
		SaturatedData,
		Error,
		{medName: string; satLiq?: string; satVap?: string; satPress?: string}
	>(
		dataBody =>
			client(`calculations/saturatedCalculate`, {
				data: {
					medName: dataBody.medName,
					satLiq: dataBody.satLiq,
					satVap: dataBody.satVap,
					satPress: dataBody.satPress,
				},
			}),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

export function useCreateCalculation(options?) {
	return useMutation<
		Calculation,
		Error,
		{positionId: number; calculation: CalculationCreation}
	>(
		calculationData =>
			client(`positions/${calculationData.positionId}/calculations`, {
				data: calculationData.calculation,
			}),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

export function useDuplicateCalculation(options?) {
	return useMutation<
		Calculation,
		Error,
		{
			positionId: number
			calculationId: number
			calculation: CalculationDuplicateCreation
			differentPosition?: CalculationDuplicateDifferentPosition
		}
	>(
		calculationData =>
			client(
				`positions/${calculationData.positionId}/calculations/${calculationData.calculationId}/duplicate`,
				{
					data: {
						...calculationData.calculation,
						isForDifferentPosition: calculationData.differentPosition
							? calculationData.differentPosition.isForDifferentPosition
							: false,
						differentPositionId: calculationData.differentPosition
							? calculationData.differentPosition.differentPositionId
							: 0,
					},
				},
			),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

function onUpdateMutation(positionId, newItem) {
	const queryKey = ['calculations', {positionId}]
	const previousItems = queryCache.getQueryData(queryKey)

	queryCache.setQueryData<Calculation[], Error>(queryKey, old => {
		if (!old) {
			return
		}

		return old.map(item => {
			return item.id === newItem.id ? {...item, ...newItem} : item
		})
	})

	return () => queryCache.setQueryData(queryKey, previousItems)
}

export function useUpdateCalculation(positionId, options?) {
	const {isPatchUpdate, ...mutateOptions} = options

	return useMutation<
		Calculation,
		Error,
		{id: string; name: string} | Calculation
	>(
		updates =>
			client(`calculations/${updates.id}`, {
				method: isPatchUpdate ? 'PATCH' : 'PUT',
				data: isPatchUpdate ? parsePatchBody(updates) : updates,
			}),
		{
			onMutate: newItem => onUpdateMutation(positionId, newItem),
			...defaultMutationOptions,
			...mutateOptions,
		},
	)
}

export function useAppCalculate(options?) {
	return useMutation<
		OnePhase & Condenser & Evaporator,
		Error,
		{data: OnePhase & Condenser & Evaporator}
	>(
		({data}) => {
			return client(`applications/${data.application}/calculate`, {
				data,
			})
		},
		{
			...options,
		},
	)
}

export function useDeleteCalculation(options?) {
	return useMutation<Calculation, Error, Calculation>(
		calculation =>
			client(
				`positions/${calculation.positionId}/calculations/${calculation.id}`,
				{
					method: 'DELETE',
				},
			),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}
