import React from 'react'
import {Form, FormInstance} from 'antd'
import InputNumberUnderline from '../InputNumberUnderline'
import TemperatureUnit from '../SharedFormItem/TemperatureUnit'
import {usePrevious, useConvertCommaToDot} from '../../utils/hookUtils'
import {convertTemperature} from '../../utils/utils'

interface Props {
	form: FormInstance
	isRequired?: boolean
}

const InletTemInput: React.FunctionComponent<Props> = ({form, isRequired}) => {
	const {getFieldValue, setFieldsValue} = form
	const cboT = getFieldValue('cboT')
	const inletTemperatureHotSide = getFieldValue('inletTemperatureHotSide')
	const inletTemperatureColdSide = getFieldValue('inletTemperatureColdSide')
	const prevCboT = usePrevious(cboT)

	React.useEffect(() => {
		const hot = useConvertCommaToDot(inletTemperatureHotSide)
		setFieldsValue({inletTemperatureHotSide: hot})
		const cold = useConvertCommaToDot(inletTemperatureColdSide)
		setFieldsValue({inletTemperatureColdSide: cold})
	}, [inletTemperatureColdSide, inletTemperatureHotSide])

	React.useEffect(() => {
		setFieldsValue({
			inletTemperatureHotSide: convertTemperature(
				inletTemperatureHotSide,
				prevCboT,
				cboT,
			),
		})
		setFieldsValue({
			inletTemperatureColdSide: convertTemperature(
				inletTemperatureColdSide,
				prevCboT,
				cboT,
			),
		})
	}, [cboT, setFieldsValue, prevCboT])

	return (
		<Form.Item label="Inlet. Temp" className="required-group">
			<Form.Item
				name={['inletTemperatureHotSide']}
				style={{display: 'inline-block'}}
				rules={[{required: isRequired == undefined ? true : isRequired}]}
			>
				<InputNumberUnderline state="hot" />
			</Form.Item>
			<Form.Item
				name={['inletTemperatureColdSide']}
				style={{display: 'inline-block'}}
				rules={[{required: isRequired == undefined ? true : isRequired}]}
			>
				<InputNumberUnderline state="cold" />
			</Form.Item>
			<TemperatureUnit />
		</Form.Item>
	)
}

export default InletTemInput
