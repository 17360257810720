import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Menu} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import DashboardTable from '../components/DashboardTable'
import Button from '../components/Button'
import {useOffers} from '../state/offers'
import {useAuth0} from '@auth0/auth0-react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {
	previousCalculationState,
	searchResultsTableVisibleState,
} from '../state/Atoms'
import SearchResultsTable from '../components/SearchProperties/SearchResultsTable'
import FullPageLoading from '../components/FullPageLoading'

const Dashboard: React.FunctionComponent = () => {
	const {user} = useAuth0()
	const [, setPreviousCalculationData] = useRecoilState(
		previousCalculationState,
	)
	const searchResultsTableVisible = useRecoilValue(
		searchResultsTableVisibleState,
	)

	React.useEffect(() => {
		setPreviousCalculationData(null)
	}, [])
	const history = useHistory()
	const [searchCreatorEmail, setSearchCreatorEmail] = React.useState(user.email)
	const [isSharedOffer, setIsSharedOffer] = React.useState(false)
	const searchParams = new URLSearchParams(useLocation().search)
	const searchKeyword = searchParams.get('keyword') as string
	const {data: offers, isLoading} = useOffers({
		query: {
			creatorEmail: searchCreatorEmail,
			keyword: searchKeyword,
			isShared: isSharedOffer,
		},
	})

	function onMenuChange(active) {
		const {key} = active

		switch (key) {
			case 'my-offer':
				setIsSharedOffer(false)
				setSearchCreatorEmail(user.email)
				break
			case 'shared':
				setIsSharedOffer(true)
				setSearchCreatorEmail(null)
				break
			case 'all':
				setIsSharedOffer(false)
				setSearchCreatorEmail(null)
				break
			default:
				setIsSharedOffer(true)
				setSearchCreatorEmail(null)
				break
		}
	}

	function handleNewOfferButton() {
		history.push('/workspace')
	}
	if (isLoading) {
		return <FullPageLoading />
	}

	return (
		<div className="main-content main-content--dashboard page-dashboard">
			<div className="dashboard-button-row">
				<Button
					onClick={handleNewOfferButton}
					className="vahterus-button"
					icon={<PlusOutlined />}
				>
					New
				</Button>
			</div>
			<Menu
				className="dashboard-menu"
				mode="horizontal"
				onSelect={onMenuChange}
				defaultSelectedKeys={['my-offer']}
			>
				<Menu.Item className="dashboard-menu-item" key="my-offer">
					My Offer
				</Menu.Item>
				{user['http://vahterus/roles'].includes('Internal') ? (
					<Menu.Item className="dashboard-menu-item" key="all">
						ALL
					</Menu.Item>
				) : null}
				<Menu.Item className="dashboard-menu-item" key="shared">
					SHARED
				</Menu.Item>
			</Menu>
			{!searchResultsTableVisible ? (
				<DashboardTable loading={isLoading} offers={offers} />
			) : null}
			{searchResultsTableVisible ? <SearchResultsTable /> : null}
		</div>
	)
}
export default Dashboard
