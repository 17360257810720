import React from 'react'
import {Form, FormInstance} from 'antd'
import InputNumberUnderline from '../InputNumberUnderline'
import TemperatureUnit from '../SharedFormItem/TemperatureUnit'
import {usePrevious, useConvertCommaToDot} from '../../utils/hookUtils'
import {convertTemperature} from '../../utils/utils'

interface Props {
	form: FormInstance
}

const OutletTempInput: React.FunctionComponent<Props> = ({form}) => {
	const {getFieldValue, setFieldsValue} = form
	const cboT = getFieldValue('cboT')
	const outletTemperatureLiquid = getFieldValue('outletTemperatureLiquid')
	const prevCboT = usePrevious(cboT)

	React.useEffect(() => {
		const liquid = useConvertCommaToDot(outletTemperatureLiquid)
		setFieldsValue({outletTemperatureLiquid: liquid})
	}, [outletTemperatureLiquid])

	React.useEffect(() => {
		setFieldsValue({
			outletTemperatureLiquid: convertTemperature(
				outletTemperatureLiquid,
				prevCboT,
				cboT,
			),
		})
	}, [cboT, setFieldsValue, prevCboT])

	return (
		<Form.Item label="Outlet. Temp">
			<Form.Item
				name={['outletTemperatureLiquid']}
				style={{display: 'inline-block'}}
			>
				<InputNumberUnderline state="hot" />
			</Form.Item>
			<div style={{flex: 1}}>&nbsp;</div>
			<TemperatureUnit />
		</Form.Item>
	)
}

export default OutletTempInput
