import React from 'react'
import {FormInstance, Form, Select, Button, Alert} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'
import FullPageLoading from '../FullPageLoading'
import SelectUnderline from '../SelectUnderline'
import {enumToValues} from '../../utils/utils'
import {ApplicationType} from '../../model/CalculationEnums'
import {applicationLabels} from '../../model/enumLabels'
import OnePhaseSearchProperties from './OnePhaseSearchPropertiesInputs'
import CondenserSearchProperties from './CondenserSearchPropertiesInputs'
import EvaporatorSearchProperties from './EvaporatorSearchProperties'
import CascadeSearchProperties from './CascadeSearchProperties'
import PartialCascadeSearchProperties from './PartialCascadeSearchProperties'
import CapacityInput from '../SharedFormItem/CapacityInput'
import SizeInput from '../SharedFormItem/SizeInput'
import InputUnderline from '../InputUnderline'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {
	advancedSearchQueryState,
	searchPropertiesFormVisibleState,
	searchResultsTableVisibleState,
} from '../../state/Atoms'

const SearchPropertiesContext = React.createContext<[FormInstance]>(null)
const {Option} = Select

const SearchPropertiesForm: React.FunctionComponent = () => {
	const [form] = Form.useForm()
	const [application, setApplication] = React.useState(1)
	const [infoTextVisible, setInfoTextVisible] = React.useState(false)
	const setSearchQuery = useSetRecoilState(advancedSearchQueryState)
	React.useEffect(() => {
		form.setFieldsValue({
			cboQ: 0,
			cboT: 0,
		})
	}, [])

	const setSearchResultsTableVisible = useSetRecoilState(
		searchResultsTableVisibleState,
	)
	const [searchPropertiesFormVisible, setSearchPropertiesFormVisible] =
		useRecoilState(searchPropertiesFormVisibleState)

	const onApplicationChange = value => {
		setApplication(value)
	}

	const renderApplicationDependentInputs = application => {
		switch (application) {
			case ApplicationType.OnePhase:
				return <OnePhaseSearchProperties form={form} />
			case ApplicationType.Condenser:
				return <CondenserSearchProperties form={form} />
			case ApplicationType.Evaporators:
				return <EvaporatorSearchProperties form={form} />
			case ApplicationType.Cascade:
				return <CascadeSearchProperties form={form} />
			case ApplicationType.PartialCascade:
				return <PartialCascadeSearchProperties form={form} />
			default:
				return <OnePhaseSearchProperties form={form} />
		}
	}

	const onSearch = () => {
		form.submit()
		setSearchResultsTableVisible(true)
	}

	const onReset = () => {
		setApplication(1)
		form.resetFields()
		form.setFieldsValue({
			cboQ: 0,
			cboT: 0,
		})
		setSearchResultsTableVisible(false)
	}

	const onCancelSearch = () => {
		setApplication(1)
		form.resetFields()
		setSearchPropertiesFormVisible(!searchPropertiesFormVisible)
		setSearchResultsTableVisible(false)
	}

	const onFinish = values => {
		const asArr = Object.entries(values)
		const filtered = asArr.filter(([key, value]) => {
			if (
				key != 'cboQ' &&
				key != 'cboT' &&
				key != 'mediumSourceHotSide' &&
				key != 'mediumSourceColdSide' &&
				key != 'mediumHotSideOtherMedia' &&
				key != 'mediumColdSideOtherMedia'
			) {
				if (value) {
					return [key, value]
				}
			}
		})
		setSearchQuery(Object.fromEntries(filtered))
	}

	return (
		<Form
			form={form}
			className="search-properties-form"
			name="searchProperties"
			layout="horizontal"
			onFinish={onFinish}
			labelAlign="left"
			colon={false}
		>
			<SearchPropertiesContext.Provider value={[form]}>
				<React.Suspense fallback={<FullPageLoading />}>
					<div className="search-properties-form">
						<div className="search-properties-form__button-row">
							<Button
								onClick={onSearch}
								className="vahterus-button"
								type="primary"
							>
								Search
							</Button>
							<Button
								onClick={onReset}
								className="vahterus-button"
								type="default"
							>
								Clear Search
							</Button>
							<Button
								onClick={onCancelSearch}
								className="vahterus-button"
								type="default"
							>
								Cancel
							</Button>
						</div>
						<div className="search-properties-form__header">
							<h2>
								Search Properties&emsp;&emsp;
								<InfoCircleOutlined
									onClick={() => {
										setInfoTextVisible(!infoTextVisible)
									}}
								/>
							</h2>
							{infoTextVisible ? (
								<Alert
									className="search-info-banner"
									message="Using the Advanced Search"
									description="Search for design cases based on any of the properties below. You do not have to fill in all fields to yield results."
									type="info"
									banner
									closable
								/>
							) : null}
						</div>
						<div className="search-properties-form__grid">
							<div className="input-group">
								<Form.Item
									name="application"
									label="Application"
									initialValue={application}
								>
									<SelectUnderline onChange={onApplicationChange}>
										{enumToValues(ApplicationType, true).map(key => (
											<Option key={key} value={key}>
												{applicationLabels[key]}
											</Option>
										))}
									</SelectUnderline>
								</Form.Item>
								<Form.Item label="PSHE Type" name="psheType">
									<InputUnderline />
								</Form.Item>
								<SizeInput isRequired={false} />
								<CapacityInput form={form as FormInstance} />
							</div>
							<div className="input-group">
								{renderApplicationDependentInputs(application)}
							</div>
						</div>
					</div>
				</React.Suspense>
			</SearchPropertiesContext.Provider>
		</Form>
	)
}

export default SearchPropertiesForm
