import React from 'react'
import {Input} from 'antd'
import {SearchProps} from 'antd/es/input'

const {Search} = Input

const SearchUnderline: React.FunctionComponent<SearchProps> = props => {
	return <Search className="input-search-underline" {...props} />
}

export default SearchUnderline
