import {
	areaLabels,
	capacityUnitLabels,
	designPressureUnitLabels,
	dynamicViscoUnitLabels,
	endPlateTypeLabels,
	feetLabels,
	flowDirectorMaterialLabels,
	foulingFactorUnitLabels,
	kValueLabels,
	massFlowUnitLabels,
	massUnitLabels,
	mediumLabels,
	paintingLabels,
	plateMaterialLabels,
	plateThicknessLabels,
	positionLabels,
	pressureUnitLabels,
	shellMaterialLabels,
	specificGravityUnitLables,
	specificHeatUnitLables,
	temUnitLabels,
	thermalConductivityUnitLabels,
	typeApporvalLabels,
	volumeUnitLabels,
} from '../../model/enumLabels'
import {
	CondenserType,
	FlowDirectorMaterial,
	LiquidSide,
	MediumSource,
	UnitEntalphyOption,
	UnitSurfaceTensionOption,
} from '../../model/CalculationEnums'
import {
	getDesignPressureUnit,
	getMediumName,
	getNumberOfPlateNozzle,
	getShellMaterial,
} from './dataSheetUtils'
import {LiquidSidePhase} from '../../model/Condenser'
import {
	ThermalDesignData,
	PlateShellData,
	MediumData,
	ConnectionsData,
	MultiCondenserMediumData,
} from '../../model/DataSheet'

// Connections, Construction, and Materials are all repeated in each datasheet, so they
// are collected in this separate func so it can be repeated easily
const getConnectionsConstructionAndMaterialsData = (
	heatExchangerData,
	dataText,
) => {
	// CONSTRUCTION DATA
	const {
		endPlateType,
		cboMass,
		massOfHeatExchanger,
		position,
		typeApproval,
		designPressure,
		designPressureOnDataSheet,
		designCode,
		cboT,
		designTemperatureMin,
		designTemperatureMax,
		feet,
		designPressurePlateSide,
		designPressureUnitOnDataSheet,
	} = heatExchangerData

	const constructionData = [
		{
			name: 'End Type',
			value: endPlateTypeLabels[endPlateType],
			value2: '',
		},
		{
			name: 'Weight (dry)',
			unit: massUnitLabels[cboMass],
			value: massOfHeatExchanger.toFixed(0),
			value2: '',
		},
		{
			name: 'Position',
			value: positionLabels[position],
			value2: '',
		},
		{
			name: 'Design code',
			value: designCode || typeApporvalLabels[typeApproval],
			value2: '',
		},
		{
			name:
				dataText?.constructionRow5Text != dataText?.constructionRow6Text
					? 'Shell design pressure'
					: 'Design pressure',
			unit: designPressureOnDataSheet
				? designPressureUnitLabels[designPressureUnitOnDataSheet]
				: getDesignPressureUnit(designPressure),
			value: designPressureOnDataSheet || dataText?.constructionRow5Text,
		},
	]
	if (dataText?.constructionRow5Text != dataText?.constructionRow6Text)
		constructionData.push({
			name: 'Plate design pressure',
			unit: getDesignPressureUnit(designPressure),
			value: dataText?.constructionRow6Text || designPressurePlateSide,
		})
	constructionData.push(
		{
			name: 'Design temperature',
			unit: temUnitLabels[cboT],
			value: `${designTemperatureMin}/${designTemperatureMax}`,
			value2: dataText?.constructionRow7Text,
		},
		{
			name: 'Support',
			value: feetLabels[feet],
			value2: dataText?.constructionRow8Text,
		},
	)

	const constructionDataWithKey = constructionData.map((item, index) => ({
		key: index,
		...item,
	}))

	const constructionDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
		{
			dataIndex: 'value2',
			key: 'value2',
		},
	]

	// MATERIALS DATA
	const materialsData = [
		{
			name: 'Plate material',
			value:
				plateMaterialLabels[heatExchangerData.plateMaterial] +
				' ' +
				plateThicknessLabels[heatExchangerData.plateThickness],
		},
		{
			name: 'Shell material',
			value: getShellMaterial(
				endPlateType,
				shellMaterialLabels[heatExchangerData.shellMaterial],
			),
		},
		{
			name: 'Flow director material',
			value:
				heatExchangerData.condenserType === CondenserType.Normal &&
				heatExchangerData.flowDirectorMaterial === FlowDirectorMaterial.EPDM
					? `${
							flowDirectorMaterialLabels[heatExchangerData.flowDirectorMaterial]
					  } (short)`
					: flowDirectorMaterialLabels[heatExchangerData.flowDirectorMaterial],
		},
		{
			name: 'Plate connections',
			value: dataText?.materialsPlateConnections,
		},
		{
			name: 'Shell connections IN',
			value: dataText?.materialsShellConnectionsIn,
		},
		{
			name: 'Shell connections OUT',
			value: dataText?.materialsShellConnectionsOut,
		},
		{
			name: 'Surface treatment',
			value: paintingLabels[heatExchangerData.paint],
		},
	]

	const materialsDataWithKey = materialsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const materialsDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]

	// CONNECTIONS DATA
	const connectionsData: ConnectionsData[] = [
		{
			name: 'Plate side IN',
			size: +heatExchangerData.nozzlePlateSide,
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlate,
		},
		{
			name: 'Plate side OUT',
			size: +heatExchangerData.nozzlePlateSide,
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlateOut,
		},
		{
			name: 'Shell side IN',
			size: heatExchangerData.nozzleShell,
			numberOfPieces: heatExchangerData.numberOfShellNozzles,
			velocity: heatExchangerData.velocityShell,
		},
		{
			name: 'Shell side OUT',
			size: heatExchangerData.nozzleShellOut,
			numberOfPieces: heatExchangerData.numberOfShellNozzlesOut,
			velocity: heatExchangerData.velocityShellOut,
		},
	]

	const connectionDataWithKey = connectionsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const connectionDataColumns = [
		{
			title: 'Connections',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Size (DN)',
			dataIndex: 'size',
			key: 'size',
		},
		{
			title: 'Nb of pieces',
			dataIndex: 'numberOfPieces',
			key: 'numberOfPieces',
		},
		{
			title: 'Velocity',
			dataIndex: 'velocity',
			key: 'velocity',
		},
	]

	return {
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	}
}

//--------------------------------------------------------------------------------

// 	get multi-condenser Model Data (Overview page of the MC datasheet)

//--------------------------------------------------------------------------------

export const getMultiCondModelData = (heatExchangerData, dataText) => {
	const appTitle = 'MultiCondenser'

	// THERMAL DATA
	const {
		cboQ,
		capacity,
		cboM,
		flowRateLiquid,
		flowRateVapor,
		cboArea,
		area,
		cboT,
		lmtd,
		cboKval,
		kval,
		cboFF,
		foulingFactor,
		excessDesignArea,
		inletTemperatureLiquid,
		gasInletTemperature,
		condensingTemperature,
		outletTemperatureLiquid,
		subCooledTemperature,
		cboDp,
		pressureDropLiquidOutput,
		pressureDropVaporOutput,
		mediumSourceVaporSide,
		mediumVaporSide,
		mediumVaporSideOtherMedia,
		mediumSourceLiquidSide,
		mediumLiquidSide,
		mediumLiquidSideOtherMedia,
		concentrationLiquidSide,
	} = heatExchangerData

	const isPlateSideLiquid =
		heatExchangerData.liquidSideMultiCondenser === LiquidSide.PlateSide

	const mediumNameVaporSide =
		mediumSourceVaporSide != MediumSource.Imported
			? mediumLabels[mediumVaporSide]
			: mediumVaporSideOtherMedia

	const mediumNameLiquidSide = getMediumName({
		mediumSource: mediumSourceLiquidSide,
		mediumName: mediumLiquidSide,
		mediumNameOtherMedia: mediumLiquidSideOtherMedia,
		concentration: concentrationLiquidSide,
	})

	const thermalDesignData: ThermalDesignData[] = [
		{
			name: '',
			unit: '',
			value: '',
		},
		{
			name: 'Capacity',
			unit: capacityUnitLabels[cboQ],
			value: capacity,
		},
		{
			name: `Flow rate /${isPlateSideLiquid ? 'Cold' : 'Hot'} side`,
			unit: massFlowUnitLabels[cboM],
			value: isPlateSideLiquid ? flowRateLiquid : flowRateVapor,
		},
		{
			name: `Flow rate /${isPlateSideLiquid ? 'Hot' : 'Cold'} side`,
			unit: massFlowUnitLabels[cboM],
			value: isPlateSideLiquid ? flowRateVapor : flowRateLiquid,
		},
		{
			name: 'Heat transfer area',
			unit: areaLabels[cboArea],
			value: area,
		},
		{
			name: 'Logarithmic mean T',
			unit: temUnitLabels[cboT],
			value: lmtd,
		},
		{
			name: 'K-value',
			unit: kValueLabels[cboKval],
			value: kval,
		},
		{
			name: 'Fouling factor',
			unit: foulingFactorUnitLabels[cboFF],
			value: foulingFactor,
		},
		{
			name: 'Extra Capacity',
			unit: '%',
			value: excessDesignArea,
		},
	]

	const thermalDesignDataWithKey = thermalDesignData.map((item, index) => ({
		key: index,
		...item,
	}))

	const thermalDesignColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Thermal Design',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]

	//====================================MEDIUM DATA===============================
	const {
		deltaPCondSide,
		cboVisco,
		dynamicViscosityVaporSideL,
		dynamicViscosityVaporSideV,
		dynamicViscosityVaporSideSuperh,
		cboH,
		specificGravityVaporSideL,
		specificGravityVaporSideV,
		specificGravityVaporSideSuperh,
		specificHeatVaporSideL,
		specificHeatVaporSideV,
		specificHeatVaporSideSuperh,
		thermalConductivityVaporSideL,
		thermalConductivityVaporSideV,
		thermalConductivityVaporSideSuperh,
		surfaceTensionVaporSideL,
		cboSigma,
		cboGravi,
		cboSpH,
		cboCdy,
		enthalpyVaporSideL,
		enthalpyVaporSideOutlet,
		enthalpyVaporSideSuperh,
		enthalpyVaporSideV,
	} = heatExchangerData

	const mediumData: MultiCondenserMediumData[] = [
		{
			name: 'Inlet Temperature',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? inletTemperatureLiquid : gasInletTemperature,
			value2: isPlateSideLiquid ? gasInletTemperature : inletTemperatureLiquid,
			vaporSideMediumName: 'Dynamic Viscosity',
			vaporSideMediumUnit: dynamicViscoUnitLabels[cboVisco],
			vaporSidevalue: dynamicViscosityVaporSideL,
			vaporSidevalue2: dynamicViscosityVaporSideV,
			vaporSidevalue3: dynamicViscosityVaporSideSuperh,
		},
		{
			name: 'Condensing',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? null : condensingTemperature,
			value2: isPlateSideLiquid ? condensingTemperature : null,
			vaporSideMediumName: 'Density',
			vaporSideMediumUnit: specificGravityUnitLables[cboGravi],
			vaporSidevalue: specificGravityVaporSideL,
			vaporSidevalue2: specificGravityVaporSideV,
			vaporSidevalue3: specificGravityVaporSideSuperh,
		},
		{
			name: 'Outlet Temperature',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? outletTemperatureLiquid : subCooledTemperature,
			value2: isPlateSideLiquid
				? subCooledTemperature
				: outletTemperatureLiquid,
			vaporSideMediumName: 'Specific Heat',
			vaporSideMediumUnit: specificHeatUnitLables[cboSpH],
			vaporSidevalue: specificHeatVaporSideL,
			vaporSidevalue2: specificHeatVaporSideV,
			vaporSidevalue3: specificHeatVaporSideSuperh,
		},
		{
			name: 'Pressure drop',
			unit: pressureUnitLabels[cboDp],
			value: isPlateSideLiquid
				? pressureDropVaporOutput
				: pressureDropLiquidOutput,
			value2: isPlateSideLiquid
				? pressureDropLiquidOutput
				: pressureDropVaporOutput,
			vaporSideMediumName: 'Thermal Conductivity',
			vaporSideMediumUnit: thermalConductivityUnitLabels[cboCdy],
			vaporSidevalue: thermalConductivityVaporSideL,
			vaporSidevalue2: thermalConductivityVaporSideV,
			vaporSidevalue3: thermalConductivityVaporSideSuperh,
		},
		{
			name: 'Delta P / 1°C',
			unit: '',
			value: deltaPCondSide,
			vaporSideMediumName: 'Surface Tension',
			vaporSideMediumUnit: UnitSurfaceTensionOption[cboSigma],
			vaporSidevalue: surfaceTensionVaporSideL,
			vaporSidevalue2: '',
			vaporSidevalue3: '',
		},
		{
			name: '*) Intermediate temperatures on liquid side',
			unit: '',
			value: '',
			vaporSideMediumName: 'Enthalpy',
			vaporSideMediumUnit: UnitEntalphyOption[cboH],
			vaporSidevalue: '',
			vaporSidevalue2: enthalpyVaporSideV,
			vaporSidevalue3: enthalpyVaporSideSuperh,
		},
		{
			name: '',
			unit: '',
			value: '',
			vaporSideMediumName: 'Enthalpy / Liquid (min)',
			vaporSideMediumUnit: UnitEntalphyOption[cboH],
			vaporSidevalue: enthalpyVaporSideOutlet,
			vaporSidevalue2: '',
			vaporSidevalue3: '',
		},
		{
			name: '',
			unit: '',
			value: '',
			vaporSideMediumName: 'Enthalpy / Saturated Liquid',
			vaporSideMediumUnit: UnitEntalphyOption[cboH],
			vaporSidevalue: enthalpyVaporSideL,
			vaporSidevalue2: '',
			vaporSidevalue3: '',
		},
	]

	const mediumDataWithKey = mediumData.map((item, index) => ({
		key: index,
		...item,
	}))

	const mediumDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: isPlateSideLiquid ? mediumNameLiquidSide : mediumNameVaporSide,
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: isPlateSideLiquid ? mediumNameVaporSide : mediumNameLiquidSide,
		},
		{
			dataIndex: 'vaporSideMediumName',
			key: 'vaporSideMediumName',
			title: 'Vapor Side Medium Properties',
		},
		{
			dataIndex: 'vaporSideMediumUnit',
			key: 'vaporSideMediumUnit',
		},
		{
			dataIndex: 'vaporSidevalue',
			key: 'valvaporSidevalueue',
			title: 'Liquid',
		},
		{
			dataIndex: 'vaporSidevalue2',
			key: 'vaporSidevalue2',
			title: 'Saturated Vapor',
		},
		{
			dataIndex: 'vaporSidevalue3',
			key: 'vaporSidevalue3',
			title: 'Superheated',
		},
	]

	const {
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	} = getConnectionsConstructionAndMaterialsData(heatExchangerData, dataText)

	return {
		appTitle,
		thermalDesignDataWithKey,
		thermalDesignColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	}
}

//--------------------------------------------------------------------------------

// 	get multi-condenser Desuperheater Data

//--------------------------------------------------------------------------------

export const getMultiCondDesupData = (heatExchangerData, dataText) => {
	const appTitle = 'MultiCondDesuperheater'
	// THERMAL DATA
	const {
		cboQ,
		capacityDesuperHeater,
		cboArea,
		areaDesuperHeater,
		cboT,
		lmtdDesuperHeater,
		cboKval,
		kvalDesuperHeater,
		cboFF,
		foulingFactor,
		excessDesignAreaDesuperHeater,
		liquidSideMultiCondenser,
	} = heatExchangerData

	const isPlateSideLiquid = liquidSideMultiCondenser === LiquidSide.PlateSide

	const thermalDesignData: ThermalDesignData[] = [
		{
			name: 'Capacity',
			unit: capacityUnitLabels[cboQ],
			value: capacityDesuperHeater,
		},
		{
			name: 'Heat transfer area',
			unit: areaLabels[cboArea],
			value: areaDesuperHeater,
		},
		{
			name: 'Logarithmic mean T',
			unit: temUnitLabels[cboT],
			value: lmtdDesuperHeater,
		},
		{
			name: 'K-value',
			unit: kValueLabels[cboKval],
			value: kvalDesuperHeater,
		},
		{
			name: 'Fouling factor',
			unit: foulingFactorUnitLabels[cboFF],
			value: foulingFactor,
		},
		{
			name: 'Extra Capacity',
			unit: '%',
			value: excessDesignAreaDesuperHeater,
		},
	]

	const thermalDesignDataWithKey = thermalDesignData.map((item, index) => ({
		key: index,
		...item,
	}))

	const thermalDesignColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]
	// PLATE SHELL DATA
	const {
		midMediumTempT3B,
		gasInletTemperature,
		cboDp,
		pressureDropLiquidOutputDesuperHeater,
		pressureDropVaporOutputDesuperHeater,
		outletTemperatureLiquid,
		condensingTemperature,
		flowRateLiquid,
		flowRateVapor,
		cboM,
		cboV,
	} = heatExchangerData

	const plateShellData: PlateShellData[] = [
		{
			name: 'Temp IN',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? midMediumTempT3B : gasInletTemperature,
			value2: isPlateSideLiquid ? gasInletTemperature : midMediumTempT3B,
		},
		{
			name: 'Temp OUT',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid
				? outletTemperatureLiquid
				: condensingTemperature,
			value2: isPlateSideLiquid
				? condensingTemperature
				: outletTemperatureLiquid,
		},
		{
			name: 'Evaporating temperature',
			unit: temUnitLabels[cboT],
			value: '',
			value2: '',
		},
		{
			name: 'Condensing temperature',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? null : condensingTemperature,
			value2: isPlateSideLiquid ? condensingTemperature : null,
		},
		{
			name: '',
			unit: '',
			value: '',
			value2: '',
		},
		{
			name: 'Flow rate',
			unit: massFlowUnitLabels[cboM],
			value: isPlateSideLiquid ? flowRateLiquid : flowRateVapor,
			value2: isPlateSideLiquid ? flowRateVapor : flowRateLiquid,
		},
		{
			name: 'Pressure drop',
			unit: pressureUnitLabels[cboDp],
			value: isPlateSideLiquid
				? pressureDropLiquidOutputDesuperHeater
				: pressureDropVaporOutputDesuperHeater,
			value2: isPlateSideLiquid
				? pressureDropVaporOutputDesuperHeater
				: pressureDropLiquidOutputDesuperHeater,
		},
		{
			name: 'Liquid volume',
			unit: volumeUnitLabels[cboV],
			value: '',
			value2: '',
		},
	]

	const plateShellDataWithKey = plateShellData.map((item, index) => ({
		key: index,
		...item,
	}))

	const plateShellColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: `Plate Side (${isPlateSideLiquid ? 'Cold' : 'Hot'})`,
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: `Shell Side (${isPlateSideLiquid ? 'Hot' : 'Cold'})`,
		},
	]

	// MEDIUM DATA
	const {
		mediumLiquidSide,
		mediumVaporSide,
		mediumSourceVaporSide,
		mediumSourceLiquidSide,
		mediumVaporSideOtherMedia,
		mediumLiquidSideOtherMedia,
		cboGravi,
		cboSpH,
		cboP,
		cboCdy,
		dynamicViscosityLiquidSide,
		specificGravityLiquidSide,
		specificHeatLiquidSide,
		thermalConductivityLiquidSide,
		saturatedPressure,
		cboVisco,
		concentrationLiquidSide,
		liquidSidePhase,
		gasPressureOnLiquidSide,
	} = heatExchangerData
	const mediumData: MediumData[] = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value: dynamicViscosityLiquidSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value: specificGravityLiquidSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value: specificHeatLiquidSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value: thermalConductivityLiquidSide,
		},
	]

	const mediumDataWithKey = mediumData.map((item, index) => ({
		key: index,
		...item,
	}))

	const mediumNameVaporSide =
		mediumSourceVaporSide != MediumSource.Imported
			? mediumLabels[mediumVaporSide]
			: mediumVaporSideOtherMedia

	const mediumNameLiquid = getMediumName({
		mediumSource: mediumSourceLiquidSide,
		mediumName: mediumLiquidSide,
		mediumNameOtherMedia: mediumLiquidSideOtherMedia,
		concentration: concentrationLiquidSide,
	})

	const mediumNameLiquidSide =
		liquidSidePhase === LiquidSidePhase.Gas
			? mediumNameLiquid +
			  ` (${gasPressureOnLiquidSide.toFixed(2)} ${pressureUnitLabels[cboP]})`
			: mediumNameLiquid

	const mediumDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
			title: '',
		},
		{
			dataIndex: isPlateSideLiquid ? 'value' : 'value2',
			key: isPlateSideLiquid ? 'value' : 'value2',
			title: isPlateSideLiquid
				? mediumNameLiquidSide
				: mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`,
		},
		{
			dataIndex: isPlateSideLiquid ? 'value2' : 'value',
			key: isPlateSideLiquid ? 'value2' : 'value',
			title: isPlateSideLiquid
				? mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`
				: mediumNameLiquidSide,
		},
	]

	const {
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	} = getConnectionsConstructionAndMaterialsData(heatExchangerData, dataText)

	return {
		appTitle,
		thermalDesignDataWithKey,
		thermalDesignColumns,
		plateShellDataWithKey,
		plateShellColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	}
}
//--------------------------------------------------------------------------------

// 	get multi-condenser Condenser Data

//--------------------------------------------------------------------------------

export const getMultiCondCondData = (heatExchangerData, dataText) => {
	const appTitle = 'MultiCondCond'
	// THERMAL DATA
	const {
		cboQ,
		capacityCondenser,
		cboArea,
		areaCondenser,
		cboT,
		lmtdCondenser,
		cboKval,
		kvalCondenser,
		cboFF,
		foulingFactor,
		excessDesignAreaCondenser,
		liquidSideMultiCondenser,
	} = heatExchangerData

	const isPlateSideLiquid = liquidSideMultiCondenser === LiquidSide.PlateSide

	const thermalDesignData: ThermalDesignData[] = [
		{
			name: 'Capacity',
			unit: capacityUnitLabels[cboQ],
			value: capacityCondenser,
		},
		{
			name: 'Heat transfer area',
			unit: areaLabels[cboArea],
			value: areaCondenser,
		},
		{
			name: 'Logarithmic mean T',
			unit: temUnitLabels[cboT],
			value: lmtdCondenser,
		},
		{
			name: 'K-value',
			unit: kValueLabels[cboKval],
			value: kvalCondenser,
		},
		{
			name: 'Fouling factor',
			unit: foulingFactorUnitLabels[cboFF],
			value: foulingFactor,
		},
		{
			name: 'Extra Capacity',
			unit: '%',
			value: excessDesignAreaCondenser,
		},
	]

	const thermalDesignDataWithKey = thermalDesignData.map((item, index) => ({
		key: index,
		...item,
	}))

	const thermalDesignColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]
	// PLATE SHELL DATA
	const {
		midMediumTempT3A,
		midMediumTempT3B,
		cboDp,
		pressureDropLiquidOutputCondenser,
		pressureDropVaporOutputCondenser,
		condensingTemperature,
		flowRateLiquid,
		flowRateVapor,
		cboM,
		cboV,
	} = heatExchangerData

	// These values switch based on application-- why?
	const plateShellData: PlateShellData[] = [
		{
			name: 'Temp IN',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? midMediumTempT3A : condensingTemperature,
			value2: isPlateSideLiquid ? condensingTemperature : midMediumTempT3A,
		},
		{
			name: 'Temp OUT',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? midMediumTempT3B : condensingTemperature,
			value2: isPlateSideLiquid ? condensingTemperature : midMediumTempT3B,
		},
		{
			name: 'Evaporating temperature',
			unit: temUnitLabels[cboT],
			value: '',
			value2: '',
		},
		{
			name: 'Condensing temperature',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? null : condensingTemperature,
			value2: isPlateSideLiquid ? condensingTemperature : null,
		},
		{
			name: '',
			unit: '',
			value: '',
			value2: '',
		},
		{
			name: 'Flow rate',
			unit: massFlowUnitLabels[cboM],
			value: isPlateSideLiquid ? flowRateLiquid : flowRateVapor,
			value2: isPlateSideLiquid ? flowRateVapor : flowRateLiquid,
		},
		{
			name: 'Pressure drop',
			unit: pressureUnitLabels[cboDp],
			value: isPlateSideLiquid
				? pressureDropVaporOutputCondenser
				: pressureDropLiquidOutputCondenser,
			value2: isPlateSideLiquid
				? pressureDropLiquidOutputCondenser
				: pressureDropVaporOutputCondenser,
		},
		{
			name: 'Liquid volume',
			unit: volumeUnitLabels[cboV],
			value: '',
			value2: '',
		},
	]

	const plateShellDataWithKey = plateShellData.map((item, index) => ({
		key: index,
		...item,
	}))

	const plateShellColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: `Plate Side (${isPlateSideLiquid ? 'Cold' : 'Hot'})`,
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: `Shell Side (${isPlateSideLiquid ? 'Hot' : 'Cold'})`,
		},
	]

	// MEDIUM DATA
	const {
		mediumLiquidSide,
		mediumVaporSide,
		mediumSourceVaporSide,
		mediumSourceLiquidSide,
		mediumVaporSideOtherMedia,
		mediumLiquidSideOtherMedia,
		cboGravi,
		cboSpH,
		cboCdy,
		dynamicViscosityLiquidSide,
		specificGravityLiquidSide,
		specificHeatLiquidSide,
		thermalConductivityLiquidSide,
		saturatedPressure,
		cboVisco,
		concentrationLiquidSide,
		liquidSidePhase,
		gasPressureOnLiquidSide,
		cboP,
	} = heatExchangerData
	const mediumData: MediumData[] = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value: dynamicViscosityLiquidSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value: specificGravityLiquidSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value: specificHeatLiquidSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value: thermalConductivityLiquidSide,
		},
	]

	const mediumDataWithKey = mediumData.map((item, index) => ({
		key: index,
		...item,
	}))

	const mediumNameVaporSide =
		mediumSourceVaporSide != MediumSource.Imported
			? mediumLabels[mediumVaporSide]
			: mediumVaporSideOtherMedia

	const mediumNameLiquid = getMediumName({
		mediumSource: mediumSourceLiquidSide,
		mediumName: mediumLiquidSide,
		mediumNameOtherMedia: mediumLiquidSideOtherMedia,
		concentration: concentrationLiquidSide,
	})

	const mediumNameLiquidSide =
		liquidSidePhase === LiquidSidePhase.Gas
			? mediumNameLiquid +
			  ` (${gasPressureOnLiquidSide.toFixed(2)} ${pressureUnitLabels[cboP]})`
			: mediumNameLiquid

	const mediumDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: isPlateSideLiquid ? 'value' : 'value2',
			key: isPlateSideLiquid ? 'value' : 'value2',
			title: isPlateSideLiquid
				? mediumNameLiquidSide
				: mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`,
		},
		{
			dataIndex: isPlateSideLiquid ? 'value2' : 'value',
			key: isPlateSideLiquid ? 'value2' : 'value',
			title: isPlateSideLiquid
				? mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`
				: mediumNameLiquidSide,
		},
	]

	const {
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	} = getConnectionsConstructionAndMaterialsData(heatExchangerData, dataText)

	return {
		appTitle,
		thermalDesignDataWithKey,
		thermalDesignColumns,
		plateShellDataWithKey,
		plateShellColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	}
}

//--------------------------------------------------------------------------------

// 	get multi-condenser SubCooler Data

//--------------------------------------------------------------------------------

export const getMultiCondSubCooData = (heatExchangerData, dataText) => {
	const appTitle = 'MultiCondSubc'
	// THERMAL DATA
	const {
		cboQ,
		capacitySubcooler,
		cboArea,
		areaSubcooler,
		cboT,
		lmtdSubcooler,
		cboKval,
		kvalSubcooler,
		cboFF,
		foulingFactor,
		excessDesignAreaSubcooler,
		liquidSideMultiCondenser,
	} = heatExchangerData

	const isPlateSideLiquid = liquidSideMultiCondenser === LiquidSide.PlateSide

	const thermalDesignData: ThermalDesignData[] = [
		{
			name: 'Capacity',
			unit: capacityUnitLabels[cboQ],
			value: capacitySubcooler,
		},
		{
			name: 'Heat transfer area',
			unit: areaLabels[cboArea],
			value: areaSubcooler,
		},
		{
			name: 'Logarithmic mean T',
			unit: temUnitLabels[cboT],
			value: lmtdSubcooler,
		},
		{
			name: 'K-value',
			unit: kValueLabels[cboKval],
			value: kvalSubcooler,
		},
		{
			name: 'Fouling factor',
			unit: foulingFactorUnitLabels[cboFF],
			value: foulingFactor,
		},
		{
			name: 'Extra Capacity',
			unit: '%',
			value: excessDesignAreaSubcooler,
		},
	]

	const thermalDesignDataWithKey = thermalDesignData.map((item, index) => ({
		key: index,
		...item,
	}))

	const thermalDesignColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]
	// PLATE SHELL DATA
	const {
		inletTemperatureLiquid,
		cboDp,
		pressureDropLiquidOutputSubcooler,
		pressureDropVaporOutputSubcooler,
		subCooledTemperature,
		midMediumTempT3A,
		condensingTemperature,
		flowRateLiquid,
		flowRateVapor,
		cboM,
		cboV,
	} = heatExchangerData

	// These values switch based on application-- why?
	const plateShellData: PlateShellData[] = [
		{
			name: 'Temp IN',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? inletTemperatureLiquid : condensingTemperature,
			value2: isPlateSideLiquid
				? condensingTemperature
				: inletTemperatureLiquid,
		},
		{
			name: 'Temp OUT',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? midMediumTempT3A : subCooledTemperature,
			value2: isPlateSideLiquid ? subCooledTemperature : midMediumTempT3A,
		},
		{
			name: 'Evaporating temperature',
			unit: temUnitLabels[cboT],
			value: '',
			value2: '',
		},
		{
			name: 'Condensing temperature',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? null : condensingTemperature,
			value2: isPlateSideLiquid ? condensingTemperature : null,
		},
		{
			name: '',
			unit: '',
			value: '',
			value2: '',
		},
		{
			name: 'Flow rate',
			unit: massFlowUnitLabels[cboM],
			value: isPlateSideLiquid ? flowRateLiquid : flowRateVapor,
			value2: isPlateSideLiquid ? flowRateVapor : flowRateLiquid,
		},
		{
			name: 'Pressure drop',
			unit: pressureUnitLabels[cboDp],
			value: isPlateSideLiquid
				? pressureDropVaporOutputSubcooler
				: pressureDropLiquidOutputSubcooler,
			value2: isPlateSideLiquid
				? pressureDropLiquidOutputSubcooler
				: pressureDropVaporOutputSubcooler,
		},
		{
			name: 'Liquid volume',
			unit: volumeUnitLabels[cboV],
			value: '',
			value2: '',
		},
	]

	const plateShellDataWithKey = plateShellData.map((item, index) => ({
		key: index,
		...item,
	}))

	const plateShellColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: `Plate Side (${isPlateSideLiquid ? 'Cold' : 'Hot'})`,
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: `Shell Side (${isPlateSideLiquid ? 'Hot' : 'Cold'})`,
		},
	]

	// MEDIUM DATA
	const {
		mediumLiquidSide,
		mediumVaporSide,
		mediumSourceVaporSide,
		mediumSourceLiquidSide,
		mediumVaporSideOtherMedia,
		mediumLiquidSideOtherMedia,
		cboGravi,
		cboSpH,
		cboCdy,
		dynamicViscosityLiquidSide,
		specificGravityLiquidSide,
		specificHeatLiquidSide,
		thermalConductivityLiquidSide,
		saturatedPressure,
		cboVisco,
		concentrationLiquidSide,
		liquidSidePhase,
		gasPressureOnLiquidSide,
		cboP,
	} = heatExchangerData
	const mediumData: MediumData[] = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value: dynamicViscosityLiquidSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value: specificGravityLiquidSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value: specificHeatLiquidSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value: thermalConductivityLiquidSide,
		},
	]

	const mediumDataWithKey = mediumData.map((item, index) => ({
		key: index,
		...item,
	}))

	const mediumNameVaporSide =
		mediumSourceVaporSide != MediumSource.Imported
			? mediumLabels[mediumVaporSide]
			: mediumVaporSideOtherMedia

	const mediumNameLiquid = getMediumName({
		mediumSource: mediumSourceLiquidSide,
		mediumName: mediumLiquidSide,
		mediumNameOtherMedia: mediumLiquidSideOtherMedia,
		concentration: concentrationLiquidSide,
	})

	const mediumNameLiquidSide =
		liquidSidePhase === LiquidSidePhase.Gas
			? mediumNameLiquid +
			  ` (${gasPressureOnLiquidSide.toFixed(2)} ${pressureUnitLabels[cboP]})`
			: mediumNameLiquid

	const mediumDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: isPlateSideLiquid ? 'value' : 'value2',
			key: isPlateSideLiquid ? 'value' : 'value2',
			title: isPlateSideLiquid
				? mediumNameLiquidSide
				: mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`,
		},
		{
			dataIndex: isPlateSideLiquid ? 'value2' : 'value',
			key: isPlateSideLiquid ? 'value2' : 'value',
			title: isPlateSideLiquid
				? mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`
				: mediumNameLiquidSide,
		},
	]

	const {
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	} = getConnectionsConstructionAndMaterialsData(heatExchangerData, dataText)

	return {
		appTitle,
		thermalDesignDataWithKey,
		thermalDesignColumns,
		plateShellDataWithKey,
		plateShellColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	}
}

export const getHeaderData = heatExchangerData => {
	const condenserAppTitle = 'MultiCondCond'
	const desuperheaterAppTitle = 'MultiCondDesuperheater'
	const subcoolerAppTitle = 'MultiCondSubc'
	const condenserPsheType = heatExchangerData.psheTypeCondenser
	const desuperheaterPsheType = heatExchangerData.psheTypeDesuperHeater
	const subcoolerPsheType = heatExchangerData.psheTypeSubcooler

	return {
		condenserAppTitle,
		desuperheaterAppTitle,
		subcoolerAppTitle,
		condenserPsheType,
		desuperheaterPsheType,
		subcoolerPsheType,
	}
}

//--------------------------------------------------------------------------------

// 	get ALL multi-condenser data COMBINED
// **this is for exporting to excel, not for the datasheet

//--------------------------------------------------------------------------------
export const getAllMultiCondenserData = (heatExchangerData, dataText) => {
	//TODO: this appTitle may need to be changed later for the other pages of excel
	const appTitle = 'MultiCondenser'

	const headerData = getHeaderData(heatExchangerData)

	const modelData = getMultiCondModelData(heatExchangerData, dataText)

	const modelThermalDesignDataWithKey = modelData.thermalDesignDataWithKey
	const modelThermalDesignColumns = modelData.thermalDesignColumns
	const modelMediumDataWithKey = modelData.mediumDataWithKey
	const modelMediumDataColumns = modelData.mediumDataColumns

	const desupData = getMultiCondDesupData(heatExchangerData, dataText)

	const desupThermalDesignDataWithKey = desupData.thermalDesignDataWithKey
	const desupThermalDesignColumns = desupData.thermalDesignColumns
	const desupPlateShellDataWithKey = desupData.plateShellDataWithKey
	const desupPlateShellColumns = desupData.plateShellColumns
	const desupMediumDataWithKey = desupData.mediumDataWithKey
	const desupmediumDataColumns = desupData.mediumDataColumns

	const condData = getMultiCondCondData(heatExchangerData, dataText)

	const condThermalDesignDataWithKey = condData.thermalDesignDataWithKey
	const condThermalDesignColumns = condData.thermalDesignColumns
	const condPlateShellDataWithKey = condData.plateShellDataWithKey
	const condPlateShellColumns = condData.plateShellColumns
	const condMediumDataWithKey = condData.mediumDataWithKey
	const condmediumDataColumns = condData.mediumDataColumns

	const subcoolerData = getMultiCondSubCooData(heatExchangerData, dataText)

	const subcoolerThermalDesignDataWithKey =
		subcoolerData.thermalDesignDataWithKey
	const subcoolerThermalDesignColumns = subcoolerData.thermalDesignColumns
	const subcoolerPlateShellDataWithKey = subcoolerData.plateShellDataWithKey
	const subcoolerPlateShellColumns = subcoolerData.plateShellColumns
	const subcoolerMediumDataWithKey = subcoolerData.mediumDataWithKey
	const subcoolermediumDataColumns = subcoolerData.mediumDataColumns

	const {
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	} = getConnectionsConstructionAndMaterialsData(heatExchangerData, dataText)

	// for drawings in excel export
	const shellDiam = heatExchangerData.shellDiameter
	const liquidSideMultiCondenser = heatExchangerData.liquidSideMultiCondenser
	const multiPassCombo = heatExchangerData.multiPassCombination

	return {
		appTitle,
		headerData,
		modelThermalDesignDataWithKey,
		modelThermalDesignColumns,
		modelMediumDataWithKey,
		modelMediumDataColumns,
		desupThermalDesignDataWithKey,
		desupThermalDesignColumns,
		desupPlateShellDataWithKey,
		desupPlateShellColumns,
		desupMediumDataWithKey,
		desupmediumDataColumns,
		condThermalDesignDataWithKey,
		condThermalDesignColumns,
		condPlateShellDataWithKey,
		condPlateShellColumns,
		condMediumDataWithKey,
		condmediumDataColumns,
		subcoolerThermalDesignDataWithKey,
		subcoolerThermalDesignColumns,
		subcoolerPlateShellDataWithKey,
		subcoolerPlateShellColumns,
		subcoolerMediumDataWithKey,
		subcoolermediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
		liquidSideMultiCondenser,
		multiPassCombo,
		shellDiam,
	}
}
