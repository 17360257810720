import React from 'react'
import GlobalHeader from '../components/GlobalHeader'

const BlockPage = () => {
	return (
		<div className="block-page">
			<GlobalHeader />
			<div className="block">
				<h1 className="block__heading">
					A new version of CPQ is available, please click on this link to update
					to the new version!{' '}
					<a href="https://sales.vahterus.cloud">
						https://sales.vahterus.cloud
					</a>
				</h1>
			</div>
		</div>
	)
}

export default BlockPage
