import React from 'react'
import {Tooltip} from 'antd'
import classNames from 'classnames'
import {InputProps} from 'antd/lib/input'
import IconInfo from '../assets/imgs/icon-info.svg'
import InputUnderline from './InputUnderline'
export type InputState = 'default' | 'hot' | 'cold'

interface Props extends InputProps {
	state?: InputState
	isOutput?: boolean
	addonAfter?: string
	tooltipText?: string
	isPercentage?: boolean
}

const InputNumberUnderline: React.FunctionComponent<Props> = ({
	className,
	addonAfter,
	state,
	isOutput,
	disabled,
	tooltipText,
	isPercentage,
	...props
}) => {
	const prefixCls = 'input-number-underline'
	const classes = classNames(prefixCls, className, {
		[`${prefixCls}-${state}`]: state,
		[`${prefixCls}-output`]: isOutput,
	})
	return (
		<div className="vahterus-input-wrapper">
			<InputUnderline
				type="text"
				// formatter={value => (isOutput ? precise(value) : `${value}`)}
				addonAfter={isPercentage && '%'}
				disabled={isOutput || disabled}
				{...props}
				className={classes}
			/>
			{tooltipText ? (
				<div className="input-number-underline__tooltip">
					<Tooltip title={tooltipText}>
						<img src={IconInfo} alt="icon-info" />
					</Tooltip>
				</div>
			) : null}
			{addonAfter ? (
				<span className="input-number-underline__addonAfter">{addonAfter}</span>
			) : null}
		</div>
	)
}

InputNumberUnderline.defaultProps = {
	state: 'default',
	isOutput: false,
}

export default InputNumberUnderline
