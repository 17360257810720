import {useMutation, useQuery} from 'react-query'
import {DrawingServerStatus, DrawingStatus} from '../model/Calculation'
import {client} from '../utils/apiClient'

type DrawingRequestBody = {
	positionId: number
	calculationId: number
	email: string
}

export function useCreateDrawing(options?) {
	return useMutation<DrawingRequestBody[], Error, DrawingRequestBody[]>(
		drawingRequestBody => {
			return Promise.all(
				drawingRequestBody.map(requestBody => {
					const {positionId, calculationId, email} = requestBody
					return client(
						`positions/${positionId}/calculations/${calculationId}/sendDrawing`,
						{data: {newEmail: email}},
					)
				}),
			)
		},
		{
			...options,
		},
	)
}

export function useDrawingServerStatus(options?) {
	return useQuery<DrawingServerStatus[], Error>({
		queryKey: ['drawingServerStatus'],
		queryFn: () =>
			client(`integration/drawingServer/status`, {isRegionBound: false}),
		config: {...options},
	})
}

export function useDrawingStatus(drawingId, options?) {
	return useQuery<DrawingStatus, Error>({
		queryKey: ['drawingStatus', {drawingId}],
		queryFn: () =>
			client(`integration/drawing/${drawingId}/status`, {isRegionBound: false}),
		config: {...options},
	})
}
