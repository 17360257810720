import React from 'react'
import {Form, FormInstance} from 'antd'
import InputNumberUnderline from '../InputNumberUnderline'
import TemperatureUnit from '../SharedFormItem/TemperatureUnit'
import {usePrevious, useConvertCommaToDot} from '../../utils/hookUtils'
import {convertTemperature} from '../../utils/utils'

interface Props {
	form: FormInstance
	isRequired?: boolean
}

const InletTemInput: React.FunctionComponent<Props> = ({form, isRequired}) => {
	const {getFieldValue, setFieldsValue} = form
	const cboT = getFieldValue('cboT')
	const inletTemperatureCondSide = getFieldValue('inletTemperatureCondSide')
	const inletTemperatureEvapSide = getFieldValue('inletTemperatureEvapSide')
	const prevCboT = usePrevious(cboT)

	React.useEffect(() => {
		const cond = useConvertCommaToDot(inletTemperatureCondSide)
		setFieldsValue({inletTemperatureCondSide: cond})
		const evap = useConvertCommaToDot(inletTemperatureEvapSide)
		setFieldsValue({inletTemperatureEvapSide: evap})
	}, [inletTemperatureCondSide, inletTemperatureEvapSide])

	React.useEffect(() => {
		setFieldsValue({
			inletTemperatureCondSide: convertTemperature(
				inletTemperatureCondSide,
				prevCboT,
				cboT,
			),
		})
		setFieldsValue({
			inletTemperatureEvapSide: convertTemperature(
				inletTemperatureEvapSide,
				prevCboT,
				cboT,
			),
		})
	}, [cboT, setFieldsValue, prevCboT])

	return (
		<Form.Item label="Inlet. Temp" className="required-group">
			<Form.Item
				name={['inletTemperatureCondSide']}
				style={{display: 'inline-block'}}
				rules={[{required: isRequired == undefined ? true : isRequired}]}
			>
				<InputNumberUnderline state="hot" />
			</Form.Item>
			<Form.Item
				name={['inletTemperatureEvapSide']}
				style={{display: 'inline-block'}}
				rules={[{required: isRequired == undefined ? true : isRequired}]}
			>
				<InputNumberUnderline state="cold" />
			</Form.Item>
			<TemperatureUnit />
		</Form.Item>
	)
}

export default InletTemInput
