import React, {FC} from 'react'
import ArrowDownBlueIcon from '../../assets/imgs/datasheet-arrow-down-blue.svg'
import ArrowDownRedIcon from '../../assets/imgs/datasheet-arrow-down-red.svg'
import ArrowUpBlueIcon from '../../assets/imgs/datasheet-arrow-up-blue.svg'
import ArrowUpRedIcon from '../../assets/imgs/datasheet-arrow-up-red.svg'
import ArrowLeftBlueIcon from '../../assets/imgs/datasheet-arrow-left-blue.svg'
import ArrowLeftRedIcon from '../../assets/imgs/datasheet-arrow-left-red.svg'
import ArrowRightRedIcon from '../../assets/imgs/datasheet-arrow-right-red.svg'
import ArrowRightBlueIcon from '../../assets/imgs/datasheet-arrow-right-blue.svg'
import TwoStraightLines from '../../assets/imgs/datasheet-two-straight-lines.svg'
import {
	ApplicationType,
	EndPlateType,
	LiquidSide,
	MultiPassCombination,
	NumberOfPasses,
	NumberOfShellNozzles,
	ShellDiameter,
} from '../../model/CalculationEnums'
import classNames from 'classnames'
type ArrowSign = 'l' | 'r' | 'u' | 'd' | 'x'

type IDrawing<P> = React.FunctionComponent<P> & {
	VentCondenser: typeof DrawingVentCondenser
	Combined: typeof DrawingEvaporatorCombined
	MultiCondenser: typeof DrawingMultiCond
}

const renderStraightLines = (endPlateType: number) => {
	switch (endPlateType) {
		case EndPlateType.OpenableVersion:
			return (
				<>
					<span
						className="drawing__icon drawing__straight-lines--up drawing__straight-lines--up--left"
						key={'straight-lines--up--left'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
					<span
						className="drawing__icon drawing__straight-lines--down drawing__straight-lines--down--left"
						key={'sdstraight-lines--down--left'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
				</>
			)
		case EndPlateType.OpenableGirthFlange:
			return (
				<>
					<span
						className="drawing__icon drawing__straight-lines--up drawing__straight-lines--up--left"
						key={'straight-lines--up--left'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
					<span
						className="drawing__icon drawing__straight-lines--down drawing__straight-lines--down--left"
						key={'sdstraight-lines--down--left'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
				</>
			)
		case EndPlateType.OpenableGirthFlangeDouble:
			return (
				<>
					<span
						className="drawing__icon drawing__straight-lines--up drawing__straight-lines--up--left"
						key={'straight-lines--up--left'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
					<span
						className="drawing__icon drawing__straight-lines--down drawing__straight-lines--down--left"
						key={'sdstraight-lines--down--left'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
					<span
						className="drawing__icon drawing__straight-lines--up drawing__straight-lines--up--right"
						key={'straight-lines--up--right'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
					<span
						className="drawing__icon drawing__straight-lines--down drawing__straight-lines--down--right"
						key={'sdstraight-lines--down--right'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
				</>
			)
		case EndPlateType.OpenableDouble:
			return (
				<>
					<span
						className="drawing__icon drawing__straight-lines--up drawing__straight-lines--up--left"
						key={'straight-lines--up--left'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
					<span
						className="drawing__icon drawing__straight-lines--down drawing__straight-lines--down--left"
						key={'sdstraight-lines--down--left'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
					<span
						className="drawing__icon drawing__straight-lines--up drawing__straight-lines--up--right"
						key={'straight-lines--up--right'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
					<span
						className="drawing__icon drawing__straight-lines--down drawing__straight-lines--down--right"
						key={'sdstraight-lines--down--right'}
					>
						<img src={TwoStraightLines} alt="two-straight-lines-icon" />
					</span>
				</>
			)

		default:
			return null
	}
}

const renderArrowIcon = (
	arrowSign: ArrowSign,
	isHot: boolean,
	key?: string,
) => {
	switch (arrowSign) {
		case 'u':
			return (
				<span className="drawing__icon" key={key}>
					<img
						src={isHot ? ArrowUpRedIcon : ArrowUpBlueIcon}
						alt="arrow-icon"
					/>
				</span>
			)
		case 'd':
			return (
				<span className="drawing__icon" key={key}>
					<img
						src={isHot ? ArrowDownRedIcon : ArrowDownBlueIcon}
						alt="arrow-icon"
					/>
				</span>
			)
		case 'l':
			return (
				<span className="drawing__icon" key={key}>
					<img
						src={isHot ? ArrowLeftRedIcon : ArrowLeftBlueIcon}
						alt="arrow-icon"
					/>
				</span>
			)
		case 'r':
			return (
				<span className="drawing__icon" key={key}>
					<img
						src={isHot ? ArrowRightRedIcon : ArrowRightBlueIcon}
						alt="arrow-icon"
					/>
				</span>
			)
		case 'x':
			//'x' returns an empty span to move arrows in the same container to the correct side of the shared container
			return <span className="drawing__icon" key={key}></span>
	}
}

interface DrawingVentCondenserProps {
	arrowsPlateSide: string
	endPlateType: number
	numberOfInlet: NumberOfShellNozzles
	numberOfOutlet: NumberOfShellNozzles
	numberOfOutletUp: NumberOfShellNozzles
	isPlateHotSide: boolean
}

const emptyArrowSigns = ['x', 'x', 'x', 'x'] as ArrowSign[]

const DrawingVentCondenser: FC<DrawingVentCondenserProps> = ({
	arrowsPlateSide,
	endPlateType,
	numberOfInlet,
	numberOfOutlet,
	numberOfOutletUp,
	isPlateHotSide,
}) => {
	const splitPlateSide = arrowsPlateSide
		? (arrowsPlateSide.split('') as ArrowSign[])
		: emptyArrowSigns

	return (
		<div>
			<div className="drawing">
				<div className="drawing__plate">PLATE</div>
				{renderStraightLines(endPlateType)}
				<div className="drawing__arrow-container drawing__arrow-container--up">
					{Array(numberOfOutletUp)
						.fill(0)
						.map((_, index) =>
							renderArrowIcon('u', !isPlateHotSide, index.toString()),
						)}
				</div>
				<div className="drawing__arrow-container drawing__arrow-container--down-in">
					{Array(numberOfInlet)
						.fill(0)
						.map((_, index) =>
							renderArrowIcon('u', !isPlateHotSide, index.toString()),
						)}
				</div>
				<div className="drawing__arrow-container drawing__arrow-container--down">
					{Array(numberOfOutlet)
						.fill(0)
						.map((_, index) =>
							renderArrowIcon('d', !isPlateHotSide, index.toString()),
						)}
				</div>
				<div className="drawing__arrow-container drawing__arrow-container--left">
					{renderArrowIcon(splitPlateSide[0], isPlateHotSide)}
					{renderArrowIcon(splitPlateSide[1], isPlateHotSide)}
				</div>
				<div className="drawing__arrow-container drawing__arrow-container--right">
					{renderArrowIcon(splitPlateSide[2], isPlateHotSide)}
					{renderArrowIcon(splitPlateSide[3], isPlateHotSide)}
				</div>
			</div>
		</div>
	)
}

interface DrawingEvaporatorCombinedProps {
	arrowsPlateSide: string
	endPlateType: number
	numberOfInlet: NumberOfShellNozzles
	numberOfOutlet: NumberOfShellNozzles
	combinedC: number
	isPlateHotSide: boolean
}

const DrawingEvaporatorCombined: FC<DrawingEvaporatorCombinedProps> = ({
	arrowsPlateSide,
	combinedC,
	endPlateType,
	numberOfOutlet,
	numberOfInlet,
	isPlateHotSide,
}) => {
	const splitPlateSide = arrowsPlateSide
		? (arrowsPlateSide.split('') as ArrowSign[])
		: emptyArrowSigns

	const renderInlet = () => {
		if (numberOfInlet === 1) {
			return renderArrowIcon('r', !isPlateHotSide)
		} else {
			return (
				<>
					{renderArrowIcon('r', !isPlateHotSide)}
					{renderArrowIcon('l', !isPlateHotSide)}
				</>
			)
		}
	}

	const renderCombinedCOutlet = () => {
		if (numberOfOutlet === 1) {
			return renderArrowIcon('r', !isPlateHotSide)
		} else {
			return (
				<>
					{renderArrowIcon('r', !isPlateHotSide)}
					{renderArrowIcon('l', !isPlateHotSide)}
				</>
			)
		}
	}

	return (
		<div>
			<div className="drawing">
				<div className="drawing__plate">PLATE</div>
				{renderStraightLines(endPlateType)}
				{combinedC ? (
					<div className="drawing__arrow-container drawing__arrow-container--evaporator-combinedC-outlet">
						{renderCombinedCOutlet()}
					</div>
				) : (
					<div className="drawing__arrow-container drawing__arrow-container--up">
						{Array(numberOfOutlet)
							.fill(0)
							.map((_, index) =>
								renderArrowIcon('u', !isPlateHotSide, index.toString()),
							)}
					</div>
				)}
				<div className="drawing__arrow-container drawing__arrow-container--evaporator-inlet">
					{renderInlet()}
				</div>
				<div className="drawing__arrow-container drawing__arrow-container--left">
					{renderArrowIcon(splitPlateSide[0], isPlateHotSide)}
					{renderArrowIcon(splitPlateSide[1], isPlateHotSide)}
				</div>
				<div className="drawing__arrow-container drawing__arrow-container--right">
					{renderArrowIcon(splitPlateSide[2], isPlateHotSide)}
					{renderArrowIcon(splitPlateSide[3], isPlateHotSide)}
				</div>
			</div>
		</div>
	)
}

interface MultiCondProps {
	appTitle?: string
	liquidSideMultiCondenser: LiquidSide
	multiPassCombo: MultiPassCombination
	endPlateType: number
	psheTypeDesuperheater: string
	psheTypeCondenser: string
	psheTypeSubcooler: string
	numberOfInlet: NumberOfShellNozzles
	numberOfOutlet: NumberOfShellNozzles
	shellDiam: ShellDiameter
}

const DrawingMultiCond: FC<MultiCondProps> = ({
	appTitle,
	liquidSideMultiCondenser,
	multiPassCombo,
	endPlateType,
	psheTypeDesuperheater,
	psheTypeCondenser,
	psheTypeSubcooler,
	numberOfInlet,
	numberOfOutlet,
	shellDiam,
}) => {
	const shellUpClassNames = classNames(
		'drawing__arrow-container',
		'drawing__arrow-container--up',
	)
	const shellDownClassNames = classNames(
		'drawing__arrow-container',
		'drawing__arrow-container--down',
	)

	let desupArrowsPlateSide = emptyArrowSigns
	let condArrowsPlateSide = emptyArrowSigns
	let subcArrowsPlateSide = emptyArrowSigns
	let desupArrowsShellSideUp = null
	let desupArrowsShellSideDown = null
	let condArrowsShellSide = emptyArrowSigns
	let subcArrowsShellSideUp = null
	let subcArrowsShellSideDown = null

	const isColdSideLiquid = liquidSideMultiCondenser != LiquidSide.PlateSide

	switch (multiPassCombo) {
		case MultiPassCombination.DesupCondSubcWaterOnPlateSide: //1
			desupArrowsPlateSide = ['x', 'l', 'l', 'x']
			condArrowsPlateSide = isColdSideLiquid
				? ['l', 'x', 'x', 'l']
				: ['l', 'x', 'l', 'l']
			subcArrowsPlateSide = isColdSideLiquid
				? ['x', 'l', 'l', 'x']
				: ['l', 'l', 'l', 'x']
			desupArrowsShellSideUp = Array(numberOfInlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('u', !isColdSideLiquid, index.toString()),
				)
			desupArrowsShellSideDown = Array(numberOfOutlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('u', !isColdSideLiquid, index.toString()),
				)
			condArrowsShellSide = ['d', 'x', 'd', 'x']
			subcArrowsShellSideUp = Array(numberOfInlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('u', !isColdSideLiquid, index.toString()),
				)
			subcArrowsShellSideDown = Array(numberOfOutlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('u', !isColdSideLiquid, index.toString()),
				)
			break
		case MultiPassCombination.DesupCondWaterOnPlateSide: //2
			desupArrowsPlateSide = ['x', 'l', 'l', 'x']
			condArrowsPlateSide = isColdSideLiquid
				? ['l', 'x', 'x', 'l']
				: ['l', 'x', 'l', 'x']
			subcArrowsPlateSide = ['x', 'x', 'x', 'x']
			desupArrowsShellSideUp = Array(numberOfInlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('u', !isColdSideLiquid, index.toString()),
				)
			desupArrowsShellSideDown = Array(numberOfOutlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('u', !isColdSideLiquid, index.toString()),
				)
			condArrowsShellSide = ['d', 'x', 'd', 'x']
			break
		case MultiPassCombination.CondSubcWaterOnPlateSide: //3
			desupArrowsPlateSide = ['x', 'x', 'x', 'x']
			condArrowsPlateSide = isColdSideLiquid
				? ['l', 'x', 'x', 'l']
				: ['l', 'x', 'l', 'x']
			subcArrowsPlateSide = isColdSideLiquid
				? ['x', 'l', 'l', 'x']
				: ['l', 'x', 'l', 'x']

			condArrowsShellSide = ['d', 'x', 'd', 'x']
			subcArrowsShellSideUp = Array(numberOfInlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('u', !isColdSideLiquid, index.toString()),
				)
			subcArrowsShellSideDown = Array(numberOfOutlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('u', !isColdSideLiquid, index.toString()),
				)
			break
		case MultiPassCombination.DesupCondSubcWaterOnShellSide: //4
			desupArrowsPlateSide = ['x', 'r', 'r', 'x']
			condArrowsPlateSide = ['r', 'x', 'x', 'r']
			subcArrowsPlateSide = ['x', 'r', 'r', 'x']
			desupArrowsShellSideUp = Array(numberOfInlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('d', !isColdSideLiquid, index.toString()),
				)
			desupArrowsShellSideDown = Array(numberOfOutlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('d', !isColdSideLiquid, index.toString()),
				)
			condArrowsShellSide = isColdSideLiquid
				? ['u', 'x', 'u', 'x']
				: ['u', 'd', 'u', 'd']
			subcArrowsShellSideUp = Array(numberOfInlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('d', !isColdSideLiquid, index.toString()),
				)
			subcArrowsShellSideDown = Array(numberOfOutlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('d', !isColdSideLiquid, index.toString()),
				)
			break
		case MultiPassCombination.DesupCondWaterOnShellSide: //5
			desupArrowsPlateSide = ['x', 'r', 'r', 'x']
			condArrowsPlateSide = ['r', 'x', 'x', 'r']
			subcArrowsPlateSide = ['x', 'x', 'x', 'x']
			desupArrowsShellSideUp = Array(numberOfInlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('d', !isColdSideLiquid, index.toString()),
				)
			desupArrowsShellSideDown = Array(numberOfOutlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('d', !isColdSideLiquid, index.toString()),
				)
			condArrowsShellSide = isColdSideLiquid
				? ['u', 'x', 'u', 'x']
				: ['u', 'd', 'u', 'd']
			break
		case MultiPassCombination.CondSubcWaterOnShellSide: //6
			desupArrowsPlateSide = ['x', 'x', 'x', 'x']
			condArrowsPlateSide = ['r', 'x', 'x', 'r']
			subcArrowsPlateSide = ['x', 'r', 'r', 'x']
			condArrowsShellSide = isColdSideLiquid
				? ['u', 'x', 'u', 'x']
				: ['u', 'd', 'u', 'd']
			subcArrowsShellSideUp = Array(numberOfInlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('d', !isColdSideLiquid, index.toString()),
				)
			subcArrowsShellSideDown = Array(numberOfOutlet)
				.fill(0)
				.map((_, index) =>
					renderArrowIcon('d', !isColdSideLiquid, index.toString()),
				)
			break
		default:
			break
	}

	return (
		<div className="multi-condenser-drawings-wrapper">
			{/* Desuperheater */}
			{appTitle == 'MultiCondenser' || appTitle == 'MultiCondDesuperheater' ? (
				<div className="drawing">
					{appTitle == 'MultiCondenser' ? (
						<p className="pshe-label">{psheTypeDesuperheater}</p>
					) : (
						<p className="pshe-label">Shell &#981;{shellDiam}</p>
					)}
					<div className="drawing__plate">PLATE</div>
					{renderStraightLines(endPlateType)}
					<div className={shellUpClassNames}>{desupArrowsShellSideUp}</div>
					<div className={shellDownClassNames}>{desupArrowsShellSideDown}</div>
					<div className="drawing__arrow-container drawing__arrow-container--left">
						{renderArrowIcon(desupArrowsPlateSide[0], isColdSideLiquid)}
						{renderArrowIcon(desupArrowsPlateSide[1], isColdSideLiquid)}
					</div>
					<div className="drawing__arrow-container drawing__arrow-container--right">
						{renderArrowIcon(desupArrowsPlateSide[2], isColdSideLiquid)}
						{renderArrowIcon(desupArrowsPlateSide[3], isColdSideLiquid)}
					</div>
					{appTitle == 'MultiCondenser' ? (
						<p className="unit-label">Desuperheater</p>
					) : null}
				</div>
			) : null}

			{/* Condenser */}
			{appTitle == 'MultiCondenser' || appTitle == 'MultiCondCond' ? (
				<div className="drawing">
					{appTitle == 'MultiCondenser' ? (
						<p className="pshe-label">{psheTypeCondenser}</p>
					) : (
						<p className="pshe-label">Shell &#981;{shellDiam}</p>
					)}
					<div className="drawing__plate">PLATE</div>
					{renderStraightLines(endPlateType)}
					<div className={shellUpClassNames}>
						{renderArrowIcon(condArrowsShellSide[0], !isColdSideLiquid)}
						{renderArrowIcon(condArrowsShellSide[1], !isColdSideLiquid)}
					</div>
					<div className={shellDownClassNames}>
						{renderArrowIcon(condArrowsShellSide[2], !isColdSideLiquid)}
						{renderArrowIcon(condArrowsShellSide[3], !isColdSideLiquid)}
					</div>
					<div className="drawing__arrow-container drawing__arrow-container--left">
						{renderArrowIcon(condArrowsPlateSide[0], isColdSideLiquid)}
						{renderArrowIcon(condArrowsPlateSide[1], isColdSideLiquid)}
					</div>
					<div className="drawing__arrow-container drawing__arrow-container--right">
						{renderArrowIcon(condArrowsPlateSide[2], isColdSideLiquid)}
						{renderArrowIcon(condArrowsPlateSide[3], isColdSideLiquid)}
					</div>
					{appTitle == 'MultiCondenser' ? (
						<p className="unit-label">Condenser</p>
					) : null}
				</div>
			) : null}

			{/* Subcooler */}
			{appTitle == 'MultiCondenser' || appTitle == 'MultiCondSubc' ? (
				<div className="drawing">
					{appTitle == 'MultiCondenser' ? (
						<p className="pshe-label">{psheTypeSubcooler}</p>
					) : (
						<p className="pshe-label">Shell &#981;{shellDiam}</p>
					)}
					<div className="drawing__plate">PLATE</div>
					{renderStraightLines(endPlateType)}
					<div className={shellUpClassNames}>{subcArrowsShellSideUp}</div>
					<div className={shellDownClassNames}>{subcArrowsShellSideDown}</div>
					<div className="drawing__arrow-container drawing__arrow-container--left">
						{renderArrowIcon(subcArrowsPlateSide[0], !isColdSideLiquid)}
						{renderArrowIcon(subcArrowsPlateSide[1], !isColdSideLiquid)}
					</div>
					<div className="drawing__arrow-container drawing__arrow-container--right">
						{renderArrowIcon(subcArrowsPlateSide[2], !isColdSideLiquid)}
						{renderArrowIcon(subcArrowsPlateSide[3], !isColdSideLiquid)}
					</div>
					{appTitle == 'MultiCondenser' ? (
						<p className="unit-label">Subcooler</p>
					) : null}
				</div>
			) : null}
		</div>
	)
}

interface Props {
	applicationType?: ApplicationType
	arrowsPlateSide?: string
	arrowsShellSide?: string
	isPlateHotSide: boolean
	endPlateType: number
	numberOfPassesHotSide: NumberOfPasses
	numberOfPassesColdSide: NumberOfPasses
	liquidSide: LiquidSide
	numberOfPassesLiquidSide: NumberOfPasses
}

const Drawing: IDrawing<Props> = ({
	applicationType,
	arrowsPlateSide,
	arrowsShellSide,
	isPlateHotSide,
	endPlateType,
	numberOfPassesHotSide,
	numberOfPassesColdSide,
	liquidSide,
	numberOfPassesLiquidSide,
}) => {
	let numberOfPassesShellSide = NumberOfPasses._1Pass
	if (isPlateHotSide) {
		numberOfPassesShellSide = numberOfPassesColdSide
	} else {
		numberOfPassesShellSide = numberOfPassesHotSide
	}
	if (liquidSide == LiquidSide.PlateSide) {
		numberOfPassesShellSide = NumberOfPasses._1Pass
	}
	if (liquidSide == LiquidSide.ShellSide) {
		numberOfPassesShellSide = numberOfPassesLiquidSide
	}
	const splitPlateSide = arrowsPlateSide
		? (arrowsPlateSide.split('') as ArrowSign[])
		: emptyArrowSigns
	const splitShellSide = arrowsShellSide
		? (arrowsShellSide.split('') as ArrowSign[])
		: emptyArrowSigns
	const shellUpClassNames = classNames(
		'drawing__arrow-container',
		'drawing__arrow-container--up',
		{
			'drawing__arrow-container--up--middle':
				applicationType === ApplicationType.Cascade,
		},
	)
	const shellDownClassNames = classNames(
		'drawing__arrow-container',
		'drawing__arrow-container--down',
		{
			'drawing__arrow-container--down--middle':
				applicationType === ApplicationType.Cascade,
		},
	)
	return (
		<div>
			<div className="drawing">
				<div className="drawing__plate">PLATE</div>
				{renderStraightLines(endPlateType)}
				<div className={shellUpClassNames}>
					{numberOfPassesShellSide == NumberOfPasses._1Pass &&
					splitShellSide[0] != 'x'
						? renderArrowIcon('x', !isPlateHotSide)
						: null}
					{renderArrowIcon(splitShellSide[0], !isPlateHotSide)}
					{renderArrowIcon(splitShellSide[1], !isPlateHotSide)}
					{numberOfPassesShellSide == NumberOfPasses._1Pass &&
					splitShellSide[1] != 'x'
						? renderArrowIcon('x', !isPlateHotSide)
						: null}
				</div>
				<div className={shellDownClassNames}>
					{numberOfPassesShellSide == NumberOfPasses._1Pass &&
					splitShellSide[2] != 'x'
						? renderArrowIcon('x', !isPlateHotSide)
						: null}
					{renderArrowIcon(splitShellSide[2], !isPlateHotSide)}
					{renderArrowIcon(splitShellSide[3], !isPlateHotSide)}
					{numberOfPassesShellSide == NumberOfPasses._1Pass &&
					splitShellSide[3] != 'x'
						? renderArrowIcon('x', !isPlateHotSide)
						: null}
				</div>
				<div className="drawing__arrow-container drawing__arrow-container--left">
					{renderArrowIcon(splitPlateSide[0], isPlateHotSide)}
					{renderArrowIcon(splitPlateSide[1], isPlateHotSide)}
				</div>
				<div className="drawing__arrow-container drawing__arrow-container--right">
					{renderArrowIcon(splitPlateSide[2], isPlateHotSide)}
					{renderArrowIcon(splitPlateSide[3], isPlateHotSide)}
				</div>
			</div>
		</div>
	)
}

Drawing.VentCondenser = DrawingVentCondenser
Drawing.Combined = DrawingEvaporatorCombined
Drawing.MultiCondenser = DrawingMultiCond

export default Drawing
