// Enumerations for Hazard Analysis

// unit: °C
export enum FluidTemperatureInPlatepack {
	LessThan65 = 0.0,
	From65To110 = 0.1,
	GreaterThan110 = 0.2,
}

export enum DangerousnessOfTheContentOfPshePlatePack {
	No = 0.0,
	Little = 0.1,
}

export enum SignificanceOfTheProcessMediaConfusionCausedByPlateDamage {
	No = 0.0,
	Little = 0.1,
	Significant = 1.5,
}

// unit: dm3
export enum VolumeOfContentsInPlatepack {
	LessThan50 = 0.0,
	From50To200 = 0.05,
	GreaterThan200 = 0.1,
}

export enum CorrosionAndErosion {
	No = 0.0,
	Moderate = 0.15,
	Significant = 1.0,
}

// unit: full pressure cycles during lifetime
export enum Fatigue {
	LessThan500 = 0.0,
	From500To10000 = 0.05,
	GreaterThan10000 = 1.0,
}

export enum MeansOfExaminationForPlatepack {
	Vacuum = 0.0,
	Standard = 0.05,
}

export enum Installation {
	Welded4Nozzles = 0.0,
	Welded2Nozzles = 0.05,
	OpenableModel = 0.1,
}

// unit: year
export enum OperationLifetime {
	LessThan10 = 0.0,
	From10To20 = 0.05,
	GreaterThan20 = 0.5,
}

// unit: year
export enum InspectionOfThePlatepack {
	LessThan5 = 0.0,
	From5To10 = 0.1,
	GreaterThan10 = 1.0,
}

export enum HaPlateMaterialAttribute {
	AneNro,
	Rm,
	Ainekerroin,
}

export enum HaPlateMaterial {
	_14404,
	_254SMO,
	HastelloyC22,
	HastelloyC276,
	Nickel201,
	TitaniumGr1,
	_904L,
	_2205,
	Supra316L,
	ASTMFDX27,
	_14307,
}

export enum HaPlateSizeAndTypeAttribute {
	Attr1,
	Attr2,
}

export enum HaPlateSizeAndType {
	_2HA,
	_3HH,
	_3LL,
	_3WH,
	_3WA,
	_4HH,
	_4LL,
	_5HH,
	_5LL,
	_5WH,
	_5WL,
	_5TH,
	_5SH,
	_6HH,
	_6LL,
	_7HH,
	_7LL,
	_7WH,
	_7WL,
	_9HH,
	_9LL,
	_9HA,
	_9LA,
	_12HH,
	_12LL,
	_14HH,
	_14LL,
}

export enum HaPlateSize {
	_2,
	_3,
	_31,
	_32,
	_4,
	_5,
	_51,
	_52,
	_53,
	_6,
	_7,
	_71,
	_9,
	_12,
	_14,
}

// Plate thickness only accept the following values, as stated in the Excel sheet's JS function
export enum HaPlateThickness {
	_0_7mm = 0.7,
	_0_8mm = 0.8,
	_1mm = 1,
	_1_2mm = 1.2,
	_1_25mm = 1.25,
	_1_5mm = 1.5,
}

export enum AsmeOptions {
	_1_3xDP = 1.3,
	_3_0xDP = 3,
}
