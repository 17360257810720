import React from 'react'
import {Divider} from 'antd'
import Offer, {MFileInfo} from '../model/Offer'
import {formatDate} from '../utils/utils'
import {useTranslation} from 'react-i18next'

interface OfferDetailsType {
	offerDetail: Offer
	mFilesInfo: MFileInfo
	calculationType?: string
}
const OfferDetails: React.FunctionComponent<OfferDetailsType> = ({
	calculationType,
	offerDetail,
	mFilesInfo,
}) => {
	const [t] = useTranslation()

	const {mFileId, reference} = offerDetail
	const {
		companyName,
		contactPersonName,
		telephone,
		mobile,
		email,
		address,
		town,
		zipCode,
		country,
		responsiblePersonName,
		responsiblePersonEmail,
		responsiblePersonMobile,
		responsiblePersonTelephone,
	} = mFilesInfo
	return (
		<div className="offer-details on-pdf">
			<div>
				<table className="info-table">
					<tbody>
						<tr>
							<th>{t('offer.details.to')}:</th>
							<td>{companyName}</td>
						</tr>
						<tr>
							<th>{t('offer.details.att')}:</th>
							<td>
								<span>{<span>{contactPersonName}</span>}</span>
								{address ? <span>{address}</span> : null}
								{zipCode ? <span>{zipCode}</span> : null}
								{town ? <span>{town}</span> : null}
								{country ? <span>{country}</span> : null}
							</td>
						</tr>
						<tr>
							<th>{t('offer.details.tel')}:</th>
							<td>{`${telephone} / ${mobile}`}</td>
						</tr>
						<tr>
							<th>{t('offer.details.email')}:</th>
							<td>{email}</td>
						</tr>
					</tbody>
				</table>
				<Divider />
				<table className="info-table">
					<tbody>
						<tr>
							<th>{t('offer.details.from')}:</th>
							<td>
								<span>Vahterus Oy</span>
								<span>{responsiblePersonName}</span>
							</td>
						</tr>
						<tr>
							<th>{t('offer.details.tel')}:</th>
							<td>{`${responsiblePersonTelephone} / ${responsiblePersonMobile}`}</td>
						</tr>
						<tr>
							<th>{t('offer.details.email')}:</th>
							<td>{responsiblePersonEmail}</td>
						</tr>
					</tbody>
				</table>
				<Divider />
				<table className="info-table">
					<tbody>
						<tr>
							<th>{t('offer.details.reference')}:</th>
							<td>{reference}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div>
				<table className="offer-table">
					<tbody>
						<tr className="offer-table__offer-row">
							{/* <th style={{padding: '4px 16px'}}>{t('offer.details.offer')}:</th> */}
							<th style={{padding: '4px 16px'}}>{t('offer.details.offer')}</th>
							<td style={{padding: '4px 16px'}}>{mFileId}</td>
						</tr>
						{calculationType ? (
							<tr className="offer-table__offer-row">
								<th style={{padding: '4px 16px'}}>{t('offer.type')}:</th>
								<td style={{padding: '4px 16px'}}>{calculationType}</td>
							</tr>
						) : null}
						<tr className="offer-table__date-row">
							<th>{t('offer.details.date')}:</th>
							<td>{formatDate(new Date(), false)}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default OfferDetails
