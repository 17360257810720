import {queryCache} from 'react-query'
import {Region} from '../model/Region'
import {sec} from './security'
import axios from 'axios'

export const AUTH_CONFIG = {
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
	redirectUri:
		process.env.REACT_APP_DEPLOYMENT_TYPE == 'development'
			? `${window.location.href}dashboard`
			: process.env.REACT_APP_AUTH0_REDIRECT_URI,
	audience: process.env.REACT_APP_AUTH0_AUDIENCE,
	scope: 'read:current_user update:current_user_metadata',
}

const isDev = process.env.NODE_ENV === 'development'
const apiEndPoint = process.env.REACT_APP_API_END_POINT
// const calculateApiEndPoint = process.env.REACT_APP_CALCULATE_API_END_POINT

const apiURL = isDev ? 'http://localhost:5000/api' : apiEndPoint

// const calculateApiURL = isDev
// 	? 'http://localhost:5000/api'
// 	: calculateApiEndPoint

async function client(
	endpoint,
	{
		data = null,
		headers: customHeaders = {},
		method = null,
		responseType = null,
		isRegionBound = true,
		isDownload = false,
		filename = '',
		...customConfig
	} = {},
) {
	const accessToken = await sec.getAccessTokenSilently()({
		audience: AUTH_CONFIG.audience,
		scope: AUTH_CONFIG.scope,
	})
	let region = 'eu'
	const savedRegion = localStorage.getItem('VahterusRegion')
	if (savedRegion) {
		switch (parseInt(savedRegion)) {
			case Region.EU:
				region = 'eu'
				break
			case Region.US:
				region = 'us'
				break
			case Region.CN:
				region = 'cn'
				break
		}
	}
	const getHttpMethod = (data, method) => {
		if (method) return method
		if (data) return 'POST'
		return 'GET'
	}
	const config = {
		url: isRegionBound
			? `${apiURL}/${region}/${endpoint}`
			: `${apiURL}/${endpoint}`,
		method: getHttpMethod(data, method),
		responseType: responseType,
		data: data ? JSON.stringify(data) : undefined,
		headers: {
			Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
			'Content-Type': data ? 'application/json' : undefined,
			...customHeaders,
		},
		...customConfig,
	}

	return axios(config)
		.then(response => {
			if (response.status === 401) {
				queryCache.clear()
				// window.location.assign(window.location)
				return Promise.reject({message: 'Please re-authenticate.'})
			}
			if (isDownload) {
				const href = URL.createObjectURL(response.data)
				// create "a" HTLM element with href to file & click
				const link = document.createElement('a')
				link.href = href
				link.setAttribute('download', filename) //or any other extension
				document.body.appendChild(link)
				link.click()

				// clean up "a" element & remove ObjectURL
				document.body.removeChild(link)
				URL.revokeObjectURL(href)
				return
			}
			return response.data
		})
		.catch(error => {
			if (error.response) {
				return Promise.reject(error.response.data)
			} else if (error.request) {
				return Promise.reject(error.request)
			} else {
				return Promise.reject(error.message)
			}
		})
}

export const parsePatchBody = body => {
	const keys = Object.keys(body)
	const updateBody = keys.map(key => ({
		value: body[key],
		path: `/${key}`,
		op: 'replace',
	}))

	return updateBody
}

export {client}
