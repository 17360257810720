import React from 'react'
import FullPageLoading from './components/FullPageLoading'
import GlobalHeader from './components/GlobalHeader'
import {Redirect, Route, Switch} from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import {useRecoilState} from 'recoil'
import {regionState} from './state/Region'
import DrawingPage from './pages/DrawingPage'
import ExportPreview from './pages/ExportPreview'
import {Region} from './model/Region'
import {useAuth0} from '@auth0/auth0-react'
import MaintainanceApp from './MaintainanceApp'

const Workspace = React.lazy(
	() => import(/* webpackChunkName: "Workspace" */ './pages/Workspace'),
)

const Calculator = React.lazy(
	() => import(/* webpackChunkName: "Calculator" */ './pages/Calculator'),
)

const CalculationPreview = React.lazy(
	() =>
		import(
			/* webpackChunkName: "CalculatorPreview" */ './pages/CalculationPreview'
		),
)

const Sizing = React.lazy(
	() => import(/* webpackChunkName: "Sizing" */ './pages/Sizing'),
)

const Offer = React.lazy(
	() => import(/* webpackChunkName: "Offer" */ './pages/Offer'),
)

const OfferDocuments = React.lazy(
	() => import(/* webpackChunkName: "Offer" */ './pages/OfferDocuments'),
)

const AiResult = React.lazy(
	() =>
		import(/* webpackChunkName: "SolutionsComparision" */ './pages/AiResult'),
)

const AuthenticatedApp = () => {
	const {user} = useAuth0()
	const [region, setRegion] = useRecoilState(regionState)
	React.useEffect(() => {
		const savedRegion = localStorage.getItem('VahterusRegion')
		if (savedRegion && parseInt(savedRegion) != region) {
			setRegion(parseInt(savedRegion))
		}
		if (!savedRegion) {
			if (user['http://vahterus/roles'].includes('InternalChina')) {
				setRegion(Region.CN)
				localStorage.setItem('VahterusRegion', Region.CN.toString())
			}
			if (user['http://vahterus/roles'].includes('InternalUS')) {
				setRegion(Region.US)
				localStorage.setItem('VahterusRegion', Region.US.toString())
			}
			if (user['http://vahterus/roles'].includes('InternalEU')) {
				setRegion(Region.EU)
				localStorage.setItem('VahterusRegion', Region.EU.toString())
			}
			if (user['http://vahterus/roles'].includes('ExternalConsultantCN')) {
				setRegion(Region.CN)
				localStorage.setItem('VahterusRegion', Region.CN.toString())
			}
			if (user['http://vahterus/roles'].includes('ExternalConsultantUS')) {
				setRegion(Region.US)
				localStorage.setItem('VahterusRegion', Region.US.toString())
			}
			if (user['http://vahterus/roles'].includes('ExternalConsultantEU')) {
				setRegion(Region.EU)
				localStorage.setItem('VahterusRegion', Region.EU.toString())
			}
			if (user['http://vahterus/roles'].includes('ExternalDistributorCN')) {
				setRegion(Region.CN)
				localStorage.setItem('VahterusRegion', Region.CN.toString())
			}
			if (user['http://vahterus/roles'].includes('ExternalDistributorUS')) {
				setRegion(Region.US)
				localStorage.setItem('VahterusRegion', Region.US.toString())
			}
			if (user['http://vahterus/roles'].includes('ExternalDistributorEU')) {
				setRegion(Region.EU)
				localStorage.setItem('VahterusRegion', Region.EU.toString())
			}
		}
	}, [])
	return (
		<React.Suspense fallback={<FullPageLoading />}>
			{[
				'tri.hoang@vahterus.com',
				'thu.hoang@vahterus.com',
				'cuongph@techvify.com.vn',
				'tacy.tran@techvify.com.vn',
				'joel.blomfelt@vahterus.com',
				'sandra.fernandez@vahterus.com'
			].includes(user.email) ? (
				<>
					<GlobalHeader />
					<Switch>
						<Route path="/playground" component={DrawingPage} />
						<Route path="/dashboard" component={Dashboard} />
						<Route exact path="/calculator" component={Calculator} />
						<Route
							path="/calculator/preview-calculation"
							component={CalculationPreview}
						/>
						<Route
							path="/workspace/:offerId/sizing/:positionId/calculations/ai-result"
							component={AiResult}
						/>
						<Route
							path="/workspace/:offerId/sizing/:positionId/calculations/:calculationId/ai-result"
							component={AiResult}
						/>
						<Route
							path="/workspace/:offerId/sizing/:positionId/calculations/:calculationId/preview-calculation"
							component={CalculationPreview}
						/>
						<Route
							path="/workspace/:offerId/sizing/:positionId/calculations/:calculationId"
							component={Sizing}
						/>
						<Route
							path="/workspace/:offerId/sizing/:positionId/calculations"
							component={Sizing}
						/>
						<Route path="/workspace/:offerId" component={Workspace} />
						<Route path="/workspace" component={Workspace} />
						<Route
							path="/offer/:offerId/positions/:positionId/documents"
							component={OfferDocuments}
						/>
						<Route path="/offer/:offerId/export" component={ExportPreview} />
						<Route path="/offer/:offerId" component={Offer} />
						<Route exact path="/">
							<Redirect to="/dashboard" />
						</Route>
					</Switch>
				</>
			) : (
				<MaintainanceApp />
			)}
		</React.Suspense>
	)
}
export default AuthenticatedApp
