import React from 'react'

interface Props {
	pdfId: string
	marginTop?: string
}

const PDFPage: React.FunctionComponent<Props> = ({
	pdfId,
	children,
	marginTop,
}) => {
	return (
		<div
			style={{backgroundColor: '#FFFFFF', marginTop}}
			contentEditable={true}
			suppressContentEditableWarning={true}
			id={pdfId}
			className="pdf-page"
		>
			{children}
		</div>
	)
}

export default PDFPage
