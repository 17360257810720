import {queryCache, useMutation, useQuery} from 'react-query'
import {client, parsePatchBody} from '../utils/apiClient'
import Offer, {OfferForCreation, OfferInfoPreviewItem} from '../model/Offer'
import Position from '../model/Position'
import queryString from 'query-string'
import {Application} from '../model/Application'

const defaultMutationOptions = {
	onError: (err, variables, recover) =>
		typeof recover === 'function' ? recover() : null,
	onSettled: () => queryCache.invalidateQueries('offers'),
}

export function useOffers(options) {
	const {query, ...configOptions} = options
	const queryText = queryString.stringify(query)
	const results = useQuery<Offer[], Error>({
		queryKey: ['offers', queryText],
		queryFn: () => client(`offers?${queryText}`),
		config: {...configOptions},
	})

	return results
}

export function useOffer(offerId?: number, options?) {
	const results = useQuery<Offer, Error>({
		queryKey: ['offers', offerId],
		queryFn: () => client(`offers/${offerId}`),
		config: {...options},
	})

	return results
}

export function useOffersByAdvancedSearch(options?) {
	const {query, ...configOptions} = options
	const queryText = queryString.stringify(query)
	const results = useQuery<Application[], Error>({
		queryKey: ['offersByAdvancedSearch', queryText],
		queryFn: () => client(`offers/advancedSearch?${queryText}`),
		config: {...configOptions},
	})
	return results
}

export function useCreateOffer(options?) {
	return useMutation<Offer, Error, OfferForCreation>(
		offer => client(`offers`, {data: offer}),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

export function useDuplicateOffer(offerId?: number, options?) {
	return useMutation<Offer, Error, OfferForCreation>(
		offer => client(`offers/${offerId}/duplicate`, {data: offer}),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

function onUpdateMutation(offerId: number, newItem) {
	const queryKeyOffer = ['offers', {offerId}]

	const previousItems = queryCache.getQueryData(queryKeyOffer)

	queryCache.setQueryData<Position[], Error>(queryKeyOffer, old => {
		return {...old, ...newItem}
	})
	queryCache.invalidateQueries
	if (newItem.positions) {
		const queryKeyPositionOffer = ['positions', {offerId}]
		const previousPositions = queryCache.getQueryData(queryKeyPositionOffer)
		newItem.positions.map(position => {
			queryCache.setQueryData<Position[], Error>(queryKeyPositionOffer, old => {
				return old.map(item => {
					return item.id === position.id ? {...item, ...position} : item
				})
			})
		})
		return () => {
			queryCache.setQueryData(queryKeyOffer, previousItems)
			queryCache.setQueryData(queryKeyPositionOffer, previousPositions)
		}
	} else {
		return () => {
			queryCache.setQueryData(queryKeyOffer, previousItems)
		}
	}
}

export function useUpdateOffer(offerId, options?) {
	const {isPatchUpdate, updatePositions, ...mutateOptions} = options
	return useMutation<
		{
			offerGreeting?: string
			language?: string
			termsOfWarranty?: string
			offerValidity?: string
			documents?: string
			deliveryTime?: string
			offerNote?: string
			allowUser?: string
			currencyCode?: number
			currencyRate?: number
		},
		Error,
		{
			offerGreeting?: string
			language?: string
			termsOfWarranty?: string
			offerValidity?: string
			documents?: string
			deliveryTime?: string
			offerNote?: string
			offerInfo?: OfferInfoPreviewItem[]
			currencyCode?: number
			currencyRate?: number
		}
	>(
		updates => {
			return client(`offers/${offerId}`, {
				method: isPatchUpdate ? 'PATCH' : 'PUT',
				data: isPatchUpdate
					? parsePatchBody(updates)
					: {updatedOffer: updates, updatePositions: updatePositions},
			})
		},
		{
			onMutate: newItem => onUpdateMutation(+offerId, newItem),
			...defaultMutationOptions,
			...mutateOptions,
		},
	)
}

// export function useAddCalculationsToOffer(offerId?, options?) {
// 	return useMutation<any, Error, {calculationIds: number[]}>(
// 		data => client(`offers/${offerId}/addCalculations`, {data}),
// 		{
// 			...defaultMutationOptions,
// 			...options,
// 		},
// 	)
// }
