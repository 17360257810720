import {
	areaLabels,
	capacityUnitLabels,
	designPressureUnitLabels,
	dynamicViscoUnitLabels,
	endPlateTypeLabels,
	feetLabels,
	flowDirectorMaterialLabels,
	foulingFactorUnitLabels,
	kValueLabels,
	massFlowUnitLabels,
	massUnitLabels,
	paintingLabels,
	plateMaterialLabels,
	plateThicknessLabels,
	positionLabels,
	pressureUnitLabels,
	shellMaterialLabels,
	sigmaUnitLabels,
	specificGravityUnitLables,
	specificHeatUnitLables,
	temUnitLabels,
	thermalConductivityUnitLabels,
	typeApporvalLabels,
	volumeUnitLabels,
} from '../../model/enumLabels'
import {HotSide} from '../../model/CalculationEnums'
import {isInfinitiveNumber} from '../../utils/utils'
import {
	getDesignPressureUnit,
	getMediumName,
	getNumberOfPlateNozzle,
	getShellMaterial,
} from './dataSheetUtils'
import {
	ThermalDesignData,
	PlateShellData,
	ConnectionsData,
} from '../../model/DataSheet'

export const getPartialCascadeData = (heatExchangerData, dataText) => {
	const appTitle = 'Partial2Phase'
	//====================================THERMAL DATA===============================
	const {
		cboQ,
		capacity,
		cboArea,
		area,
		cboT,
		lmtd,
		cboKval,
		kval,
		cboFF,
		foulingFactor,
		excessDesignArea,
		saturatedLiquidTemperatureHotSide,
		saturatedVaporTemperatureHotSide,
		saturatedLiquidTemperatureColdSide,
		saturatedVaporTemperatureColdSide,
	} = heatExchangerData
	const thermalDesignData: ThermalDesignData[] = [
		{
			name: 'Capacity',
			unit: capacityUnitLabels[cboQ],
			value: capacity,
		},
		{
			name: 'Heat transfer area',
			unit: areaLabels[cboArea],
			value: area,
		},
		{
			name: 'Logarithmic mean T',
			unit: temUnitLabels[cboT],
			value: lmtd,
		},
		{
			name: 'K-value',
			unit: kValueLabels[cboKval],
			value: kval,
		},
		{
			name: 'Fouling factor',
			unit: foulingFactorUnitLabels[cboFF],
			value: foulingFactor,
		},
		{
			name: 'Excess Design/Area',
			unit: '%',
			value: excessDesignArea,
		},
	]

	const thermalDesignDataWithKey = thermalDesignData.map((item, index) => ({
		key: index,
		...item,
	}))

	const thermalDesignColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]
	//====================================PLATE SHELL DATA===============================

	const isHotSidePlate = heatExchangerData.hotSide === HotSide.PlateSide
	const {
		cboM,
		saturatedPressureColdSide,
		saturatedPressureHotSide,
		cboDp,
		cboP,
		cboV,
		volumePlateSide,
		volumeShellSide,
		inletTemperatureHotSide,
		inletTemperatureColdSide,
		outletTemperatureHotSide,
		outletTemperatureColdSide,
		flowRateHotSide,
		flowRateColdSide,
		vaporMassFractionHotSideIn,
		vaporMassFractionHotSideOut,
		vaporMassFractionColdSideIn,
		vaporMassFractionColdSideOut,
		pressureDropColdSideOutput,
		pressureDropHotSideOutput,
		designPressure,
	} = heatExchangerData

	const saturationHotValue =
		!isInfinitiveNumber(saturatedLiquidTemperatureHotSide) &&
		!isInfinitiveNumber(saturatedLiquidTemperatureHotSide)
			? `${saturatedLiquidTemperatureHotSide}/${saturatedVaporTemperatureHotSide}`
			: null

	const saturationColdValue =
		!isInfinitiveNumber(saturatedLiquidTemperatureColdSide) &&
		!isInfinitiveNumber(saturatedVaporTemperatureColdSide)
			? `${saturatedLiquidTemperatureColdSide}/${saturatedVaporTemperatureColdSide}`
			: null

	const plateShellData: PlateShellData[] = [
		{
			name: 'TempIN',
			unit: temUnitLabels[cboT],
			value: isHotSidePlate
				? inletTemperatureHotSide
				: inletTemperatureColdSide,
			value2: isHotSidePlate
				? inletTemperatureColdSide
				: inletTemperatureHotSide,
		},
		{
			name: 'TempOUT',
			unit: temUnitLabels[cboT],
			value: isHotSidePlate
				? outletTemperatureHotSide
				: outletTemperatureColdSide,
			value2: isHotSidePlate
				? outletTemperatureColdSide
				: outletTemperatureHotSide,
		},
		{
			name: 'Saturation temperature',
			unit: temUnitLabels[cboT],
			value: isHotSidePlate ? saturationHotValue : saturationColdValue,
			value2: isHotSidePlate ? saturationColdValue : saturationHotValue,
		},
		{
			name: 'Pressure',
			unit: pressureUnitLabels[cboP],
			value: isHotSidePlate
				? saturatedPressureHotSide
				: saturatedPressureColdSide,
			value2: isHotSidePlate
				? saturatedPressureColdSide
				: saturatedPressureHotSide,
		},
		{
			name: 'Liquid Flow rate/INLET',
			unit: massFlowUnitLabels[cboM],
			value: isHotSidePlate
				? (1 - vaporMassFractionHotSideIn) * flowRateHotSide
				: (1 - vaporMassFractionColdSideIn) * flowRateColdSide,
			value2: isHotSidePlate
				? (1 - vaporMassFractionColdSideIn) * flowRateColdSide
				: (1 - vaporMassFractionHotSideIn) * flowRateHotSide,
		},
		{
			name: 'Liquid Flow rate/OUTLET',
			unit: massFlowUnitLabels[cboM],
			value: isHotSidePlate
				? (1 - vaporMassFractionHotSideOut) * flowRateHotSide
				: (1 - vaporMassFractionColdSideOut) * flowRateColdSide,
			value2: isHotSidePlate
				? (1 - vaporMassFractionColdSideOut) * flowRateColdSide
				: (1 - vaporMassFractionHotSideOut) * flowRateHotSide,
		},
		{
			name: 'Vapor Flow rate/INLET',
			unit: massFlowUnitLabels[cboM],
			value: isHotSidePlate
				? vaporMassFractionHotSideIn * flowRateHotSide
				: vaporMassFractionColdSideIn * flowRateColdSide,
			value2: isHotSidePlate
				? vaporMassFractionColdSideIn * flowRateColdSide
				: vaporMassFractionHotSideIn * flowRateHotSide,
		},
		{
			name: 'Vapor Flow rate/OUTLET',
			unit: massFlowUnitLabels[cboM],
			value: isHotSidePlate
				? vaporMassFractionHotSideOut * flowRateHotSide
				: vaporMassFractionColdSideOut * flowRateColdSide,
			value2: isHotSidePlate
				? vaporMassFractionColdSideOut * flowRateColdSide
				: vaporMassFractionHotSideOut * flowRateHotSide,
		},
		{
			name: 'Pressure drop',
			unit: pressureUnitLabels[cboDp],
			value: isHotSidePlate
				? pressureDropHotSideOutput
				: pressureDropColdSideOutput,
			value2: isHotSidePlate
				? pressureDropColdSideOutput
				: pressureDropHotSideOutput,
		},
		{
			name: 'Liquid volume',
			unit: volumeUnitLabels[cboV],
			value: volumePlateSide,
			value2: volumeShellSide,
		},
	]

	const plateShellDataWithKey = plateShellData.map((item, index) => ({
		key: index,
		...item,
	}))

	const plateShellColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: `Plate Side (${isHotSidePlate ? 'Hot' : 'Cold'})`,
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: `Shell Side (${isHotSidePlate ? 'Cold' : 'Hot'})`,
		},
	]

	//====================================MEDIUM DATA===============================
	const {
		mediumHotSide,
		mediumColdSide,
		mediumSourceHotSide,
		mediumSourceColdSide,
		mediumHotSideOtherMedia,
		mediumColdSideOtherMedia,
		concentrationHotSide,
		concentrationColdSide,
	} = heatExchangerData
	const mediumData = []

	const mediumDataWithKey = mediumData.map((item, index) => ({
		key: index,
		...item,
	}))

	const mediumNameHotSide = getMediumName({
		mediumSource: mediumSourceHotSide,
		mediumName: mediumHotSide,
		mediumNameOtherMedia: mediumHotSideOtherMedia,
		concentration: concentrationHotSide,
	})
	const mediumNameColdSide = getMediumName({
		mediumSource: mediumSourceColdSide,
		mediumName: mediumColdSide,
		mediumNameOtherMedia: mediumColdSideOtherMedia,
		concentration: concentrationColdSide,
	})

	const mediumDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: isHotSidePlate ? 'value' : 'value2',
			key: isHotSidePlate ? 'value' : 'value2',
			title: isHotSidePlate ? mediumNameHotSide : mediumNameColdSide,
		},
		{
			dataIndex: isHotSidePlate ? 'value2' : 'value',
			key: isHotSidePlate ? 'value2' : 'value',
			title: isHotSidePlate ? mediumNameColdSide : mediumNameHotSide,
		},
	]
	//====================================CONSTRUCTION DATA===============================
	const {
		endPlateType,
		cboMass,
		massOfHeatExchanger,
		position,
		typeApproval,
		designPressureOnDataSheet,
		designCode,
		designTemperatureMin,
		designTemperatureMax,
		feet,
		designPressurePlateSide,
		designPressureUnitOnDataSheet,
	} = heatExchangerData
	const constructionData = [
		{
			name: 'End Type',
			value: endPlateTypeLabels[endPlateType],
			value2: dataText?.constructionRow1Text,
		},
		{
			name: 'Weight (dry)',
			unit: massUnitLabels[cboMass],
			value: massOfHeatExchanger.toFixed(0),
			value2: dataText?.constructionRow2Text,
		},
		{
			name: 'Position',
			value: positionLabels[position],
			value2: dataText?.constructionRow3Text,
		},
		{
			name: 'Design code',
			value: designCode || typeApporvalLabels[typeApproval],
			value2: dataText?.constructionRow4Text,
		},
		{
			name:
				dataText?.constructionRow5Text != dataText?.constructionRow6Text
					? 'Shell design pressure'
					: 'Design pressure',
			unit: designPressureOnDataSheet
				? designPressureUnitLabels[designPressureUnitOnDataSheet]
				: getDesignPressureUnit(designPressure),
			value: designPressureOnDataSheet || dataText?.constructionRow5Text,
		},
	]
	if (dataText?.constructionRow5Text != dataText?.constructionRow6Text)
		constructionData.push({
			name: 'Plate design pressure',
			unit: getDesignPressureUnit(designPressure),
			value: dataText?.constructionRow6Text || designPressurePlateSide,
		})
	constructionData.push(
		{
			name: 'Design temperature',
			unit: temUnitLabels[cboT],
			value: `${designTemperatureMin}/${designTemperatureMax}`,
			value2: dataText?.constructionRow7Text,
		},
		{
			name: 'Support',
			value: feetLabels[feet],
			value2: dataText?.constructionRow8Text,
		},
	)

	const constructionDataWithKey = constructionData.map((item, index) => ({
		key: index,
		...item,
	}))

	const constructionDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
		{
			dataIndex: 'value2',
			key: 'value2',
		},
	]

	//====================================MATERIALS DATA===============================
	const materialsData = [
		{
			name: 'Plate material',
			value:
				plateMaterialLabels[heatExchangerData.plateMaterial] +
				' ' +
				plateThicknessLabels[heatExchangerData.plateThickness],
		},
		{
			name: 'Shell material',
			value: getShellMaterial(
				endPlateType,
				shellMaterialLabels[heatExchangerData.shellMaterial],
			),
		},
		{
			name: 'Flow director material',
			value: flowDirectorMaterialLabels[heatExchangerData.flowDirectorMaterial],
		},
		{
			name: 'Plate connections',
			value: dataText?.materialsPlateConnections,
		},
		{
			name: 'Shell connections IN',
			value: dataText?.materialsShellConnectionsIn,
		},
		{
			name: 'Shell connections OUT',
			value: dataText?.materialsShellConnectionsOut,
		},
		{
			name: 'Surface treatment',
			value: paintingLabels[heatExchangerData.paint],
		},
		// {
		// 	name: 'Finishing (Shell externally)',
		// },
		// {
		// 	name: '- Stainless steel parts',
		// 	value: 'Pearl blasting',
		// },
		// {
		// 	name: '- Carbon steel parts',
		// 	value: paintingLabels[heatExchangerData.paint],
		// },
	]

	const materialsDataWithKey = materialsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const materialsDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]
	//====================================CONNECTIONS DATA===============================
	const connectionsData: ConnectionsData[] = [
		{
			name: 'Plate side IN',
			size: +heatExchangerData.nozzlePlateSide,
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlate,
		},
		{
			name: 'Plate side OUT',
			size: +heatExchangerData.nozzlePlateSide,
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlateOut,
		},
		{
			name: 'Shell side IN',
			size: heatExchangerData.nozzleShell,
			numberOfPieces: heatExchangerData.numberOfShellNozzles,
			velocity: heatExchangerData.velocityShell,
		},
		{
			name: 'Shell side OUT',
			size: heatExchangerData.nozzleShellOut,
			numberOfPieces: heatExchangerData.numberOfShellNozzles,
			velocity: heatExchangerData.velocityShellOut,
		},
	]

	const connectionDataWithKey = connectionsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const connectionDataColumns = [
		{
			title: 'Connections',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Size (DN)',
			dataIndex: 'size',
			key: 'size',
		},
		{
			title: 'Nb of pieces',
			dataIndex: 'numberOfPieces',
			key: 'numberOfPieces',
		},
		{
			title: 'Velocity',
			dataIndex: 'velocity',
			key: 'velocity',
		},
	]

	return {
		appTitle,
		thermalDesignDataWithKey,
		thermalDesignColumns,
		plateShellDataWithKey,
		plateShellColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	}
}

export const getMediumPropertiesData = heatExchangerData => {
	//====================================MEDIUM PROPERTIES HOT Liq===============================
	const {
		cboSpH,
		saturatedLiquidSpecificGravityHotSide,
		outletSpecificGravityHotSide,
		cboGravi,
		inletSpecificGravityHotSide,
		saturatedLiquidDynamicViscosityHotSide,
		outletDynamicViscosityHotSide,
		inletDynamicViscosityHotSide,
		inletSpecificHeatHotSide,
		outletSpecificHeatHotSide,
		saturatedLiquidSpecificHeatHotSide,
		inletThermalConductivityHotSide,
		outletThermalConductivityHotSide,
		saturatedLiquidThermalConductivityHotSide,
		saturatedLiquidSurfaceTensionHotSide,
		cboVisco,
		cboCdy,
		cboSigma,
	} = heatExchangerData

	const mediumPropertiesHotLiqData = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value: inletDynamicViscosityHotSide,
			value2: outletDynamicViscosityHotSide,
			value3: saturatedLiquidDynamicViscosityHotSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value: inletSpecificGravityHotSide,
			value2: outletSpecificGravityHotSide,
			value3: saturatedLiquidSpecificGravityHotSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value: inletSpecificHeatHotSide,
			value2: outletSpecificHeatHotSide,
			value3: saturatedLiquidSpecificHeatHotSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value: inletThermalConductivityHotSide,
			value2: outletThermalConductivityHotSide,
			value3: saturatedLiquidThermalConductivityHotSide,
		},
		{
			name: 'Surface Tension',
			unit: sigmaUnitLabels[cboSigma],
			value3: saturatedLiquidSurfaceTensionHotSide,
		},
	]

	const mediumPropertiesHotLiqDataWithKey = mediumPropertiesHotLiqData.map(
		(item, index) => ({
			key: index,
			...item,
		}),
	)

	const mediumPropertiesHotLiqDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: 'INLET',
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: 'OUTLET',
		},
		{
			dataIndex: 'value3',
			key: 'value3',
			title: 'Sat.Liq',
		},
	]

	//====================================MEDIUM PROPERTIES HOT VAP===============================
	const {
		saturatedVaporDynamicViscosityHotSide,
		saturatedVaporSpecificGravityHotSide,
		saturatedVaporSpecificHeatHotSide,
		saturatedVaporThermalConductivityHotSide,
	} = heatExchangerData

	const mediumPropertiesHotVapData = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value3: saturatedVaporDynamicViscosityHotSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value3: saturatedVaporSpecificGravityHotSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value3: saturatedVaporSpecificHeatHotSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value3: saturatedVaporThermalConductivityHotSide,
		},
	]

	const mediumPropertiesHotVapDataWithKey = mediumPropertiesHotVapData.map(
		(item, index) => ({
			key: index,
			...item,
		}),
	)

	const mediumPropertiesHotVapDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
		{
			dataIndex: 'value2',
			key: 'value2',
		},
		{
			dataIndex: 'value3',
			key: 'value3',
			title: 'Sat.Vap',
		},
	]

	//====================================MEDIUM PROPERTIES COLD Liq===============================
	const {
		inletDynamicViscosityColdSide,
		outletDynamicViscosityColdSide,
		saturatedLiquidDynamicViscosityColdSide,
		inletSpecificGravityColdSide,
		outletSpecificGravityColdSide,
		saturatedLiquidSpecificGravityColdSide,
		inletSpecificHeatColdSide,
		outletSpecificHeatColdSide,
		saturatedLiquidSpecificHeatColdSide,
		inletThermalConductivityColdSide,
		outletThermalConductivityColdSide,
		saturatedVaporThermalConductivityColdSide,
		saturatedLiquidSurfaceTensionColdSide,
	} = heatExchangerData

	const mediumPropertiesColdLiqData = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value: inletDynamicViscosityColdSide,
			value2: outletDynamicViscosityColdSide,
			value3: saturatedLiquidDynamicViscosityColdSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value: inletSpecificGravityColdSide,
			value2: outletSpecificGravityColdSide,
			value3: saturatedLiquidSpecificGravityColdSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value: inletSpecificHeatColdSide,
			value2: outletSpecificHeatColdSide,
			value3: saturatedLiquidSpecificHeatColdSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value: inletThermalConductivityColdSide,
			value2: outletThermalConductivityColdSide,
			value3: saturatedVaporThermalConductivityColdSide,
		},
		{
			name: 'Surface Tension',
			unit: sigmaUnitLabels[cboSigma],
			value3: saturatedLiquidSurfaceTensionColdSide,
		},
	]

	const mediumPropertiesColdLiqDataWithKey = mediumPropertiesColdLiqData.map(
		(item, index) => ({
			key: index,
			...item,
		}),
	)

	const mediumPropertiesColdLiqDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: 'INLET',
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: 'OUTLET',
		},
		{
			dataIndex: 'value3',
			key: 'value3',
			title: 'Sat.Liq',
		},
	]

	//====================================MEDIUM PROPERTIES COLD VAP===============================
	const {
		saturatedVaporDynamicViscosityColdSide,
		saturatedVaporSpecificGravityColdSide,
		saturatedVaporSpecificHeatColdSide,
		saturatedLiquidThermalConductivityColdSide,
	} = heatExchangerData

	const mediumPropertiesColdVapData = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value3: saturatedVaporDynamicViscosityColdSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value3: saturatedVaporSpecificGravityColdSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value3: saturatedVaporSpecificHeatColdSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value3: saturatedLiquidThermalConductivityColdSide,
		},
	]

	const mediumPropertiesColdVapDataWithKey = mediumPropertiesColdVapData.map(
		(item, index) => ({
			key: index,
			...item,
		}),
	)

	const mediumPropertiesColdVapDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
		{
			dataIndex: 'value2',
			key: 'value2',
		},
		{
			dataIndex: 'value3',
			key: 'value3',
			title: 'Sat.Vap',
		},
	]

	return {
		mediumPropertiesHotLiqDataWithKey,
		mediumPropertiesHotLiqDataColumns,
		mediumPropertiesHotVapDataWithKey,
		mediumPropertiesHotVapDataColumns,
		mediumPropertiesColdLiqDataWithKey,
		mediumPropertiesColdLiqDataColumns,
		mediumPropertiesColdVapDataWithKey,
		mediumPropertiesColdVapDataColumns,
	}
}
