import React from 'react'
import VahterusLogo from '../../assets/imgs/vahterus-logo.png'
import {Divider} from 'antd'
import {useHeatExchanger} from '../../state/heatExchanger'
import {Calculation} from '../../model/Calculation'
import FullPageLoading from '../FullPageLoading'
import {
	formatDate,
	isInfinitiveNumber,
	precise,
	toFixed,
} from '../../utils/utils'
import {getOnePhaseData} from './onePhaseData'
import {
	ApplicationType,
	CondenserType,
	HotSide,
	LiquidSide,
	MultiPassCombination,
} from '../../model/CalculationEnums'
import {getCondenserData} from './condenserData'
import {getEvaporatorData} from './evaporatorData'
import {getCascadeData} from './cascadeData'
import {
	getMediumPropertiesData,
	getPartialCascadeData,
} from './partialCascadeData'
import Drawing from './Drawing'
import Offer, {MFileInfo} from '../../model/Offer'
import {Circulation, EvaporatorType} from '../../model/Evaporator'
import {useCalculationDataText} from '../../state/calculations'
import PDFPage from './PDFPage'
import {usePosition} from '../../state/position'
import {TFunction, useTranslation} from 'react-i18next'
import {velocityUnitLabels} from '../../model/enumLabels'
import Position from '../../model/Position'
import {Datatext} from '../../model/DataSheet'
import {
	getMultiCondCondData,
	getMultiCondDesupData,
	getMultiCondModelData,
	getMultiCondSubCooData,
} from './multiCondenserData'

interface Props {
	calculation: Calculation
	offerDetail: Offer
	mFilesInfo: MFileInfo
	exportId?: string
	positionId: string
	contentEditable?: boolean
}

export const getDataFunc = (
	applicationType: ApplicationType,
	multiCondParam?: string,
) => {
	switch (applicationType) {
		case ApplicationType.OnePhase:
			return getOnePhaseData
		case ApplicationType.Condenser:
			return getCondenserData
		case ApplicationType.Evaporators:
			return getEvaporatorData
		case ApplicationType.Cascade:
			return getCascadeData
		case ApplicationType.PartialCascade:
			return getPartialCascadeData
		case ApplicationType.MultiCondenser:
			if (multiCondParam == 'multiCond-Model') {
				return getMultiCondModelData
			}
			if (multiCondParam == 'multiCond-Desup') {
				return getMultiCondDesupData
			}
			if (multiCondParam == 'multiCond-Cond') {
				return getMultiCondCondData
			}
			if (multiCondParam == 'multiCond-Subcoo') {
				return getMultiCondSubCooData
			} else {
				return getMultiCondModelData
			}
		default:
			return getOnePhaseData
	}
}

const shouldBeTranslated = (
	t: TFunction<'translation', undefined>,
	text: string,
	translatePrefix: string,
) => {
	if (typeof text === 'string') {
		if (text.includes('.') || text.includes(':')) {
			return text
		}
	}
	const translatedText = t(`${translatePrefix}.${text}`)
	return translatedText.includes('datasheet.table') ? text : translatedText
}

const renderConnectionsTurnedNinety = (
	flowDir: number,
	t: TFunction<'translation', undefined>,
) => {
	if (flowDir === 2) {
		return <p>{t('datasheet.infoTable.connectionsTurnedNinety')}</p>
	}
}

const renderArrowsDrawing = (
	heatExchangerData,
	t: TFunction<'translation', undefined>,
	appTitle?: string,
) => {
	const {application, arrowsPlateSide, arrowsShellSide} = heatExchangerData

	const renderShellDiam = shellDiam => {
		return <p className="center-align bold">Shell &#981;{shellDiam}</p>
	}

	const renderFlowDirectionLabel = flowDir => {
		if (flowDir === 0) {
			return <p>{t('datasheet.table.data.Countercurrent')}</p>
		}
		if (flowDir === 1) {
			return <p>{t('datasheet.table.data.Cocurrent')}</p>
		}
		if (flowDir === 2) {
			return <p>{t('datasheet.table.data.Crossflow')}</p>
		}
	}

	switch (application) {
		case ApplicationType.OnePhase: {
			const isHotSidePlate = heatExchangerData.hotSide === HotSide.PlateSide
			return (
				<>
					{renderShellDiam(heatExchangerData.shellDiameter)}
					<Drawing
						isPlateHotSide={isHotSidePlate}
						arrowsPlateSide={arrowsPlateSide}
						arrowsShellSide={arrowsShellSide}
						endPlateType={heatExchangerData.endPlateType}
						numberOfPassesColdSide={heatExchangerData.numberOfPassesColdSide}
						numberOfPassesHotSide={heatExchangerData.numberOfPassesHotSide}
						liquidSide={heatExchangerData.liquidSide}
						numberOfPassesLiquidSide={
							heatExchangerData.numberOfPassesLiquidSide
						}
					/>
					{renderFlowDirectionLabel(heatExchangerData.flowDirection)}
				</>
			)
		}

		case ApplicationType.Condenser: {
			const isPlateSideLiquid =
				heatExchangerData.liquidSide === LiquidSide.PlateSide

			if (heatExchangerData.condenserType === CondenserType.Vent_Condenser) {
				return (
					<>
						{renderShellDiam(heatExchangerData.shellDiameter)}
						<Drawing.VentCondenser
							isPlateHotSide={!isPlateSideLiquid}
							arrowsPlateSide={arrowsPlateSide}
							numberOfInlet={heatExchangerData.numberOfShellNozzles}
							numberOfOutlet={heatExchangerData.numberOfShellNozzlesOut}
							numberOfOutletUp={heatExchangerData.numberOfShellNozzlesUp}
							endPlateType={heatExchangerData.endPlateType}
						/>
						{renderFlowDirectionLabel(heatExchangerData.flowDirection)}
					</>
				)
			}

			return (
				<>
					{renderShellDiam(heatExchangerData.shellDiameter)}
					<Drawing
						isPlateHotSide={!isPlateSideLiquid}
						arrowsPlateSide={arrowsPlateSide}
						arrowsShellSide={arrowsShellSide}
						endPlateType={heatExchangerData.endPlateType}
						numberOfPassesColdSide={heatExchangerData.numberOfPassesColdSide}
						numberOfPassesHotSide={heatExchangerData.numberOfPassesHotSide}
						liquidSide={heatExchangerData.liquidSide}
						numberOfPassesLiquidSide={
							heatExchangerData.numberOfPassesLiquidSide
						}
					/>
					{renderFlowDirectionLabel(heatExchangerData.flowDirection)}
				</>
			)
		}

		case ApplicationType.Evaporators: {
			const {circulation, combinedC} = heatExchangerData
			const isHotSidePlate =
				heatExchangerData.evaporatorType === EvaporatorType.FloodedEvaporator

			if (circulation === Circulation.Combined) {
				return (
					<>
						{renderShellDiam(heatExchangerData.shellDiameter)}
						<Drawing.Combined
							endPlateType={heatExchangerData.endPlateType}
							isPlateHotSide={isHotSidePlate}
							arrowsPlateSide={arrowsPlateSide}
							numberOfInlet={heatExchangerData.numberOfShellNozzles}
							numberOfOutlet={heatExchangerData.numberOfShellNozzlesOut}
							combinedC={combinedC}
						/>
						{renderFlowDirectionLabel(heatExchangerData.flowDirection)}
					</>
				)
			}

			return (
				<>
					{renderShellDiam(heatExchangerData.shellDiameter)}
					<Drawing
						isPlateHotSide={isHotSidePlate}
						arrowsPlateSide={arrowsPlateSide}
						arrowsShellSide={arrowsShellSide}
						endPlateType={heatExchangerData.endPlateType}
						numberOfPassesColdSide={heatExchangerData.numberOfPassesColdSide}
						numberOfPassesHotSide={heatExchangerData.numberOfPassesHotSide}
						liquidSide={heatExchangerData.liquidSide}
						numberOfPassesLiquidSide={
							heatExchangerData.numberOfPassesLiquidSide
						}
					/>
					{renderFlowDirectionLabel(heatExchangerData.flowDirection)}
				</>
			)
		}

		case ApplicationType.Cascade: {
			const isHotSidePlate =
				heatExchangerData.evaporatorType === EvaporatorType.FloodedEvaporator

			const {circulation, combinedC} = heatExchangerData
			if (circulation === Circulation.Combined) {
				return (
					<>
						{renderShellDiam(heatExchangerData.shellDiameter)}
						<Drawing.Combined
							isPlateHotSide={isHotSidePlate}
							arrowsPlateSide={arrowsPlateSide}
							numberOfInlet={heatExchangerData.numberOfShellNozzles}
							numberOfOutlet={heatExchangerData.numberOfShellNozzlesOut}
							combinedC={combinedC}
							endPlateType={heatExchangerData.endPlateType}
						/>
						{renderFlowDirectionLabel(heatExchangerData.flowDirection)}
					</>
				)
			}

			return (
				<>
					{renderShellDiam(heatExchangerData.shellDiameter)}
					<Drawing
						applicationType={ApplicationType.Cascade}
						isPlateHotSide={isHotSidePlate}
						arrowsPlateSide={arrowsPlateSide}
						arrowsShellSide={arrowsShellSide}
						endPlateType={heatExchangerData.endPlateType}
						numberOfPassesColdSide={heatExchangerData.numberOfPassesColdSide}
						numberOfPassesHotSide={heatExchangerData.numberOfPassesHotSide}
						liquidSide={heatExchangerData.liquidSide}
						numberOfPassesLiquidSide={
							heatExchangerData.numberOfPassesLiquidSide
						}
					/>
					{renderFlowDirectionLabel(heatExchangerData.flowDirection)}
				</>
			)
		}

		case ApplicationType.PartialCascade: {
			const isHotSidePlate = heatExchangerData.hotSide === HotSide.PlateSide
			return (
				<>
					<Drawing
						isPlateHotSide={isHotSidePlate}
						arrowsPlateSide={arrowsPlateSide}
						arrowsShellSide={arrowsShellSide}
						endPlateType={heatExchangerData.endPlateType}
						numberOfPassesColdSide={heatExchangerData.numberOfPassesColdSidePC}
						numberOfPassesHotSide={heatExchangerData.numberOfPassesHotSidePC}
						liquidSide={heatExchangerData.liquidSide}
						numberOfPassesLiquidSide={
							heatExchangerData.numberOfPassesLiquidSide
						}
					/>
					{renderFlowDirectionLabel(heatExchangerData.flowDirection)}
				</>
			)
		}
		case ApplicationType.MultiCondenser: {
			return (
				<Drawing.MultiCondenser
					appTitle={appTitle}
					liquidSideMultiCondenser={heatExchangerData.liquidSideMultiCondenser}
					multiPassCombo={heatExchangerData.multiPassCombination}
					endPlateType={heatExchangerData.endPlateType}
					psheTypeDesuperheater={heatExchangerData.psheTypeDesuperHeater}
					psheTypeCondenser={heatExchangerData.psheTypeCondenser}
					psheTypeSubcooler={heatExchangerData.psheTypeSubcooler}
					numberOfInlet={heatExchangerData.numberOfShellNozzles}
					numberOfOutlet={heatExchangerData.numberOfShellNozzlesOut}
					shellDiam={heatExchangerData.shellDiameter}
				/>
				//no flow direction label, replaced with psheType and appication within drawing component
			)
		}
	}
}

const renderColsGroup = () => {
	return (
		<colgroup>
			<col span={1} style={{width: '35%'}} />
			{/* name */}
			<col span={1} style={{width: '15%'}} />
			{/* unit */}
			<col span={1} style={{width: '25%'}} />
			{/* value */}
			<col span={1} style={{width: '25%'}} />
			{/* value2? */}
		</colgroup>
	)
}

const renderMultiCondThermalColsGroup = () => {
	return (
		<colgroup>
			<col span={1} style={{width: '20%'}} />

			<col span={1} style={{width: '7%'}} />

			<col span={1} style={{width: '9%'}} />

			<col span={1} style={{width: '9%'}} />

			<col span={1} style={{width: '20%'}} />

			<col span={1} style={{width: '7%'}} />

			<col span={1} style={{width: '9%'}} />

			<col span={1} style={{width: '9%'}} />

			<col span={1} style={{width: '9%'}} />
		</colgroup>
	)
}

export const renderDataSheetContent = (
	heatExchangerData,
	t: TFunction<'translation', undefined>,
	dataText: Datatext,
	calculation: Calculation,
	offerDetail: Offer,
	mFilesInfo: MFileInfo,
	position: Position,
	columnsAndKeys,
) => {
	const {
		appTitle,
		thermalDesignDataWithKey,
		thermalDesignColumns,
		plateShellDataWithKey,
		plateShellColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	} = columnsAndKeys

	return (
		<div className="data-sheet">
			{renderHeader(
				heatExchangerData,
				t,
				offerDetail,
				mFilesInfo,
				position,
				appTitle,
			)}
			<div className="data-sheet__content">
				<Divider />
				<div className="data-sheet__section thermal-design-section">
					<h3 className="data-sheet__table-heading">
						{`${t('datasheet.table.heading.thermalDesign')} / ${
							calculation.name
						}`}
					</h3>
					<div className="data-sheet__drawing">
						{renderArrowsDrawing(heatExchangerData, t, appTitle)}
					</div>
					<table
						className={
							appTitle == 'MultiCondenser'
								? 'data-table__multiCondenser'
								: 'data-table'
						}
					>
						{appTitle == 'MultiCondenser'
							? renderMultiCondThermalColsGroup()
							: renderColsGroup()}
						<tbody>
							{thermalDesignDataWithKey.map(data => (
								<tr key={data.key}>
									{thermalDesignColumns.map(column => (
										<td
											colSpan={column.key === 'value' ? 2 : 1}
											key={column.key}
										>
											{shouldBeTranslated(
												t,
												toFixed(data[column.dataIndex]),
												'datasheet.table.data',
											)}
										</td>
									))}
								</tr>
							))}
							{/*--------------------PLATE SHELL SECTION------------------*/}
							{!(appTitle == 'MultiCondenser') ? (
								<>
									<tr className="break-row"></tr>
									<tr>
										{plateShellColumns.map(column => (
											<th key={column.key}>
												{shouldBeTranslated(
													t,
													column.title,
													'datasheet.table.data',
												)}
											</th>
										))}
									</tr>
									{plateShellDataWithKey.map(data => (
										<tr key={data.key}>
											{plateShellColumns.map(column => (
												<td key={column.key}>
													{shouldBeTranslated(
														t,
														toFixed(data[column.dataIndex]),
														'datasheet.table.data',
													)}
												</td>
											))}
										</tr>
									))}
								</>
							) : null}

							{/*--------------------MEDIUM SECTION------------------*/}
							<tr className="break-row"></tr>
							<tr>
								{mediumDataColumns.map(column => (
									<th key={column.key}>
										{shouldBeTranslated(
											t,
											column.title,
											'datasheet.table.data',
										)}
									</th>
								))}
							</tr>
							{mediumDataWithKey.map(data => (
								<tr key={data.key}>
									{mediumDataColumns.map(column => (
										<td key={column.key}>
											{shouldBeTranslated(
												t,
												precise(data[column.dataIndex], 4),
												'datasheet.table.data',
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>

				{/*--------------------CONNECTIONS SECTION------------------*/}
				<Divider />
				<div className="data-sheet__section">
					<table className="data-table">
						{renderColsGroup()}
						<thead>
							<tr>
								{connectionDataColumns.map(column => (
									<th key={column.key}>
										{column.title === 'Velocity'
											? shouldBeTranslated(
													t,
													column.title,
													'datasheet.table.data',
											  ) + ` ${velocityUnitLabels[heatExchangerData.cboW]}`
											: shouldBeTranslated(
													t,
													column.title,
													'datasheet.table.data',
											  )}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{connectionDataWithKey.map(data => (
								<tr key={data.key}>
									{connectionDataColumns.map(column => (
										<td key={column.key}>
											{shouldBeTranslated(
												t,
												precise(data[column.dataIndex]),
												'datasheet.table.data',
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
				{/*--------------------CONSTRUCTION SECTION------------------*/}
				<Divider />
				<div className="data-sheet__section">
					<h3 className="data-sheet__table-heading">
						{t('datasheet.table.heading.constructionDesign')}
					</h3>
					<table className="data-table">
						{renderColsGroup()}
						<tbody>
							{constructionDataWithKey.map(data => (
								<tr key={data.key}>
									{constructionDataColumns.map(column => (
										<td key={column.key}>
											{shouldBeTranslated(
												t,
												data[column.dataIndex],
												'datasheet.table.data',
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<Divider />
				<div className="data-sheet__section">
					<h3 className="data-sheet__table-heading">
						{t('datasheet.table.heading.materials')}
					</h3>
					<table className="data-table">
						{renderColsGroup()}
						<tbody>
							{materialsDataWithKey.map(data => (
								<tr key={data.key}>
									{materialsDataColumns.map(column => (
										<td
											colSpan={column.dataIndex === 'value' ? 3 : 1}
											key={column.key}
										>
											{shouldBeTranslated(
												t,
												data[column.dataIndex],
												'datasheet.table.data',
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<Divider />
			{renderFooter(t)}
		</div>
	)
}

export const renderDataSheetExpandedProperties = (
	heatExchangerData,
	t: TFunction<'translation', undefined>,
	calculation: Calculation,
	offerDetail: Offer,
	mFilesInfo: MFileInfo,
	position: Position,
	appTitle: string,
) => {
	const {
		mediumPropertiesHotLiqDataWithKey,
		mediumPropertiesHotLiqDataColumns,
		mediumPropertiesHotVapDataWithKey,
		mediumPropertiesHotVapDataColumns,
		mediumPropertiesColdLiqDataWithKey,
		mediumPropertiesColdLiqDataColumns,
		mediumPropertiesColdVapDataWithKey,
		mediumPropertiesColdVapDataColumns,
	} = getMediumPropertiesData(heatExchangerData)

	return (
		<div className="data-sheet">
			{renderHeader(
				heatExchangerData,
				t,
				offerDetail,
				mFilesInfo,
				position,
				appTitle,
			)}
			<Divider />
			<div className="data-sheet__content">
				<h3 className="data-sheet__table-heading">
					{`${t('datasheet.table.heading.medium')} / ${calculation.name}`}
				</h3>
				{/*--------------------MEDIUM PROPERTIES HOT Liq------------------*/}
				<table className="data-table">
					<thead>
						<tr>
							{mediumPropertiesHotLiqDataColumns.map(column => (
								<th key={column.key}>
									{shouldBeTranslated(t, column.title, 'datasheet.table.data')}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{mediumPropertiesHotLiqDataWithKey.map(data => (
							<tr key={data.key}>
								{mediumPropertiesHotLiqDataColumns.map(column => (
									<td key={column.key}>
										{!isInfinitiveNumber(data[column.dataIndex])
											? shouldBeTranslated(
													t,
													precise(data[column.dataIndex]),
													'datasheet.table.data',
											  )
											: null}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
				{/*--------------------MEDIUM PROPERTIES HOT VAP------------------*/}
				<table className="data-table" style={{marginTop: 24}}>
					<thead>
						<tr>
							{mediumPropertiesHotVapDataColumns.map(column => (
								<th key={column.key}>
									{shouldBeTranslated(t, column.title, 'datasheet.table.data')}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{mediumPropertiesHotVapDataWithKey.map(data => (
							<tr key={data.key}>
								{mediumPropertiesHotVapDataColumns.map(column => (
									<td key={column.key}>
										{!isInfinitiveNumber(data[column.dataIndex])
											? shouldBeTranslated(
													t,
													precise(data[column.dataIndex]),
													'datasheet.table.data',
											  )
											: null}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
				<Divider />
				{/*--------------------MEDIUM PROPERTIES COLD LIQ------------------*/}
				<table className="data-table" style={{marginTop: 24}}>
					<thead>
						<tr>
							{mediumPropertiesColdLiqDataColumns.map(column => (
								<th key={column.key}>
									{shouldBeTranslated(t, column.title, 'datasheet.table.data')}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{mediumPropertiesColdLiqDataWithKey.map(data => (
							<tr key={data.key}>
								{mediumPropertiesColdLiqDataColumns.map(column => (
									<td key={column.key}>
										{!isInfinitiveNumber(data[column.dataIndex])
											? shouldBeTranslated(
													t,
													precise(data[column.dataIndex]),
													'datasheet.table.data',
											  )
											: null}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
				{/*--------------------MEDIUM PROPERTIES COLD VAP------------------*/}
				<table className="data-table" style={{marginTop: 24}}>
					<thead>
						<tr>
							{mediumPropertiesColdVapDataColumns.map(column => (
								<th key={column.key}>
									{shouldBeTranslated(t, column.title, 'datasheet.table.data')}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{mediumPropertiesColdVapDataWithKey.map(data => (
							<tr key={data.key}>
								{mediumPropertiesColdVapDataColumns.map(column => (
									<td key={column.key}>
										{!isInfinitiveNumber(data[column.dataIndex])
											? shouldBeTranslated(
													t,
													precise(data[column.dataIndex]),
													'datasheet.table.data',
											  )
											: null}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{renderFooter(t)}
		</div>
	)
}

const renderHeader = (
	heatExchangerData,
	t: TFunction<'translation', undefined>,
	offerDetail: Offer,
	mFilesInfo: MFileInfo,
	position: Position,
	appTitle: string,
) => {
	// initialize calculationType
	let calculationType = ''
	// set it based on multiPassCombination
	switch (appTitle) {
		case 'MultiCondenser':
			if (
				heatExchangerData.multiPassCombination ==
					MultiPassCombination.DesupCondSubcWaterOnPlateSide ||
				heatExchangerData.multiPassCombination ==
					MultiPassCombination.DesupCondSubcWaterOnShellSide
			) {
				calculationType = `PSHE ${heatExchangerData.psheTypeDesuperHeater} + ${heatExchangerData.psheTypeCondenser} + ${heatExchangerData.psheTypeSubcooler}`
			}
			if (
				heatExchangerData.multiPassCombination ==
					MultiPassCombination.DesupCondWaterOnPlateSide ||
				heatExchangerData.multiPassCombination ==
					MultiPassCombination.DesupCondWaterOnShellSide
			) {
				calculationType = `PSHE ${heatExchangerData.psheTypeDesuperHeater} + ${heatExchangerData.psheTypeCondenser}`
			}
			if (
				heatExchangerData.multiPassCombination ==
					MultiPassCombination.CondSubcWaterOnPlateSide ||
				heatExchangerData.multiPassCombination ==
					MultiPassCombination.CondSubcWaterOnShellSide
			) {
				calculationType = `PSHE ${heatExchangerData.psheTypeCondenser} + ${heatExchangerData.psheTypeSubcooler}`
			}
			break
		case 'MultiCondDesuperheater':
			calculationType = `PSHE ${heatExchangerData.psheTypeDesuperHeater}`
			break
		case 'MultiCondCond':
			calculationType = `PSHE ${heatExchangerData.psheTypeCondenser}`
			break
		case 'MultiCondSubc':
			calculationType = `PSHE ${heatExchangerData.psheTypeSubcooler}`
			break
		//default to show all other appTitles outside of MultiCondensers
		default:
			calculationType = `PSHE ${heatExchangerData.psheType}`
			break
	}

	const {reference, projectName} = offerDetail
	const {responsiblePersonName, companyName} = mFilesInfo
	const {itemNo, name} = position
	return (
		<>
			<div className="data-sheet__header">
				<img className="data-sheet__logo" src={VahterusLogo} alt="logo" />
				<div>
					<h2>
						{t('datasheet.header.title')}&nbsp;
						{t(`datasheet.header.appTitle.${appTitle}`)}
					</h2>
				</div>
				<p>{heatExchangerData.sizingVersion}</p>
			</div>
			<Divider />
			<div className="data-sheet__info-section">
				<table className="info-table">
					<colgroup>
						<col span={1} style={{width: '25%'}} />
						<col span={2} style={{width: '80%'}} />
					</colgroup>
					<tbody>
						<tr>
							<td>{t('datasheet.infoTable.date')}</td>
							<td>{formatDate(new Date(), false)}</td>
						</tr>
						<tr>
							<td>{t('datasheet.infoTable.offerNumber')}</td>
							<td>{name}</td>
						</tr>
						<tr>
							<td>{t('datasheet.infoTable.madeBy')}</td>
							<td>{responsiblePersonName}</td>
						</tr>
						<tr className="break-row"></tr>
						<tr>
							<td>{t('datasheet.infoTable.customer')}</td>
							<td>{companyName}</td>
						</tr>
						<tr>
							<td>{t('datasheet.infoTable.reference')}</td>
							<td>{reference}</td>
						</tr>
						<tr>
							<td>{t('datasheet.infoTable.project')}</td>
							<td>{projectName}</td>
						</tr>
						<tr>
							<td>{t('datasheet.infoTable.itemNameNo')}</td>
							<td>{itemNo}</td>
						</tr>
					</tbody>
				</table>
				<div className="data-sheet__calculation-type">
					<p style={{fontSize: '20px', fontWeight: 700}}>
						{t('datasheet.infoTable.type')}&nbsp;{calculationType}
					</p>
					{renderConnectionsTurnedNinety(heatExchangerData.flowDirection, t)}
				</div>
			</div>
		</>
	)
}

const renderFooter = (t: TFunction<'translation', undefined>) => {
	return (
		<div className="data-sheet__footer">
			<div>
				<p>Vahterus Oy</p>
				<p>Pruukintie 7, 23600 Kalanti, Finland</p>
				<p>FIN-23600 KALANTI</p>
			</div>
			<div>
				<p>{t('datasheet.footer.phone')}: +358 (0)2 840 70</p>
				<p>{t('datasheet.footer.fax')}: +358 (0)2 840 7299</p>
			</div>
			<div>
				<p>{t('datasheet.footer.email')}: sales@vahterus.com</p>
				<p>{t('datasheet.footer.web')}: vahterus.com</p>
			</div>
		</div>
	)
}

const DataSheet: React.FunctionComponent<Props> = ({
	exportId,
	calculation,
	offerDetail,
	mFilesInfo,
	positionId,
}) => {
	const {
		data: heatExchangerData,
		isError: isHeatExchangerError,
		isSuccess: isHeatExchangerSuccess,
	} = useHeatExchanger(
		calculation.heatExchangerType,
		calculation.heatExchangerId,
		{
			enabled: calculation.id !== null,
			refetchOnWindowFocus: false,
		},
	)

	const {
		data: dataText,
		isError: isDataTextError,
		isSuccess: isDataTextSuccess,
	} = useCalculationDataText(positionId, calculation.id)
	const {
		data: position,
		isError: isPositionError,
		isSuccess: isPositionSuccess,
	} = usePosition(+offerDetail.id, +positionId, {
		query: {isHeatExchangerIncluded: false},
	})

	const [t] = useTranslation()

	const renderPdfPages = () => {
		if (
			heatExchangerData.application !== ApplicationType.PartialCascade &&
			heatExchangerData.application !== ApplicationType.MultiCondenser
		) {
			//changes the data func name and keep all of the variable names the same
			const columnsAndKeys = getDataFunc(heatExchangerData.application)(
				heatExchangerData,
				dataText,
			)

			return (
				<PDFPage pdfId={exportId}>
					{renderDataSheetContent(
						heatExchangerData,
						t,
						dataText,
						calculation,
						offerDetail,
						mFilesInfo,
						position,
						columnsAndKeys,
					)}
				</PDFPage>
			)
		}
		if (heatExchangerData.application === ApplicationType.PartialCascade) {
			const columnsAndKeys = getDataFunc(heatExchangerData.application)(
				heatExchangerData,
				dataText,
			)
			return (
				<>
					<PDFPage pdfId={exportId}>
						{renderDataSheetContent(
							heatExchangerData,
							t,
							dataText,
							calculation,
							offerDetail,
							mFilesInfo,
							position,
							columnsAndKeys,
						)}
					</PDFPage>
					{}
					{isHeatExchangerSuccess &&
					heatExchangerData.application === ApplicationType.PartialCascade ? (
						<div style={{marginTop: '48px'}}>
							<PDFPage pdfId={`extended-properties-${exportId}`}>
								{renderDataSheetExpandedProperties(
									heatExchangerData,
									t,
									calculation,
									offerDetail,
									mFilesInfo,
									position,
									'Partial2Phase',
								)}
							</PDFPage>
						</div>
					) : null}
				</>
			)
		}
		//calls different function for each unit in multi-cond
		if (heatExchangerData.application === ApplicationType.MultiCondenser) {
			const modelColumnsAndKeys = getDataFunc(
				heatExchangerData.application,
				'multiCond-Model',
			)(heatExchangerData, dataText)
			const desupColumnsAndKeys = getDataFunc(
				heatExchangerData.application,
				'multiCond-Desup',
			)(heatExchangerData, dataText)
			const condColumnsAndKeys = getDataFunc(
				heatExchangerData.application,
				'multiCond-Cond',
			)(heatExchangerData, dataText)
			const subCooColumnsAndKeys = getDataFunc(
				heatExchangerData.application,
				'multiCond-Subcoo',
			)(heatExchangerData, dataText)
			return (
				<>
					{/* First page needs new layout with multiple drawings */}
					<PDFPage pdfId={exportId}>
						{renderDataSheetContent(
							heatExchangerData,
							t,
							dataText,
							calculation,
							offerDetail,
							mFilesInfo,
							position,
							modelColumnsAndKeys,
						)}
					</PDFPage>
					{/* 2nd page: Desuperheater */}
					<PDFPage
						pdfId={`calculation-export-MC-desuperheater-page-${calculation.id}`}
					>
						{renderDataSheetContent(
							heatExchangerData,
							t,
							dataText,
							calculation,
							offerDetail,
							mFilesInfo,
							position,
							desupColumnsAndKeys,
						)}
					</PDFPage>
					{/* 3rd page: Condenser */}
					<PDFPage
						pdfId={`calculation-export-MC-condenser-page-${calculation.id}`}
					>
						{renderDataSheetContent(
							heatExchangerData,
							t,
							dataText,
							calculation,
							offerDetail,
							mFilesInfo,
							position,
							condColumnsAndKeys,
						)}
					</PDFPage>
					{/* 4th page: Subcooler */}
					<PDFPage
						pdfId={`calculation-export-MC-subcooler-page-${calculation.id}`}
					>
						{renderDataSheetContent(
							heatExchangerData,
							t,
							dataText,
							calculation,
							offerDetail,
							mFilesInfo,
							position,
							subCooColumnsAndKeys,
						)}
					</PDFPage>
				</>
			)
		}
	}

	return isHeatExchangerError || isDataTextError || isPositionError ? (
		<p>There is an error while fetching the datasheet</p>
	) : isHeatExchangerSuccess && isDataTextSuccess && isPositionSuccess ? (
		renderPdfPages()
	) : (
		<FullPageLoading />
	)
}

export default DataSheet
