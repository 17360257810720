import React from 'react'
import PDFPage from './PDFPage'
import VahterusLogo from '../../assets/imgs/vahterus-logo.png'
import OfferDetails from '../OfferDetails'
import Offer, {MFileInfo, OfferInfoPreviewItem} from '../../model/Offer'
import Position from '../../model/Position'
import {Divider} from 'antd'
import {endPlateTypeLabels} from '../../model/enumLabels'
import {defaultProposalOfferGreeting} from '../../utils/constants'

import {formatPrice} from '../../utils/utils'
import {useTranslation} from 'react-i18next'
import {OfferInfoPreviewContent} from './PartialOfferDetailPDF'
import {useAuth0} from '@auth0/auth0-react'

interface Props {
	positions: Position[]
	offer: Offer
	offerInfo: OfferInfoPreviewItem[]
	mFiles: MFileInfo
	previewExtra: boolean
	pdfId: string
	secondPageElemIndex: number
}

const alphabetArray = 'abcdefghijklmnopqrstuvwxyz'.split('')

interface PositionTableData {
	key: string
	item: string
	itemNo: string
	model: string
	name: string
	finalShownPrice: number
	quantity: number
	isExtra?: boolean
	showOnOffer?: number
}

const OfferDetailPDF: React.FunctionComponent<Props> = ({
	positions,
	offer,
	offerInfo,
	mFiles,
	previewExtra,
	pdfId,
	secondPageElemIndex,
}) => {
	const [t] = useTranslation()
	const {user} = useAuth0()
	const renderOfferInfo = () => {
		return (
			<>
				<OfferDetails offerDetail={offer} mFilesInfo={mFiles} />
				<Divider />
				<pre style={{marginBottom: '48px'}}>
					{offer.offerGreeting
						? offer.offerGreeting
						: defaultProposalOfferGreeting}
					{/*  : (t('offer.info.defaultOfferGreeting') as string)} */}
				</pre>
			</>
		)
	}

	const shouldBeTranslated = (text: string, translatePrefix: string) => {
		const translatedText = t(`${translatePrefix}.${text}`)
		return translatedText.includes('datasheet.table') ? text : translatedText
	}

	const renderBreakDownTables = () => {
		const extrasSingle = record => {
			let total = 0
			record.extras.forEach(extra => {
				if (extra.showOnOffer) {
					total += extra.price * extra.quantity
				}
			})
			record.parts.forEach(part => {
				if (part.showOnOffer) {
					total += part.price * part.quantity * (1 - record.discount)
				}
			})
			return total
		}

		const columns = [
			{
				title: t('offer.details.item'),
				dataIndex: 'item',
				key: 'item',
			},
			{
				title: t('offer.details.description'),
				dataIndex: 'itemNo',
				key: 'itemNo',
			},
			{
				title: t('offer.details.model'),
				dataIndex: 'model',
				key: 'model',
				render: function renderModel(value, record) {
					// eslint-disable-next-line no-prototype-builtins
					if (record.hasOwnProperty('psheType')) {
						return (
							<span>
								{record.psheType} {endPlateTypeLabels[record.endPlateType]}
							</span>
						)
					}
					// eslint-disable-next-line no-prototype-builtins
					else if (record.hasOwnProperty('multiCondAllPsheTypes')) {
						return (
							<span>
								{record.multiCondAllPsheTypes}{' '}
								{endPlateTypeLabels[record.endPlateType]}
							</span>
						)
					} else {
						return <span>{record.model ? record.model : ''}</span>
					}
				},
			},
			{
				title: t('offer.details.name'),
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: t('offer.details.price'),
				dataIndex: 'finalShownPrice',
				key: 'finalShownPrice',
				render: function displayPrice(text, record) {
					if (record.isExtra) {
						return (
							<span>
								{formatPrice(
									text,
									offer.currencyRate,
									offer.currencyCode,
									1,
									1,
								)}
							</span>
						)
					} else {
						const total = text - extrasSingle(record)
						return !user['http://vahterus/roles'].includes(
							'ExternalUserJCI',
						) ? (
							<span>
								{formatPrice(
									total,
									offer.currencyRate,
									offer.currencyCode,
									1,
									1,
								)}
							</span>
						) : null
					}
				},
			},
			{
				title: t('offer.details.qty'),
				dataIndex: 'quantity',
				key: 'quantity',
			},
			{
				title: t('offer.details.total'),
				dataIndex: 'total',
				key: 'total',
				render: function displayTotal(text, record) {
					if (record.isExtra) {
						const {finalShownPrice, quantity} = record
						const total = finalShownPrice * quantity
						return (
							<span>
								{formatPrice(
									total,
									offer.currencyRate,
									offer.currencyCode,
									0,
									0,
								)}
							</span>
						)
					} else {
						const {finalShownPrice, quantity} = record
						const total = (finalShownPrice - extrasSingle(record)) * quantity
						return (
							<span>
								{formatPrice(
									total,
									offer.currencyRate,
									offer.currencyCode,
									0,
									0,
								)}
							</span>
						)
					}
				},
			},
		]

		const positionsWithKey = positions
			.filter(position => position.calculations.length > 0)
			.sort((a, b) => a.order - b.order)
			.map((position, index) => ({
				...position,
				item: index + 1,
				key: position.id,
			}))

		const positionsTableData: PositionTableData[] = previewExtra
			? positionsWithKey.reduce((prv, position) => {
					// flatten extras of positions
					const parsedExtraToTableFormat = position.extras.map(
						(item, index) => {
							return {
								item: `${position.item + '(' + alphabetArray[index] + ')'}`,
								itemNo: item.name,
								finalShownPrice: item.price,
								// this needs to be multiply with quantity of heat exchanger
								quantity: item.quantity * position.quantity,
								isExtra: true,
								showOnOffer: item.showOnOffer,
							}
						},
					)
					const parsedPartsToTableFormat = position.parts.map(item => {
						return {
							itemNo: item.name,
							finalShownPrice: item.price * (1 - position.discount),
							// this needs to be multiply with quantity of heat exchanger
							quantity: item.quantity * position.quantity,
							isExtra: true,
							showOnOffer: item.showOnOffer,
						}
					})
					const parsedAdditionalItemsToTableFormat =
						position.additionalItems.map(item => {
							return {
								item: item.item,
								itemNo: item.description,
								model: item.model,
								name: item.name,
								quantity: item.quantity,
								finalShownPrice: item.price,
								extras: [],
								parts: [],
							}
						})

					const positionWithExtra = [
						position,
						...parsedExtraToTableFormat,
						...parsedPartsToTableFormat,
						...parsedAdditionalItemsToTableFormat,
					]
					return [...prv, ...positionWithExtra]
			  }, [])
			: positionsWithKey

		let grandTotal = positions.reduce((accumulator, currentValue) => {
			return accumulator + currentValue.finalShownPrice * currentValue.quantity
		}, 0)
		positions.forEach(position => {
			position.additionalItems.forEach(item => {
				grandTotal = grandTotal + item.price * item.quantity
			})
		})

		return (
			<div className="on-pdf">
				<table className="preview-position-table">
					<colgroup>
						<col span={1} style={{width: '5%'}} />
						<col span={1} style={{width: '20%'}} />
						<col span={1} style={{width: '30%'}} />
						<col span={1} style={{width: '15%'}} />
						<col span={1} style={{width: '10%'}} />
						<col span={1} style={{width: '5%'}} />
						<col span={1} style={{width: '15%'}} />
					</colgroup>
					<thead>
						<tr>
							{columns.map(column => (
								<th key={column.key}>
									{shouldBeTranslated(column.title, 'datasheet.table.data')}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{positionsTableData.map((data, index) =>
							(data.isExtra && data.showOnOffer) || !data.isExtra ? (
								<tr key={index} className={data.isExtra && 'extra'}>
									{columns.map((column, index2) => {
										if (column.render) {
											return (
												<td key={index2}>
													{column.render(data[column.dataIndex], data)}
												</td>
											)
										}

										return (
											<td key={index2}>
												{shouldBeTranslated(
													data[column.dataIndex],
													'datasheet.table.data',
												)}
											</td>
										)
									})}
								</tr>
							) : null,
						)}
					</tbody>
					<tfoot>
						<tr>
							<td style={{width: '19%'}}>Price excluding taxes and duties.</td>
							<td></td>
							<td></td>
							<td></td>
							<td>
								<b>{t('offer.details.grandtotal')}:</b>
							</td>
							<td></td>
							<td>
								<b style={{textDecoration: 'underline double'}}>
									{!user['http://vahterus/roles'].includes('ExternalUserJCI')
										? formatPrice(
												grandTotal,
												offer.currencyRate,
												offer.currencyCode,
												0,
												0,
										  )
										: null}
								</b>
							</td>
						</tr>
						<tr>
							<td colSpan={3} style={{border: '0'}}>
								Prices are subject to escalation of raw material prices and
								following terms as attachment 1.
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		)
	}

	return (
		<PDFPage pdfId={pdfId}>
			<div className="offer-detail-pdf">
				<div className="offer-detail-pdf__header on-pdf">
					<img
						className="offer-detail-pdf__logo"
						src={VahterusLogo}
						alt="logo"
					/>
					<div>
						<p>Vahterus Oy, Pruukintie 7, 23600 Kalanti, Finland</p>
						<p>vahterus.com sales@vahterus.com +358 2 840 70</p>
					</div>
				</div>
				{renderOfferInfo()}
				{renderBreakDownTables()}

				<div>
					<table className="offer-info-table">
						<colgroup>
							<col span={1} style={{width: '20%'}} />
							<col span={4} style={{width: '100%'}} />
						</colgroup>
						<tbody>
							<OfferInfoPreviewContent
								items={offerInfo}
								lastElemIndex={secondPageElemIndex}
							/>
						</tbody>
					</table>
				</div>
			</div>
		</PDFPage>
	)
}

export default OfferDetailPDF
