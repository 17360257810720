import {
	areaLabels,
	capacityUnitLabels,
	dynamicViscoUnitLabels,
	endPlateTypeLabels,
	feetLabels,
	flowDirectorMaterialLabels,
	foulingFactorUnitLabels,
	kValueLabels,
	massFlowUnitLabels,
	massUnitLabels,
	paintingLabels,
	plateMaterialLabels,
	plateThicknessLabels,
	positionLabels,
	pressureUnitLabels,
	shellMaterialLabels,
	specificGravityUnitLables,
	specificHeatUnitLables,
	temUnitLabels,
	thermalConductivityUnitLabels,
	typeApporvalLabels,
	volumeUnitLabels,
	nozzleShellPEDENLabels,
	nozzleShellASMELabels,
	nozzleShellPEDLabels,
	designPressureUnitLabels,
} from '../../model/enumLabels'
import {
	ThermalDesignData,
	PlateShellData,
	MediumData,
	ConnectionsData,
} from '../../model/DataSheet'
import {
	HotSide,
	MediumSource,
	NumberOfShellNozzles,
	TypeApproval,
} from '../../model/CalculationEnums'
import {OnePhaseLiquidMedium} from '../../model/OnePhase'
import {
	getDesignPressureUnit,
	getMediumName,
	getNumberOfPlateNozzle,
	getShellMaterial,
} from './dataSheetUtils'

const getNumberOfShellNozzle = (numberOfShellNozzles: NumberOfShellNozzles) => {
	switch (numberOfShellNozzles) {
		case NumberOfShellNozzles._1pc:
		case NumberOfShellNozzles._2pc:
			return 1
		case NumberOfShellNozzles._4pc:
			return 2
	}
}

const getNozzlePlateSideSize = (text, number, typeApproval) => {
	switch (typeApproval) {
		case TypeApproval.PED:
			if (text.includes('/')) {
				return nozzleShellPEDLabels[text]
			} else {
				return nozzleShellPEDLabels[number]
			}
		case TypeApproval.PEDEN:
			if (text.includes('/')) {
				return nozzleShellPEDENLabels[text]
			} else {
				return nozzleShellPEDENLabels[number]
			}
		case TypeApproval.ASME:
			if (text.includes('/')) {
				return nozzleShellASMELabels[text]
			} else {
				return nozzleShellASMELabels[number]
			}
		default:
			if (text.includes('/')) {
				return nozzleShellPEDLabels[text]
			} else {
				return nozzleShellPEDLabels[number]
			}
	}
}

const getNozzleShellSideSize = (number, typeApproval) => {
	switch (typeApproval) {
		case TypeApproval.PED:
			return nozzleShellPEDLabels[number]

		case TypeApproval.PEDEN:
			return nozzleShellPEDENLabels[number]

		case TypeApproval.ASME:
			return nozzleShellASMELabels[number]

		default:
			return nozzleShellPEDLabels[number]
	}
}

export const getOnePhaseData = (heatExchangerData, dataText) => {
	const {hotSideMedium, coldSideMedium} = heatExchangerData

	const appTitle =
		hotSideMedium === OnePhaseLiquidMedium.Liquid &&
		coldSideMedium === OnePhaseLiquidMedium.Liquid
			? 'LiquidLiquid'
			: hotSideMedium === OnePhaseLiquidMedium.Gas &&
			  coldSideMedium === OnePhaseLiquidMedium.Gas
			? 'GasGas'
			: 'LiquidGas'
	//====================================THERMAL DATA===============================
	const {
		cboQ,
		capacity,
		cboArea,
		area,
		cboT,
		lmtd,
		cboKval,
		kval,
		excessDesignArea,
		inletTempHotSide,
		inletTempColdSide,
		outletTempHotSide,
		outletTempColdSide,
		cboFF,
		foulingFactor,
	} = heatExchangerData
	const thermalDesignData: ThermalDesignData[] = [
		{
			name: 'Capacity',
			unit: capacityUnitLabels[cboQ],
			value: capacity,
		},
		{
			name: 'Heat transfer area',
			unit: areaLabels[cboArea],
			value: area,
		},
		{
			name: 'Logarithmic mean T',
			unit: temUnitLabels[cboT],
			value: lmtd,
		},
		{
			name: 'K-value',
			unit: kValueLabels[cboKval],
			value: kval,
		},
		{
			name: 'Fouling factor',
			unit: foulingFactorUnitLabels[cboFF],
			value: foulingFactor,
		},
		{
			name: 'Excess Design/Area',
			unit: '%',
			value: excessDesignArea,
		},
	]

	const thermalDesignDataWithKey = thermalDesignData.map((item, index) => ({
		key: index,
		...item,
	}))

	const thermalDesignColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]
	//====================================PLATE SHELL DATA===============================

	const isHotSidePlate = heatExchangerData.hotSide === HotSide.PlateSide
	const {
		cboM,
		flowRateHotSide,
		flowRateColdSide,
		cboDp,
		pressureDropHotSideOutput,
		pressureDropColdSideOutput,
		cboV,
		volumePlateSide,
		volumeShellSide,
	} = heatExchangerData

	const plateShellData: PlateShellData[] = [
		{
			name: 'TempIN',
			unit: temUnitLabels[cboT],
			value: isHotSidePlate ? inletTempHotSide : inletTempColdSide,
			value2: isHotSidePlate ? inletTempColdSide : inletTempHotSide,
		},
		{
			name: 'TempOUT',
			unit: temUnitLabels[cboT],
			value: isHotSidePlate ? outletTempHotSide : outletTempColdSide,
			value2: isHotSidePlate ? outletTempColdSide : outletTempHotSide,
		},
		{
			name: 'Flow rate',
			unit: massFlowUnitLabels[cboM],
			value: isHotSidePlate ? flowRateHotSide : flowRateColdSide,
			value2: isHotSidePlate ? flowRateColdSide : flowRateHotSide,
		},
		{
			name: 'Pressure drop',
			unit: pressureUnitLabels[cboDp],
			value: isHotSidePlate
				? pressureDropHotSideOutput
				: pressureDropColdSideOutput,
			value2: isHotSidePlate
				? pressureDropColdSideOutput
				: pressureDropHotSideOutput,
		},
		{
			name: 'Liquid volume',
			unit: volumeUnitLabels[cboV],
			value: volumePlateSide,
			value2: volumeShellSide,
		},
	]

	const plateShellDataWithKey = plateShellData.map((item, index) => ({
		key: index,
		...item,
	}))

	const plateShellColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: `Plate Side (${isHotSidePlate ? 'Hot' : 'Cold'})`,
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: `Shell Side (${isHotSidePlate ? 'Cold' : 'Hot'})`,
		},
	]

	//====================================MEDIUM DATA===============================
	const {
		dynamicViscosityHotSide,
		mediumHotSide,
		mediumColdSide,
		mediumHotSideOtherMedia,
		mediumColdSideOtherMedia,
		mediumSourceHotSide,
		mediumSourceColdSide,
		thermalConductivityHotSide,
		thermalConductivityColdSide,
		dynamicViscosityColdSide,
		cboGravi,
		specificGravityHotSide,
		specificGravityColdSide,
		cboSpH,
		specificHeatHotSide,
		specificHeatColdSide,
		concentrationHotSide,
		concentrationColdSide,
		cboCdy,
		cboVisco,
		pressureHotside,
		pressureColdSide,
		cboP,
	} = heatExchangerData
	const mediumData: MediumData[] = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value: dynamicViscosityHotSide,
			value2: dynamicViscosityColdSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value: specificGravityHotSide,
			value2: specificGravityColdSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value: specificHeatHotSide,
			value2: specificHeatColdSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value: thermalConductivityHotSide,
			value2: thermalConductivityColdSide,
		},
	]

	const mediumDataWithKey = mediumData.map((item, index) => ({
		key: index,
		...item,
	}))

	const mediumNameHotSide = getMediumName({
		mediumSource: mediumSourceHotSide,
		mediumName: mediumHotSide,
		mediumNameOtherMedia: mediumHotSideOtherMedia,
		concentration: concentrationHotSide,
	})
	const mediumNameColdSide = getMediumName({
		mediumSource: mediumSourceColdSide,
		mediumName: mediumColdSide,
		mediumNameOtherMedia: mediumColdSideOtherMedia,
		concentration: concentrationColdSide,
	})

	const mediumNameHotSideTitle =
		hotSideMedium === OnePhaseLiquidMedium.Gas &&
		mediumSourceHotSide === MediumSource.RefProp
			? mediumNameHotSide +
			  ` (${pressureHotside.toFixed(2)} ${pressureUnitLabels[cboP]})`
			: mediumNameHotSide
	const mediumNameColdSideTitle =
		coldSideMedium === OnePhaseLiquidMedium.Gas &&
		mediumSourceColdSide === MediumSource.RefProp
			? mediumNameColdSide +
			  ` (${pressureColdSide.toFixed(2)} ${pressureUnitLabels[cboP]})`
			: mediumNameColdSide

	const mediumDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: isHotSidePlate ? 'value' : 'value2',
			key: isHotSidePlate ? 'value' : 'value2',
			title: isHotSidePlate ? mediumNameHotSideTitle : mediumNameColdSideTitle,
		},
		{
			dataIndex: isHotSidePlate ? 'value2' : 'value',
			key: isHotSidePlate ? 'value2' : 'value',
			title: isHotSidePlate ? mediumNameColdSideTitle : mediumNameHotSideTitle,
		},
	]
	//====================================CONSTRUCTION DATA===============================
	const {
		endPlateType,
		cboMass,
		massOfHeatExchanger,
		position,
		typeApproval,
		designPressure,
		designPressureOnDataSheet,
		designCode,
		designTemperatureMin,
		designTemperatureMax,
		feet,
		designPressurePlateSide,
		designPressureUnitOnDataSheet,
	} = heatExchangerData
	const constructionData = [
		{
			name: 'End Type',
			value: endPlateTypeLabels[endPlateType],
			value2: dataText?.constructionRow1Text,
		},
		{
			name: 'Weight (dry)',
			unit: massUnitLabels[cboMass],
			value: massOfHeatExchanger.toFixed(0),
			value2: dataText?.constructionRow2Text,
		},
		{
			name: 'Position',
			value: positionLabels[position],
			value2: dataText?.constructionRow3Text,
		},
		{
			name: 'Design code',
			value: designCode || typeApporvalLabels[typeApproval],
			value2: dataText?.constructionRow4Text,
		},
		{
			name:
				dataText?.constructionRow5Text != dataText?.constructionRow6Text
					? 'Shell design pressure'
					: 'Design pressure',
			unit: designPressureOnDataSheet
				? designPressureUnitLabels[designPressureUnitOnDataSheet]
				: getDesignPressureUnit(designPressure),
			value: designPressureOnDataSheet || dataText?.constructionRow5Text,
		},
	]
	if (dataText?.constructionRow5Text != dataText?.constructionRow6Text)
		constructionData.push({
			name: 'Plate design pressure',
			unit: getDesignPressureUnit(designPressure),
			value: dataText?.constructionRow6Text || designPressurePlateSide,
		})
	constructionData.push(
		{
			name: 'Design temperature',
			unit: temUnitLabels[cboT],
			value: `${designTemperatureMin}/${designTemperatureMax}`,
			value2: dataText?.constructionRow7Text,
		},
		{
			name: 'Support',
			value: feetLabels[feet],
			value2: dataText?.constructionRow8Text,
		},
	)

	const constructionDataWithKey = constructionData.map((item, index) => ({
		key: index,
		...item,
	}))

	const constructionDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
		{
			dataIndex: 'value2',
			key: 'value2',
		},
	]

	//====================================MATERIALS DATA===============================
	const materialsData = [
		{
			name: 'Plate material',
			value:
				plateMaterialLabels[heatExchangerData.plateMaterial] +
				' ' +
				plateThicknessLabels[heatExchangerData.plateThickness],
		},
		{
			name: 'Shell material',
			value: getShellMaterial(
				endPlateType,
				shellMaterialLabels[heatExchangerData.shellMaterial],
			),
		},
		{
			name: 'Flow director material',
			value: flowDirectorMaterialLabels[heatExchangerData.flowDirectorMaterial],
		},
		{
			name: 'Plate connections',
			value: dataText?.materialsPlateConnections,
		},
		{
			name: 'Shell connections IN',
			value: dataText?.materialsShellConnectionsIn,
		},
		{
			name: 'Shell connections OUT',
			value: dataText?.materialsShellConnectionsOut,
		},
		{
			name: 'Surface treatment',
			value: paintingLabels[heatExchangerData.paint],
		},
		// {
		// 	name: 'Finishing (Shell externally)',
		// },
		// {
		// 	name: '- Stainless steel parts',
		// 	value: 'Pearl blasting',
		// },
		// {
		// 	name: '- Carbon steel parts',
		// 	value: paintingLabels[heatExchangerData.paint],
		// },
	]

	const materialsDataWithKey = materialsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const materialsDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]

	//====================================CONNECTIONS DATA===============================
	const connectionsData: ConnectionsData[] = [
		{
			name: 'Plate side IN',
			size: getNozzlePlateSideSize(
				heatExchangerData.nozzlePlatesideText,
				heatExchangerData.nozzlePlateSide,
				heatExchangerData.typeApproval,
			),
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlate,
		},
		{
			name: 'Plate side OUT',
			size: getNozzlePlateSideSize(
				heatExchangerData.nozzlePlatesideText,
				heatExchangerData.nozzlePlateSide,
				heatExchangerData.typeApproval,
			),
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlate,
		},
		{
			name: 'Shell side IN',
			size: getNozzleShellSideSize(
				heatExchangerData.nozzleShell,
				heatExchangerData.typeApproval,
			),
			numberOfPieces: getNumberOfShellNozzle(
				heatExchangerData.numberOfShellNozzles,
			),
			velocity: heatExchangerData.velocityShell,
		},
		{
			name: 'Shell side OUT',
			size: getNozzleShellSideSize(
				heatExchangerData.nozzleShell,
				heatExchangerData.typeApproval,
			),
			numberOfPieces: getNumberOfShellNozzle(
				heatExchangerData.numberOfShellNozzles,
			),
			velocity: heatExchangerData.velocityShell,
		},
	]

	const connectionDataWithKey = connectionsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const connectionDataColumns = [
		{
			title: 'Connections',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Size (DN)',
			dataIndex: 'size',
			key: 'size',
		},
		{
			title: 'Nb of pieces',
			dataIndex: 'numberOfPieces',
			key: 'numberOfPieces',
		},
		{
			title: 'Velocity',
			dataIndex: 'velocity',
			key: 'velocity',
		},
	]

	return {
		appTitle,
		thermalDesignDataWithKey,
		thermalDesignColumns,
		plateShellDataWithKey,
		plateShellColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	}
}
