import {Application} from './Application'
import {
	CalculationState,
	CondenserType,
	FlowDirection,
	LiquidSide,
	Medium,
	NozzleShell,
	NumberOfPasses,
	NumberOfShellNozzles,
	PlateShellConnections,
	UnitEntalphyOption,
	UnitSurfaceTensionOption,
} from './CalculationEnums'

export default interface Condenser extends Application {
	condenserType: CondenserType
	numberOfPassesLiquidSide: NumberOfPasses
	liquidSide: LiquidSide
	flowDirection: FlowDirection
	pressureDropLiquidSide: number
	gasInletTemperature: number
	condensingTemperature: number
	subCooledTemperature: number
	condensionDegree: number
	inletTemperatureLiquid: number
	outletTemperatureLiquid: number
	mediumVaporSide: Medium
	mediumLiquidSide: Medium
	concentrationLiquidSide: number
	liquidSidePhase: LiquidSidePhase
	nozzleShellOut: NozzleShell
	nozzleShellOutUp: NozzleShell
	numberOfShellNozzlesOut: NumberOfShellNozzles
	numberOfShellNozzlesUp: NumberOfShellNozzles
	cboSigma: UnitSurfaceTensionOption
	cboH: UnitEntalphyOption
	plateConnections: PlateShellConnections
	flowRateLiquid: number
	flowRateVapor: number
	dynamicViscosityLiquidSide: number
	specificGravityLiquidSide: number
	specificHeatLiquidSide: number
	thermalConductivityLiquidSide: number
	saturatedPressure: number
	deltaPCondSide: number
	dynamicViscosityVaporSideL: number
	specificGravityVaporSideL: number
	specificHeatVaporSideL: number
	thermalConductivityVaporSideL: number
	surfaceTensionVaporSideL: number
	dynamicViscosityVaporSideV: number
	specificGravityVaporSideV: number
	specificHeatVaporSideV: number
	thermalConductivityVaporSideV: number
	dynamicViscosityVaporSideSuperh: number
	specificGravityVaporSideSuperh: number
	specificHeatVaporSideSuperh: number
	thermalConductivityVaporSideSuperh: number
	enthalpyVaporSideOutlet: number
	enthalpyVaporSideL: number
	enthalpyVaporSideV: number
	enthalpyVaporSideSuperh: number
	pressureDropLiquidOutput: number
	pressureDropVaporOutput: number
	foulingFactorOutput: number
	velocityPlate: number
	velocityPlateOut: number
	velocityShellOut: number
	velocityShellOutUp: number
	optimumVelocityPlateInLow: number
	optimumVelocityPlateInHigh: number
	optimumVelocityPlateOutLow: number
	optimumVelocityPlateOutHigh: number
	optimumVelocityShellInLow: number
	optimumVelocityShellInHigh: number
	optimumVelocityShellOutLow: number
	optimumVelocityShellOutHigh: number
	optimumVelocityShellOutUpLow: number
	optimumVelocityShellOutUpHigh: number
	nbShell3: number
	dnShell3: number
	nozzleShellPrice3: number
	flangeShellPrice3: number
	temperatureEfficiency: number
	massOfRefrigerantInCondenser: number
	calculationSuccessful: CalculationState
	extraLengthForCondenserSeparator: number
	velocityInDemisterCombined: number
	allowVelocityInDemisterCombined: number
}

export enum LiquidSidePhase {
	Liquid = 0,
	Gas = 1,
}
