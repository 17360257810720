import {
	areaLabels,
	capacityUnitLabels,
	designPressureUnitLabels,
	dynamicViscoUnitLabels,
	endPlateTypeLabels,
	feetLabels,
	flowDirectorMaterialLabels,
	foulingFactorUnitLabels,
	kValueLabels,
	massFlowUnitLabels,
	massUnitLabels,
	mediumLabels,
	paintingLabels,
	plateMaterialLabels,
	plateThicknessLabels,
	positionLabels,
	pressureUnitLabels,
	shellMaterialLabels,
	specificGravityUnitLables,
	specificHeatUnitLables,
	temUnitLabels,
	thermalConductivityUnitLabels,
	typeApporvalLabels,
	volumeUnitLabels,
} from '../../model/enumLabels'
import {
	CondenserType,
	FlowDirectorMaterial,
	LiquidSide,
	MediumSource,
} from '../../model/CalculationEnums'
import {precise} from '../../utils/utils'
import {
	getDesignPressureUnit,
	getMediumName,
	getNumberOfPlateNozzle,
	getShellMaterial,
} from './dataSheetUtils'
import {LiquidSidePhase} from '../../model/Condenser'
import {
	ThermalDesignData,
	PlateShellData,
	MediumData,
	ConnectionsData,
} from '../../model/DataSheet'

export const getCondenserData = (heatExchangerData, dataText) => {
	const getCondenserType = condenserType => {
		switch (condenserType) {
			case CondenserType.Vent_Condenser:
				return 'Vent Condenser'
			case CondenserType.CondGasSeparator:
				return 'Condenser-Gas-Separator'
			case CondenserType.Normal:
				return 'Condenser'
		}
	}
	const appTitle = getCondenserType(heatExchangerData.condenserType)

	//====================================THERMAL DATA===============================
	const {
		cboQ,
		capacity,
		cboArea,
		area,
		cboT,
		lmtd,
		cboKval,
		kval,
		cboFF,
		foulingFactor,
		excessDesignArea,
	} = heatExchangerData
	const thermalDesignData: ThermalDesignData[] = [
		{
			name: 'Capacity',
			unit: capacityUnitLabels[cboQ],
			value: capacity,
		},
		{
			name: 'Heat transfer area',
			unit: areaLabels[cboArea],
			value: area,
		},
		{
			name: 'Logarithmic mean T',
			unit: temUnitLabels[cboT],
			value: lmtd,
		},
		{
			name: 'K-value',
			unit: kValueLabels[cboKval],
			value: kval,
		},
		{
			name: 'Fouling factor',
			unit: foulingFactorUnitLabels[cboFF],
			value: foulingFactor,
		},
		{
			name: 'Excess Design/Area',
			unit: '%',
			value: excessDesignArea,
		},
	]

	const thermalDesignDataWithKey = thermalDesignData.map((item, index) => ({
		key: index,
		...item,
	}))

	const thermalDesignColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]
	//====================================PLATE SHELL DATA===============================

	const isPlateSideLiquid =
		heatExchangerData.liquidSide === LiquidSide.PlateSide

	const {
		cboM,
		inletTemperatureLiquid,
		gasInletTemperature,
		cboDp,
		cboV,
		volumePlateSide,
		volumeShellSide,
		flowRateLiquid,
		flowRateVapor,
		pressureDropLiquidOutput,
		pressureDropVaporOutput,
		subCooledTemperature,
		outletTemperatureLiquid,
		condensingTemperature,
	} = heatExchangerData

	const plateShellData: PlateShellData[] = [
		{
			name: 'TempIN',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? inletTemperatureLiquid : gasInletTemperature,
			value2: isPlateSideLiquid ? gasInletTemperature : inletTemperatureLiquid,
		},
		{
			name: 'TempOUT',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? outletTemperatureLiquid : subCooledTemperature,
			value2: isPlateSideLiquid
				? subCooledTemperature
				: outletTemperatureLiquid,
		},
		{
			name: 'Condensing temperature',
			unit: temUnitLabels[cboT],
			value: isPlateSideLiquid ? null : condensingTemperature,
			value2: isPlateSideLiquid ? condensingTemperature : null,
		},
		{
			name: 'Flow rate',
			unit: massFlowUnitLabels[cboM],
			value: isPlateSideLiquid ? flowRateLiquid : flowRateVapor,
			value2: isPlateSideLiquid ? flowRateVapor : flowRateLiquid,
		},
		{
			name: 'Pressure drop',
			unit: pressureUnitLabels[cboDp],
			value: isPlateSideLiquid
				? pressureDropLiquidOutput
				: pressureDropVaporOutput,
			value2: isPlateSideLiquid
				? pressureDropVaporOutput
				: pressureDropLiquidOutput,
		},
		{
			name: 'Liquid volume',
			unit: volumeUnitLabels[cboV],
			value: volumePlateSide,
			value2: volumeShellSide,
		},
	]

	const plateShellDataWithKey = plateShellData.map((item, index) => ({
		key: index,
		...item,
	}))

	const plateShellColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
			title: `Plate Side (${isPlateSideLiquid ? 'Cold' : 'Hot'})`,
		},
		{
			dataIndex: 'value2',
			key: 'value2',
			title: `Shell Side (${isPlateSideLiquid ? 'Hot' : 'Cold'})`,
		},
	]

	//====================================MEDIUM DATA===============================
	const {
		mediumLiquidSide,
		mediumVaporSide,
		mediumSourceVaporSide,
		mediumSourceLiquidSide,
		mediumVaporSideOtherMedia,
		mediumLiquidSideOtherMedia,
		cboGravi,
		cboSpH,
		cboCdy,
		dynamicViscosityLiquidSide,
		specificGravityLiquidSide,
		specificHeatLiquidSide,
		thermalConductivityLiquidSide,
		saturatedPressure,
		cboVisco,
		concentrationLiquidSide,
		liquidSidePhase,
		gasPressureOnLiquidSide,
		cboP,
	} = heatExchangerData
	const mediumData: MediumData[] = [
		{
			name: 'Dynamic viscosity',
			unit: dynamicViscoUnitLabels[cboVisco],
			value: dynamicViscosityLiquidSide,
		},
		{
			name: 'Density',
			unit: specificGravityUnitLables[cboGravi],
			value: specificGravityLiquidSide,
		},
		{
			name: 'Specific heat',
			unit: specificHeatUnitLables[cboSpH],
			value: specificHeatLiquidSide,
		},
		{
			name: 'Thermal conductivity',
			unit: thermalConductivityUnitLabels[cboCdy],
			value: thermalConductivityLiquidSide,
		},
	]

	const mediumDataWithKey = mediumData.map((item, index) => ({
		key: index,
		...item,
	}))

	const mediumNameVaporSide =
		mediumSourceVaporSide != MediumSource.Imported
			? mediumLabels[mediumVaporSide]
			: mediumVaporSideOtherMedia

	const mediumNameLiquid = getMediumName({
		mediumSource: mediumSourceLiquidSide,
		mediumName: mediumLiquidSide,
		mediumNameOtherMedia: mediumLiquidSideOtherMedia,
		concentration: concentrationLiquidSide,
	})

	const mediumNameLiquidSide =
		liquidSidePhase === LiquidSidePhase.Gas
			? mediumNameLiquid +
			  ` (${gasPressureOnLiquidSide.toFixed(2)} ${pressureUnitLabels[cboP]})`
			: mediumNameLiquid

	const mediumDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: isPlateSideLiquid ? 'value' : 'value2',
			key: isPlateSideLiquid ? 'value' : 'value2',
			title: isPlateSideLiquid
				? mediumNameLiquidSide
				: mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`,
		},
		{
			dataIndex: isPlateSideLiquid ? 'value2' : 'value',
			key: isPlateSideLiquid ? 'value2' : 'value',
			title: isPlateSideLiquid
				? mediumNameVaporSide +
				  ` (${saturatedPressure.toFixed(2)} ${pressureUnitLabels[cboP]})`
				: mediumNameLiquidSide,
		},
	]
	//====================================CONSTRUCTION DATA===============================
	const {
		endPlateType,
		cboMass,
		massOfHeatExchanger,
		position,
		typeApproval,
		designPressure,
		designPressureOnDataSheet,
		designCode,
		designTemperatureMin,
		designTemperatureMax,
		feet,
		designPressurePlateSide,
		designPressureUnitOnDataSheet,
	} = heatExchangerData
	const constructionData = [
		{
			name: 'End Type',
			value: endPlateTypeLabels[endPlateType],
			value2: dataText?.constructionRow1Text,
		},
		{
			name: 'Weight (dry)',
			unit: massUnitLabels[cboMass],
			value: massOfHeatExchanger.toFixed(0),
			value2: dataText?.constructionRow2Text,
		},
		{
			name: 'Position',
			value: positionLabels[position],
			value2: dataText?.constructionRow3Text,
		},
		{
			name: 'Design code',
			value: designCode || typeApporvalLabels[typeApproval],
			value2: dataText?.constructionRow4Text,
		},
		{
			name:
				dataText?.constructionRow5Text != dataText?.constructionRow6Text
					? 'Shell design pressure'
					: 'Design pressure',
			unit: designPressureOnDataSheet
				? designPressureUnitLabels[designPressureUnitOnDataSheet]
				: getDesignPressureUnit(designPressure),
			value: designPressureOnDataSheet || dataText?.constructionRow5Text,
		},
	]
	if (dataText?.constructionRow5Text != dataText?.constructionRow6Text)
		constructionData.push({
			name: 'Plate design pressure',
			unit: getDesignPressureUnit(designPressure),
			value: dataText?.constructionRow6Text || designPressurePlateSide,
		})
	constructionData.push(
		{
			name: 'Design temperature',
			unit: temUnitLabels[cboT],
			value: `${designTemperatureMin}/${designTemperatureMax}`,
			value2: dataText?.constructionRow7Text,
		},
		{
			name: 'Support',
			value: feetLabels[feet],
			value2: dataText?.constructionRow8Text,
		},
	)

	const constructionDataWithKey = constructionData.map((item, index) => ({
		key: index,
		...item,
	}))

	const constructionDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
			title: 'Medium',
		},
		{
			dataIndex: 'unit',
			key: 'unit',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
		{
			dataIndex: 'value2',
			key: 'value2',
		},
	]

	//====================================MATERIALS DATA===============================
	const materialsData = [
		{
			name: 'Plate material',
			value:
				plateMaterialLabels[heatExchangerData.plateMaterial] +
				' ' +
				plateThicknessLabels[heatExchangerData.plateThickness],
		},
		{
			name: 'Shell material',
			value: getShellMaterial(
				endPlateType,
				shellMaterialLabels[heatExchangerData.shellMaterial],
			),
		},
		{
			name: 'Flow director material',
			value:
				heatExchangerData.condenserType === CondenserType.Normal &&
				heatExchangerData.flowDirectorMaterial === FlowDirectorMaterial.EPDM
					? `${
							flowDirectorMaterialLabels[heatExchangerData.flowDirectorMaterial]
					  } (short)`
					: flowDirectorMaterialLabels[heatExchangerData.flowDirectorMaterial],
		},
		{
			name: 'Plate connections',
			value: dataText?.materialsPlateConnections,
		},
		{
			name: 'Shell connections IN',
			value: dataText?.materialsShellConnectionsIn,
		},
		{
			name: 'Shell connections OUT',
			value: dataText?.materialsShellConnectionsOut,
		},
		{
			name: 'Surface treatment',
			value: paintingLabels[heatExchangerData.paint],
		},
		// {
		// 	name: 'Finishing (Shell externally)',
		// },
		// {
		// 	name: '- Stainless steel parts',
		// 	value: 'Pearl blasting',
		// },
		// {
		// 	name: '- Carbon steel parts',
		// 	value: paintingLabels[heatExchangerData.paint],
		// },
	]

	const materialsDataWithKey = materialsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const materialsDataColumns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'value',
			key: 'value',
		},
	]

	//====================================CONNECTIONS DATA===============================
	const isVentCondenser =
		heatExchangerData.condenserType === CondenserType.Vent_Condenser
	const connectionsData: ConnectionsData[] = [
		{
			name: 'Plate side IN',
			size: +heatExchangerData.nozzlePlateSide,
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlate,
		},
		{
			name: 'Plate side OUT',
			size: +heatExchangerData.nozzlePlateSide,
			numberOfPieces: getNumberOfPlateNozzle(
				heatExchangerData.numberOfPlateNozzles,
			),
			velocity: heatExchangerData.velocityPlateOut,
		},
		{
			name: 'Shell side IN',
			size: heatExchangerData.nozzleShell,
			numberOfPieces: heatExchangerData.numberOfShellNozzles,
			velocity: heatExchangerData.velocityShell,
		},
		{
			name: 'Shell side OUT',
			size: !isVentCondenser
				? heatExchangerData.nozzleShellOut
				: `
			${precise(heatExchangerData.nozzleShellOutUp)} \\ 
			${precise(heatExchangerData.nozzleShellOut)} (u\\d)`,
			numberOfPieces: !isVentCondenser
				? heatExchangerData.numberOfShellNozzlesOut
				: `
			${precise(heatExchangerData.numberOfShellNozzlesUp)} \\ 
			${precise(heatExchangerData.numberOfShellNozzlesOut)} (u\\d)`,
			velocity: !isVentCondenser
				? heatExchangerData.velocityShellOut
				: `
			${precise(heatExchangerData.velocityShellOutUp)} \\ 
			${precise(heatExchangerData.velocityShellOut)} (u\\d)`,
		},
	]

	const connectionDataWithKey = connectionsData.map((item, index) => ({
		key: index,
		...item,
	}))

	const connectionDataColumns = [
		{
			title: 'Connections',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Size (DN)',
			dataIndex: 'size',
			key: 'size',
		},
		{
			title: 'Nb of pieces',
			dataIndex: 'numberOfPieces',
			key: 'numberOfPieces',
		},
		{
			title: 'Velocity',
			dataIndex: 'velocity',
			key: 'velocity',
		},
	]

	return {
		appTitle,
		thermalDesignDataWithKey,
		thermalDesignColumns,
		plateShellDataWithKey,
		plateShellColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	}
}
