import React from 'react'
import logo from '../assets/imgs/vahterus-logo.png'
import Button from '../components/Button'
import {useAuth0} from '@auth0/auth0-react'
import {useLocation} from 'react-router-dom'
import {Alert} from 'antd'

const Login = () => {
	const search = useLocation().search
	const error = new URLSearchParams(search).get('error')
	const errorDescription = new URLSearchParams(search).get('error_description')
	const {loginWithRedirect} = useAuth0()
	const renderErrorAlert = () => {
		if (!error && !errorDescription) {
			return null
		}

		return (
			<Alert
				style={{marginTop: '15px'}}
				message={`error`}
				description={errorDescription}
				type="error"
				banner={true}
				closable
			/>
		)
	}
	return (
		<div className="login-page">
			<div className="login">
				<div className="login__logo">
					<img src={logo} height={30} alt="logo" />
				</div>
				<h1 className="login__heading">Sales Tool</h1>
				<Button className="login__button" onClick={() => loginWithRedirect()}>
					Log in
				</Button>
				{renderErrorAlert()}
			</div>
		</div>
	)
}

export default Login
