import {useQuery, useMutation, queryCache} from 'react-query'
import {client, parsePatchBody} from '../utils/apiClient'
import Position, {
	AdditionalItem,
	PositionForCreation,
	PositionForDuplication,
} from '../model/Position'
import queryString from 'query-string'

const defaultMutationOptions = {
	onError: (err, variables, recover) =>
		typeof recover === 'function' ? recover() : null,
	onSettled: () => {
		queryCache.invalidateQueries(['offers'])
		queryCache.invalidateQueries('positions')
	},
}

export function usePositions(offerId: number, options?) {
	const {query, ...configOptions} = options
	const queryText = queryString.stringify(query)
	return useQuery<Position[], Error>({
		queryKey: ['positions', {offerId}],
		queryFn: () => client(`offers/${offerId}/positions?${queryText}`),
		config: {...configOptions},
	})
}

export function useDuplicatePosition(options?) {
	return useMutation<
		Position,
		Error,
		{
			offerId: number
			duplicatedPositionId: string
			position: PositionForDuplication
		}
	>(
		postData =>
			client(
				`offers/${postData.offerId}/positions/${postData.duplicatedPositionId}/duplicate`,
				{data: postData.position},
			),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

export function usePosition(offerId: number, positionId: number, options?) {
	const {query, ...configOptions} = options
	const queryText = queryString.stringify(query)
	return useQuery<Position, Error>({
		queryKey: ['position', {positionId}],
		queryFn: () =>
			client(`offers/${offerId}/positions/${positionId}?${queryText}`),
		config: {...configOptions},
	})
}

function onUpdateMutation(offerId: number, newItem) {
	const queryKey = ['positions', {offerId}]
	const previousItems = queryCache.getQueryData(queryKey)

	queryCache.setQueryData<Position[], Error>(queryKey, old => {
		return old.map(item => {
			return item.id === newItem.id ? {...item, ...newItem} : item
		})
	})

	return () => queryCache.setQueryData(queryKey, previousItems)
}

export function useUpdatePosition(offerId, options?) {
	const {isPatchUpdate, ...mutateOptions} = options
	return useMutation<
		{
			positionId?: string | number
			extras?: string
			parts?: string
			additionalItems?: AdditionalItem[]
			name?: string
			itemNo?: string
			discount?: number
			calculationsForDatasheetDbString?: string
		},
		Error,
		{
			positionId?: string | number
			extras?: string
			parts?: string
			additionalItems?: AdditionalItem[]
			name?: string
			itemNo?: string
			discount?: number
			calculationsForDatasheetDbString?: string
		}
	>(
		updates =>
			client(`offers/${offerId}/positions/${updates.positionId}`, {
				method: isPatchUpdate ? 'PATCH' : 'PUT',
				data: isPatchUpdate ? parsePatchBody(updates) : updates,
			}),
		{
			onMutate: newItem => onUpdateMutation(+offerId, newItem),
			...defaultMutationOptions,
			...mutateOptions,
		},
	)
}

export function useUpdatePricingCalculation(
	offerId: number,
	positionId: number,
	options?,
) {
	return useMutation<
		{id: number; heatExchangerId: number; heatExchangerType: number},
		Error,
		{id: number; heatExchangerId: number; heatExchangerType: number}
	>(
		data =>
			client(`offers/${offerId}/positions/${positionId}/pricingCalculation`, {
				method: 'PUT',
				data,
			}),
		{
			onMutate: newItem => onUpdateMutation(offerId, newItem),
			...defaultMutationOptions,
			...options,
		},
	)
}

export function useCreatePosition(options?) {
	return useMutation<
		Position,
		Error,
		{offerId: number; position: PositionForCreation}
	>(
		postData =>
			client(`offers/${postData.offerId}/positions`, {data: postData.position}),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}

export function useDeletePosition(offerId, options?) {
	return useMutation<Position, Error, Position>(
		position =>
			client(`offers/${offerId}/positions/${position.id}`, {
				method: 'DELETE',
			}),
		{
			...defaultMutationOptions,
			...options,
		},
	)
}
