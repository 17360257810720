import React from 'react'
import {Spin} from 'antd'

const FullPageLoading = () => {
	return (
		<div className="full-page-loading">
			<Spin size="large" style={{color: 'red'}} />
		</div>
	)
}

export default FullPageLoading
