import React from 'react'
import {client} from '../../utils/apiClient'
import Button from '../../components/Button'
import {FileExcelOutlined} from '@ant-design/icons'
import {Menu} from 'antd'
import {saveAs} from 'file-saver'
import {useHeatExchanger} from '../../state/heatExchanger'
import {Calculation} from '../../model/Calculation'
import {Circulation, EvaporatorType} from '../../model/Evaporator'
import FullPageLoading from '../FullPageLoading'
import {
	ApplicationType,
	CondenserType,
	HotSide,
	LiquidSide,
	MultiPassCombination,
} from '../../model/CalculationEnums'
import {
	formatDate,
	isInfinitiveNumber,
	precise,
	toFixed,
} from '../../utils/utils'
import {getOnePhaseData} from './onePhaseData'
import {getCondenserData} from './condenserData'
import {getEvaporatorData} from './evaporatorData'
import {getCascadeData} from './cascadeData'
import {
	getMediumPropertiesData,
	getPartialCascadeData,
} from './partialCascadeData'
import Offer, {MFileInfo} from '../../model/Offer'
import {useCalculationDataText} from '../../state/calculations'
import {usePosition} from '../../state/position'
import {TFunction, useTranslation} from 'react-i18next'
import {Datatext} from '../../model/DataSheet'
import Position from '../../model/Position'
import {getAllMultiCondenserData} from './multiCondenserData'

interface Props {
	calculation: Calculation
	offerDetail: Offer
	mFilesInfo: MFileInfo
	exportId?: string
	positionId: string
	key: number
}

const getDataFunc = (applicationType: ApplicationType) => {
	switch (applicationType) {
		case ApplicationType.OnePhase:
			return getOnePhaseData
		case ApplicationType.Condenser:
			return getCondenserData
		case ApplicationType.Evaporators:
			return getEvaporatorData
		case ApplicationType.Cascade:
			return getCascadeData
		case ApplicationType.PartialCascade:
			return getPartialCascadeData
		default:
			return getOnePhaseData
	}
}

const getTitleFunc = (t, heatExchangerData, dataText) => {
	const {appTitle} = getDataFunc(heatExchangerData.application)(
		heatExchangerData,
		dataText,
	)
	return t(`datasheet.header.appTitle.${appTitle}`)
}

export const requestExcelFromServer = (application, data, exportId) => {
	const route =
		application == ApplicationType.MultiCondenser
			? 'multi-condenser-excel'
			: 'excel'
	client(route, {data: data, responseType: 'blob'})
		.then(blob => {
			saveAs(
				blob,
				`${data.header.infoTable.offerNumber.value}-${exportId}.xlsx`,
			)
		})
		.catch(error => console.log(error))
}

const shouldBeTranslated = (
	t: TFunction<'translation', undefined>,
	text: string,
	translatePrefix: string,
) => {
	if (typeof text === 'string') {
		if (text.includes('.') || text.includes(':')) {
			return text
		}
	}
	const translatedText = t(`${translatePrefix}.${text}`)
	return translatedText.includes('datasheet.table') ? text : translatedText
}

// 'normal case' all applications that are NOT Multi-Condenser
export const exportNormalCaseToExcel = (
	heatExchangerData,
	isHeatExchangerLoading: boolean,
	isDataTextLoading: boolean,
	isPositionLoading: boolean,
	isHeatExchangerError: boolean,
	isDataTextError: boolean,
	isPositionError: boolean,
	offerDetail: Offer,
	mFilesInfo: MFileInfo,
	position: Position,
	dataText: Datatext,
	t: TFunction<'translation', undefined>,
) => {
	if (isHeatExchangerLoading || isDataTextLoading || isPositionLoading) {
		return <FullPageLoading />
	}

	if (isHeatExchangerError || isDataTextError || isPositionError) {
		return <p>There is an error while fetching the datasheet</p>
	}

	const appTitle = getTitleFunc(t, heatExchangerData, dataText)

	const getFlowDirectionLabel = flowDir => {
		if (flowDir === 0) {
			return t('datasheet.table.data.Countercurrent')
		}
		if (flowDir === 1) {
			return t('datasheet.table.data.Cocurrent')
		}
		if (flowDir === 2) {
			return t('datasheet.table.data.Crossflow')
		}
	}

	const calculationType = `PSHE ${heatExchangerData.psheType}`

	const {application, arrowsPlateSide, arrowsShellSide} = heatExchangerData
	const flowDirection = getFlowDirectionLabel(heatExchangerData.flowDirection)

	const getArrowsDrawingData = () => {
		switch (application) {
			case ApplicationType.OnePhase: {
				const isHotSidePlate = heatExchangerData.hotSide === HotSide.PlateSide
				return {
					endPlateType: heatExchangerData.endPlateType,
					shellDiameter: `Shell Φ${heatExchangerData.shellDiameter}`,
					flowDirection: flowDirection,
					circulation: '',
					evaporatorType: '',
					consdenserType: '',
					applicationType: application,
					isPlateHotside: isHotSidePlate ? 1 : 0,
					arrowsPlateSide: arrowsPlateSide,
					arrowsShellSide: arrowsShellSide,
					numberOfInlet: 0,
					numberOfOutlet: 0,
					numberOfOutletUp: 0,
					combinedC: 0,
				}
			}

			case ApplicationType.Condenser: {
				const isPlateSideLiquid =
					heatExchangerData.liquidSide === LiquidSide.PlateSide

				if (heatExchangerData.condenserType === CondenserType.Vent_Condenser) {
					return {
						endPlateType: heatExchangerData.endPlateType,
						shellDiameter: `Shell Φ${heatExchangerData.shellDiameter}`,
						flowDirection: flowDirection,
						circulation: '',
						evaporatorType: '',
						condenserType: heatExchangerData.condenserType,
						applicationType: application,
						isPlateHotside: !isPlateSideLiquid ? 1 : 0,
						arrowsPlateSide: arrowsPlateSide,
						arrowsShellSide: '',
						numberOfInlet: heatExchangerData.numberOfShellNozzles,
						numberOfOutlet: heatExchangerData.numberOfShellNozzlesOut,
						numberOfOutletUp: heatExchangerData.numberOfShellNozzlesUp,
						combinedC: 0,
					}
				}

				return {
					endPlateType: heatExchangerData.endPlateType,
					shellDiameter: `Shell Φ${heatExchangerData.shellDiameter}`,
					flowDirection: flowDirection,
					circulation: '',
					evaporatorType: '',
					condenserType: heatExchangerData.condenserType,
					applicationType: application,
					isPlateHotside: !isPlateSideLiquid ? 1 : 0,
					arrowsPlateSide: arrowsPlateSide,
					arrowsShellSide: arrowsShellSide,
					numberOfInlet: 0,
					numberOfOutlet: 0,
					numberOfOutletUp: 0,
					combinedC: 0,
				}
			}

			case ApplicationType.Evaporators: {
				const {circulation, combinedC} = heatExchangerData
				const isHotSidePlate =
					heatExchangerData.evaporatorType === EvaporatorType.FloodedEvaporator

				if (circulation === Circulation.Combined) {
					return {
						endPlateType: heatExchangerData.endPlateType,
						shellDiameter: `Shell Φ${heatExchangerData.shellDiameter}`,
						flowDirection: flowDirection,
						circulation: circulation,
						evaporatorType: heatExchangerData.evaporatorType,
						condenserType: '',
						applicationType: application,
						isPlateHotside: !isHotSidePlate ? 1 : 0,
						arrowsPlateSide: arrowsPlateSide,
						arrowsShellSide: '',
						numberOfInlet: heatExchangerData.numberOfShellNozzles,
						numberOfOutlet: heatExchangerData.numberOfShellNozzlesOut,
						numberOfOutletUp: 0,
						combinedC: combinedC ? 1 : 0,
					}
				}
				return {
					endPlateType: heatExchangerData.endPlateType,
					shellDiameter: `Shell Φ${heatExchangerData.shellDiameter}`,
					flowDirection: flowDirection,
					circulation: circulation,
					evaporatorType: heatExchangerData.evaporatorType,
					condenserType: '',
					applicationType: application,
					isPlateHotside: isHotSidePlate ? 1 : 0,
					arrowsPlateSide: arrowsPlateSide,
					arrowsShellSide: arrowsShellSide,
					numberOfInlet: 0,
					numberOfOutlet: 0,
					numberOfOutletUp: 0,
					combinedC: 0,
				}
			}

			case ApplicationType.Cascade: {
				const isHotSidePlate =
					heatExchangerData.evaporatorType === EvaporatorType.FloodedEvaporator

				const {circulation, combinedC} = heatExchangerData
				if (circulation === Circulation.Combined) {
					return {
						endPlateType: heatExchangerData.endPlateType,
						shellDiameter: `Shell Φ${heatExchangerData.shellDiameter}`,
						flowDirection: flowDirection,
						circulation: circulation,
						evaporatorType: heatExchangerData.evaporatorType,
						condenserType: '',
						applicationType: application,
						isPlateHotside: isHotSidePlate ? 1 : 0,
						arrowsPlateSide: arrowsPlateSide,
						arrowsShellSide: '',
						numberOfInlet: heatExchangerData.numberOfShellNozzles,
						numberOfOutlet: heatExchangerData.numberOfShellNozzlesOut,
						numberOfOutletUp: 0,
						combinedC: combinedC ? 1 : 0,
					}
				}
				return {
					endPlateType: heatExchangerData.endPlateType,
					shellDiameter: `Shell Φ${heatExchangerData.shellDiameter}`,
					flowDirection: flowDirection,
					circulation: circulation,
					evaporatorType: heatExchangerData.evaporatorType,
					condenserType: '',
					applicationType: application,
					isPlateHotside: isHotSidePlate ? 1 : 0,
					arrowsPlateSide: arrowsPlateSide,
					arrowsShellSide: arrowsShellSide,
					numberOfInlet: 0,
					numberOfOutlet: 0,
					numberOfOutletUp: 0,
					combinedC: 0,
				}
			}

			case ApplicationType.PartialCascade: {
				const isHotSidePlate = heatExchangerData.hotSide === HotSide.PlateSide
				return {
					endPlateType: heatExchangerData.endPlateType,
					shellDiameter: `Shell Φ${heatExchangerData.shellDiameter}`,
					flowDirection: flowDirection,
					circulation: '',
					evaporatorType: '',
					condenserType: '',
					applicationType: application,
					isPlateHotside: isHotSidePlate ? 1 : 0,
					arrowsPlateSide: arrowsPlateSide,
					arrowsShellSide: arrowsShellSide,
					numberOfInlet: 0,
					numberOfOutlet: 0,
					numberOfOutletUp: 0,
					combinedC: 0,
				}
			}
		}
	}

	const {reference, projectName} = offerDetail
	const {responsiblePersonName, companyName} = mFilesInfo
	const {itemNo, name} = position

	const {
		thermalDesignDataWithKey,
		thermalDesignColumns,
		plateShellDataWithKey,
		plateShellColumns,
		mediumDataWithKey,
		mediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
	} = getDataFunc(heatExchangerData.application)(heatExchangerData, dataText)

	const thermalDesignData = [...thermalDesignDataWithKey]

	thermalDesignDataWithKey.map((data, index) =>
		thermalDesignColumns.map(
			column =>
				(thermalDesignData[index][column.dataIndex] = shouldBeTranslated(
					t,
					data[column.dataIndex],
					'datasheet.table.data',
				)),
		),
	)

	const plateShellData = [...plateShellDataWithKey]

	plateShellDataWithKey.map((data, index) =>
		plateShellColumns.map(
			column =>
				(plateShellData[index][column.dataIndex] = shouldBeTranslated(
					t,
					toFixed(data[column.dataIndex]),
					'datasheet.table.data',
				)),
		),
	)

	const plateShellColumnsTranslated = [...plateShellColumns]

	plateShellColumns.map(
		(column, index) =>
			(plateShellColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	const mediumData = [...mediumDataWithKey]

	mediumDataWithKey.map((data, index) =>
		mediumDataColumns.map(
			column =>
				(mediumData[index][column.dataIndex] = shouldBeTranslated(
					t,
					precise(data[column.dataIndex], 4),
					'datasheet.table.data',
				)),
		),
	)

	const mediumDataColumnsTranslated = [...mediumDataColumns]

	mediumDataColumns.map(
		(column, index) =>
			(mediumDataColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	const connectionData = [...connectionDataWithKey]

	connectionDataWithKey.map((data, index) =>
		connectionDataColumns.map(
			column =>
				(connectionData[index][column.dataIndex] = shouldBeTranslated(
					t,
					precise(data[column.dataIndex]),
					'datasheet.table.data',
				)),
		),
	)

	const connectionDataColumnsTranslated = [...connectionDataColumns]

	connectionDataColumns.map(
		(column, index) =>
			(connectionDataColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	const constructionData = [...constructionDataWithKey]

	constructionDataWithKey.map((data, index) =>
		constructionDataColumns.map(
			column =>
				(constructionData[index][column.dataIndex] = shouldBeTranslated(
					t,
					data[column.dataIndex],
					'datasheet.table.data',
				)),
		),
	)

	const materialsData = [...materialsDataWithKey]

	materialsDataWithKey.map((data, index) =>
		materialsDataColumns.map(
			column =>
				(materialsData[index][column.dataIndex] = shouldBeTranslated(
					t,
					data[column.dataIndex],
					'datasheet.table.data',
				)),
		),
	)

	const {
		mediumPropertiesHotLiqDataWithKey,
		mediumPropertiesHotLiqDataColumns,
		mediumPropertiesHotVapDataWithKey,
		mediumPropertiesHotVapDataColumns,
		mediumPropertiesColdLiqDataWithKey,
		mediumPropertiesColdLiqDataColumns,
		mediumPropertiesColdVapDataWithKey,
		mediumPropertiesColdVapDataColumns,
	} = getMediumPropertiesData(heatExchangerData)

	const mediumPropertiesHotLiqData = [...mediumPropertiesHotLiqDataWithKey]

	mediumPropertiesHotLiqDataWithKey.map((data, index) =>
		mediumPropertiesHotLiqDataColumns.map(
			column =>
				(mediumPropertiesHotLiqData[index][column.dataIndex] =
					!isInfinitiveNumber(data[column.dataIndex])
						? shouldBeTranslated(
								t,
								precise(data[column.dataIndex]),
								'datasheet.table.data',
						  )
						: null),
		),
	)

	const mediumPropertiesHotLiqDataColumnsTranslated = [
		...mediumPropertiesHotLiqDataColumns,
	]

	mediumPropertiesHotLiqDataColumns.map(
		(column, index) =>
			(mediumPropertiesHotLiqDataColumnsTranslated[index].title =
				shouldBeTranslated(t, column.title, 'datasheet.table.data')),
	)

	const mediumPropertiesHotVapData = [...mediumPropertiesHotVapDataWithKey]

	mediumPropertiesHotVapDataWithKey.map((data, index) =>
		mediumPropertiesHotVapDataColumns.map(
			column =>
				(mediumPropertiesHotVapData[index][column.dataIndex] =
					!isInfinitiveNumber(data[column.dataIndex])
						? shouldBeTranslated(
								t,
								precise(data[column.dataIndex]),
								'datasheet.table.data',
						  )
						: null),
		),
	)

	const mediumPropertiesHotVapDataColumnsTranslated = [
		...mediumPropertiesHotVapDataColumns,
	]

	mediumPropertiesHotVapDataColumns.map(
		(column, index) =>
			(mediumPropertiesHotVapDataColumnsTranslated[index].title =
				shouldBeTranslated(t, column.title, 'datasheet.table.data')),
	)

	const mediumPropertiesColdVapData = [...mediumPropertiesColdVapDataWithKey]

	mediumPropertiesColdVapDataWithKey.map((data, index) =>
		mediumPropertiesColdVapDataColumns.map(
			column =>
				(mediumPropertiesColdVapData[index][column.dataIndex] =
					!isInfinitiveNumber(data[column.dataIndex])
						? shouldBeTranslated(
								t,
								precise(data[column.dataIndex]),
								'datasheet.table.data',
						  )
						: null),
		),
	)

	const mediumPropertiesColdVapDataColumnsTranslated = [
		...mediumPropertiesColdVapDataColumns,
	]

	mediumPropertiesColdVapDataColumns.map(
		(column, index) =>
			(mediumPropertiesColdVapDataColumnsTranslated[index].title =
				shouldBeTranslated(t, column.title, 'datasheet.table.data')),
	)

	const mediumPropertiesColdLiqData = [...mediumPropertiesColdLiqDataWithKey]

	mediumPropertiesColdLiqDataWithKey.map((data, index) =>
		mediumPropertiesColdLiqDataColumns.map(
			column =>
				(mediumPropertiesColdLiqData[index][column.dataIndex] =
					!isInfinitiveNumber(data[column.dataIndex])
						? shouldBeTranslated(
								t,
								precise(data[column.dataIndex]),
								'datasheet.table.data',
						  )
						: null),
		),
	)

	const mediumPropertiesColdLiqDataColumnsTranslated = [
		...mediumPropertiesColdLiqDataColumns,
	]

	mediumPropertiesColdLiqDataColumns.map(
		(column, index) =>
			(mediumPropertiesColdLiqDataColumnsTranslated[index].title =
				shouldBeTranslated(t, column.title, 'datasheet.table.data')),
	)

	const payload = {
		header: {
			title: t('datasheet.header.title'),
			applicationType: appTitle,
			sizingVersion: heatExchangerData.sizingVersion,
			infoTable: {
				date: {
					key: t('datasheet.infoTable.date'),
					value: formatDate(new Date(), false),
				},
				offerNumber: {
					key: t('datasheet.infoTable.offerNumber'),
					value: name,
				},
				madeBy: {
					key: t('datasheet.infoTable.madeBy'),
					value: responsiblePersonName,
				},
				customer: {
					key: t('datasheet.infoTable.customer'),
					value: companyName,
				},
				reference: {
					key: t('datasheet.infoTable.reference'),
					value: reference,
				},
				project: {
					key: t('datasheet.infoTable.project'),
					value: projectName,
				},
				itemNo: {
					key: t('datasheet.infoTable.itemNameNo'),
					value: itemNo,
				},
				calculationType: {
					key: `${t('datasheet.infoTable.type')} ${calculationType}`,
				},
			},
		},
		footer: {
			phone: t('datasheet.footer.phone'),
			fax: t('datasheet.footer.fax'),
			email: t('datasheet.footer.email'),
			web: t('datasheet.footer.web'),
		},
		datasheetContent: {
			thermalDesignHeading: t('datasheet.table.heading.thermalDesign'),
			thermalDesignData: thermalDesignData,
			plateShellColumnsTranslated: plateShellColumnsTranslated,
			plateShellData: plateShellData,
			mediumDataColumnsTranslated: mediumDataColumnsTranslated,
			mediumData: mediumData,
			connectionDataColumnsTranslated: connectionDataColumnsTranslated,
			connectionData: connectionData,
			constructionHeading: t('datasheet.table.heading.constructionDesign'),
			constructionData: constructionData,
			materialHeading: t('datasheet.table.heading.materials'),
			materialData: materialsData,
		},
		datasheetExpandedPropertiesContent: {
			mediumPropertiesHotLiqData: mediumPropertiesHotLiqData,
			mediumPropertiesHotLiqDataColumnsTranslated:
				mediumPropertiesHotLiqDataColumnsTranslated,
			mediumPropertiesHotVapData: mediumPropertiesHotVapData,
			mediumPropertiesHotVapDataColumnsTranslated:
				mediumPropertiesHotVapDataColumnsTranslated,
			mediumPropertiesColdLiqData: mediumPropertiesColdLiqData,
			mediumPropertiesColdLiqDataColumnsTranslated:
				mediumPropertiesColdLiqDataColumnsTranslated,
			mediumPropertiesColdVapData: mediumPropertiesColdVapData,
			mediumPropertiesColdVapDataColumnsTranslated:
				mediumPropertiesColdVapDataColumnsTranslated,
		},
		drawing: {...getArrowsDrawingData()},
	}
	return payload
}

export const exportMultiCondenserToExcel = (
	heatExchangerData,
	isHeatExchangerLoading: boolean,
	isDataTextLoading: boolean,
	isPositionLoading: boolean,
	isHeatExchangerError: boolean,
	isDataTextError: boolean,
	isPositionError: boolean,
	offerDetail: Offer,
	mFilesInfo: MFileInfo,
	position: Position,
	dataText: Datatext,
	t: TFunction<'translation', undefined>,
) => {
	if (isHeatExchangerLoading || isDataTextLoading || isPositionLoading) {
		return <FullPageLoading />
	}

	if (isHeatExchangerError || isDataTextError || isPositionError) {
		return <p>There is an error while fetching the datasheet</p>
	}

	const {
		appTitle,
		headerData,
		modelThermalDesignDataWithKey,
		modelThermalDesignColumns,
		desupThermalDesignDataWithKey,
		desupThermalDesignColumns,
		desupPlateShellDataWithKey,
		desupPlateShellColumns,
		desupMediumDataWithKey,
		desupmediumDataColumns,
		condThermalDesignDataWithKey,
		condThermalDesignColumns,
		condPlateShellDataWithKey,
		condPlateShellColumns,
		condMediumDataWithKey,
		condmediumDataColumns,
		subcoolerThermalDesignDataWithKey,
		subcoolerThermalDesignColumns,
		subcoolerPlateShellDataWithKey,
		subcoolerPlateShellColumns,
		subcoolerMediumDataWithKey,
		subcoolermediumDataColumns,
		constructionDataWithKey,
		constructionDataColumns,
		materialsDataWithKey,
		materialsDataColumns,
		connectionDataWithKey,
		connectionDataColumns,
		liquidSideMultiCondenser,
		multiPassCombo,
		shellDiam,
	} = getAllMultiCondenserData(heatExchangerData, dataText)

	const getFlowDirectionLabel = flowDir => {
		if (flowDir === 0) {
			return t('datasheet.table.data.Countercurrent')
		}
		if (flowDir === 1) {
			return t('datasheet.table.data.Cocurrent')
		}
		if (flowDir === 2) {
			return t('datasheet.table.data.Crossflow')
		}
	}

	// initialize calculationType
	let calculationType = ''
	// set it based on multiPassCombination
	if (
		heatExchangerData.multiPassCombination ==
			MultiPassCombination.DesupCondSubcWaterOnPlateSide ||
		heatExchangerData.multiPassCombination ==
			MultiPassCombination.DesupCondSubcWaterOnShellSide
	) {
		calculationType = `PSHE ${heatExchangerData.psheTypeCondenser} + ${heatExchangerData.psheTypeDesuperHeater} + ${heatExchangerData.psheTypeSubcooler}`
	}
	if (
		heatExchangerData.multiPassCombination ==
			MultiPassCombination.DesupCondWaterOnPlateSide ||
		heatExchangerData.multiPassCombination ==
			MultiPassCombination.DesupCondWaterOnShellSide
	) {
		calculationType = `PSHE ${heatExchangerData.psheTypeCondenser} + ${heatExchangerData.psheTypeDesuperHeater}`
	}
	if (
		heatExchangerData.multiPassCombination ==
			MultiPassCombination.CondSubcWaterOnPlateSide ||
		heatExchangerData.multiPassCombination ==
			MultiPassCombination.CondSubcWaterOnShellSide
	) {
		calculationType = `PSHE ${heatExchangerData.psheTypeCondenser} + ${heatExchangerData.psheTypeSubcooler}`
	}

	const flowDirection = getFlowDirectionLabel(heatExchangerData.flowDirection)

	const {reference, projectName} = offerDetail
	const {responsiblePersonName, companyName} = mFilesInfo
	const {itemNo, name} = position

	{
		/* MODEL */
	}
	const modelThermalDesignData = [...modelThermalDesignDataWithKey]

	modelThermalDesignDataWithKey.map((data, index) =>
		modelThermalDesignColumns.map(
			column =>
				(modelThermalDesignData[index][column.dataIndex] = shouldBeTranslated(
					t,
					toFixed(data[column.dataIndex]),
					'datasheet.table.data',
				)),
		),
	)

	{
		/* DESUPERHEATER */
	}
	const desupThermalDesignData = [...desupThermalDesignDataWithKey]

	desupThermalDesignDataWithKey.map((data, index) =>
		desupThermalDesignColumns.map(
			column =>
				(desupThermalDesignData[index][column.dataIndex] = shouldBeTranslated(
					t,
					toFixed(data[column.dataIndex]),
					'datasheet.table.data',
				)),
		),
	)
	const desupPlateShellData = [...desupPlateShellDataWithKey]

	desupPlateShellDataWithKey.map((data, index) =>
		desupPlateShellColumns.map(
			column =>
				(desupPlateShellData[index][column.dataIndex] = shouldBeTranslated(
					t,
					toFixed(data[column.dataIndex]),
					'datasheet.table.data',
				)),
		),
	)

	const desupPlateShellColumnsTranslated = [...desupPlateShellColumns]

	desupPlateShellColumns.map(
		(column, index) =>
			(desupPlateShellColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	const desupMediumData = [...desupMediumDataWithKey]

	desupMediumDataWithKey.map((data, index) =>
		desupmediumDataColumns.map(
			column =>
				(desupMediumData[index][column.dataIndex] = shouldBeTranslated(
					t,
					precise(data[column.dataIndex], 4),
					'datasheet.table.data',
				)),
		),
	)

	const desupMediumDataColumnsTranslated = [...desupmediumDataColumns]

	desupmediumDataColumns.map(
		(column, index) =>
			(desupMediumDataColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	{
		/* CONDENSER */
	}
	const condThermalDesignData = [...condThermalDesignDataWithKey]

	condThermalDesignDataWithKey.map((data, index) =>
		condThermalDesignColumns.map(
			column =>
				(condThermalDesignData[index][column.dataIndex] = shouldBeTranslated(
					t,
					toFixed(data[column.dataIndex]),
					'datasheet.table.data',
				)),
		),
	)

	const condPlateShellData = [...condPlateShellDataWithKey]

	condPlateShellDataWithKey.map((data, index) =>
		condPlateShellColumns.map(
			column =>
				(condPlateShellData[index][column.dataIndex] = shouldBeTranslated(
					t,
					toFixed(data[column.dataIndex]),
					'datasheet.table.data',
				)),
		),
	)

	const condPlateShellColumnsTranslated = [...condPlateShellColumns]

	condPlateShellColumns.map(
		(column, index) =>
			(condPlateShellColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	const condMediumData = [...condMediumDataWithKey]

	condMediumDataWithKey.map((data, index) =>
		condmediumDataColumns.map(
			column =>
				(condMediumData[index][column.dataIndex] = shouldBeTranslated(
					t,
					precise(data[column.dataIndex], 4),
					'datasheet.table.data',
				)),
		),
	)

	const condMediumDataColumnsTranslated = [...condmediumDataColumns]

	condmediumDataColumns.map(
		(column, index) =>
			(condMediumDataColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	{
		/* SUBCOOLER */
	}
	const subcoolerThermalDesignData = [...subcoolerThermalDesignDataWithKey]

	subcoolerThermalDesignDataWithKey.map((data, index) =>
		subcoolerThermalDesignColumns.map(
			column =>
				(subcoolerThermalDesignData[index][column.dataIndex] =
					shouldBeTranslated(
						t,
						toFixed(data[column.dataIndex]),
						'datasheet.table.data',
					)),
		),
	)

	const subcoolerPlateShellData = [...subcoolerPlateShellDataWithKey]

	subcoolerPlateShellDataWithKey.map((data, index) =>
		subcoolerPlateShellColumns.map(
			column =>
				(subcoolerPlateShellData[index][column.dataIndex] = shouldBeTranslated(
					t,
					toFixed(data[column.dataIndex]),
					'datasheet.table.data',
				)),
		),
	)

	const subcoolerPlateShellColumnsTranslated = [...subcoolerPlateShellColumns]

	subcoolerPlateShellColumns.map(
		(column, index) =>
			(subcoolerPlateShellColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	const subcoolerMediumData = [...subcoolerMediumDataWithKey]

	subcoolerMediumDataWithKey.map((data, index) =>
		subcoolermediumDataColumns.map(
			column =>
				(subcoolerMediumData[index][column.dataIndex] = shouldBeTranslated(
					t,
					precise(data[column.dataIndex], 4),
					'datasheet.table.data',
				)),
		),
	)

	const subcoolerMediumDataColumnsTranslated = [...subcoolermediumDataColumns]

	subcoolermediumDataColumns.map(
		(column, index) =>
			(subcoolerMediumDataColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	const connectionData = [...connectionDataWithKey]

	connectionDataWithKey.map((data, index) =>
		connectionDataColumns.map(
			column =>
				(connectionData[index][column.dataIndex] = shouldBeTranslated(
					t,
					precise(data[column.dataIndex]),
					'datasheet.table.data',
				)),
		),
	)

	const connectionDataColumnsTranslated = [...connectionDataColumns]

	connectionDataColumns.map(
		(column, index) =>
			(connectionDataColumnsTranslated[index].title = shouldBeTranslated(
				t,
				column.title,
				'datasheet.table.data',
			)),
	)

	const constructionData = [...constructionDataWithKey]

	constructionDataWithKey.map((data, index) =>
		constructionDataColumns.map(
			column =>
				(constructionData[index][column.dataIndex] = shouldBeTranslated(
					t,
					data[column.dataIndex],
					'datasheet.table.data',
				)),
		),
	)

	const materialsData = [...materialsDataWithKey]

	materialsDataWithKey.map((data, index) =>
		materialsDataColumns.map(
			column =>
				(materialsData[index][column.dataIndex] = shouldBeTranslated(
					t,
					data[column.dataIndex],
					'datasheet.table.data',
				)),
		),
	)

	const {
		mediumPropertiesHotLiqDataWithKey,
		mediumPropertiesHotLiqDataColumns,
		mediumPropertiesHotVapDataWithKey,
		mediumPropertiesHotVapDataColumns,
		mediumPropertiesColdLiqDataWithKey,
		mediumPropertiesColdLiqDataColumns,
		mediumPropertiesColdVapDataWithKey,
		mediumPropertiesColdVapDataColumns,
	} = getMediumPropertiesData(heatExchangerData)

	const mediumPropertiesHotLiqData = [...mediumPropertiesHotLiqDataWithKey]

	mediumPropertiesHotLiqDataWithKey.map((data, index) =>
		mediumPropertiesHotLiqDataColumns.map(
			column =>
				(mediumPropertiesHotLiqData[index][column.dataIndex] =
					!isInfinitiveNumber(data[column.dataIndex])
						? shouldBeTranslated(
								t,
								precise(data[column.dataIndex]),
								'datasheet.table.data',
						  )
						: null),
		),
	)

	const mediumPropertiesHotLiqDataColumnsTranslated = [
		...mediumPropertiesHotLiqDataColumns,
	]

	mediumPropertiesHotLiqDataColumns.map(
		(column, index) =>
			(mediumPropertiesHotLiqDataColumnsTranslated[index].title =
				shouldBeTranslated(t, column.title, 'datasheet.table.data')),
	)

	const mediumPropertiesHotVapData = [...mediumPropertiesHotVapDataWithKey]

	mediumPropertiesHotVapDataWithKey.map((data, index) =>
		mediumPropertiesHotVapDataColumns.map(
			column =>
				(mediumPropertiesHotVapData[index][column.dataIndex] =
					!isInfinitiveNumber(data[column.dataIndex])
						? shouldBeTranslated(
								t,
								precise(data[column.dataIndex]),
								'datasheet.table.data',
						  )
						: null),
		),
	)

	const mediumPropertiesHotVapDataColumnsTranslated = [
		...mediumPropertiesHotVapDataColumns,
	]

	mediumPropertiesHotVapDataColumns.map(
		(column, index) =>
			(mediumPropertiesHotVapDataColumnsTranslated[index].title =
				shouldBeTranslated(t, column.title, 'datasheet.table.data')),
	)

	const mediumPropertiesColdVapData = [...mediumPropertiesColdVapDataWithKey]

	mediumPropertiesColdVapDataWithKey.map((data, index) =>
		mediumPropertiesColdVapDataColumns.map(
			column =>
				(mediumPropertiesColdVapData[index][column.dataIndex] =
					!isInfinitiveNumber(data[column.dataIndex])
						? shouldBeTranslated(
								t,
								precise(data[column.dataIndex]),
								'datasheet.table.data',
						  )
						: null),
		),
	)

	const mediumPropertiesColdVapDataColumnsTranslated = [
		...mediumPropertiesColdVapDataColumns,
	]

	mediumPropertiesColdVapDataColumns.map(
		(column, index) =>
			(mediumPropertiesColdVapDataColumnsTranslated[index].title =
				shouldBeTranslated(t, column.title, 'datasheet.table.data')),
	)

	const mediumPropertiesColdLiqData = [...mediumPropertiesColdLiqDataWithKey]

	mediumPropertiesColdLiqDataWithKey.map((data, index) =>
		mediumPropertiesColdLiqDataColumns.map(
			column =>
				(mediumPropertiesColdLiqData[index][column.dataIndex] =
					!isInfinitiveNumber(data[column.dataIndex])
						? shouldBeTranslated(
								t,
								precise(data[column.dataIndex]),
								'datasheet.table.data',
						  )
						: null),
		),
	)

	const mediumPropertiesColdLiqDataColumnsTranslated = [
		...mediumPropertiesColdLiqDataColumns,
	]

	mediumPropertiesColdLiqDataColumns.map(
		(column, index) =>
			(mediumPropertiesColdLiqDataColumnsTranslated[index].title =
				shouldBeTranslated(t, column.title, 'datasheet.table.data')),
	)
	const isPlateSideLiquid =
		heatExchangerData.liquidSideMultiCondenser === LiquidSide.PlateSide

	const payload = {
		header: {
			title: t('datasheet.header.title'),
			applicationType: appTitle,
			sizingVersion: heatExchangerData.sizingVersion,
			condenserAppTitle: shouldBeTranslated(
				t,
				headerData.condenserAppTitle,
				'datasheet.header.appTitle',
			),
			desuperheaterAppTitle: shouldBeTranslated(
				t,
				headerData.desuperheaterAppTitle,
				'datasheet.header.appTitle',
			),
			subcoolerAppTitle: shouldBeTranslated(
				t,
				headerData.subcoolerAppTitle,
				'datasheet.header.appTitle',
			),
			condenserPsheType: `${t('datasheet.infoTable.type')} ${
				headerData.condenserPsheType
			}`,
			desuperheaterPsheType: `${t('datasheet.infoTable.type')} ${
				headerData.desuperheaterPsheType
			}`,
			subcoolerPsheType: `${t('datasheet.infoTable.type')} ${
				headerData.subcoolerPsheType
			}`,
			infoTable: {
				date: {
					key: t('datasheet.infoTable.date'),
					value: formatDate(new Date(), false),
				},
				offerNumber: {
					key: t('datasheet.infoTable.offerNumber'),
					value: name,
				},
				madeBy: {
					key: t('datasheet.infoTable.madeBy'),
					value: responsiblePersonName,
				},
				customer: {
					key: t('datasheet.infoTable.customer'),
					value: companyName,
				},
				reference: {
					key: t('datasheet.infoTable.reference'),
					value: reference,
				},
				project: {
					key: t('datasheet.infoTable.project'),
					value: projectName,
				},
				itemNo: {
					key: t('datasheet.infoTable.itemNameNo'),
					value: itemNo,
				},
				calculationType: {
					key: `${t('datasheet.infoTable.type')} ${calculationType}`,
				},
			},
		},
		footer: {
			phone: t('datasheet.footer.phone'),
			fax: t('datasheet.footer.fax'),
			email: t('datasheet.footer.email'),
			web: t('datasheet.footer.web'),
		},
		datasheetContent: {
			thermalDesignHeading: t('datasheet.table.heading.thermalDesign'),
			mediumHeading: t('datasheet.table.heading.medium'),
			mediumColumnHeading1: isPlateSideLiquid ? 'Cold' : 'Hot',
			mediumColumnHeading2: isPlateSideLiquid ? 'Hot' : 'Cold',
			modelThermalDesignData: modelThermalDesignData,
			desupThermalDesignData: desupThermalDesignData,
			condThermalDesignData: condThermalDesignData,
			subcoolerThermalDesignData: subcoolerThermalDesignData,
			desupPlateShellColumnsTranslated: desupPlateShellColumnsTranslated,
			condPlateShellColumnsTranslated: condPlateShellColumnsTranslated,
			subcoolerPlateShellColumnsTranslated:
				subcoolerPlateShellColumnsTranslated,
			desupPlateShellData: desupPlateShellData,
			condPlateShellData: condPlateShellData,
			subcoolerPlateShellData: subcoolerPlateShellData,
			desupMediumDataColumnsTranslated: desupMediumDataColumnsTranslated,
			condMediumDataColumnsTranslated: condMediumDataColumnsTranslated,
			subcoolerMediumDataColumnsTranslated:
				subcoolerMediumDataColumnsTranslated,
			desupMediumData: desupMediumData,
			condMediumData: condMediumData,
			subcoolerMediumData: subcoolerMediumData,
			connectionDataColumnsTranslated: connectionDataColumnsTranslated,
			connectionData: connectionData,
			constructionHeading: t('datasheet.table.heading.constructionDesign'),
			constructionData: constructionData,
			materialHeading: t('datasheet.table.heading.materials'),
			materialData: materialsData,
		},
		datasheetExpandedPropertiesContent: {
			mediumPropertiesHotLiqData: mediumPropertiesHotLiqData,
			mediumPropertiesHotLiqDataColumnsTranslated:
				mediumPropertiesHotLiqDataColumnsTranslated,
			mediumPropertiesHotVapData: mediumPropertiesHotVapData,
			mediumPropertiesHotVapDataColumnsTranslated:
				mediumPropertiesHotVapDataColumnsTranslated,
			mediumPropertiesColdLiqData: mediumPropertiesColdLiqData,
			mediumPropertiesColdLiqDataColumnsTranslated:
				mediumPropertiesColdLiqDataColumnsTranslated,
			mediumPropertiesColdVapData: mediumPropertiesColdVapData,
			mediumPropertiesColdVapDataColumnsTranslated:
				mediumPropertiesColdVapDataColumnsTranslated,
		},
		drawing: {
			liquidSideMultiCondenser: liquidSideMultiCondenser,
			multiPassCombo: multiPassCombo,
			shellDiameterValue: shellDiam,
			shellDiameterLabel: `Shell Φ${shellDiam}`,
			flowDirection: flowDirection,
		},
	}
	return payload
}

const ExportDatasheetToExcel: React.FunctionComponent<Props> = props => {
	const {exportId, calculation, offerDetail, mFilesInfo, positionId, key} =
		props
	const {
		data: heatExchangerData,
		isLoading: isHeatExchangerLoading,
		isError: isHeatExchangerError,
	} = useHeatExchanger(
		calculation.heatExchangerType,
		calculation.heatExchangerId,
		{
			enabled: calculation.id !== null,
			refetchOnWindowFocus: false,
		},
	)

	const {
		data: dataText,
		isLoading: isDataTextLoading,
		isError: isDataTextError,
	} = useCalculationDataText(positionId, calculation.id)

	const {
		data: position,
		isLoading: isPositionLoading,
		isError: isPositionError,
	} = usePosition(+offerDetail.id, +positionId, {
		query: {isHeatExchangerIncluded: false},
	})

	const [t] = useTranslation()

	return (
		<Menu.Item key={key} {...props}>
			<Button
				disabled={
					isHeatExchangerLoading || isDataTextLoading || isPositionLoading
				}
				type="default"
				block
				style={{border: 'none'}}
				icon={<FileExcelOutlined />}
				onClick={() => {
					if (heatExchangerData.application == ApplicationType.MultiCondenser) {
						requestExcelFromServer(
							heatExchangerData.application,
							exportMultiCondenserToExcel(
								heatExchangerData,
								isHeatExchangerLoading,
								isDataTextLoading,
								isPositionLoading,
								isHeatExchangerError,
								isDataTextError,
								isPositionError,
								offerDetail,
								mFilesInfo,
								position,
								dataText,
								t,
							),
							exportId,
						)
					} else {
						requestExcelFromServer(
							heatExchangerData.application,
							exportNormalCaseToExcel(
								heatExchangerData,
								isHeatExchangerLoading,
								isDataTextLoading,
								isPositionLoading,
								isHeatExchangerError,
								isDataTextError,
								isPositionError,
								offerDetail,
								mFilesInfo,
								position,
								dataText,
								t,
							),
							exportId,
						)
					}
				}}
			>
				{position.name}-{exportId}
			</Button>
		</Menu.Item>
	)
}

export default ExportDatasheetToExcel
