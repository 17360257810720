import {Form, FormInstance} from 'antd'
import React from 'react'
import InletTemInput from '../Condenser/InletTemInput'
// import MediumNameInput from '../Condenser/MediumNameInput'
import OutletTempInput from '../Condenser/OutletTempInput'

interface Props {
	form: FormInstance
}

const CondenserSearchProperties: React.FunctionComponent<Props> = () => {
	return (
		<div>
			<Form.Item
				noStyle
				shouldUpdate={(prevValues, curValues) =>
					prevValues.cboT !== curValues.cboT
				}
			>
				{form => {
					return (
						<InletTemInput form={form as FormInstance} isRequired={false} />
					)
				}}
			</Form.Item>
			<Form.Item
				noStyle
				shouldUpdate={(prevValues, curValues) =>
					prevValues.cboT !== curValues.cboT
				}
			>
				{form => {
					return (
						<OutletTempInput form={form as FormInstance} isRequired={false} />
					)
				}}
			</Form.Item>
			{/* <Form.Item
				noStyle
				dependencies={['mediumSourceHotSide', 'mediumSourceColdSide']}
			>
				{form => {
					return <MediumNameInput form={form as FormInstance} />
				}}
			</Form.Item> */}
		</div>
	)
}
export default CondenserSearchProperties
