import React from 'react'
import {Input} from 'antd'
import {InputProps} from 'antd/lib/input'
import classNames from 'classnames'
import SearchUnderline from './SearchUnderline'

export type InputState = 'default' | 'hot' | 'cold'

interface Props extends InputProps {
	state?: InputState
	isOutput?: boolean
}

type IInputUnderline<P> = React.FunctionComponent<P> & {
	Search: typeof SearchUnderline
}

const InputUnderline: IInputUnderline<Props> = ({
	className,
	state,
	isOutput,
	disabled,
	...props
}) => {
	const prefixCls = 'input-underline'
	const classes = classNames(prefixCls, className, {
		[`${prefixCls}-${state}`]: state,
		[`${prefixCls}-output`]: isOutput,
	})

	return (
		<Input disabled={isOutput || disabled} {...props} className={classes} />
	)
}

InputUnderline.defaultProps = {
	state: 'default',
	isOutput: false,
}

InputUnderline.Search = SearchUnderline

export default InputUnderline
