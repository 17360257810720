import React, {FC} from 'react'
import {Drawing} from '../model/Calculation'
import {DrawingState} from '../model/CalculationEnums'
import {
	LoadingOutlined,
	CheckCircleFilled,
	ExclamationCircleOutlined,
	CloseCircleOutlined,
	ClockCircleOutlined,
} from '@ant-design/icons'
import {useDrawingStatus} from '../state/drawing'
import {client} from '../utils/apiClient'

interface Props {
	drawing: Drawing
}

const DrawingStatusLine: FC<Props> = ({drawing}) => {
	const [downloadLabel, setDownloadLabel] = React.useState(<a>[download]</a>)
	if (drawing == undefined) return <></>
	const {
		data: drawingStatus,
		isLoading,
		isError,
	} = useDrawingStatus(drawing.id, {
		refetchInterval: 2000,
	})
	if (isLoading || isError)
		return (
			<li>
				Fetching drawing status <LoadingOutlined style={{marginLeft: '16px'}} />
			</li>
		)
	const pickAnIcon = (drawing, name) => {
		switch (drawing.state) {
			case DrawingState.Completed:
				return (
					<>
						<CheckCircleFilled style={{color: '#52c41a', marginLeft: '16px'}} />{' '}
						&nbsp; (Complete) &nbsp;{' '}
						<span
							onClick={() => {
								setDownloadLabel(
									<span>
										(downloading)
										<LoadingOutlined style={{marginLeft: '16px'}} />
									</span>,
								)
								client(`integration/drawing/${drawing.id}/download`, {
									responseType: 'blob',
									isRegionBound: false,
									isDownload: true,
									filename: `drawing_${name}_${
										new Date(drawing.received).toLocaleDateString() +
										'_' +
										new Date(drawing.received).toLocaleTimeString()
									}.zip`,
								}).then(() => {
									setDownloadLabel(<a>[download]</a>)
								})
							}}
						>
							{downloadLabel}
						</span>
						&nbsp;{' '}
					</>
				)
			case DrawingState.New:
				return (
					<>
						<ClockCircleOutlined style={{marginLeft: '16px'}} /> &nbsp;
						(Queue)&nbsp;{' '}
					</>
				)
			case DrawingState.Running:
				return (
					<>
						<LoadingOutlined style={{marginLeft: '16px'}} /> &nbsp;
						(Running)&nbsp;{' '}
					</>
				)
			case DrawingState.Failed:
				return (
					<>
						<ExclamationCircleOutlined
							style={{color: '#8B8000', marginLeft: '16px'}}
						/>{' '}
						&nbsp; (Fail - retrying)&nbsp;{' '}
					</>
				)
			case DrawingState.PermanentlyFailed:
				return (
					<>
						<CloseCircleOutlined style={{color: 'red', marginLeft: '16px'}} />
						&nbsp; (Error)&nbsp;{' '}
					</>
				)

			default:
				break
		}
	}
	const requestedTime = new Date(drawingStatus.received)
	requestedTime.setHours(requestedTime.getHours() - 3)
	return (
		<li key={drawingStatus.id} style={{fontSize: '16px'}}>
			{drawing.name}
			{pickAnIcon(drawingStatus, drawing.name)}
			{'[request at: '}
			{requestedTime.toDateString() + ' ' + requestedTime.toTimeString()}
			{']'}
		</li>
	)
}

export default DrawingStatusLine
