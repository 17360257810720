import React from 'react'
import {message, Dropdown, Menu} from 'antd'
import {FilePdfOutlined} from '@ant-design/icons'
import Button from '../components/Button'
import {PrintData, transformComponentToCanvas} from '../utils/worker'
import {jsPDF} from 'jspdf'

interface Props {
	offerPrintInfo: PrintData[]
	calculationPrintInfo: PrintData[]
	fileName: string
	gettingData?: boolean
}

const PdfGenerateButton: React.FunctionComponent<Props> = ({
	calculationPrintInfo,
	offerPrintInfo,
	fileName,
	gettingData,
}) => {
	const [isLoading, setIsLoading] = React.useState(false)

	React.useEffect(() => {
		if (gettingData) {
			setIsLoading(true)
		} else {
			setIsLoading(false)
		}
	}, [gettingData])

	const printPDF = async (data: PrintData[]) => {
		const hide = message.loading('Exporting...', 0)
		setIsLoading(true)
		const canvasArray = await transformComponentToCanvas(data)
		const pdf = new jsPDF('p', 'mm', 'a4', true)
		pdf.setFillColor('#FFFFFF')
		canvasArray.forEach(canvas => {
			const imgProps = pdf.getImageProperties(canvas)
			const pdfWidth = pdf.internal.pageSize.getWidth()
			const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

			pdf.addImage(canvas, 'jpeg', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST')

			const pages = pdf.getNumberOfPages()

			if (pages < data.length) {
				pdf.addPage()
			}
		})
		pdf.save(`${fileName}.pdf`)
		hide()
		setIsLoading(false)
	}

	return (
		// <Button disabled={isLoading} onClick={printPDF}>
		// 	Download PDF ({printData.length})
		// </Button>
		<Dropdown
			overlay={
				<Menu>
					{offerPrintInfo.length > 0 ? (
						<>
							<Menu.Item key={'all'}>
								<Button
									type="default"
									block
									style={{border: 'none', textAlign: 'left'}}
									icon={<FilePdfOutlined />}
									disabled={isLoading}
									onClick={() =>
										printPDF([...offerPrintInfo, ...calculationPrintInfo])
									}
								>
									All ({[...offerPrintInfo, ...calculationPrintInfo].length})
								</Button>
							</Menu.Item>
							<Menu.Item key={'offer'}>
								<Button
									type="default"
									block
									style={{border: 'none', textAlign: 'left'}}
									icon={<FilePdfOutlined />}
									disabled={isLoading}
									onClick={() => printPDF(offerPrintInfo)}
								>
									Offer
								</Button>
							</Menu.Item>
						</>
					) : null}

					<Menu.Item key={'datasheet'}>
						<Button
							type="default"
							block
							style={{border: 'none', textAlign: 'left'}}
							icon={<FilePdfOutlined />}
							disabled={isLoading}
							onClick={() => {
								printPDF(calculationPrintInfo)
							}}
						>
							Datasheet
						</Button>
					</Menu.Item>
				</Menu>
			}
			placement="bottomLeft"
		>
			<Button>Download PDF</Button>
		</Dropdown>
	)
}

export default PdfGenerateButton
