import React from 'react'
import {Form, FormInstance} from 'antd'
import InputNumberUnderline from '../InputNumberUnderline'
import TemperatureUnit from '../SharedFormItem/TemperatureUnit'
import {usePrevious, useConvertCommaToDot} from '../../utils/hookUtils'
import {convertTemperature} from '../../utils/utils'

interface Props {
	form: FormInstance
	isRequired?: boolean
}

const OutletTempInput: React.FunctionComponent<Props> = ({
	form,
	isRequired,
}) => {
	const {getFieldValue, setFieldsValue} = form
	const cboT = getFieldValue('cboT')
	const outletTemperatureLiquid = getFieldValue('outletTemperatureLiquid')
	const subCooledTemperature = getFieldValue('subCooledTemperature')
	const prevCboT = usePrevious(cboT)

	React.useEffect(() => {
		const liquid = useConvertCommaToDot(outletTemperatureLiquid)
		setFieldsValue({outletTemperatureLiquid: liquid})
		const sub = useConvertCommaToDot(subCooledTemperature)
		setFieldsValue({subCooledTemperature: sub})
	}, [outletTemperatureLiquid, subCooledTemperature])

	React.useEffect(() => {
		setFieldsValue({
			subCooledTemperature: convertTemperature(
				subCooledTemperature,
				prevCboT,
				cboT,
			),
		})
		setFieldsValue({
			outletTemperatureLiquid: convertTemperature(
				outletTemperatureLiquid,
				prevCboT,
				cboT,
			),
		})
	}, [cboT, setFieldsValue, prevCboT])

	return (
		<Form.Item label="Outlet. Temp">
			<Form.Item
				name={['subCooledTemperature']}
				style={{display: 'inline-block'}}
				rules={[{required: isRequired == undefined ? true : isRequired}]}
			>
				<InputNumberUnderline state="hot" />
			</Form.Item>
			<Form.Item
				name={['outletTemperatureLiquid']}
				style={{display: 'inline-block'}}
			>
				<InputNumberUnderline state="cold" />
			</Form.Item>
			<TemperatureUnit />
		</Form.Item>
	)
}

export default OutletTempInput
