import {DesignClassification} from '../model/CalculationEnums'

export const A4HeightInPixels = 2127
export const PdfOfferInfoMaxHeightInPixel = 1680

export const defaultOfferGreeting =
	'Dear Sirs,\n' +
	'\n' +
	'We have a pleasure of offering You our Vahterus PSHE Plate & Shell fully welded heat exchanger(s) according to the enclosed specification.'

export const defaultProposalOfferGreeting =
	'Dear Sirs,\n' +
	'\n' +
	'We have a pleasure of offering You our Vahterus PSHE Plate & Shell fully welded heat exchanger(s) according to the enclosed specification.'

export const defaultOfferDeliveryTime =
	'XX weeks from received order (Ex Works),  delivery time estimated on today’s factory loading, delivery times will be confirmed at time of actual order.'

export const defaultTermsAndConditions = 'Orgalim S2022'

export const defaultOfferValidity = '1 month'

export const defaultProposalOfferValidity =
	'Price indication only. Due to current situation with limited material availability the price and delivery \n' +
	'time will be checked closer to the actual order date'

export const defaultTermsOfWarranty =
	'12 months from delivery (Ex Works, Orgalim S2022)'

export const getDefaultManufacturingText = (
	designClassification: DesignClassification,
) => {
	let text = ''
	switch (designClassification) {
		case DesignClassification.PED:
			text = 'PED2014/68/EU'
			break
		case DesignClassification.U_STAMP:
			text = 'ASME U-Stamp'
			break
		case DesignClassification.WITHOUTU_STAMP:
			text = 'ASME Without U-Stamp'
			break
		case DesignClassification.SELO:
			text = 'SELO'
			break
		case DesignClassification.ABS:
			text = 'American Bureau of Shipping'
			break
		case DesignClassification.BV:
			text = 'Bureau Veritas'
			break
		case DesignClassification.DNV:
			text = 'DNV'
			break
		case DesignClassification.CCS:
			text = 'China Classification Society'
			break
		case DesignClassification.KRS:
			text = 'Korean Register of Shipping'
			break
		case DesignClassification.LR:
			text = "Lloyd's Register"
			break
		case DesignClassification.NK:
			text = 'ClassNK'
			break
		case DesignClassification.Rina:
			text = 'RINA'
			break
		default:
			text = 'PED2014/68/EU'
			break
	}
	return text
}

export const getDefaultOfferNote = (responsiblePersonName: string) => {
	return (
		'The conditions and price are subject to change in case of any alterations in the above mentioned unit/s.\n' +
		"Vahterus Oy' s liability for defects is limited to the price of the product.\n" +
		'The product shall remain the property of Vahterus Oy until fully paid.\n' +
		'\n' +
		'We hope our offer fulfills your requirements and I will contact you in the near future to discuss, however, should you have any questions in the meantime please do not hesitate to contact us.\n' +
		'\n' +
		'Kind regards, \n' +
		responsiblePersonName +
		'\n' +
		'Vahterus Oy\n' +
		'\n' +
		'Enclosures: Thermotechnical datasheet(s) + drawing(s)'
	)
}

export const getDefaultProposalOfferNote = (responsiblePersonName: string) => {
	return (
		'The conditions and price are subject to change in case of any alterations in the above mentioned unit/s.\n' +
		"Vahterus Oy' s liability for defects is limited to the price of the product.\n" +
		'The product shall remain the property of Vahterus Oy until fully paid.\n' +
		'\n' +
		'We hope our offer fulfills your requirements and I will contact you in the near future to discuss, however, should you have any questions in the meantime please do not hesitate to contact us.\n' +
		'\n' +
		'Kind regards, \n' +
		responsiblePersonName +
		'\n' +
		'Vahterus Oy\n' +
		'\n' +
		'Enclosures: Thermotechnical datasheet(s) + drawing(s)'
	)
}

export const getDefaultDocumentList = (
	lang,
	designClassification: DesignClassification,
) => {
	let text = []
	switch (designClassification) {
		case DesignClassification.PED:
			if (lang == 'fi') {
				text = [
					'EC- Vaatimustenmukaisuusvakuutus',
					'Käyttö- ja huolto-ohje',
					'Mittapiirustus',
					'Tekninen datalehti',
				]
			} else if (lang == 'de') {
				text = [
					'EG-Konformitätserklärung',
					'Betriebsanleitung',
					'Zeichnung',
					'Technisches Datenblatt',
				]
			} else if (lang == 'da') {
				text = [
					'EC-Declaration of Conformity',
					'Instruction and safety manual',
					'Dimensional Drawing',
					'Teknisk dataliste',
				]
			} else if (lang == 'cn') {
				text = [
					'EC-Declaration of Conformity 欧盟符合性声明书',
					'Instruction and safety manual操作指导手册',
					'Dimensional Drawing 尺寸图',
					'Technical Datasheet 热工计算书',
				]
			} else {
				text = [
					'EC-Declaration of Conformity',
					'Instruction and safety manual',
					'Dimensional Drawing',
					'Technical Datasheet',
				]
			}

			break
		case DesignClassification.U_STAMP:
			if (lang == 'fi') {
				text = [
					'General Arrangement Drawing',
					'Manufacturer Data Report',
					'Operating & Maintenance Manual',
					'Tekninen datalehti',
				]
			} else if (lang == 'de') {
				text = [
					'General Arrangement Drawing',
					'Manufacturer Data Report',
					'Operating & Maintenance Manual',
					'Technisches Datenblatt',
				]
			} else if (lang == 'da') {
				text = [
					'General Arrangement Drawing',
					'Manufacturer Data Report',
					'Operating & Maintenance Manual',
					'Teknisk dataliste',
				]
			} else if (lang == 'cn') {
				text = [
					'General Arrangement Drawing 总布置图',
					'Manufacturer Data Report 制造商数据报告',
					'Operating & Maintenance Manual 操作和维护手册',
					'Technical Datasheet 热工计算书',
				]
			} else {
				text = [
					'General Arrangement Drawing',
					'Manufacturer Data Report',
					'Operating & Maintenance Manual',
					'Technical Datasheet',
				]
			}
			break
		case DesignClassification.WITHOUTU_STAMP:
			if (lang == 'fi') {
				text = [
					'General Arrangement Drawing',
					'Operating & Maintenance Manual',
					'Tekninen datalehti',
				]
			} else if (lang == 'de') {
				text = [
					'General Arrangement Drawing',
					'Operating & Maintenance Manual',
					'Technisches Datenblatt',
				]
			} else if (lang == 'da') {
				text = [
					'General Arrangement Drawing',
					'Operating & Maintenance Manual',
					'Teknisk dataliste',
				]
			} else if (lang == 'cn') {
				text = [
					'General Arrangement Drawing 总布置图',
					'Operating & Maintenance Manual 操作和维护手册',
					'Technical Datasheet 热工计算书',
				]
			} else {
				text = [
					'General Arrangement Drawing',
					'Operating & Maintenance Manual',
					'Technical Datasheet',
				]
			}
			break

		default:
			if (lang == 'fi') {
				text = [
					'EC- Vaatimustenmukaisuusvakuutus',
					'Käyttö- ja huolto-ohje',
					'Mittapiirustus',
					'Tekninen datalehti',
				]
			} else if (lang == 'de') {
				text = [
					'EC-Declaration of Conformity',
					'Betriebsanleitung',
					'Zeichnung',
					'Technisches Datenblatt',
				]
			} else if (lang == 'da') {
				text = [
					'EC-Declaration of Conformity',
					'Instruction and safety manual',
					'Dimensional Drawing',
					'Teknisk dataliste',
				]
			} else if (lang == 'cn') {
				text = [
					'EC-Declaration of Conformity 欧盟符合性声明书',
					'Instruction and safety manual操作指导手册',
					'Dimensional Drawing 尺寸图',
					'Technical Datasheet 热工计算书',
				]
			} else {
				text = [
					'EC-Declaration of Conformity',
					'Instruction and safety manual',
					'Dimensional Drawing',
					'Technical Datasheet',
				]
			}
			break
	}
	return text
}

export interface OfferInfoField {
	labelI18nKey?: string
	name: string
	title?: string
	inputType?: string
	inputClassName?: string
	valuePropName?: string
	textAreaProps?: {
		wrapperCol?: {
			span: number
		}
		rows?: number
	}
}

export const getDefaultOfferInfoFields = (): OfferInfoField[] => [
	{
		labelI18nKey: 'designManufacturing',
		name: 'designManufacturing',
		title: null,
	},
	{
		labelI18nKey: 'documents',
		name: 'documents',
		inputType: 'documents',
		title: null,
		valuePropName: 'value',
	},
	{
		labelI18nKey: 'Remarks',
		name: 'deviations',
		title: null,
		inputType: 'textArea',
		textAreaProps: {
			wrapperCol: {
				span: 24,
			},
			rows: 4,
		},
	},
	{
		labelI18nKey: 'deliveryTime',
		name: 'deliveryTime',
		title: null,
	},
	{
		labelI18nKey: 'termsOfDelivery',
		name: 'termsOfDelivery',
		title: null,
	},
	{
		labelI18nKey: 'termsOfPayment',
		name: 'termsOfPayment',
		title: null,
	},
	{
		labelI18nKey: 'termsOfWarranty',
		name: 'termsOfWarranty',
		title: null,
	},
	{
		labelI18nKey: 'offerValidity',
		name: 'offerValidity',
		title: null,
	},
	{
		labelI18nKey: 'termsAndConditions',
		name: 'termsAndConditions',
		title: null,
	},
	{
		labelI18nKey: '',
		name: 'offerNote',
		title: null,
		inputType: 'textArea',
		inputClassName: 'offer-info-form__note',
		textAreaProps: {
			wrapperCol: {
				span: 24,
			},
			rows: 16,
		},
	},
]

export const getDefaultOfferInfoCustomField = (): OfferInfoField => ({
	labelI18nKey: '',
	name: '',
	title: null,
	inputType: 'textArea',
	textAreaProps: {
		wrapperCol: {
			span: 24,
		},
		rows: 4,
	},
})
