import {message, Table} from 'antd'
import {ColumnsType} from 'antd/lib/table'
import React from 'react'
import {Application} from '../../model/Application'
import {endPlateTypeLabels} from '../../model/enumLabels'
import {Link} from 'react-router-dom'
import {useOffersByAdvancedSearch} from '../../state/offers'
import {useRecoilValue} from 'recoil'
import {advancedSearchQueryState} from '../../state/Atoms'

const SearchResultsTable: React.FunctionComponent = () => {
	const query = useRecoilValue(advancedSearchQueryState)

	const {
		data: offers,
		isError,
		isLoading,
		error,
		refetch,
	} = useOffersByAdvancedSearch({
		query: query,
		enabled: false,
	})
	if (isError) {
		console.log('ERROR', error)
		message.error({
			content: 'Failed to find a matching design case.',
		})
	}

	React.useEffect(() => {
		console.log('query', query)
		if (query != null) refetch()
	}, [query])

	const columns: ColumnsType<Application> = [
		{
			title: 'POSITION',
			dataIndex: 'position',
			render: function displayPosition(text, record) {
				return (
					<Link to={`/workspace/${record.offerId}`}>{record.offerNumber}</Link>
				)
			},
		},
		{
			title: 'MODEL',
			dataIndex: 'model',
			key: 'model',
			render: function displayModel(text, record) {
				return (
					<span>
						{record.psheType} {endPlateTypeLabels[record.endPlateType]}
					</span>
				)
			},
		},
		{
			title: 'CALCULATION NAME',
			dataIndex: 'name',
			key: 'name',
			render: function displayCalculationName(text, record) {
				return (
					<Link
						to={`/workspace/${record.offerId}/sizing/${record.positionId}/calculations/${record.id}`}
					>
						{record.item}
					</Link>
				)
			},
		},
		{
			title: 'CUSTOMER',
			dataIndex: 'customerName',
			render: function displayCustomerName(text, record) {
				return <span>{record.customer}</span>
			},
		},
		{
			title: 'REFERENCE',
			dataIndex: 'reference',
			render: function displayReference(text, record) {
				return (
					<span>
						{record.reference ? (
							record.reference
						) : (
							<span style={{color: 'rgba(0,0,0,0.5)'}}>N/a</span>
						)}
					</span>
				)
			},
		},
		{title: 'PRICE', dataIndex: 'totalPrice', key: 'totalPrice'},
	]

	return (
		<>
			<Table
				loading={isLoading}
				className="search-results-table"
				columns={columns}
				dataSource={
					offers
						? offers.map(offer => ({
								key: offer.id.toString(),
								...offer,
						  }))
						: []
				}
				pagination={{pageSize: 20, showSizeChanger: false}}
			/>
		</>
	)
}
export default SearchResultsTable
