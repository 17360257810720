import {useEffect, useState, useRef} from 'react'
import {precise} from './utils'

export function useScroll() {
	const [lastScrollTop, setLastScrollTop] = useState(0)
	const [bodyOffset, setBodyOffset] = useState(
		document.body.getBoundingClientRect(),
	)
	const [scrollY, setScrollY] = useState(bodyOffset.top)
	const [scrollX, setScrollX] = useState(bodyOffset.left)
	const [scrollDirection, setScrollDirection] = useState(null)

	const listener = () => {
		setBodyOffset(document.body.getBoundingClientRect())
		setScrollY(-bodyOffset.top)
		setScrollX(bodyOffset.left)
		setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up')
		setLastScrollTop(-bodyOffset.top)
	}

	useEffect(() => {
		window.addEventListener('scroll', listener)
		return () => {
			window.removeEventListener('scroll', listener)
		}
	})

	return {
		scrollY,
		scrollX,
		scrollDirection,
	}
}

export function useConvertCommaToDot(value, isOutput = false) {
	let newValue = value
	// eslint-disable-next-line no-useless-escape
	if (!value || (typeof value === 'string' && value.match(/[,\.]/g))) {
		if (typeof value === 'string') {
			newValue = value.replace(',', '.')
		}
	}
	newValue = newValue || newValue === 0 ? newValue : ''
	return isOutput ? precise(parseFloat(newValue)) : newValue
}

export function usePrevious(value) {
	const ref = useRef(value)

	useEffect(() => {
		ref.current = value
	}, [value])

	return ref.current
}

export function useClickOutside(callback) {
	const callbackRef = useRef(null)
	const innerRef = useRef(null)

	useEffect(() => {
		callbackRef.current = callback
	})
	useEffect(() => {
		document.addEventListener('click', handleClick)
		return () => document.removeEventListener('click', handleClick)
		function handleClick(e) {
			if (
				innerRef.current &&
				callbackRef.current &&
				!innerRef?.current?.contains(e.target)
			)
				callbackRef.current(e)
		}
	}, [])

	return innerRef
}
