import html2canvas from 'html2canvas'

export function expensive(time) {
	const start = Date.now()
	let count = 0
	while (Date.now() - start < time) count++
	return count
}

export type PrintData = {htmlId: string; fileName: string}
export const transformComponentToCanvas = async (printData: PrintData[]) => {
	const canvasArray = await Promise.all(
		printData.map(async ({htmlId}) => {
			// We call the callback for each entry
			const id = htmlId
			const canvas = await html2canvas(document.getElementById(id), {
				scale: 1.2,
			}).then(function (canvas) {
				canvas.toDataURL('image/jpeg', 0.8)
				return canvas
			})
			return canvas
		}),
	)
	return canvasArray
}
