import React from 'react'
import Drawing from '../components/DataSheet/Drawing'
import {Input, Switch, Checkbox} from 'antd'
import {
	LiquidSide,
	NumberOfPasses,
	NumberOfShellNozzles,
} from '../model/CalculationEnums'
import {Select} from 'antd'
import {numberOfShellsLabels} from '../model/enumLabels'
import {enumToValues} from '../utils/utils'

const {Option} = Select

const DrawingPage = () => {
	const [mockedArrowPlateSide, setMockedArrowPlateSide] = React.useState('lrxx')
	const [mockedArrowShellSide, setMockedArrowShellSide] = React.useState('xddx')
	const [endPlateType, setEndPlateType] = React.useState(0)
	const [numberOfPassesColdSide, setNumberOfPassesColdSide] = React.useState(
		NumberOfPasses._1Pass,
	)
	const [numberOfPassesHotSide, setNumberOfPassesHotSide] = React.useState(
		NumberOfPasses._1Pass,
	)
	const [isPlateHotSide, setPlateHotSide] = React.useState(false)
	const [combinedC, setCombinedC] = React.useState(0)
	const [appType, setAppType] = React.useState('1')
	const [numberOfInlet, setNumberOfInlet] = React.useState(
		NumberOfShellNozzles._1pc,
	)
	const [numberOfOutlet, setNumberOfOutlet] = React.useState(
		NumberOfShellNozzles._1pc,
	)
	const [numberOfOutletUp, setNumberOfOutletUp] = React.useState(
		NumberOfShellNozzles._1pc,
	)

	const handleNumberOfPassesColdSide = value => {
		setNumberOfPassesColdSide(value)
	}

	const handleNumberOfPassesHotSide = value => {
		setNumberOfPassesHotSide(value)
	}

	const handleArrowPlateSide = e => {
		setMockedArrowPlateSide(e.target.value)
	}

	const handleArrowShellSide = e => {
		setMockedArrowShellSide(e.target.value)
	}

	const handleHotColdChange = e => {
		setPlateHotSide(e)
	}

	const handleAppChange = value => {
		setAppType(value)
	}

	const handleNumberOfInlet = value => {
		setNumberOfInlet(value)
	}

	const handleNumberOfOutlet = value => {
		setNumberOfOutlet(value)
	}

	const handleNumberOfOutletUp = value => {
		setNumberOfOutletUp(value)
	}

	const handleCombinedCChanged = e => {
		if (e.target.checked) {
			setCombinedC(1)
		} else {
			setCombinedC(0)
		}
	}

	const handleEndPlateTypeChange = value => {
		setEndPlateType(value)
	}

	const renderDrawing = () => {
		switch (appType) {
			case '1':
				return (
					<div>
						<Drawing
							isPlateHotSide={isPlateHotSide}
							arrowsPlateSide={mockedArrowPlateSide}
							arrowsShellSide={mockedArrowShellSide}
							endPlateType={endPlateType}
							numberOfPassesColdSide={numberOfPassesColdSide}
							numberOfPassesHotSide={numberOfPassesHotSide}
							liquidSide={LiquidSide.PlateSide} //mock
							numberOfPassesLiquidSide={1} //mock
						/>
					</div>
				)
			case '2':
				return (
					<div>
						<Drawing.VentCondenser
							isPlateHotSide={isPlateHotSide}
							arrowsPlateSide={mockedArrowPlateSide}
							numberOfInlet={numberOfInlet}
							numberOfOutlet={numberOfOutlet}
							numberOfOutletUp={numberOfOutletUp}
							endPlateType={endPlateType}
						/>
					</div>
				)
			case '3':
				return (
					<div>
						<Drawing.Combined
							isPlateHotSide={isPlateHotSide}
							arrowsPlateSide={mockedArrowPlateSide}
							numberOfInlet={numberOfInlet}
							numberOfOutlet={numberOfOutlet}
							combinedC={combinedC}
							endPlateType={endPlateType}
						/>
					</div>
				)
			case '4':
				return (
					<div>
						<Drawing.Combined
							isPlateHotSide={isPlateHotSide}
							arrowsPlateSide={mockedArrowPlateSide}
							numberOfInlet={numberOfInlet}
							numberOfOutlet={numberOfOutlet}
							combinedC={combinedC}
							endPlateType={endPlateType}
						/>
					</div>
				)
		}
	}

	return (
		<div className="main-content main-content--offer-documents page-offer-documents">
			<div style={{marginBottom: 24}}>
				<Select
					defaultValue="1"
					style={{width: 200}}
					onChange={handleAppChange}
				>
					<Option value="1">Basic Case</Option>
					<Option value="2">Vent Condenser</Option>
					<Option value="3">Evaporator Combined</Option>
					<Option value="4">Cascade Combined</Option>
				</Select>
			</div>
			<div style={{marginBottom: 24}}>
				<Select
					defaultValue={0}
					style={{width: 200}}
					onChange={handleEndPlateTypeChange}
				>
					<Option value={1}>OpenableVersion</Option>
					<Option value={6}>OpenableGirthFlange</Option>
					<Option value={7}>OpenableGirthFlangeDouble</Option>
					<Option value={4}>OpenableDouble</Option>
					<Option value={0}>None</Option>
				</Select>
			</div>
			<div style={{marginBottom: 24}}>
				<Select
					defaultValue={NumberOfPasses._1Pass}
					style={{width: 200}}
					onChange={handleNumberOfPassesHotSide}
				>
					{enumToValues(NumberOfPasses, true).map(key => (
						<Option key={key} value={key}>
							{key} pass(es) hot side
						</Option>
					))}
				</Select>
			</div>
			<div style={{marginBottom: 24}}>
				<Select
					defaultValue={NumberOfPasses._1Pass}
					style={{width: 200}}
					onChange={handleNumberOfPassesColdSide}
				>
					{enumToValues(NumberOfPasses, true).map(key => (
						<Option key={key} value={key}>
							{key} pass(es) cold side
						</Option>
					))}
				</Select>
			</div>
			<div
				style={{marginBottom: 24, marginLeft: 0}}
				className="hot-cold-inputs"
			>
				<p style={{marginRight: 24, marginLeft: 0}}>Hot side on </p>
				<Switch
					className="hot-switch"
					checkedChildren="Plate"
					unCheckedChildren="Shell"
					onChange={handleHotColdChange}
				/>
			</div>
			<div style={{marginBottom: 24, width: 200}}>
				arrowsPlateSide
				<Input value={mockedArrowPlateSide} onChange={handleArrowPlateSide} />
			</div>
			<div style={{marginBottom: 24, width: 200}}>
				arrowsShellSide
				<Input value={mockedArrowShellSide} onChange={handleArrowShellSide} />
			</div>
			{appType === '2' || appType === '3' || appType === '4' ? (
				<>
					<div style={{marginBottom: 24}}>
						<p style={{marginRight: 24, marginLeft: 0}}>Shell side/Inlet </p>
						<Select
							defaultValue={NumberOfShellNozzles._1pc}
							style={{width: 200}}
							onChange={handleNumberOfInlet}
						>
							{[NumberOfShellNozzles._1pc, NumberOfShellNozzles._2pc].map(
								key => {
									return (
										<Option key={key} value={key}>
											{numberOfShellsLabels[key]}
										</Option>
									)
								},
							)}
						</Select>
					</div>
					<div style={{marginBottom: 24}}>
						<p style={{marginRight: 24, marginLeft: 0}}>Shell/outlet</p>
						<Select
							defaultValue={NumberOfShellNozzles._1pc}
							style={{width: 200}}
							onChange={handleNumberOfOutlet}
						>
							{[NumberOfShellNozzles._1pc, NumberOfShellNozzles._2pc].map(
								key => {
									return (
										<Option key={key} value={key}>
											{numberOfShellsLabels[key]}
										</Option>
									)
								},
							)}
						</Select>
					</div>
				</>
			) : null}
			{appType === '3' || appType === '4' ? (
				<div style={{marginBottom: 24}}>
					<Checkbox onChange={handleCombinedCChanged}>CombinedC</Checkbox>
				</div>
			) : null}
			{appType === '2' ? (
				<div style={{marginBottom: 24}}>
					<p style={{marginRight: 24, marginLeft: 0}}>Shell/outlet-Up </p>
					<Select
						defaultValue={NumberOfShellNozzles._1pc}
						style={{width: 200}}
						onChange={handleNumberOfOutletUp}
					>
						{[NumberOfShellNozzles._1pc, NumberOfShellNozzles._2pc].map(key => {
							return (
								<Option key={key} value={key}>
									{numberOfShellsLabels[key]}
								</Option>
							)
						})}
					</Select>
				</div>
			) : null}

			{renderDrawing()}
		</div>
	)
}

export default DrawingPage
