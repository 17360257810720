import React from 'react'
import {UnitTemperatureLMTDOption} from '../../model/CalculationEnums'
import SelectUnderline from '../SelectUnderline'
import {enumToValues} from '../../utils/utils'
import {Form, Select} from 'antd'
import {temUnitLabels} from '../../model/enumLabels'

const {Option} = Select

interface Props {
	label?: string
}

const TemperatureUnit: React.FunctionComponent<Props> = ({label}) => {
	return (
		<Form.Item
			name={['cboT']}
			style={{display: 'inline-block'}}
			rules={[{required: true}]}
			label={label}
		>
			<SelectUnderline placeholder="Unit">
				{enumToValues(UnitTemperatureLMTDOption, true).map(key => (
					<Option value={key} key={key}>
						{temUnitLabels[key]}
					</Option>
				))}
			</SelectUnderline>
		</Form.Item>
	)
}

export default TemperatureUnit
