import React from 'react'
import {Form, FormInstance} from 'antd'
import InputNumberUnderline from '../InputNumberUnderline'
import TemperatureUnit from '../SharedFormItem/TemperatureUnit'
import {usePrevious, useConvertCommaToDot} from '../../utils/hookUtils'
import {convertTemperature} from '../../utils/utils'

interface Props {
	form: FormInstance
	isRequired?: boolean
}

const OutletTempInput: React.FunctionComponent<Props> = ({
	form,
	isRequired,
}) => {
	const {getFieldValue, setFieldsValue} = form
	const cboT = getFieldValue('cboT')
	const outletTemperatureCondSide = getFieldValue('outletTemperatureCondSide')
	const prevCboT = usePrevious(cboT)

	React.useEffect(() => {
		const value = useConvertCommaToDot(outletTemperatureCondSide)
		setFieldsValue({outletTemperatureCondSide: value})
	}, [outletTemperatureCondSide])

	React.useEffect(() => {
		setFieldsValue({
			outletTemperatureCondSide: convertTemperature(
				outletTemperatureCondSide,
				prevCboT,
				cboT,
			),
		})
	}, [cboT, setFieldsValue, prevCboT])

	return (
		<Form.Item label="Outlet. Temp" className="required-group">
			<Form.Item
				name={['outletTemperatureCondSide']}
				style={{display: 'inline-block'}}
				rules={[{required: isRequired == undefined ? true : isRequired}]}
			>
				<InputNumberUnderline state="hot" />
			</Form.Item>
			<div style={{flex: 1}}>&nbsp;</div>
			<TemperatureUnit />
		</Form.Item>
	)
}

export default OutletTempInput
