import React from 'react'
import {Select} from 'antd'
import {SelectProps, SelectValue} from 'antd/lib/select'
import classNames from 'classnames'

export type InputState = 'default' | 'hot' | 'cold'

interface Props extends SelectProps<SelectValue> {
	state?: InputState
	isOutput?: boolean
}

const SelectUnderline: React.FunctionComponent<Props> = ({
	state,
	isOutput,
	className,
	disabled,
	children,
	...props
}) => {
	const prefixCls = 'select-underline'
	const classes = classNames(prefixCls, className, {
		[`${prefixCls}-${state}`]: state,
		[`${prefixCls}-output`]: isOutput,
	})

	return (
		<Select className={classes} disabled={isOutput || disabled} {...props}>
			{children}
		</Select>
	)
}

export default SelectUnderline
